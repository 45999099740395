import React, { useEffect } from "react";
import Header from "./components/Header";
import EventBanner from "./components/EventBanner";
import GoodsGroup from "./components/GoodsGroup";
import GoodsListBest from "./components/GoodsListBest";
import GoodsListMDPick from "./components/GoodsListMDPick";
import KumbangLink from "./components/KumbangLink";
import Footer from "components/templates/Footer";
import DefaultLayout from "components/layouts/DefaultLayout";
import BottomSheetLayout from "./components/BottomSheetLayout";
import BottomPopup from "./components/BottomPopup";
import { useQuery, useQueryClient } from "react-query";
import { getMainGoodsList } from "services/apis";
import GoodsWatch from "./components/GoodsWatch";
import styled from "styled-components";
import colors from "assets/styles/color";
import { Loading } from "components";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "features/loading/loadingSlice";

const Home = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const totalList = queryClient.getQueryData("goodsAllList");

  const { data = [], isLoading } = useQuery(
    "mainGoods",
    () => {
      return getMainGoodsList().then(({ data: { success, data } }) => {
        if (success) {
          let goods = data.map((group) => {
            let arr = [];
            group.goods.forEach(
              (item) =>
                totalList?.goods.find(
                  (list) => list.goodsId === item.goodsId,
                ) &&
                arr.push(
                  totalList?.goods.find(
                    (list) => list.goodsId === item.goodsId,
                  ),
                ),
            );
            return {
              ...group,
              goods: arr,
            };
          });

          return {
            goodsWatch:
              goods.find((item) => item.goodsGroupName === "위클리특가") || {},
            bestGoods:
              goods.find((item) => item.goodsGroupName === "BEST 상품") || {},
            mdGoods:
              goods.find((item) => item.goodsGroupName === "MD 추천 상품") ||
              {},
            recommandGoods:
              goods.find((item) => item.goodsGroupName === "추천상품") || {},
          };
        }
      });
    },
    {
      enabled: totalList && totalList?.goods.length !== 0,
    },
  );

  return (
    <DefaultLayout headerVisible={false} navigationVisible={true}>
      <Header />
      <EventBanner />
      <GoodsGroup />
      {data?.goodsWatch?.goods?.length !== 0 && (
        <GoodsWatch goodsData={data.goodsWatch || {}} />
      )}
      <GoodsListBest
        goodsData={data.bestGoods || {}}
        firstPosition={data?.goodsWatch?.goods?.length === 0}
      />
      <GoodsListMDPick
        goodsData={data.recommandGoods || {}}
        listType={"carousel"}
      />
      <GoodsListMDPick goodsData={data.mdGoods || {}} />
      <BestReviewWrap>
        <span className="home-header-title color-200">베스트 리뷰</span>
        <Content>
          <div className="reviews">
            <div className="vreview-best-review-widget" />
          </div>
        </Content>
      </BestReviewWrap>
      <KumbangLink />
      <Footer />
      <BottomSheetLayout />
      <BottomPopup />
    </DefaultLayout>
  );
};

const BestReviewWrap = styled.div`
  /* padding: 0 16px; */
  margin-top: 27px;
`;

const HeaderTitle = styled.span`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: -0.48px;
  color: ${colors.black200};
  padding: 0 16px;
  margin-bottom: 4px;
`;

const Content = styled.div`
  margin: 4px 0px 44px 0px;
`;

export default React.memo(Home);
