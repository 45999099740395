import NumberInput from "components/common/Input/NumberInput";
import TextInput from "components/common/Input/TextInput";
import Flex from "components/common/flex/Flex";
import DefaultLayout from "components/layouts/DefaultLayout";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { showAlert } from "features/modal/modalSlice";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { getFindAccount, getRequestKey } from "services/apis";
import styled from "styled-components";
import { encodeByAES56, decodeByAES256 } from "utils/utils";
import {Buffer} from 'buffer';

const FindId = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleClickSubmit = () => {
    if (canSubmit) {
      dispatch(showLoading());
      getRequestKey().then(({ data: { success, data, message } }) => {
        if (success) {
          const { encId, publicKey } = data;
          const requestData = {
            encId,
            data: encodeByAES56(
              Buffer.from(publicKey, "base64"),
              JSON.stringify({
                name: Buffer.from(name).toString("base64"),
                birthday: Buffer.from(
                  year.padStart(4, "0") +
                    month.padStart(2, "0") +
                    day.padStart(2, "0"),
                ).toString("base64"),
                phoneNumber: Buffer.from(phoneNumber).toString("base64"),
              }),
            ),
          };
          getFindAccount(JSON.stringify(requestData))
            .then((encodeData) => {
              const responseData = decodeByAES256(
                Buffer.from(publicKey, "base64"),
                encodeData.data,
              );
              const { success, data, message } = JSON.parse(responseData);
              if (success) {
                navigate(routes.authFindIdResult, {
                  replace: true,
                  state: data,
                });
              } else {
                dispatch(showAlert({ message }));
              }
            })
            .catch((error) => {
              dispatch(showAlert({ message: error.response.data.message }));
            })
            .finally(() => {
              dispatch(hideLoading());
            });
        } else {
          dispatch(showAlert({ message }));
        }
      });
    }
  };
  const canSubmit = name && year && month && day && phoneNumber;
  return (
    <DefaultLayout title="아이디 찾기">
      <Container>
        <TitleHeader>아이디 찾기</TitleHeader>
        <InputSection>
          <InputLabel>이름</InputLabel>
          <InputWrap placeholder="이름" value={name} onChange={setName} />
        </InputSection>
        <InputSection>
          <InputLabel>생년월일</InputLabel>
          <Flex row style={{ gap: 8 }}>
            <Flex>
              <NumberInputWrap
                placeholder="년(4자)"
                maxLength={4}
                value={year}
                onChange={setYear}
              />
            </Flex>
            <Flex>
              <NumberInputWrap
                placeholder="월"
                maxLength={2}
                value={month}
                onChange={setMonth}
              />
            </Flex>
            <Flex>
              <NumberInputWrap
                placeholder="일"
                maxLength={2}
                value={day}
                onChange={setDay}
              />
            </Flex>
          </Flex>
        </InputSection>
        <InputSection>
          <InputLabel>휴대전화</InputLabel>
          <NumberInputWrap
            placeholder="휴대전화 번호를 입력해주세요."
            maxLength={11}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
        </InputSection>
        <Button
          className={canSubmit && "pointer"}
          disabled={!canSubmit}
          onClick={handleClickSubmit}
        >
          확인
        </Button>
      </Container>
    </DefaultLayout>
  );
};

const Container = styled(Flex)`
  padding: 16px;
`;

const TitleHeader = styled.span`
  font-weight: bold;
  font-size: 21px;
  letter-spacing: -0.42px;
  color: #222222;
  margin-bottom: 4px;
`;

const InputSection = styled(Flex)`
  margin-top: 24px;
`;

const InputLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #333333;
  margin-bottom: 8px;
`;

const NumberInputWrap = styled(NumberInput)`
  &:focus {
    border: 1px solid #656667;
  }
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  letter-spacing: -0.32px;
  width: 100%;
`;

const InputWrap = styled(TextInput)`
  &:focus {
    border: 1px solid #656667;
  }
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  letter-spacing: -0.32px;
  width: 100%;
`;

const Button = styled(Flex)`
  ${({ theme }) => theme.flexCenter};
  margin-top: 56px;
  height: 48px;
  background: ${({ disabled }) => (disabled ? "#dddddd" : "#ff6e0e")};
  color: ${({ disabled }) => (disabled ? "#bbbbbb" : "white")};
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
`;
export default FindId;
