import { useState } from "react";

export const useModal = (initialMode = false) => {
  const [modalVisible, setModalVisible] = useState(initialMode);

  const modalOpen = () => setModalVisible(true);
  const modalClose = () => setModalVisible(false);

  return [modalVisible, modalOpen, modalClose];
};
