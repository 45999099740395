import styled from "styled-components";
import { useModal } from "hooks/useModal";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PHONE_CODE, MEMO } from "utils/constants";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "features/modal/modalSlice";
import { selectBuyInfo, setDeliveryInfo } from "features/buy/buySlice";

import fonts from "assets/styles/fonts";
import { CustomModal } from "components";
import colors from "assets/styles/color";
import useLongPress from "hooks/useLongPress";
import DownTriangle from "assets/icons/down-triangle.webp";
import CheckActive from "assets/icons/check-active.webp";
import CheckboxClicked from "assets/icons/checkbox-clicked.webp";
import CheckboxDisabled from "assets/icons/checkbox-disabled.webp";

/**
 * 신규 배송지 입력 폼
 * 배송지 목록이 없으면 false로 옴
 * @param {boolean} ListStatus - 배송지 목록 여부
 */
const DeliveryForm = ({ ListStatus = true }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { DeliveryInfo } = useSelector(selectBuyInfo);
  const [isChecked, setIsChecked] = useState(DeliveryInfo?.isDefault);
  const [initialIsDefault, setInitialIsDefault] = useState(
    DeliveryInfo?.isDefault,
  ); // 기본 배송지 상태에 따른 초기값 기억하기 위한 상태
  const [btnVisible, setBtnVisible] = useState(false); // 기본 배송지로 선택 버튼 보이게 하기 위한 state
  const [modalVisible, modalOpen, modalClose] = useModal(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [PhoneSecond, setPhoneSecond] = useState(
    DeliveryInfo?.phone?.slice(3, 7) || "",
  );
  const [PhoneThird, setPhoneThird] = useState(
    DeliveryInfo?.phone?.slice(7) || "",
  );
  const [MobileSecond, setMobileSecond] = useState(
    DeliveryInfo?.mobile?.slice(3, 7) || "",
  );
  const [MobileThird, setMobileThird] = useState(
    DeliveryInfo?.mobile?.slice(7) || "",
    "",
  );

  useEffect(() => {
    location.pathname.startsWith("/goods-purchase")
      ? dispatch(setDeliveryInfo({ isDefault: true })) // 주문/결제 페이지에서는 기본 배송지로 추가 설정
      : ListStatus && setBtnVisible(true); // 기본배송지로 추가 버튼 보이게 하기
    // 배송지 목록 없을 때는 버튼 안 보이고 백엔드에서 자동으로 기본배송지로
  }, [location?.pathname]);

  // input 바뀔 때마다 값 집어넣기
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      setDeliveryInfo({
        [name]: value,
      }),
    );
  };

  const handlePhoneChange = (e, order) => {
    const { name, value } = e.target;
    const prevState = DeliveryInfo[name].slice(0, 3);

    if (order === "second") {
      name === "phone" ? setPhoneSecond(value) : setMobileSecond(value);
    } else if (order === "third") {
      name === "phone" ? setPhoneThird(value) : setMobileThird(value);
    }

    let updatedPhoneValue =
      name === "phone"
        ? `${prevState}${PhoneSecond}${PhoneThird}`
        : `${prevState}${MobileSecond}${MobileThird}`;

    if (order === "second") {
      updatedPhoneValue =
        name === "phone"
          ? `${prevState}${value}${PhoneThird}`
          : `${prevState}${value}${MobileThird}`;
    } else if (order === "third") {
      updatedPhoneValue =
        name === "phone"
          ? `${prevState}${PhoneSecond}${value}`
          : `${prevState}${MobileSecond}${value}`;
    }

    dispatch(setDeliveryInfo({ [name]: updatedPhoneValue }));
  };

  const handleCompleteSearch = (data) => {
    modalClose();
    dispatch(
      setDeliveryInfo({
        postCode: data.zonecode,
        address: data.address,
      }),
    );
  };

  const handleOpenAddAddress = () => {
    modalOpen();
  };

  const handleClickCheckBox = () => {
    // 초기에 기본 배송지가 있으면 수정 불가능
    if (initialIsDefault) {
      dispatch(
        showAlert({
          icon: "Error",
          message: "다른 배송지를 기본 배송지로\n선택하면 자동으로 해제됩니다.",
        }),
      );
    } else {
      const newIsCheckedState = !isChecked;
      setIsChecked(newIsCheckedState);
      dispatch(setDeliveryInfo({ isDefault: newIsCheckedState }));
    }
  };

  const handlePreventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <DeliveryPaddingDiv>
      <InputWrapper>
        <Label>배송지명</Label>
        <AddressNameWrapper>
          <StyledInput
            type="text"
            name="addressName"
            value={DeliveryInfo.addressName}
            placeholder="직접 입력하거나 선택하세요."
            onChange={handleInputChange}
          />
          <TagBtnWrapper>
            <TagButton />
          </TagBtnWrapper>
        </AddressNameWrapper>
      </InputWrapper>
      <InputWrapper>
        <Label>
          수령인<Required>*</Required>
        </Label>
        <StyledInput
          type="text"
          name="receiver"
          value={DeliveryInfo.receiver}
          placeholder="이름을 입력하세요."
          onChange={handleInputChange}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>
          연락처1
          <Required>*</Required>
        </Label>
        <PhoneWrapper>
          <PhoneDropdown
            name="phone"
            placeholder="선택"
            options={PHONE_CODE}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
          <GrayBar>-</GrayBar>
          <StyledInput
            className="tel"
            type="tel"
            name="phone"
            maxLength={4}
            value={PhoneSecond}
            placeholder="0000"
            onChange={(e) => handlePhoneChange(e, "second")}
          />
          <GrayBar>-</GrayBar>
          <StyledInput
            className="tel"
            type="tel"
            name="phone"
            maxLength={4}
            value={PhoneThird}
            placeholder="0000"
            onChange={(e) => handlePhoneChange(e, "third")}
          />
        </PhoneWrapper>
      </InputWrapper>
      <InputWrapper>
        <Label>연락처2</Label>
        <PhoneWrapper>
          <PhoneDropdown
            name="mobile"
            placeholder="선택"
            options={PHONE_CODE}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
          <GrayBar>-</GrayBar>
          <StyledInput
            className="tel"
            type="tel"
            name="mobile"
            maxLength={4}
            value={MobileSecond}
            placeholder="0000"
            onChange={(e) => handlePhoneChange(e, "second")}
          />
          <GrayBar>-</GrayBar>
          <StyledInput
            className="tel"
            type="tel"
            name="mobile"
            maxLength={4}
            value={MobileThird}
            placeholder="0000"
            onChange={(e) => handlePhoneChange(e, "third")}
          />
        </PhoneWrapper>
      </InputWrapper>
      <InputWrapper>
        <Label>
          배송지<Required>*</Required>
        </Label>
        <AddressWrap>
          <Flex>
            <AddressInput
              type="text"
              name="postCode"
              value={DeliveryInfo.postCode}
              onChange={handlePreventDefault}
              readOnly
            />
            <SearchAddressBtn onClick={handleOpenAddAddress}>
              주소 검색
            </SearchAddressBtn>
          </Flex>
          <AddressInput
            type="text"
            name="address"
            value={DeliveryInfo.address}
            onChange={handlePreventDefault}
            readOnly
          />
          <AddressInput
            type="text"
            name="addressDetail"
            value={DeliveryInfo.addressDetail}
            onChange={handleInputChange}
          />
          {btnVisible && (
            <CheckContainer isChecked={isChecked} onClick={handleClickCheckBox}>
              <CheckboxIcon
                src={isChecked ? CheckboxClicked : CheckboxDisabled}
                alt="체크박스"
              />
              <IsDefaultLabel>기본 배송지로 선택</IsDefaultLabel>
            </CheckContainer>
          )}
        </AddressWrap>
      </InputWrapper>
      <InputWrapper>
        <Label>수령메모</Label>
        <Flex className="column">
          <MemoDropdown
            name="memo"
            placeholder="선택해주세요."
            options={MEMO}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        </Flex>
      </InputWrapper>
      <CustomModal modalVisible={modalVisible} modalClose={modalClose}>
        {modalVisible && (
          <DaumPostcodeEmbed onComplete={handleCompleteSearch} />
        )}
      </CustomModal>
    </DeliveryPaddingDiv>
  );
};
const PhoneDropdown = ({
  name,
  placeholder,
  options,
  openDropdown,
  setOpenDropdown,
}) => {
  const dispatch = useDispatch();
  const { DeliveryInfo } = useSelector(selectBuyInfo);
  const { areaCode1, areaCode2 } = DeliveryInfo;

  const [selectedOption, setSelectedOption] = useState(null);

  const isOpen = openDropdown === name;

  useEffect(() => {
    if (name === "phone") {
      setSelectedOption(areaCode1);
    } else if (name === "mobile") {
      setSelectedOption(areaCode2);
    }
  }, []);

  const handleClickDropdownBtn = () => {
    setOpenDropdown(isOpen ? null : name);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);

    const prevState = DeliveryInfo[name];
    const prefix = prevState.substring(0, 3);
    const newValue =
      prevState === "" ? option : prevState.replace(prefix, option);
    const areaCode = name === "phone" ? "areaCode1" : "areaCode2";
    dispatch(setDeliveryInfo({ [name]: newValue, [areaCode]: option }));
    setOpenDropdown(null);
  };

  return (
    <DropdownWrapper>
      <DropdownButton onClick={handleClickDropdownBtn}>
        <TitleWrapper>
          {selectedOption || <DropdownTitle>{placeholder}</DropdownTitle>}
          <DownIcon src={DownTriangle} alt="화살표" />
        </TitleWrapper>
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {options.map((option, index) => (
            <DropdownItem key={option} onClick={() => handleSelect(option)}>
              {option}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

const MemoDropdown = ({
  name,
  placeholder,
  options,
  openDropdown,
  setOpenDropdown,
}) => {
  const dispatch = useDispatch();
  const isOpen = openDropdown === name;
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCustomMemoVisible, setIsCustomMemoVisible] = useState(false);
  const { DeliveryInfo } = useSelector(selectBuyInfo);
  const { memo } = DeliveryInfo;

  useEffect(() => {
    // 옵션 중에 없으면 input 열기
    const isMemoInOptions = options.includes(memo);
    setIsCustomMemoVisible(!isMemoInOptions);

    // 만약에 옵션이 있으면 그 memo 고르기, 아니면 직접 입력 고르기
    if (isMemoInOptions) {
      setSelectedOption(memo);
    } else {
      setSelectedOption(options[0]);
    }
  }, [memo, options]);

  const handleClickDropdownBtn = () => {
    setOpenDropdown(isOpen ? null : name);
  };

  const handleSelect = (option) => {
    if (option === options[0]) {
      // 직접 입력 가능하도록 하기 + 그 전에 고른 옵션 초기화
      dispatch(setDeliveryInfo({ [name]: "" }));
      setIsCustomMemoVisible(true);
    } else {
      // 다른 옵션 고르면 옵션 들어가게 하기
      dispatch(setDeliveryInfo({ [name]: option }));
      setIsCustomMemoVisible(false);
    }
    setSelectedOption(option);
    setOpenDropdown(null);
  };

  const handleCustomMemo = (e) => {
    dispatch(setDeliveryInfo({ memo: e.target.value }));
  };

  return (
    <DropdownWrapper>
      <DropdownButton onClick={handleClickDropdownBtn}>
        <TitleWrapper>
          {selectedOption || <DropdownTitle>{placeholder}</DropdownTitle>}
          <DownIcon src={DownTriangle} alt="화살표" />
        </TitleWrapper>
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {options.map((option, index) => (
            <DropdownItem key={option} onClick={() => handleSelect(option)}>
              {option}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
      {isOpen ? (
        <InvisibleBox />
      ) : (
        isCustomMemoVisible && (
          <CustomMemo
            placeholder="20자 이내로 입력하세요."
            maxLength={20}
            onChange={(e) => handleCustomMemo(e)}
            value={memo}
            rows={1}
            onKeyPress={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          />
        )
      )}
    </DropdownWrapper>
  );
};

const TagButton = () => {
  const dispatch = useDispatch();
  const TagArr = ["집", "회사", "가족", "친구"];
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleClickTag = (index) => {
    if (clickedIndex === index) {
      setClickedIndex(null);
      dispatch(setDeliveryInfo({ addressName: "" }));
    } else {
      dispatch(setDeliveryInfo({ addressName: TagArr[index] }));
      setClickedIndex(index);
    }
  };

  return TagArr.map((tag, index) => (
    <TagBtn
      key={tag}
      onClick={() => handleClickTag(index)}
      className={index === clickedIndex && "clicked"}
    >
      {index === clickedIndex && <CheckIcon src={CheckActive} alt="체크" />}
      {tag}
    </TagBtn>
  ));
};

export default DeliveryForm;

const Flex = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexBox("row", "flex-start", "center")}

  &.column {
    ${({ theme }) => theme.flexBox("column", "flex-start", "flex-start")}
  }
`;

// 입력 폼 관련 css
const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  ${({ theme }) => theme.flexBox("row", "space-between", "flex-start")}
`;

const Label = styled.div`
  color: ${colors.black300};
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.3px;
  min-width: 60px;
  height: 36px;

  ${({ theme }) => theme.flexBox("row", "flex-start", "center")}
`;

const StyledInput = styled.input`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${colors.commonBorder};
  color: ${colors.black300};
  font-size: 14px;
  letter-spacing: -0.28px;
  padding: 8px;

  &.tel {
    font-family: ${fonts.spoqa};
    text-align: center;
    &::placeholder {
      color: ${colors.black900};
    }
  }

  &:focus {
    border: 1px solid #222222;
  }
`;

const AddressInput = styled(StyledInput)`
  border: 1px solid #dbdbdb;
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Required = styled.span`
  color: ${colors.primaryOrange};
`;

// 드롭다운
const DropdownWrapper = styled.div`
  position: relative;
  min-width: 60px;
  width: 100%;
`;

const DropdownButton = styled.button`
  width: 100%;
  height: 36px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.value ? "#222222" : "#dbdbdb")};
  padding: 8px 0 8px 8px;
  text-align: left;
  ${({ theme }) => theme.flexBox("row", "center", "center")}
`;

const DropdownMenu = styled.div`
  position: absolute;
  padding: 4px 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border: 1px solid ${colors.inputBorder};
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
`;

const DropdownItem = styled.div`
  height: 32px;
  padding: 6px 11px;
  font-size: 14px;
  letter-spacing: -0.28px;
  color: ${colors.black300};
  cursor: pointer;
  font-family: ${fonts.spoqa};

  &:hover {
    background-color: #fee2d3;
  }
`;

const DownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownTitle = styled.span`
  color: ${colors.black900};
  font-size: 14px;
  letter-spacing: -0.56px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  font-family: ${fonts.spoqa};
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
`;

const PhoneWrapper = styled.div`
  width: 100%;
  height: auto;
  ${({ theme }) => theme.flexCenter}
`;

const GrayBar = styled.div`
  font-family: ${fonts.spoqa};
  letter-spacing: -0.28px;
  color: ${colors.inputBorder};
`;

const SearchAddressBtn = styled.button`
  min-width: 70px;
  height: 36px;
  border-radius: 4px;
  background-color: #817777;
  color: white;
  font-weight: 500;
  font-size: 13px;
  padding: 8px;
  margin-left: 7px;
  cursor: pointer;
`;

const DeliveryPaddingDiv = styled.div`
  padding: 16px;
  width: 100%;
`;

const AddressNameWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexBox("column", "space-between", "center")}
`;

const TagBtnWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
`;

const TagBtn = styled.button`
  width: 40px;
  cursor: pointer;
  border-radius: 12px;
  background-color: #fee2d3;
  color: #f76f26;
  font-size: 14px;
  letter-spacing: -0.28px;
  padding: 0 4px;
  margin-right: 8px;

  &.clicked {
    font-weight: 700;
    border: 1px solid #ffb382;
    width: 50px;
  }
`;

const CheckIcon = styled.img`
  width: 10.5px;
  height: 10.5px;
`;

const CustomMemo = styled.textarea`
  width: 100%;
  height: 60px;
  border-radius: 4px;
  border: 1px solid ${colors.commonBorder};
  color: ${colors.black300};
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 22px;
  padding: 7px 8px 9px;
  margin-top: 8px;
  resize: none;
  outline: none;
  overflow: hidden;
  &:focus {
    border-color: ${colors.black200};
  }
`;

// 기본 배송지로 선택 버튼
const IsDefaultLabel = styled.span`
  color: ${colors.black400};
  height: 24px;
  font-size: 14px;
  font-family: ${fonts.spoqa};
  letter-spacing: -0.28px;
  line-height: 24px;
  vertical-align: center;
`;

const CheckboxIcon = styled.img`
  margin-right: 11px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
const CheckContainer = styled.div`
  width: 100%;
  height: 32px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.isChecked ? "#ffb382" : "transparent")};
  background-color: ${colors.white200};

  display: flex;
`;

const InvisibleBox = styled.div`
  display: "block";
  width: 100%;
  height: 200px;
`;

const AddressWrap = styled.div`
  width: 100%;
`;
