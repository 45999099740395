import React, { useEffect, useRef } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import NotFound from "./pages/NotFound";
import Home from "./pages/home/Home";
import Detail from "./pages/goods-detail/GoodsDetail";
import Login from "pages/login/Login";
import Goods from "pages/goods/Goods";
import Gateway from "pages/auth/gateway/Gateway";
import SignUpId from "pages/auth/sign-up/id/SignUpId";
import AcceptTerms from "pages/auth/sign-up/accept-terms/AcceptTerms";
import RegisterEmail from "pages/auth/sign-up/register-email/RegisterEmail";
import RegisterPin from "pages/auth/sign-up/register-pin/RegisterPin";
import HtmlViewer from "pages/common/html-viewer/HtmlViewer";
import GoodsCategory from "pages/goods-category/GoodsCategory";
import MyPage from "pages/my-page/MyPage";
import GoodsPurchase from "pages/goods-purchase/GoodsPurchase";
import ChangeUserInfo from "pages/change-user-info/ChangeUserInfo";
import OrderHistory from "pages/order-history/OrderHistory";
import OrderDetail from "pages/order-detail/OrderDetail";
import SelfVerification from "pages/auth/self-verification/SelfVerification";
import EditEmail from "pages/edit-profile/edit-email/EditEmail";
import FindStorePage from "pages/goods-purchase/components/FindStorePage";
import Leave from "pages/edit-profile/leave/Leave";
import Settlement from "pages/settlement/Settlement";
import { useSelector } from "react-redux";
import PurchaseReceipt from "pages/purchase-receipt/PurchaseReceipt";
import FindId from "pages/auth/find-id/FindId";
import Result from "pages/auth/find-id/result/Result";
import ResetPassword from "pages/auth/reset-password/ResetPassword";
import ChangePassword from "pages/edit-profile/change-password/ChangePassword";
import NoticeDetail from "pages/notice/detail/NoticeDetail";
import ManageAccount from "pages/manage-account/ManageAccount";
import RegistrerAccount from "pages/register-account/RegistrerAccount";
import DeleteAccountPin from "pages/delete-account-pin/DeleteAccountPin";
import ManageMentShipping from "pages/edit-profile/management-shipping/ManageMentShipping";
import TossSuccess from "pages/toss/TossSuccess";
import TossFail from "pages/toss/TossFail";
import PurchaseComplete from "pages/purchase-result/PurchaseComplete";
import PurchaseFailed from "pages/purchase-result/PurchaseFailed";
import PurchaseUnabled from "pages/purchase-result/PurchaseUnabled";
import ReviewWidget from "pages/my-page/components/ReviewWidget";
import SecureKeypad from "pages/common/html-viewer/SecureKeypad";
import AuthRegisterShop from "pages/auth/sign-up/register-shop/AuthRegisterShop";

export const routes = {
  commonHtmlViewer: "/common/html-viewer",
  home: "/home",
  login: "/login",
  goods: "/goods",
  goodsCategory: "/goods-category",
  goodsPurchase: (id) => `/goods-purchase/${id}`,
  goodsDetail: (id) => `/detail/${id}`,
  commonSecureKeypad: "/common/secure-keypad",
  authGateway: "/auth/gateway",
  authSignUpId: "/auth/sign-up/id",
  authSignUpAcceptTerms: "/auth/sign-up/accept-terms",
  authSignUpRegisterShop: "/auth/sign-up/register-shop",
  authSignUpRegisterEmail: "/auth/sign-up/register-email",
  authSignUpRegisterPin: "/auth/sign-up/register-pin",
  authFindId: "/auth/find-id",
  authFindIdResult: "/auth/find-id/result",
  authResetPassword: "/auth/reset-password",
  myPage: "/my-page",
  mypageChangeInfo: "/my-page/change-info",
  orderHistory: "/my-page/order-history",
  orderDetail: "/my-page/order-detail/:id",
  reviewHistory: "/my-page/review-history",
  selfVerification: "/auth/self-verification",
  editEmail: "/my-page/email-info",
  shippingManagement: "/my-page/shipping-management",
  findStore: "/goods-purchase/find-store",
  leave: "/my-page/leave",
  changePassword: "/my-page/change-password",
  settlement: "/settlement",
  purchaseReceipt: "/my-page/order-detail/purchase-receipt",
  noticeDetail: "/notice/detail",
  depositAccountManage: "/my-page/manage-account",
  registerAccount: "/my-page/register-account",
  deleteAccount: "/my-page/delete-account",
  tossSuccess: (txId) => `toss-success/${txId}`,
  tossFail: (txId) => `toss-fail/${txId}`,
  purchaseComplete: "/purchase-complete",
  purchaseFailed: "/purchase-failed",
  PurchaseUnabled: "/purchase-unabled",
};
const AppRoutes = () => {
  // scroll restoration
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/">
        <Route index element={<Navigate to={routes.home} replace />} />
        <Route path={routes.home} element={<Home />} />
        <Route path={routes.login} element={<Login />} />
        <Route path={`${routes.goods}/:id`} element={<Goods />} />
        <Route
          path={`${routes.goodsCategory}/:id`}
          element={<GoodsCategory />}
        />
        <Route path="/goods-purchase/:id" element={<GoodsPurchase />} />
        <Route path="/detail/:id" element={<Detail />} />
        <Route path={routes.commonSecureKeypad} element={<SecureKeypad />} />
        <Route path={routes.authGateway} element={<Gateway />} />
        <Route path={routes.authSignUpId} element={<SignUpId />} />
        <Route path={routes.authSignUpAcceptTerms} element={<AcceptTerms />} />
        <Route
          path={routes.authSignUpRegisterShop}
          element={<AuthRegisterShop />}
        />
        <Route
          path={routes.authSignUpRegisterEmail}
          element={<RegisterEmail />}
        />
        <Route path={routes.authFindId} element={<FindId />} />
        <Route path={routes.authFindIdResult} element={<Result />} />
        <Route path={routes.authResetPassword} element={<ResetPassword />} />
        <Route path={routes.myPage} element={<MyPage />} />
        <Route path={routes.mypageChangeInfo} element={<ChangeUserInfo />} />
        <Route path={routes.orderHistory} element={<OrderHistory />} />
        <Route path={routes.orderDetail} element={<OrderDetail />} />
        <Route path={routes.reviewHistory} element={<ReviewWidget />} />
        <Route path={routes.purchaseReceipt} element={<PurchaseReceipt />} />
        <Route path={routes.selfVerification} element={<SelfVerification />} />
        <Route path={routes.editEmail} element={<EditEmail />} />
        <Route
          path={routes.shippingManagement}
          element={<ManageMentShipping />}
        />
        <Route path={routes.changePassword} element={<ChangePassword />} />
        <Route path={routes.leave} element={<Leave />} />
        <Route path={routes.authSignUpRegisterPin} element={<RegisterPin />} />
        <Route path={routes.commonHtmlViewer} element={<HtmlViewer />} />
        <Route path={routes.findStore} element={<FindStorePage />} />
        <Route path={routes.settlement} element={<Settlement />} />
        <Route path={routes.noticeDetail} element={<NoticeDetail />} />
        <Route path={routes.depositAccountManage} element={<ManageAccount />} />
        <Route path={routes.registerAccount} element={<RegistrerAccount />} />
        <Route path={routes.deleteAccount} element={<DeleteAccountPin />} />
        <Route path="/toss-success/:id/:txId" element={<TossSuccess />} />
        <Route path="/toss-fail/:id/:txId" element={<TossFail />} />
        <Route path={routes.purchaseComplete} element={<PurchaseComplete />} />
        <Route path={routes.purchaseFailed} element={<PurchaseFailed />} />
        <Route path={routes.PurchaseUnabled} element={<PurchaseUnabled />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
