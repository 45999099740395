import React from "react";
import styled from "styled-components";
import CancelIcon from "assets/icons/cancel-modal.webp";
import { Button } from "components";
import colors from "assets/styles/color";

const UnableLeave = ({ modalClose, message }) => {
  return (
    <>
      <MarkIcon src={CancelIcon} alt="icon" />
      <MainTitle>탈퇴할 수 없습니다.</MainTitle>
      <SubText>{message}</SubText>
      <Button
        text="보유자산 확인하기"
        size="sm"
        variant="round"
        onClickFn={() => {
          modalClose();
        }}
        addStyle={{ fontWeight: "bold" }}
      />
    </>
  );
};

export default UnableLeave;
const MarkIcon = styled.img`
  width: 48px;
  height: 48px;

  margin-top: 16px;
  margin-bottom: 24px;
`;

const MainTitle = styled.div`
  color: ${colors.black200};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.36px;
  line-height: 28px;
  text-align: center;
  white-space: pre-line;

  margin-bottom: 13px;
`;

const SubText = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${colors.black600};
  line-height: 24.5px;
  letter-spacing: -0.3px;
  text-align: center;
  padding: 0 18px;
  margin-bottom: 32px;
  white-space: pre-line;
`;
