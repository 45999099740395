import React from "react";
import styled from "styled-components";
import orderInquiry from "assets/icons/order-inquiry.webp";
import orderHistory from "assets/icons/order-history.webp";
import rightArrow from "assets/icons/right-arrow.webp";
import check from "assets/icons/check.webp";
import loading from "assets/icons/loading.webp";
import location from "assets/icons/location.webp";
import complete from "assets/icons/complete-withdrawal.webp";
import delivery from "assets/icons/delivery.webp";
import { useQuery } from "react-query";
import { getMypageTransSummary } from "services/apis";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

const OrderInfo = () => {
  const navigate = useNavigate();
  const { data: transSummary = {} } = useQuery("transSummary", () => {
    return getMypageTransSummary().then(({ data: { success, data } }) => {
      if (success) {
        return data;
      }
    });
  });
  return (
    <>
      <OrderInfoContainer>
        <OrderTitle>주문 내역</OrderTitle>
        <OrderInquiryTitle>
          <OrderImg src={orderInquiry} alt="주문조회" />
          <Title>주문 조회 (상품 제작 과정 안내)</Title>
        </OrderInquiryTitle>
        <ProgressBox>
          {Object.keys(transSummary).length === 0 ? (
            <NoItem>
              <span>진행중인 내역이 없습니다.</span>
            </NoItem>
          ) : (
            <ProgressItems>
              <Item
                name="결제완료"
                count={transSummary.PAYMENT_COMPLETED || 0}
                status={"PAYMENT_COMPLETED"}
                icon={check}
              />
              <Item
                name="상품준비중"
                count={transSummary.PREPARING || 0}
                status={"PREPARING"}
                icon={loading}
              />
              <Item
                name="대리점도착"
                count={transSummary.ARRIVED || 0}
                status={"ARRIVED"}
                icon={location}
              />
              <Item
                name="배송중"
                count={transSummary.DELIVERY || 0}
                status={"DELIVERY"}
                icon={delivery}
              />
              <Item
                name="상품수령"
                count={transSummary.RECEIVED || 0}
                status={"RECEIVED"}
                icon={complete}
              />
            </ProgressItems>
          )}
        </ProgressBox>
      </OrderInfoContainer>
      <Bar />
      <OrderHistoryBox
        onClick={() => {
          navigate(routes.orderHistory);
        }}
      >
        <TitleBox>
          <OrderImg src={orderHistory} alt="주문내역" />
          <Title>주문 내역</Title>
        </TitleBox>
        <div>
          <OrderImg src={rightArrow} alt="오른쪽화살표" />
        </div>
      </OrderHistoryBox>
      <OrderHistoryBox
        onClick={() => {
          navigate(routes.reviewHistory);
        }}
      >
        <TitleBox>
          <OrderImg src={orderHistory} alt="상품리뷰" />
          <Title>상품 리뷰</Title>
        </TitleBox>
        <div>
          <OrderImg src={rightArrow} alt="오른쪽화살표" />
        </div>
      </OrderHistoryBox>
    </>
  );
};

export default OrderInfo;

const Item = ({ name, count, status, icon }) => {
  const navigate = useNavigate();
  return (
    <Center>
      {count === 0 ? (
        <NoItemBox>
          <StatusImg src={icon} />
        </NoItemBox>
      ) : (
        <ItemBox
          onClick={() => {
            navigate(routes.orderHistory, { state: { status } });
          }}
        >
          <Count>{count}</Count>
        </ItemBox>
      )}
      <div>
        <StatusName>{name}</StatusName>
      </div>
    </Center>
  );
};

const OrderInfoContainer = styled.div`
  padding: 16px 0px;
`;
const OrderTitle = styled.div`
  color: #333333;
  font-size: 17px;
  font-weight: bold;
  padding: 16px 0px 16px 16px;
  letter-spacing: -0.34px;
`;
const OrderImg = styled.img`
  width: 44px;
  height: 44px;
`;

const OrderInquiryTitle = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0px 0px 0px 6.5px;
`;

const Title = styled.span`
  margin-left: 5.5px;
  font-weight: bold;
  font-size: 15px;
  color: #444444;
`;

const ProgressBox = styled.div`
  height: 72px;
  margin: 0px 16px 0px 56px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  background-color: #fcfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
`;

const OrderHistoryBox = styled.div`
  padding: 0px 0px 16px 6.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const TitleBox = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
`;

const Bar = styled.div`
  height: 1px;
  background-color: #f2f2f2;
  margin: 0px 16px 0px 56px;
`;

const NoItem = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #777777;
`;
const StatusImg = styled.img`
  width: 24px;
  height: 24px;
`;

const ProgressItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  @media (max-width: 340px) {
    gap: 18px;
  }
`;
const Center = styled.div`
  ${({ theme }) => theme.flexBox("column", "center", "center")}
  width: 32px;
`;

const ItemBox = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #ff842b;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NoItemBox = styled.div`
  width: 32px;
  height: 32px;
  background-color: #efefef;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Count = styled.span`
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
`;
const StatusName = styled.span`
  font-size: 11px;
  color: #777777;
  font-family: normal;
  white-space: nowrap;
`;
