import styled from "styled-components";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import colors from "assets/styles/color";
import { getShop } from "services/apis";
import { objToQueryString } from "utils/utils";
import useGetUserLocation from "hooks/useUserLocation";
import { setShopInfo, setBuyInfo, selectBuyInfo } from "features/buy/buySlice";

import DefaultLayout from "components/layouts/DefaultLayout";
import Phone from "assets/icons/phone-black.webp";
import Location from "assets/icons/location-black.webp";
import Search from "assets/icons/search.webp";

function FindStorePage(props) {
  // loaded false면 로딩화면 띄우기
  const { ShopData, coordinates, loaded } = useBuyShop();
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <DefaultLayout title="대리점 찾기">
      <Container>
        <SearchShopName
          ShopData={ShopData}
          onSearch={handleSearch}
          coordinates={coordinates}
          loaded={loaded}
        />
      </Container>
    </DefaultLayout>
  );
}

export const useBuyShop = () => {
  const { coordinates, loaded } = useGetUserLocation();
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { goodsId } = BuyInfo;

  const { data: ShopData, isLoading } = useQuery(
    ["shop", coordinates],
    () => getShop(goodsId, objToQueryString(coordinates)),
    {
      select: (data) => data?.data?.data,
      enabled: !!loaded,
    },
  );
  return { ShopData, isLoading, coordinates, loaded };
};

const ShopComponent = (shop) => {
  // 선택하면 ShopId 저장되는 걸로
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSaveShopId = () => {
    dispatch(
      setShopInfo({
        buyShopId: shop.id,
        buyShopName: shop.name,
        buyShopAddress: shop.address,
        buyShopPhoneNumber: shop.phoneNumber,
        buyShopDistance: shop.distance.toFixed(2),
      }),
    );
    dispatch(
      setBuyInfo({
        shopId: shop.id,
        visitDate: "",
      }),
    );
    navigate(-1);
  };

  return (
    <ShopContainer onClick={handleSaveShopId}>
      <LocationIcon>
        <img src={Location} alt="위치" />
      </LocationIcon>
      <div>
        <ShopName>{shop.name}</ShopName>
        <Distance>
          {shop.distance !== 0 && shop.distance.toFixed(2) + "km"}
        </Distance>
        <Address>{shop.address}</Address>
        <Flex>
          <PhoneIcon src={Phone} alt="전화" />
          <PhoneNumber>{shop.phoneNumber}</PhoneNumber>
        </Flex>
      </div>
    </ShopContainer>
  );
};

const NearShop = ({ NearShopList }) => {
  return (
    <div>
      <Title>가까운 대리점</Title>
      {NearShopList?.map((shop) => {
        return <ShopComponent key={shop.id} {...shop} />;
      })}
    </div>
  );
};

const WholeShop = ({ WholeShopList }) => {
  return (
    <div>
      <Title>전체 대리점</Title>
      {WholeShopList?.map((shop) => {
        return <ShopComponent key={shop.id} {...shop} />;
      })}
    </div>
  );
};

const SearchShopName = ({ ShopData, onSearch, coordinates, loaded }) => {
  const [searchValue, setSearchValue] = useState("");
  const [result, setResult] = useState([]);

  const CHO_HANGUL = [
    "ㄱ",
    "ㄲ",
    "ㄴ",
    "ㄷ",
    "ㄸ",
    "ㄹ",
    "ㅁ",
    "ㅂ",
    "ㅃ",
    "ㅅ",
    "ㅆ",
    "ㅇ",
    "ㅈ",
    "ㅉ",
    "ㅊ",
    "ㅋ",
    "ㅌ",
    "ㅍ",
    "ㅎ",
  ];

  const HANGUL_START_CHARCODE = "가".charCodeAt();

  const CHO_PERIOD = Math.floor("까".charCodeAt() - "가".charCodeAt());
  const JUNG_PERIOD = Math.floor("개".charCodeAt() - "가".charCodeAt());

  function combine(cho, jung, jong) {
    return String.fromCharCode(
      HANGUL_START_CHARCODE + cho * CHO_PERIOD + jung * JUNG_PERIOD + jong,
    );
  }

  function makeRegexByCho(search = "") {
    const regex = CHO_HANGUL.reduce(
      (acc, cho, index) =>
        acc.replace(
          new RegExp(cho, "g"),
          `[${combine(index, 0, 0)}-${combine(index + 1, 0, -1)}]`,
        ),
      search,
    );

    return new RegExp(`(${regex})`, "g");
  }

  function includeByCho(search, targetWord) {
    return makeRegexByCho(search).test(targetWord);
  }

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    onSearch(e.target.value);
    const filteredData = ShopData.filter((shop, index) => {
      return e.target.value !== ""
        ? includeByCho(e.target.value, shop.name)
        : false;
    });
    setResult(filteredData);
  };

  const ShopList = () => {
    // 위치 정보 받아오기 전에는 null, 검색어 있을 때는 검색 결과
    if (!loaded || searchValue !== "") return null;

    return coordinates ? (
      <NearShop NearShopList={ShopData} />
    ) : (
      <WholeShop WholeShopList={ShopData} />
    );
  };

  return (
    <>
      <SearchBar>
        <StyledInput
          type="text"
          value={searchValue}
          onChange={handleChange}
          placeholder="대리점명 검색, 예) 종로, 강동, 대전"
        />
        <SearchIcon src={Search} alt="검색" />
      </SearchBar>
      {ShopList()}
      {searchValue && <Title>"{searchValue}"에 대한 검색 결과</Title>}
      {result.map((shop) => (
        <ShopComponent key={shop.id} {...shop} />
      ))}
    </>
  );
};

export default FindStorePage;

const Container = styled.div`
  padding: 24px 16px;
`;

const ShopContainer = styled.div`
  display: flex;
  width: 100%;
  height: 77px;
  border-top: 1px solid ${colors.inputBorder};
  padding: 9px 0;
  cursor: pointer;
  &:first-child {
    border-top: none;
  }
`;

const ShopName = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.black300};
  letter-spacing: -0.32px;
`;

const Distance = styled.span`
  font-weight: 700;
  font-size: 12px;
  color: ${colors.black900};
  letter-spacing: -0.24px;

  margin-left: 8px;
`;

const Address = styled.div`
  font-size: 12px;
  color: ${colors.black700};
  letter-spacing: -0.24px;
  margin-top: 8px;
`;

const LocationIcon = styled.div`
  padding-right: 8px;
  img {
    width: 11px;
  }
`;

const PhoneIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const PhoneNumber = styled.span`
  font-size: 12px;
  color: ${colors.black500};
  text-decoration: underline;
  margin-left: 2px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.46px;
  color: ${colors.black300};
  margin-bottom: 5px;
`;

const Flex = styled.div`
  margin-top: 8px;
  ${({ theme }) => theme.flexBox("row", "flex-start", "center")}
`;

const SearchBar = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  border: 1px solid ${colors.inputBorder};
  margin-bottom: 28px;
`;

const StyledInput = styled.input`
  width: 100%;
  margin: 10px;
  flex: 1;
`;

const SearchIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
