import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import downArrow from "assets/icons/down-arrow-black.webp";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "routes";

const TabArea = ({ goodsCategoryList, goodsCategory, setGoodsCategory }) => {
  return (
    <TabContainer>
      <DropButton
        goodsCategoryList={goodsCategoryList}
        goodsCategory={goodsCategory}
        setGoodsCategory={setGoodsCategory}
      />
    </TabContainer>
  );
};

const DropButton = ({ goodsCategoryList, goodsCategory, setGoodsCategory }) => {
  const { state } = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickSelect = (e) => {
    setGoodsCategory(e.target.value);
    navigate(`${routes.goodsCategory}/${e.target.value}`, {
      replace: true,
      state: { ...state, goodsCategoryId: e.target.value },
    });
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      if (!event.target.closest(".drop-button")) {
        setIsDropdownOpen(false);
      }
    }
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="drop-button">
      <button onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <DropSort>
          <SortName>
            {goodsCategory
              ? goodsCategoryList?.find(
                  (item) => item.goodsCategoryId === Number(goodsCategory),
                )?.kind
              : goodsCategoryList?.[0]?.kind}
          </SortName>
          <DropIcon src={downArrow} alt="아래화살표" />
        </DropSort>
      </button>
      {isDropdownOpen && (
        <DropDownList className="dropdown-content">
          {goodsCategoryList?.map((item) => {
            return (
              <SortButton
                key={item.goodsCategoryId}
                value={item.goodsCategoryId}
                onClick={handleClickSelect}
                select={
                  Number(
                    goodsCategory
                      ? goodsCategory
                      : goodsCategoryList[0]?.goodsCategoryId,
                  ) === item.goodsCategoryId
                    ? true
                    : false
                }
              >
                {item.kind}
              </SortButton>
            );
          })}
        </DropDownList>
      )}
    </div>
  );
};

export default TabArea;
const TabContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 48px;
  padding: 0px 16px;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
`;

const DropSort = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const SortName = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  font-family: NotoSansKR;
`;

const DropIcon = styled.img`
  heigth: 36px;
  width: 36px;
  //   position: absolute;
  //   left: 35px;
`;

const DropDownList = styled.div`
  position: absolute;
  width: 96px;
  margin-top: 7px;
  z-index: 10;
  background-color: #ffffff;
  padding: 8px 0px;
  border-radius: 10px;
  box-shadow: #cdc9c9 1px 1px 10px 0px;
`;

const SortButton = styled.button`
  width: 100%;
  padding: 10px 0px;
  text-align: left;
  height: 40px;
  color: ${(props) => (props.select ? "#e76e26" : "#777777")};
  font-weight: ${(props) => (props.select ? "bold" : "normal")};
  font-size: 14px;
  font-family: NotoSansKR;
  padding: 0px 14px;
  cursor: pointer;
`;
