import fonts from "assets/styles/fonts";
import Flex from "components/common/flex/Flex";
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { getFormatTime } from "utils/utils";

const TopLabel = ({ goodsTrans }) => {
  const navigate = useNavigate();
  const { orderNumber, createdAt, goodsTransStatus } = goodsTrans;

  const handleClickReceipt = () => {
    navigate(routes.purchaseReceipt, { state: { goodsTrans } });
  };

  return (
    <TopLabelWrap row>
      <DataWrap flex={1}>
        <PaymentDateWrap>
          결제일: <span className="spoqa">{getFormatTime(createdAt)}</span>
        </PaymentDateWrap>
        <OrderNumberWrap>주문번호: {orderNumber}</OrderNumberWrap>
      </DataWrap>
      {goodsTransStatus === "CONFIRMED" && (
        <InquiryButton
          className="pointer"
          onClick={() => {
            handleClickReceipt();
          }}
        >
          영수증 조회
        </InquiryButton>
      )}
    </TopLabelWrap>
  );
};

const TopLabelWrap = styled(Flex)`
  margin: 0 -16px;
  background: #fff7f1;
  padding: 8px 16px;
  align-items: center;
`;

const DataWrap = styled(Flex)`
  justify-content: space-between;
`;

const PaymentDateWrap = styled.span`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.26px;
  color: #222222;
`;

const OrderNumberWrap = styled.span`
  font-size: 13px;
  font-family: ${fonts.spoqa};
  font-weight: 400;
  color: #999999;
  margin-top: 5px;
`;

const InquiryButton = styled(Flex)`
  width: 96px;
  height: 32px;
  border: 1px solid #bdbdbd;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #777777;
  font-weight: 500;
`;

export default TopLabel;
