import React from "react";
import styled from "styled-components";
import rightArrow from "assets/icons/right-arrow.webp";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import useUserInfoQuery from "hooks/queries/user";
import fonts from "assets/styles/fonts";

const MyInfo = () => {
  const navigate = useNavigate();
  const { data } = useUserInfoQuery();
  return (
    <>
      <Title>내정보</Title>
      <Wrap
        onClick={() => {
          navigate(routes.selfVerification, {
            state: { purpose: "UPDATE_PHONENUMBER" },
          });
        }}
      >
        <div>
          <WrapTitle>휴대폰 번호</WrapTitle>
        </div>
        <Flex>
          <WrapContent>
            <Content>{data?.phone}</Content>
          </WrapContent>
          <Img src={rightArrow} alt="오른쪽화살표" />
        </Flex>
      </Wrap>
      <Line />
      <Wrap
        onClick={() => {
          navigate(routes.editEmail);
        }}
      >
        <div>
          <WrapTitle>이메일</WrapTitle>
        </div>
        <div>
          <Img src={rightArrow} alt="오른쪽화살표" />
        </div>
      </Wrap>
      <Line />
      <Wrap
        onClick={() => {
          navigate(routes.shippingManagement);
        }}
      >
        <div>
          <WrapTitle>배송지</WrapTitle>
        </div>
        <div>
          <Img src={rightArrow} alt="오른쪽화살표" />
        </div>
      </Wrap>
      <Bar />
    </>
  );
};

export default MyInfo;

const Title = styled.div`
  padding: 11px 0px 3px 0px;
  height: 24px;
  font-weight: normal;
  font-size: 13px;
  color: #666666;
`;

const Wrap = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const WrapContent = styled.span`
  height: 56px;
  margin-right: -7px;
`;
const Content = styled.span`
  line-height: 56px;
  vertical-align: middle;
  font-weight: normal;
  font-size: 14px;
  color: #444444;
  font-family: ${fonts.spoqa};
`;
const Img = styled.img`
  width: 44px;
  height: 44px;
  align-self: center;
`;
const WrapTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #222222;
`;
const Bar = styled.div`
  height: 24px;
  background-color: #ffffff;
`;
const Flex = styled.div`
  display: flex;
`;

const Line = styled.div`
  border-bottom: 1px solid #f2f2f2;
  margin: 0px 16px 0px 0px;
`;
