import { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import { useDispatch, useSelector } from "react-redux";
import { selectBuyInfo, setBuyInfo } from "features/buy/buySlice";
import { getSessionStorageItem } from "services/storageService";
import CheckboxDisabled from "assets/icons/checkbox-disabled.webp";
import CheckboxClicked from "assets/icons/checkbox-clicked.webp";

function AvailableAssets({ label, unit, maxWeight, goodsGram }) {
  const dispatch = useDispatch();
  const { BuyInfo } = useSelector(selectBuyInfo);
  const qty = getSessionStorageItem("qty");
  const TotalGoodsGram = qty * goodsGram;
  const Max = maxWeight > TotalGoodsGram ? TotalGoodsGram : maxWeight;

  const [weight, setWeight] = useState(null);

  const [isChecked, setIsChecked] = useState(false);
  const [isEntered, setIsEntered] = useState(false);

  useEffect(() => {
    if (BuyInfo?.useGram > 0) {
      setWeight(BuyInfo?.useGram);
      setIsChecked(true);
      setIsEntered(true);
    }
  }, []);

  // 사용할 가용자산 업데이트
  const updateUseGram = (value) => dispatch(setBuyInfo({ useGram: value }));

  const handleCheckBox = () => {
    setIsChecked((prev) => !prev);
    updateUseGram(!isChecked ? weight || 0 : 0);
  };

  const handleInputChange = (value) => {
    if (value === "") {
      setIsEntered(false);
      setWeight(null);
      updateUseGram(0);
      return;
    }

    setIsEntered(true);

    // 음수 무시
    if (value.startsWith("-")) {
      return;
    }

    // 최대 중량 초과 시 최대 중량으로 설정
    if (Number(value) > Max) {
      setWeight(Max);
      updateUseGram(Max);
      return;
    }

    // 그 외 값 저장
    const adjustedValue = Number(value);
    setWeight(adjustedValue);
    updateUseGram(adjustedValue);
  };

  const handleChange = (e) => {
    let value = e.target.value;

    // 금 0.005단위, 은 0.5 단위
    const step = BuyInfo?.assetType === "GOLD" ? 0.005 : 0.5;

    // value를 step으로 나눈 나머지가 0이 아니라면 가장 가까운 step 값으로 반올림
    if (Number(value) % step !== 0) {
      value = (Math.round(Number(value) / step) * step).toFixed(3);
    }

    handleInputChange(value);
  };

  return (
    <Container isChecked={isChecked}>
      <CheckboxIcon
        src={isChecked ? CheckboxClicked : CheckboxDisabled}
        alt="체크박스"
        onClick={handleCheckBox}
      />
      <InputWrapper>
        <Label>{label}</Label>
        <WeightInput
          type="number"
          readOnly={!isChecked}
          value={weight ?? ""}
          onChange={handleChange}
          placeholder={0}
          step={BuyInfo?.assetType === "GOLD" ? "0.005" : "0.5"}
        />
        <Unit isEntered={isEntered} isChecked={isChecked}>
          {unit}
        </Unit>
      </InputWrapper>
    </Container>
  );
}

export default AvailableAssets;

const Container = styled.div`
  width: 100%;
  height: 76px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.isChecked ? "#ffb382" : `${colors.purchaseBorder}`)};

  background-color: ${colors.inputInside};

  display: flex;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const CheckboxIcon = styled.img`
  margin-right: 11px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Label = styled.div`
  color: ${colors.black400};
  height: 24px;
  font-size: 14px;
  font-family: "SpoqaHanSansNeo";
  letter-spacing: -0.73px;
  line-height: 24px;
  vertical-align: center;
  margin-bottom: 2px;
`;

const WeightInput = styled.input`
  width: 100%;
  height: 36px;
  background-color: white;
  border: 1px solid ${colors.inputBorder};
  border-radius: 4px;
  color: ${colors.black400};
  font-family: "SpoqaHanSansNeo";
  text-align: right;
  letter-spacing: 0.56px;
  padding: 8px 22px 8px 8px;

  :read-only {
    color: ${colors.black900};
    font-weight: 400;
  }

  &::placeholder {
    color: ${colors.black900};
    font-size: 14px;
    font-weight: 400;
    font-family: "SpoqaHanSansNeo";
    text-align: right;
    letter-spacing: 0.56px;
  }

  /* 스텝퍼 삭제 */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Standard Property */
  appearance: none;

  /* Firefox */
  -moz-appearance: textfield;
`;

const Unit = styled.span`
  position: absolute;
  right: 15px;
  top: 74%;
  transform: translateY(-50%);

  color: ${({ isEntered }) => (isEntered ? colors.black400 : colors.black900)};
  font-family: "SpoqaHanSansNeo";
  font-size: 13px;

  ${({ isChecked }) => !isChecked && `color: ${colors.black900};`}
`;
