import React from "react";
import styled, { css } from "styled-components";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getMainGoodsList } from "services/apis";

import fonts from "assets/styles/fonts";
import colors from "assets/styles/color";
import { dateToString } from "utils/utils";
import timeIcon from "assets/icons/icon-time.webp";
import logoMini from "assets/images/logo-mini.webp";
import GoodsCard from "components/layouts/GoodsCard";
import { routes } from "routes";
import useScrollControl from "hooks/useScrollControl";
import Timezone from "components/common/Timezone";

const GoodsListMDPick = ({ goodsData, listType }) => {
  const navigate = useNavigate();
  const scrollContainerRef = useScrollControl();

  const {
    goods = [],
    time = "",
    goodsGroupName = "",
    goodsGroupId = 0,
  } = goodsData;
  let filteredGoods =
    goods &&
    goods.map((goods, i) => (
      <GoodsItem key={i.toString()} listType={listType}>
        <GoodsCard {...goods} index={i} />
      </GoodsItem>
    ));
  return (
    <GoodsListTopSection listType={listType}>
      <HeaderWrap>
        <HeaderTitleWrap>
          {/* <LogoMini src={logoMini} alt="logo" /> */}
          <HeaderTitle>{goodsGroupName}</HeaderTitle>
        </HeaderTitleWrap>
        {time !== "" && (
          <Timezone timeData={time} />
          // <TimeWrap>
          //   <TimeIcon src={timeIcon} alt="시간" />
          //   <TimeString>
          //     <span className="spoqa">
          //       {dateToString(time, "YYYY.M.D. HH")}
          //     </span>
          //     시 기준
          //   </TimeString>
          // </TimeWrap>
        )}
      </HeaderWrap>
      <GoodsListWrap ref={scrollContainerRef} listType={listType}>
        {filteredGoods}
      </GoodsListWrap>
      {goodsGroupName === "추천상품" ? (
        <BasicButton
          onClick={() => {
            navigate(`${routes.goods}/${goodsGroupId}`);
          }}
        >
          추천 상품 전체보기
        </BasicButton>
      ) : null}
    </GoodsListTopSection>
  );
};

const GoodsListTopSection = styled.div`
  padding: 0 16px 64px 16px;
  ${(props) =>
    props.listType !== "carousel"
      ? css`
          padding-bottom: 0;
        `
      : css``}
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LogoMini = styled.img`
  width: 54.4px;
  height: 12.4px;
`;

const HeaderTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.span`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: -0.48px;
  color: ${colors.black200};
  margin-top: 2px;
`;

const TimeWrap = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
`;

const TimeIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-right: 4px;
  margin-bottom: 1px;
  img {
    color: ${colors.black900};
  }
`;

const TimeString = styled.span`
  color: ${colors.black900};
  font-family: ${fonts.notoSansKR};
  font-size: 12px;
  letter-spacing: -0.24px;
  .spoqa {
    font-family: ${fonts.spoqa};
    font-size: 13px;
    letter-spacing: -0.26px;
  }
`;

const GoodsListWrap = styled.div`
  background-color: ${colors.white};
  display: flex;
  margin-top: 24px;
  ${(props) =>
    props.listType === "carousel"
      ? css`
          justify-content: flex-start;
          overflow-x: auto;
        `
      : css`
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;
        `}
`;

const GoodsItem = styled.li`
  height: 100%;
  cursor: pointer;
  ${(props) =>
    props.listType === "carousel"
      ? css`
          flex: 0 0 auto;
          width: 160px;
          margin-right: 9px;
          &:last-child {
            margin-right: 0;
          }
        `
      : css`
          flex: 0 1 calc(50% - 4px);
          max-width: calc(50% - 4px);
          &:nth-child(2n) {
            margin-left: 8px;
          }
        `}
`;

const BasicButton = styled.div`
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
`;

export default GoodsListMDPick;
