import React from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { getMainGoodsGroups } from "services/apis";
import { S3_BASE_URL } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import useScrollControl from "hooks/useScrollControl";
import { getOperatingSystem } from "utils/utils";
const GoodsGroup = () => {
  const navigate = useNavigate();

  const { data: groups = [] } = useQuery("mainGoodsGroups", () => {
    return getMainGoodsGroups().then(({ data: { success, data } }) => {
      if (success) {
        return data;
      }
    });
  });

  const handleClickGroup = (goodsGroupId) => {
    navigate(`${routes.goods}/${goodsGroupId}`, { state: { goodsGroupId } });
  };

  const scrollContainerRef = useScrollControl();
  const itemsPerRow = groups.length > 4 ? Math.ceil(groups.length / 2) : 4;
  const gridRowStyle = "grid-row";

  return (
    <div ref={scrollContainerRef} className="goods-group-section pointer">
      <div
        className={gridRowStyle}
        style={{
          marginBottom: "6px",
        }}
      >
        {groups
          .slice(0, itemsPerRow)
          .map(({ goodsGroupImage, goodsGroupName, goodsGroupId }) => {
            return (
              <div
                className="goods-group-wrap pointer"
                key={goodsGroupId}
                onClick={() => handleClickGroup(goodsGroupId)}
              >
                <div className="image-wrap">
                  <img
                    src={S3_BASE_URL + goodsGroupImage}
                    alt={goodsGroupName}
                  />
                  <div className="border"></div>
                </div>
                <FlexCenter>
                  <span>{goodsGroupName}</span>
                </FlexCenter>
              </div>
            );
          })}
      </div>
      <div className={gridRowStyle}>
        {groups
          .slice(itemsPerRow)
          .map(({ goodsGroupImage, goodsGroupName, goodsGroupId }) => {
            return (
              <div
                className="goods-group-wrap pointer"
                key={goodsGroupId}
                onClick={() => handleClickGroup(goodsGroupId)}
              >
                <div className="image-wrap">
                  <img
                    src={S3_BASE_URL + goodsGroupImage}
                    alt={goodsGroupName}
                  />
                  <div className="border"></div>
                </div>
                <span>{goodsGroupName}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const GoodsGroupSection = styled.div`
  overflow-x: auto;
  padding: 12px 0px 32px 0;
  display: inline-flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const GridRow = styled.div`
  overflow-x: scroll;
  display: inline-flex;
  gap: 10px;
  margin-bottom: ${(props) => props.firstRow && "6px"};
  padding-left: 16px;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 900px) {
    /* width: calc(130px + (480px - 360px) / 4); */
    width: 122%;
  }
`;

const GoodsGroupWrap = styled.div`
  border: 1px solid #eaeaea;
  padding: 4px 16px 4px 5px;
  display: inline-flex;
  border-radius: 24px;
  /* width: 100%; */
  &:last-child {
    margin-right: 16px;
  }

  .border {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 100%;
  }

  .image-wrap {
    position: relative;
    width: 40px;
    height: 40px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  span {
    margin-left: 14px;
    align-self: center;
    font-size: 14px;
    font-family: NotoSansKR;
    font-weight: 400;
    color: #333333;
    white-space: nowrap;
  }

  /* PC버전에서는 100vw가 아니라 480px 기준이 되도록 */
  @media only screen and (min-width: 900px) {
    /* width: calc(130px + (480px - 360px) / 4); */
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: ceneter;
`;
export default GoodsGroup;
