import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectModal,
  hideModal,
  clickConfirm,
} from "features/modal/modalSlice";
import { Button } from "components";
import colors from "assets/styles/color";

import Warning from "assets/icons/icon-warning.webp";
import Error from "assets/icons/icon-error.webp";

const icons = {
  Warning: Warning,
  Error: Error,
};

function BasicModal() {
  const dispatch = useDispatch();
  const { icon, modalVisible, modalType, message, confirmLabel, callback } =
    useSelector(selectModal);

  if (!modalVisible) return null;
  const iconSrc = icons[icon];

  const MessageStyle = () => {
    const regex = /<(.*?)\/>/g;
    const parts = message?.split(regex);
    if (!message) return null;
    return parts.map((text, index) => {
      return (
        <Message
          className={index % 2 === 1 && "orange"}
          key={`${text}-${index}`}
        >
          {text}
        </Message>
      );
    });
  };

  return (
    <Container>
      <ModalBackdrop onClick={() => dispatch(hideModal())} />
      {modalVisible && (
        <ModalBody modalVisible={modalVisible}>
          {icon && <Icon src={iconSrc} alt="아이콘" />}
          <MessageWrapper>
            <MessageStyle />
          </MessageWrapper>
          <ButtonWrapper modalType={modalType}>
            {modalType === "Alert" ? (
              <Button
                text="확인"
                size="sm"
                variant="round"
                onClickFn={() => {
                  dispatch(hideModal());
                  callback && callback();
                }}
              />
            ) : (
              <ConfirmWrapper>
                <Button
                  text="취소"
                  size="sm"
                  variant="round"
                  disabled="modal"
                  onClickFn={() => dispatch(hideModal())}
                />
                <Button
                  text={confirmLabel || "확인"}
                  size="sm"
                  variant="round"
                  onClickFn={() => dispatch(clickConfirm())}
                />
              </ConfirmWrapper>
            )}
          </ButtonWrapper>
        </ModalBody>
      )}
    </Container>
  );
}

export default BasicModal;

const Container = styled.div`
  ${({ theme }) => theme.flexCenter};
  height: 100%;
`;

const ModalBody = styled.div`
  background-color: white;
  width: 90%;
  height: auto;

  position: fixed;
  top: 47.5%;
  left: 50%;
  z-index: 1020;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  padding: 32px 16px 16px 16px;

  ${({ theme }) => theme.flexBox("column", "flex-start", "center")}

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 432px;
  }
`;

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  cursor: pointer;

  inset: 0;
  background-color: black;
  opacity: 0.54;
  z-index: 1010;
`;
const Icon = styled.img`
  width: 48px;
  height: 48px;

  margin-bottom: 10px;
`;

const Message = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.black200};
  letter-spacing: -0.36px;

  &.orange {
    color: ${colors.textOrange};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;

  button:last-child {
    font-weight: 700;
  }
`;

const ConfirmWrapper = styled.div`
  display: flex;
  width: 100%;
  /* 첫 버튼 margin 나누기 */
  button:first-child {
    margin-right: 8px;
  }
`;

const MessageWrapper = styled.span`
  text-align: center;
  line-height: 1.4;
  white-space: pre-line;
`;
