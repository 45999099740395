import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { routes } from "routes";
const ButtonToList = () => {
  const navigate = useNavigate();
  const handleClickList = () => {
    navigate(routes.orderHistory);
  };
  return (
    <ButtonWrap>
      <Button
        className="pointer"
        onClick={() => {
          handleClickList();
        }}
      >
        주문목록보기
      </Button>
    </ButtonWrap>
  );
};

export default ButtonToList;

const ButtonWrap = styled.div`
  margin: 48px 0px 0px 0px;
  display: flex;
`;
const Button = styled.button`
  border: 1px solid #bdbdbd;
  color: #444444;
  font-size: 16px;
  font-weight: normal;
  height: 48px;
  flex: 1;
  border-radius: 8px;
  background-color: #fcfcfc;
`;
