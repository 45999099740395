import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

function CustomModal({ modalVisible, modalClose, children }) {
  if (!modalVisible) return null;
  return createPortal(
    <Container>
      <ModalBackdrop onClick={modalClose} />
      {modalVisible && (
        <ModalBody modalVisible={modalVisible}>{children}</ModalBody>
      )}
    </Container>,
    document.getElementById("modal-root"),
  );
}

export default CustomModal;

const Container = styled.div`
  ${({ theme }) => theme.flexCenter};
  height: 100%;
`;
const ModalBody = styled.div`
  background-color: white;
  width: 90%;
  height: auto;

  position: fixed;
  top: 47.5%;
  left: 50%;
  z-index: 1020;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  padding: 16px;

  ${({ theme }) => theme.flexBox("column", "flex-start", "center")}

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 432px;
  }
`;

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  cursor: pointer;

  inset: 0;
  background-color: black;
  opacity: 0.54;
  z-index: 1010;
`;
