import { useEffect } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import { useDispatch, useSelector } from "react-redux";
import { hideSnackBar, selectSnackBar } from "features/snackbar/snackbarSlice";

function SnackBar() {
  // 기본값으로는 2500이 지정되어있지만 설정한 값에 따라 변경할 수 있습니다.
  const dispatch = useDispatch();
  const { show, message, time } = useSelector(selectSnackBar);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(hideSnackBar());
    }, time);

    return () => clearTimeout(timer);
  }, [show, time]);

  const MessageStyle = () => {
    const regex = /<(.*?)\/>/g;
    const parts = message?.split(regex);
    if (!message) return null;
    return parts.map((text, index) => {
      return (
        <Message
          className={index % 2 === 1 && "orange"}
          key={`${text}-${index}`}
        >
          {text}
        </Message>
      );
    });
  };
  if (!show) return null;
  return (
    <>
      {show && (
        <SnackBarBody onClick={() => dispatch(hideSnackBar())}>
          <MessageStyle />
        </SnackBarBody>
      )}
    </>
  );
}

export default SnackBar;

const SnackBarBody = styled.div`
  position: fixed;
  bottom: 88px;
  /* 중앙 정렬 */
  left: 50%;
  transform: translateX(-50%);

  width: 90%;
  height: 48px;
  border-radius: 4px;
  background-color: ${colors.snackbarBackground};
  box-shadow: 0px 4px 8px ${colors.snackbarShadow};
  padding: 14px 16px;
  z-index: 1050;

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 432px;
  }
`;

const Message = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: white;

  &.orange {
    font-weight: 700;
    color: #ffb100;
  }
`;
