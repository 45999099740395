import { useCallback, useEffect } from "react";

const useDebouncedEffect = (func = () => {}, delay = 1000, deps = []) => {
  const callback = useCallback(func, deps);

  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [callback, delay]);
};

export default useDebouncedEffect;
