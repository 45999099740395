const fonts = {
  notoSansKR: "Noto Sans KR",
  spoqa: "SpoqaHanSansNeo",
  montserrat: "Montserrat",
};

export const fontWeight = {
  thin: 100,
  light: 300, // === spoqa: thin
  regular: 400, // === spoqa: light
  medium: 500, // === spoqa: regular
  semibold: 600,
  bold: 700,
};

export default fonts;
