import { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "routes";
import { selectBuyInfo, setDeliveryInfo } from "features/buy/buySlice";
import { Accordion } from "components";
import colors from "assets/styles/color";
import { getAddressList } from "services/apis";
import { PhoneNumberFormat } from "utils/utils";

import Tab from "./Tab";
import PickUpShop from "./PickUpShop";
import DeliveryForm from "./DeliveryForm";
import { useNavigate, useParams } from "react-router-dom";

function PickUpMethod({ earliestVisitDate, isPost, isShopTaken }) {
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { isDelivery } = BuyInfo;
  const [AddressList, setAddressList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAddressList().then((res) => {
      setAddressList(res.data.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <Accordion marginTop="16px" title="수령 방법 선택">
        <PaddingDiv className="flex">
          <Tab isPost={isPost} isShopTaken={isShopTaken} />
        </PaddingDiv>
      </Accordion>
      {isDelivery ? (
        <Accordion marginTop="16px" title="택배 수령지 입력">
          {!isLoading && AddressList?.length === 0 ? (
            <DeliveryForm /> // 신규 입력폼
          ) : (
            <DefaultDelivery AddressList={AddressList} isLoading={isLoading} /> // 기본 배송지
          )}
        </Accordion>
      ) : (
        // 대리점 찾기
        <PickUpShop earliestVisitDate={earliestVisitDate} />
      )}
    </div>
  );
}

const DefaultDelivery = ({ AddressList, isLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { DeliveryInfo } = useSelector(selectBuyInfo);
  const defaultAddress =
    DeliveryInfo?.postCode === ""
      ? AddressList?.find((el) => el?.isDefault === true)
      : DeliveryInfo;

  useEffect(() => {
    if (!isLoading && defaultAddress !== undefined) {
      dispatch(setDeliveryInfo(defaultAddress));
    }
  }, [isLoading]);

  return (
    <PaddingDiv>
      <TitleWrap>
        <DeliveryBold>
          {defaultAddress?.receiver}
          {defaultAddress?.addressName && " / " + defaultAddress?.addressName}
          {defaultAddress?.isDefault && <Default>(기본)</Default>}
        </DeliveryBold>
        <ChangeBtn
          onClick={() => {
            navigate(routes.shippingManagement, {
              state: { itemId: id },
            });
          }}
        >
          변경
        </ChangeBtn>
      </TitleWrap>
      <PhoneWrap>
        <DeliveryNumber>
          {defaultAddress?.phone && PhoneNumberFormat(defaultAddress?.phone)}
          {defaultAddress?.mobile &&
            " | " + PhoneNumberFormat(defaultAddress?.mobile)}
        </DeliveryNumber>
      </PhoneWrap>
      <DeliveryNumber>({defaultAddress?.postCode})</DeliveryNumber>
      <DeliveryNormal>
        {" "}
        {defaultAddress?.address} {defaultAddress?.addressDetail}
      </DeliveryNormal>
      {defaultAddress?.memo && (
        <MemoWrap>
          <DeliveryNormal className="title">수령방법:</DeliveryNormal>
          <DeliveryNormal className="content">
            {defaultAddress?.memo}
          </DeliveryNormal>
        </MemoWrap>
      )}
    </PaddingDiv>
  );
};

export default PickUpMethod;

const PaddingDiv = styled.div`
  padding: 16px;
  width: 100%;
  height: auto;
`;

const DeliveryBold = styled.span`
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: ${colors.black300};
`;

const DeliveryNormal = styled.span`
  font-size: 14px;
  color: ${colors.black700};

  &.title {
    color: #4d4d4d;
    font-weight: 500;
    letter-spacing: -0.28px;
  }

  &.content {
    color: ${colors.black400};
    font-weight: 400;
  }
`;

const DeliveryNumber = styled(DeliveryNormal)`
  font-family: "SpoqaHanSansNeo";
`;

const PhoneWrap = styled.div`
  margin: 10px 0 20px 0;
`;

const MemoWrap = styled.div`
  margin-top: 16px;
`;

const ChangeBtn = styled.button`
  width: 40px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid ${colors.black600};

  color: ${colors.black600};
  font-size: 12px;
  cursor: pointer;
`;

const TitleWrap = styled.div`
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
`;

const Default = styled.span`
  font-weight: normal;
  color: #ea792b;
  font-size: 15px;
  margin-left: 5px;
`;
