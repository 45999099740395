// UserInfo.js
import React from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import { Accordion } from "components";
import { PhoneNumberFormat } from "utils/utils";

const UserInfoComponent = ({ name, phone, email }) => {
  return (
    <Accordion marginTop="16px" title={"주문자 " + name}>
      <PaddingDiv>
        <UserInfo>
          연락처<span>{PhoneNumberFormat(phone)}</span>
        </UserInfo>
        <UserInfo>
          이메일<span>{email}</span>
        </UserInfo>
      </PaddingDiv>
    </Accordion>
  );
};

export default UserInfoComponent;

const UserInfo = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${colors.black700};

  :first-child {
    margin-bottom: 9px;
  }

  span {
    font-family: "SpoqaHanSansNeo";
    font-weight: 400;
    margin-left: 28px;
  }
`;

const PaddingDiv = styled.div`
  padding: 16px;
`;
