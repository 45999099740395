import React, { useEffect } from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import {
  closeBottomSheet,
  hideBottomSheet,
  selectBottomSheet,
} from "features/bottomsheet/bottomsheetSlice";

import colors from "assets/styles/color";
import Cancle from "assets/icons/cancle-bottomsheet.webp";

/**
 * distanceFromTop: top으로부터의 거리
 * title: 바텀시트 상단 제목
 */
function BottomSheet({
  children,
  distanceFromTop,
  title,
  hideGreyBar = false,
  onClose,
}) {
  const dispatch = useDispatch();
  const { state } = useSelector(selectBottomSheet);

  useEffect(() => {
    dispatch(hideBottomSheet());
  }, []);

  const handleCloseBottomSheet = () => {
    if (onClose) onClose();
    dispatch(closeBottomSheet());
  };

  const HeightControlled = () => {
    let className = "";
    if (state === "Open") {
      className = "slideInTop";
    } else if (state === "Close") {
      className = "slideInBottom";
    } else {
      className = "hidden";
    }
    return className;
  };

  return (
    <Container>
      <DisableScroll state={state} />
      <BottomSheetContainer
        className={HeightControlled()}
        distanceFromTop={distanceFromTop}
      >
        <Header>
          <Title>{title}</Title>
          <CancleIcon
            onClick={handleCloseBottomSheet}
            src={Cancle}
            alt="바텀시트 닫기"
          />
          {hideGreyBar === false && <GrayBar />}
        </Header>
        <ScrollArea>{children}</ScrollArea>
      </BottomSheetContainer>
      <ModalBackdrop onClick={handleCloseBottomSheet} state={state} />
    </Container>
  );
}

export default BottomSheet;

const SlideInTop = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const SlideInBottom = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
`;

const DisableScroll = createGlobalStyle`
  body {
    overflow: ${({ state }) => (state === "Open" ? "hidden" : "auto")};
    height: ${({ state }) => (state === "Open" ? "100%" : "auto")};
  }
`;

const Container = styled.div`
  ${({ theme }) => theme.flexCenter};
  height: 100%;
`;

// 모달 배경
const ModalBackdrop = styled.div`
  position: fixed;
  ${({ theme }) => theme.flexCenter};

  display: ${({ state }) => (state === "Open" ? "" : "none")};
  inset: 0;
  background-color: black;
  opacity: 0.54;
  z-index: 999;
`;

const BottomSheetContainer = styled.div`
  background-color: white;
  width: 100%;
  height: ${({ distanceFromTop }) =>
    distanceFromTop ? distanceFromTop : "auto"};
  z-index: 1000;
  position: fixed;
  bottom: 0;

  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  &.slideInTop {
    animation: ${SlideInTop} 0.3s ease-out forwards;
  }

  &.slideInBottom {
    animation: ${SlideInBottom} 0.2s ease-out forwards;
  }

  &.hidden {
    display: none;
  }

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 480px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 50px;
  overflow: hidden;
  position: relative;
  padding: 0 16px;
  ${({ theme }) => theme.flexBox("row", "center", "end")}
`;

const GrayBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 90%;
  height: 2px;
  background-color: ${colors.purchaseBorder};
`;

const ScrollArea = styled.div`
  width: 100%;
  height: 100%;

  overflow-y: auto;
`;

const CancleIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

const Title = styled.div`
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.34px;
  color: ${colors.black200};
  flex: 1;
  text-align: center;
  vertical-align: middle;
`;
