import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Zoom } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import { S3_BASE_URL } from "utils/constants";
import colors from "assets/styles/color";

export default function ImageSlider({ data, isSoldOut }) {
  return (
    <div className="detail-aspect-ratioBox">
      <Swiper
        spaceBetween={0}
        zoom={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Zoom]}
        className={`detail-styled-swiper ${isSoldOut ? "isSoldOut" : ""}`}
      >
        {data?.map((item, index) => {
          return (
            <SwiperSlide key={index} className="detail-swiper-slide">
              <img src={S3_BASE_URL + "/" + item} alt="상품이미지" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

const AspectRatioBox = styled.div`
  overflow: hidden;
  padding-top: 100%;
  position: relative;
`;

const StyledSwiper = styled(Swiper)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;

  &.isSoldOut {
    opacity: 0.5;
  }

  .swiper-slide {
    ${({ theme }) => theme.flexCenter}
    height: auto;
    object-fit: contain;
    background-color: ${colors.white700};
    border: 1px solid ${colors.imageBorder};
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* 버튼 커스텀 */
  .swiper-pagination {
    margin-bottom: 24px;
  }

  .swiper-pagination-bullet {
    border-radius: 0;
    width: 8px;
    height: 2px;
    text-align: center;
    font-size: 12px;
    color: #000;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.2);
  }

  .swiper-pagination-bullet-active {
    width: 24px;
    height: 4px;
    color: ${colors.white};
    background: #616161;
  }
`;
