import Header from "pages/home/components/Header";
import React, { useEffect } from "react";
import styled from "styled-components";
import CustomerService from "./components/CustomerService";
import Guidance from "./components/Guidance";
import OrderInfo from "./components/OrderInfo";
import UserInfo from "./components/UserInfo";
import Footer from "components/templates/Footer";
import DefaultLayout from "components/layouts/DefaultLayout";

const MyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout headerVisible={false} navigationVisible={true}>
      <Header />
      <UserInfo />
      <Bar />
      <OrderInfo />
      <Bar />
      <Guidance />
      <Bar />
      <CustomerService />
      <Footer />
      <GrayArea />
    </DefaultLayout>
  );
};

export default MyPage;

const Bar = styled.div`
  height: 12px;
  background-color: #f2f2f2;
`;

const GrayArea = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  height: 100px;
  flex: 1;
`;
