import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  icon: "",
  modalVisible: false,
  modalType: "",
  message: "",
  confirmLabel: "",
  callback: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.modalVisible = true;
      state.modalType = "Alert";
      const { icon, message, callback } = action.payload;
      state.icon = icon || "";
      state.message = message;
      state.callback = callback;
    },
    showConfirm: (state, action) => {
      state.modalVisible = true;
      state.modalType = "Confirm";
      const { icon, message, confirmLabel, callback } = action.payload;
      state.icon = icon || "";
      state.message = message || "";
      state.confirmLabel = confirmLabel || "확인";
      state.callback = callback;
    },
    clickConfirm: (state) => {
      if (state.callback) {
        state.callback();
        state.callback = null;
      }
    },
    hideModal: (state) => {
      state.modalVisible = false;
      state.icon = "";
      state.modalType = "";
      state.message = "";
      state.confirmLabel = "";
    },
  },
});

export const { showAlert, showConfirm, clickConfirm, hideModal } =
  modalSlice.actions;
export const selectModal = (state) => state.modal;

export default modalSlice.reducer;
