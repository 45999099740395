import React, { useState } from "react";
import ic_close from "assets/images/ic_close.png";
import logo from "assets/images/logo-main.webp";
import LoginButtons from "./component/LoginButtons";
import IdLogin from "./component/IdLogin";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

const Login = () => {
  const navigate = useNavigate();
  const [isIdLogin, setIsIdLogin] = useState(false);

  const handleClickSignUp = () => {
    navigate(routes.authSignUpId);
  };

  const handleClickFindId = () => {
    navigate(routes.authFindId);
  };

  const handleClickResetPassword = () => {
    navigate(routes.authResetPassword);
  };

  return (
    <div className="login_wrap" style={{ paddingBottom: 40 }}>
      <img
        src={ic_close}
        alt="x"
        className="close_bt pointer"
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className="login_top">
        <h3>스마트한 모바일 금은방</h3>
        <img
          src={logo}
          style={{ width: "183px", height: "auto" }}
          alt="금방금방"
        />
      </div>
      {!isIdLogin ? (
        <LoginButtons
          onClickIdLogin={() => {
            setIsIdLogin(true);
          }}
        />
      ) : (
        <IdLogin />
      )}
      <div className="login_bottom">
        <ul className="id_pass">
          <li className="pointer" onClick={handleClickFindId}>
            아이디 찾기
          </li>
          <li className="divider">|</li>
          <li className="pointer" onClick={handleClickResetPassword}>
            비밀번호 재설정
          </li>
        </ul>
        <p className="pointer" onClick={handleClickSignUp}>
          회원가입
        </p>
      </div>
    </div>
  );
};

export default Login;
