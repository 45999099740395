import React from "react";
import styled from "styled-components";
import iconLeftArrow from "assets/icons/icon-left-arrow.webp";
import { useNavigate } from "react-router-dom";
import logo from "assets/icons/logo-main-mini.svg";
import { routes } from "routes";

import { useScroll } from "hooks/useScroll";
const LogoHeader = ({ title }) => {
  const navigate = useNavigate();
  const isScrolled = useScroll(); // 쉐도우용

  const handleClickGoBack = () => {
    navigate(-1);
  };

  const handleGoHome = () => {
    navigate(routes.home);
  };

  return (
    <Container isScrolled={isScrolled}>
      <GoBackIcon onClick={handleClickGoBack}>
        <img alt="뒤로가기" src={iconLeftArrow} />
      </GoBackIcon>
      <LogoContainer onClick={handleGoHome}>
        <LogoImage src={logo} alt="금방금방로고" />
      </LogoContainer>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 56px;
  background: white;
  ${({ theme }) => theme.flexCenter}
  z-index: 10;
  border-bottom: 1px solid
    ${({ isScrolled }) => (!isScrolled ? `#e0e0e0` : `transparent`)};
  box-shadow: ${({ isScrolled }) =>
    isScrolled && `0px 2px 2px rgba(0, 0, 0, 0.04)`};
`;

// 터치타겟 확보
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 48px;
  margin-left: -48px;
`;

const LogoImage = styled.img`
  width: 130.3px;
  height: auto;
`;

const GoBackIcon = styled.button`
  ${({ theme }) => theme.flexCenter}
  cursor: pointer;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
  img {
    width: 48px;
    height: 48px;
  }
`;

export default LogoHeader;
