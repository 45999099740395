import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";

import { S3_BASE_URL } from "utils/constants";
import Explanation from "./Explanation";
import BasicInfo from "./BasicInfo";
import DetailInfo from "./DetailInfo";

function InfoTab({
  goodsId,
  comment,
  goodsName,
  goodsInfos,
  goodsDetailImages,
  goodsDetailInfoImage,
  activeTab,
  handleTab,
}) {
  const TabTitle = [
    { id: "INFO", name: "상품설명" },
    {
      id: "REVIEW",
      name: (
        <div>
          리뷰 <span className="vreview-review-count">0</span>
        </div>
      ),
    },
  ];

  const TabContents = useMemo(() => {
    return activeTab === "INFO" ? (
      <>
        <Explanation goodsDetailImages={goodsDetailImages} />
        <BasicInfo goodsName={goodsName} goodsInfos={goodsInfos} />
        <DetailInfo comment={comment} />
        <ImageContainer>
          <DetailInfoImage
            src={S3_BASE_URL + goodsDetailInfoImage}
            alt="상품 상세정보 이미지"
            loading="lazy"
          />
        </ImageContainer>
      </>
    ) : (
      <div className="prdDetail" style={{ width: "100%", minHeight: "700px" }}>
        <div className="vreview-review-board-widget"></div>
      </div>
    );
  }, [
    goodsId,
    comment,
    goodsName,
    goodsInfos,
    goodsDetailImages,
    goodsDetailInfoImage,
    activeTab,
  ]);

  return (
    <>
      <TabArea>
        <input type="hidden" vreview-pdp-product-id={goodsId} />
        {TabTitle.map((tab) => (
          <Tab
            key={tab.id}
            // 선택된 탭이면 배경색 바뀌도록
            className={activeTab === tab.id ? "active" : ""}
            onClick={() => {
              handleTab(tab.id);
            }}
          >
            {/* 탭 라벨 */}
            {tab.name}
          </Tab>
        ))}
      </TabArea>

      <TabContentsArea>{TabContents}</TabContentsArea>
    </>
  );
}

const TabArea = styled.ul`
  width: 100%;
  height: 60px;
  padding: 12px 16px 0;
  border-bottom: 1px solid ${colors.Bar};
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: ${colors.white};
  ${({ theme }) => theme.flexCenter}
`;

const Tab = styled.li`
  ${({ theme }) => theme.flexCenter}

  width: calc(100%/2);
  height: 48px;
  margin-right: 11px;
  color: ${colors.black900};
  letter-spacing: -0.28px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;

  &.active {
    font-weight: 700;
    color: ${colors.dropdownOrange};
    border-bottom: 2px solid ${colors.dropdownOrange};
  }

  &:last-child {
    margin-right: 0;
  }
`;

const TabContentsArea = styled.div`
  flex-direction: column;
  padding: 16px 0;

  ${({ theme }) => theme.flexCenter}
`;
const DetailInfoImage = styled.img`
  width: 100%;
`;

const ImageContainer = styled.div`
  background-color: ${colors.inputInside};
  padding: 24px 24px 196px;
`;

export default InfoTab;
