import { useQuery } from "react-query";
import { getUserInfo } from "services/apis";

export const QUERY_KEY = "/user-info";

const fetcher = () => {
  return getUserInfo().then(({ data: { success, data } }) => {
    if (success) {
      return data;
    }
  });
};

const useUserInfoQuery = () => {
  return useQuery(QUERY_KEY, fetcher);
};

export default useUserInfoQuery;
