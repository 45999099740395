import React, { useEffect, useState } from "react";

const NumberInput = ({
  value: propValue = "",
  maxLength = 9999,
  onChange,
  ...rest
}) => {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const handleChangeInput = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "").substring(0, maxLength);
    onChange && onChange(value);
    setValue(value);
  };
  return (
    <input
      type="text"
      value={value}
      onChange={handleChangeInput}
      autoComplete="new-password"
      {...rest}
    />
  );
};

export default NumberInput;
