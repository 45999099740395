import React from "react";
import styled from "styled-components";
import rightArrow from "assets/icons/right-arrow-orange.webp";
import { S3_BASE_URL } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { getFormatTime, NumberFormat } from "utils/utils";
import { useDispatch } from "react-redux";
import {
  resetBuyInfo,
  resetShopInfo,
  resetDeliveryInfo,
} from "features/buy/buySlice";
import fonts from "assets/styles/fonts";
import colors from "assets/styles/color";

const OrderList = ({ orderList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickDetail = (goodsTransId) => {
    dispatch(resetBuyInfo());
    dispatch(resetShopInfo());
    dispatch(resetDeliveryInfo());
    navigate(`/my-page/order-detail/${goodsTransId}`, {
      state: { orderId: goodsTransId },
    });
  };

  return (
    <Container>
      {orderList.length !== 0 ? (
        <ul>
          {orderList?.map((item, index) => {
            return (
              <Item key={index}>
                <Header>
                  <Status>
                    <span>{item?.goodsStatusDescription}</span>
                  </Status>
                  <ConfirmDate>
                    <span>결제일: </span>
                    <NumberText>
                      {item.createdAt ? getFormatTime(item?.createdAt) : "-"}
                    </NumberText>
                  </ConfirmDate>
                </Header>
                <Bar />
                <Content>
                  <div style={{ flex: 1 }}>
                    <Img
                      className="pointer"
                      src={S3_BASE_URL + "/" + item.goodsImage}
                      onClick={() => {
                        navigate(`/detail/${item.goodsId}`);
                      }}
                    />
                  </div>
                  <div style={{ flex: 1.5 }}>
                    {item.brand && <InfoText>{item.brand || ""}</InfoText>}
                    <InfoBold>
                      {(item.goodsName &&
                        `${item.goodsName}${
                          item.optionName ? `(${item.optionName})` : ""
                        }`) ||
                        "-"}
                    </InfoBold>
                    <div style={{ height: "13px" }} />
                    <Flex>
                      <InfoText>결제가</InfoText>
                      <div style={{ display: "flex" }}>
                        <InfoBold>{NumberFormat(item.paymentPrice)}</InfoBold>
                        <div
                          style={{
                            fontWeight: "normal",
                            fontSize: "15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          원
                        </div>
                      </div>
                    </Flex>
                    <Flex>
                      <InfoText>자산</InfoText>
                      <InfoText>
                        {item.assetType
                          .replace("GOLD", "금")
                          .replace("SILVER", "은")}
                      </InfoText>
                    </Flex>
                    <Flex>
                      <InfoText>중량</InfoText>
                      <InfoText>{`${NumberFormat(item.totalGram)}g`}</InfoText>
                    </Flex>
                    <Flex>
                      <InfoText>수량</InfoText>
                      <InfoText>{NumberFormat(item.qty)}</InfoText>
                    </Flex>
                  </div>
                </Content>
                <DetailWrap>
                  <Detail
                    className="pointer"
                    onClick={() => {
                      handleClickDetail(item.goodsTransId);
                    }}
                  >
                    <div style={{ height: "100%" }}>
                      <span>주문상세</span>
                    </div>
                    <div style={{ height: "18px" }}>
                      <img
                        src={rightArrow}
                        alt="상세조회"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </div>
                  </Detail>
                </DetailWrap>
              </Item>
            );
          })}
        </ul>
      ) : (
        <NotOrderList>주문내역이 없습니다.</NotOrderList>
      )}

      <BottomBar />
    </Container>
  );
};

export default OrderList;
const Container = styled.div`
  width: 100%;
  padding: 8px 16px 48px 16px;
  background-color: #fcfcfc;
  border: 1px solid #f4f4f4;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Item = styled.li`
  width: 100%;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  margin-top: 8px;
`;

const Header = styled.div`
  height: 47px;
  padding: 12px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  height: auto;
  padding: 16px 16px 0px 16px;
  justify-content: space-between;
`;
const DetailWrap = styled.div`
  height: auto;
  margin: 8px 16px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Detail = styled.button`
  display: flex;
  height: 21px;
  font-weight: normal;
  font-size: 15px;
  color: #ff842b;
  align-items: end;
  margin-right: -4px;
`;
const Bar = styled.div`
  border-bottom: 1px solid ${colors.white000};
  margin: 0px 16px;
`;

const Status = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #222222;
`;
const ConfirmDate = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: #999999;
  padding-top: 6px;
`;

const InfoText = styled.div`
  font-weight: normal;
  font-size: 15px;
  display: flex;
  align-items: center;
  height: 21px;
  margin-bottom: 2px;
  color: #777777;
`;
const InfoBold = styled.div`
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  height: 21px;
  color: #222222;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Img = styled.img`
  width: 72px;
  height: 72px;
  background-color: rgb(221, 221, 221);
  border: 1px solid #d6d6d6;
`;

const BottomBar = styled.div`
  height: 48px;
`;

const NotOrderList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  font-size: 16px;
  flex: 1;
`;

const NumberText = styled.span`
  font-family: ${fonts.spoqa};
`;
