import React from "react";
import styled from "styled-components";

const Header = ({ header, subHeader }) => {
  return (
    <div>
      <HeaderName>
        <Name>{header}</Name>
      </HeaderName>
      {subHeader && (
        <Guide>
          <GuideText>{subHeader}</GuideText>
        </Guide>
      )}
    </div>
  );
};

export default Header;
const HeaderName = styled.div`
  height: 56px;
  padding: 20px 0px 16px 24px;
`;
const Name = styled.span`
  font-weight: normal;
  font-size: 14px;
  color: #ff6e0e;
`;
const Guide = styled.div`
  height: 30px;
  padding: 0px 0px 0px 24px;
  margin-top: -6px;
  display: flex;
  align-items: center;
`;
const GuideText = styled.div`
  font-weight: bold;
  font-size: 21px;
  color: #222222;
`;
