import { useEffect } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { getAddressList } from "services/apis";
import checkMini from "assets/icons/check-mini.webp";
import checkActive from "assets/icons/check-active.webp";
import checkInactive from "assets/icons/check-disactive.webp";
import exclamationMark from "assets/icons/exclamation-mark.webp";
import Error from "assets/icons/icon-error.webp";

import {
  selectBuyInfo,
  setBuyInfo,
  setDeliveryInfo,
} from "features/buy/buySlice";
import { dateToString, validateInfo } from "utils/utils";

import { Accordion } from "components";
import colors from "assets/styles/color";
import fonts, { fontWeight } from "assets/styles/fonts";
import Flex from "components/common/flex/Flex";
import PickUpShop from "pages/goods-purchase/components/PickUpShop";
import DeliveryForm from "pages/goods-purchase/components/DeliveryForm";
import DefaultDelivery from "pages/order-detail/components/DefaultDelivery";

import { putMyPageTransDelivery } from "services/apis";
import { showAlert } from "features/modal/modalSlice";
import DeliveryCorpInfo from "./DeliveryCorpInfo";

const TRANS_HISTORIES = {
  DELIVERY: [
    { status: "PAYMENT_COMPLETED", description: "결제완료" },
    { status: "PREPARING", description: "상품준비중" },
    { status: "DELIVERY", description: "배송중" },
    { status: "RECEIVED", description: "상품수령" },
    { status: "CONFIRMED", description: "구매확정" },
  ],
  VISIT: [
    { status: "PAYMENT_COMPLETED", description: "결제완료" },
    { status: "PREPARING", description: "상품준비중" },
    { status: "ARRIVED", description: "대리점도착" },
    { status: "RECEIVED", description: "상품수령" },
    { status: "CONFIRMED", description: "구매확정" },
  ],
};

const useGetAddress = () => {
  const { data: AddressList, isLoading } = useQuery(
    ["address-list"],
    () => getAddressList(),
    {
      select: (data) => data?.data?.data,
    },
  );

  return { AddressList, isLoading };
};

const HowReceive = ({
  goodsTrans = {},
  history = [],
  earliestVisitDate,
  delivery,
}) => {
  const transHistory =
    TRANS_HISTORIES[goodsTrans.isDelivery ? "DELIVERY" : "VISIT"];
  const { canModifyDelivery, isPost, isShopTaken } = goodsTrans;
  const { BuyInfo, DeliveryInfo } = useSelector(selectBuyInfo);
  const { isDelivery, shopId, visitDate } = BuyInfo;
  const {
    postCode,
    address,
    addressDetail,
    addressName,
    receiver,
    phone,
    mobile,
    memo,
    isDefault,
  } = DeliveryInfo;
  const dispatch = useDispatch();
  const { AddressList = [] } = useGetAddress();
  const originalDelivery = goodsTrans?.isDelivery === true; // 기존에 배달 선택
  const originalShop = goodsTrans?.isDelivery === false; // 기존에 매장 선택

  const handleSelectHowReceive = (e) => {
    const buttonElement = e.currentTarget;
    const buttonText = buttonElement.innerText.trim();

    if (!canModifyDelivery) {
      dispatch(
        showAlert({
          icon: "Error",
          message: "상품 수령 방법을 변경할 수 없습니다.",
        }),
      );
      return;
    }

    const isDeliveryOption = buttonText === "택배 수령";
    const condition = isDeliveryOption ? isPost : isShopTaken;
    const original = isDeliveryOption ? originalDelivery : originalShop;

    if (condition || original) {
      dispatch(setBuyInfo({ isDelivery: isDeliveryOption }));
    } else {
      dispatch(
        showAlert({
          icon: "Error",
          message: isDeliveryOption
            ? "대리점 방문만 가능한 상품입니다."
            : "택배 수령만 가능한 상품입니다.",
        }),
      );
    }
  };

  useEffect(() => {
    if (isDelivery === null && goodsTrans?.isDelivery !== undefined) {
      dispatch(setBuyInfo({ isDelivery: goodsTrans.isDelivery }));
    }
  }, [goodsTrans?.isDelivery, isDelivery, dispatch]);

  const queryClient = useQueryClient();
  const ChangeDelivery = useMutation(
    ["change-delivery"],
    () =>
      putMyPageTransDelivery(goodsTrans?.goodsTransId, {
        isDelivery,
        delivery: {
          postCode,
          address,
          addressDetail,
          addressName,
          receiver,
          phone,
          mobile,
          memo,
          isDefault,
        },
        visitDate,
        shopId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "order",
          "detail",
          goodsTrans?.goodsTransId,
        ]);
      },
    },
  );

  const handleSave = () => {
    const info = isDelivery
      ? { receiver, phone, postCode, address, addressDetail }
      : { shopId: BuyInfo?.shopId, visitDate };

    const { isValid, message } = validateInfo(isDelivery, info);

    if (!isValid) {
      dispatch(showAlert({ icon: "Warning", message }));
    } else {
      ChangeDelivery.mutate();
      dispatch(setDeliveryInfo({ isEdit: false }));
    }
  };

  const Notice = () => {
    return canModifyDelivery ? (
      <>
        <img src={exclamationMark} alt="" />
        <span>
          <span className="bold">상품출고 전까지</span>&nbsp;수령 방법을&nbsp;
          <span className="bold">변경</span>할 수 있습니다.
        </span>
      </>
    ) : (
      <>
        <img className="error" src={Error} alt="" />
        <span className="bold red">상품 수령 방법을 변경할 수 없습니다.</span>
      </>
    );
  };

  return (
    <Container>
      <Accordion title="선택한 수령 방법">
        <ContentWrap>
          <NotificationWrap>
            <Notice />
          </NotificationWrap>
          <TabButtonWrap>
            <PostBtn
              selected={isDelivery}
              status={isPost}
              originChoice={originalDelivery}
              canModifyDelivery={canModifyDelivery}
              onClick={handleSelectHowReceive}
            >
              택배 수령
            </PostBtn>

            <ShopTakenBtn
              selected={!isDelivery}
              status={isShopTaken}
              originChoice={originalShop}
              canModifyDelivery={canModifyDelivery}
              onClick={handleSelectHowReceive}
            >
              대리점 방문
            </ShopTakenBtn>
          </TabButtonWrap>
          <InnerBox title="주문 상품 제작 과정 안내">
            {transHistory.map(({ description, status: transStatus }) => {
              const reversed = [...history].reverse();
              // 가장 최근 history의 index === 0
              const historyIndex = reversed.findIndex(
                ({ status }) => status === transStatus,
              );

              const { createdAt } = reversed[historyIndex] || {};
              const isDone = historyIndex >= 0;
              const isLatest = historyIndex === 0;

              return (
                <ProcessRow
                  className={[isDone && "done", isLatest && "latest"]}
                  key={description}
                >
                  <span className="description">{description}</span>
                  <span className="created-at">
                    {isDone ? dateToString(createdAt, "YYYY-MM-DD HH:mm") : ""}
                  </span>
                </ProcessRow>
              );
            })}
          </InnerBox>
          <DeliveryCorpInfo
            isVisible={delivery?.regiNo && delivery?.deliveryCorp}
            deliveryInfo={delivery}
          />
        </ContentWrap>
      </Accordion>
      {goodsTrans &&
        Object.keys(goodsTrans).length > 0 &&
        goodsTrans.isDelivery !== isDelivery &&
        (isDelivery ? (
          <>
            <Accordion title="택배 수령지 입력" marginTop="16px">
              {AddressList?.length === 0 ? (
                <DeliveryForm /> // 신규 입력폼
              ) : (
                <DefaultDelivery AddressList={AddressList} /> // 기본 배송지
              )}
            </Accordion>
            <SaveBtn onClick={handleSave}>수령방법 변경</SaveBtn>
          </>
        ) : (
          <>
            <PickUpShop earliestVisitDate={earliestVisitDate} />
            <SaveBtn onClick={handleSave}>수령방법 변경</SaveBtn>
          </>
        ))}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 16px;
`;

const ContentWrap = styled.div`
  padding: 16px;
`;

const NotificationWrap = styled(Flex)`
  flex-direction: row;
  font-size: 14px;
  color: #444444;
  letter-spacing: -0.28px;
  align-items: center;
  .bold {
    font-weight: bold;
  }

  .red {
    color: #ff7272;
  }

  img {
    width: 32px;
    margin: -8px;
    margin-right: -4px;

    &.error {
      width: 16px;
      height: 16px;
      margin: 0 4px 0 0;
    }
  }
`;
const TabButtonWrap = styled(Flex)`
  flex-direction: row;
  margin-top: 16px;
`;

const TabButton = styled(Flex)`
  flex: 1;
  flex-direction: row;
  height: 44px;
  cursor: pointer;

  align-items: center;
  justify-content: center;

  background-color: white;
  border: 1px solid ${colors.imageBorder};
  color: ${colors.black900};

  font-size: 14px;
  font-family: ${fonts.spoqa};
  letter-spacing: -0.28px;
`;

const getButtonStyle = (selected, originChoice, canModifyDelivery, status) => {
  // 선택 가능한 상태
  const selectableStyles = `
  background-color: white;
  border: 1px solid ${colors.imageBorder};
  color: ${colors.black900}; 
  &:before {
    content: "";
    background-image: url(${checkInactive});
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;
  // 선택된 상태
  const selectedStyles = `
  border: 1px solid ${colors.primaryOrange};
  color: ${colors.primaryOrange};
  font-weight: ${fontWeight.bold};
  &:before {
    content: "";
    background-image: url(${checkActive});
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

  // 선택 불가능한 상태
  const disabledStyles = `
    background-color: ${colors.disabledBackground};
    border: 1px solid ${colors.disabledBackground};
    color: ${colors.disabledColor};
  `;

  if (originChoice && selected) return selectedStyles; // 기존 선택, 선택되어있는 상태
  if (!originChoice && canModifyDelivery && status && selected)
    // 수령 방법 변경 가능한 상태이면서 수령 가능 여부 TRUE이면서 기존 선택값 아닐 때
    return selectedStyles;
  if (!originChoice && !canModifyDelivery) return disabledStyles; // 기존 선택 아닌데, 수령 변경 불가능 상태
  if (!originChoice && canModifyDelivery && !status) return disabledStyles; // 기존 선택 아닌데, 수령 변경은 가능하지만 수령 가능 여부가 false일 때

  // 다 아니면 활성화 상태
  return selectableStyles;
};

const conditionalCSS = css`
  ${({ selected, originChoice, canModifyDelivery, status }) => css`
    ${getButtonStyle(selected, originChoice, canModifyDelivery, status)}
  `}
`;

const PostBtn = styled(TabButton)`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  ${conditionalCSS}
`;

const ShopTakenBtn = styled(TabButton)`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  ${conditionalCSS}
`;

const InnerBox = styled(Flex)`
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0 12px;
  padding-bottom: 12px;
  margin-top: 24px;

  &:before {
    content: "${({ title }) => title}";
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.28px;
    color: #333333;
    border-bottom: 1px solid #eaeaea;
  }
`;

const ProcessRow = styled(Flex)`
  margin-top: 16px;
  flex-direction: row;
  align-items: center;

  &:before {
    content: "";
    width: 12px;
    height: 12px;
    border: 2px solid #dfdfdf;
    border-radius: 8px;
    margin-right: 4px;
    background: #ededed;
  }
  .description {
    flex: 1;
    font-size: 14px;
    color: #999999;
    letter-spacing: -0.28px;
  }
  .created-at {
    font-family: ${fonts.spoqa};
    font-size: 13px;
    letter-spacing: 0.26px;
    color: #999999;
  }

  &.done {
    &:before {
      background: none;
      background-image: url(${checkMini});
      background-size: 8px;
      background-position: center;
      background-repeat: no-repeat;
    }
    .description {
      color: #444444;
    }

    .created-at {
      color: #999999;
    }
  }

  &.latest {
    &:before {
      background: #ff6e0e;
    }
    .description {
      color: #ff6e0e;
      font-weight: bold;
    }
    .created-at {
      color: #444444;
    }
  }
`;

const SaveBtn = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: white;
  color: #ff6e0e;
  border: 1px solid #ff6e0e;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
`;

export default HowReceive;
