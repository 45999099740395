import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "routes";
import colors from "assets/styles/color";
import gobackIcon from "assets/icons/goback.webp";
import mainLogo from "assets/icons/logo-main-mini.svg";

import { useScroll } from "hooks/useScroll";

function DetailHeader({ showBackButton }) {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const isScrolled = useScroll(); // 쉐도우용

  const handleGoBack = () => {
    locationState?.FromFailed ? navigate(routes.home) : navigate(-1);
  };

  const handleGoHome = () => {
    navigate(routes.home);
  };

  return (
    <div
      className={`detail-header-container flex-center ${
        showBackButton ? "" : "active"
      } ${isScrolled && "scrollable"}`}
    >
      <div className="absolute-frame">
        <button className="go-back-icon flex-center" onClick={handleGoBack}>
          <img alt="뒤로가기" src={gobackIcon} />
        </button>
        <div className="logo-container" onClick={handleGoHome}>
          <img src={mainLogo} alt="금방금방로고" />
        </div>
        <button className="go-back-icon flex-center hidden" hidden={true} />
      </div>
    </div>
  );
}

export default DetailHeader;

const HeaderContainer = styled.div`
  &.active {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  width: 100%;
  height: 64px;
  padding: 8px 0;
  background-color: ${colors.white};
  ${({ theme }) => theme.flexCenter}
  border-bottom: 1px solid ${({ isScrolled }) =>
    !isScrolled ? `#e0e0e0` : `transparent`};
  box-shadow: ${({ isScrolled }) =>
    isScrolled && `0px 2px 2px rgba(0, 0, 0, 0.04)`};
`;

const AbsoluteFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const GoBackIcon = styled.button`
  width: 48px;
  height: 48px;
  ${({ theme }) => theme.flexCenter}
  cursor: pointer;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
  img {
    width: 9px;
    height: 14px;
    color: ${colors.black};
  }
`;

// 터치타겟 확보
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 48px;
`;

const LogoImage = styled.img`
  width: 124.3px;
  height: 20.3px;
`;
