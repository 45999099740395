import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Flex from "components/common/flex/Flex";
import DefaultLayout from "components/layouts/DefaultLayout";
import useCryptoKeyboard from "hooks/useCryptoKeyboard";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { payStorageFee } from "services/apis";
import ic_del from "assets/images/ic_del.png";
import { showSnackBar } from "features/snackbar/snackbarSlice";
import { routes } from "routes";
import { resetBuyInfo } from "features/buy/buySlice";

function SecureKeypad() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state || {};
  const [secured, secureActions] = useCryptoKeyboard();
  const [keyArr, setKeyArr] = useState([]);

  const { payload, purpose, title, redirectUrl } = locationState;

  const isClick = useRef(false);

  const shuffle = () => {
    const newArr = Array.from({ length: 10 }, (_, i) => i).sort(
      () => Math.random() - 0.5,
    );

    setKeyArr(Array.from({ length: 4 }).map(() => newArr.splice(0, 3)));
  };

  const handleClickEnter = () => {
    if (!isClick.current) {
      isClick.current = true;
      dispatch(showLoading());

      if (purpose === "STORAGE_FEE") {
        payStorageFee({
          ...payload,
          finPassword: secured.result,
        })
          .then(({ data: { success, message } }) => {
            if (success) {
              dispatch(
                showSnackBar({ message: "보관료 납부가 완료되었습니다." }),
              );
              dispatch(resetBuyInfo());
              navigate(routes.home);
            } else {
              dispatch(showSnackBar({ message: message }));
              secureActions.clearAll();
            }
          })
          .finally(() => {
            dispatch(hideLoading());
            shuffle();
            isClick.current = false;
          });
      }
    }
  };

  useLayoutEffect(() => {
    shuffle();
  }, []);

  useEffect(() => {
    if (secured.count === 6) {
      handleClickEnter();
    }
  }, [secured.count]);

  return (
    <DefaultLayout title={title || "결제 비밀번호 입력"}>
      <Flex className="keypassword_wrap" flex={1}>
        <Flex className="password_view">
          <h3>{"결제 비밀번호 입력"}</h3>
          <div className="password_num">
            <ul>
              {Array.from({ length: 6 }).map((_, i) => (
                <li
                  className={secured.count > i ? "on" : ""}
                  key={i.toString()}
                />
              ))}
            </ul>
          </div>
        </Flex>
        <div className="password_key_wrap">
          <div className="password_key">
            {keyArr.map((row, i) => {
              return (
                <ul key={i.toString()}>
                  {i === 3 && (
                    <li
                      className="ft15 on pointer"
                      onClick={() => {
                        secureActions.clearAll();
                      }}
                    >
                      전체 삭제
                    </li>
                  )}

                  {row.map((key, j) => {
                    return (
                      <li
                        className="pointer"
                        key={j.toString()}
                        onClick={() => {
                          secureActions.pressKey(key);
                        }}
                      >
                        {key}
                      </li>
                    );
                  })}

                  {i === 3 && (
                    <li
                      className="pointer"
                      onClick={() => {
                        secureActions.removeOne();
                      }}
                    >
                      <img src={ic_del} alt="삭제" />
                    </li>
                  )}
                </ul>
              );
            })}
          </div>
        </div>
      </Flex>
    </DefaultLayout>
  );
}

export default SecureKeypad;
