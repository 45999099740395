import { put, takeLatest } from "redux-saga/effects";
import { hideModal, clickConfirm } from "./modalSlice";

function* confirmSaga() {
  yield put(hideModal());
}

function* watchConfirm() {
  // clickConfirm 액션이 디스패치 되면 callback 함수를 실행한다
  yield takeLatest(clickConfirm.type, confirmSaga);
}

export default watchConfirm;
