const theme = {
  // 사용 방법 : ${({ theme }) => theme.flexBox("column", "flex-start", "flex-start")}
  flexBox: (direction = "row", justify = "center", align = "center") => `
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
  `,
  // 사용 방법 : ${({ theme }) => theme.flexCenter}
  flexCenter: `
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  flexCenterColumn: `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    `,
};

export default theme;
