import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  width: 64px;
  height: 64px;
  position: relative;

  svg:nth-child(1) {
    position: absolute;
    z-index: 1;
  }

  svg:nth-child(2) {
    position: absolute;
    z-index: 2;

    g {
      transform-origin: 32px 32px;
      animation: ${rotate} 2s linear infinite;
    }
  }
`;

export default function LoadingBar() {
  return (
    <Loader>
      {/* Orange Circle */}
      <svg width="64" height="64" viewBox="0 0 64 64">
        <path
          d="M32 64A32.008 32.008 0 0 1 19.544 2.515a32.008 32.008 0 0 1 24.911 58.97A31.8 31.8 0 0 1 32 64zm0-56a23.924 23.924 0 1 0 9.342 1.886A23.851 23.851 0 0 0 32 8z"
          style={{ fill: "#ff6e0e" }}
        />
      </svg>

      {/* Gray rotating sector */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
      >
        <defs>
          <clipPath id="doklebz0wa">
            <path
              d="M32 64A32.008 32.008 0 0 1 19.544 2.515a32.008 32.008 0 0 1 24.911 58.97A31.8 31.8 0 0 1 32 64zm0-56a23.924 23.924 0 1 0 9.342 1.886A23.851 23.851 0 0 0 32 8z"
              style={{ fill: "#0464f9" }}
            />
          </clipPath>
        </defs>
        <g style={{ clipPath: "url(#doklebz0wa)" }}>
          <path
            data-name="Rectangle"
            d="M0 0h36v62L0 38z"
            transform="translate(32 -6)"
            style={{ fill: "#ddd" }}
          />
        </g>
      </svg>
    </Loader>
  );
}
