export const SERVICE_TYPE = process.env.REACT_APP_SERVICE_TYPE;

export const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;

export const APP_VERSION = process.env.REACT_APP_VERSION;

export const PHONE_CODE = [
  "010",
  "011",
  "016",
  "017",
  "018",
  "019",
  "02",
  "031",
  "032",
  "033",
  "041",
  "042",
  "043",
  "044",
  "051",
  "052",
  "053",
  "054",
  "055",
  "061",
  "062",
  "063",
  "064",
];

export const MEMO = [
  "배송 메모를 직접 입력할게요.",
  "문 앞에 놓아주세요.",
  "택배함에 넣어주세요.",
  "경비실에 맡겨주세요.",
  "부재 시 연락주세요.",
  "직접 받고 부재 시 문 앞에 놓아주세요.",
];

// 이벤트 시 임의의 경로 부여
export const GA_PATH = {
  purchaseComplete: "/purchase-complete", // 결제 완료 경로
  purchaseFailed: "/purchase-failed", // 결제 실패 경로
  eventBannerClick: "/event-banner", // 이벤트 배너 클릭 경로
  noticeBannerClick: "/notice-banner", // 공지사항 배너 클릭 경로
};
