import React from "react";
import styled from "styled-components";
import phone from "assets/icons/phone.webp";
import fonts from "assets/styles/fonts";

const CustomerService = () => {
  return (
    <>
      <Container>
        <TitleBox>고객센터</TitleBox>
      </Container>
      <InfoBox>
        <TitleWrap
          onClick={() => {
            window.location.href = "tel:16708978";
          }}
        >
          <PhoneWrap>
            <Img src={phone} alt="전화" />
            <Title>1670 - 8978</Title>
          </PhoneWrap>
          <SubTitle>(유료)</SubTitle>
        </TitleWrap>
        <InfoContent style={{ alignContent: "center" }}>
          운영시간: <NumberText>09:00 ~ 17:00</NumberText> 주말, 공휴일 휴무
        </InfoContent>
      </InfoBox>
      <WhiteSpace />
    </>
  );
};

export default CustomerService;

const Container = styled.div`
  padding: 16px 16px 0px 16px;
`;
const TitleBox = styled.div`
  height: 48px;
  padding: 16px 0px;
  font-weight: bold;
  font-size: 17px;
  color: #333333;
  letter-spacing: -0.34px;
`;
const InfoBox = styled.div`
  width: 100%;
  padding: 10px 16px 10px 6.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 340px) {
    flex-direction: column;
  }
`;
const TitleWrap = styled.button`
  align-items: center;
  display: flex;
  cursor: pointer;
`;
const Title = styled.span`
  margin-left: 5.5px;
  font-weight: bold;
  font-size: 17px;
  color: #444444;
`;
const SubTitle = styled.span`
  font-weight: normal;
  font-size: 15px;
  color: #333333;
`;
const Img = styled.img`
  width: 44px;
  height: 44px;
`;
const InfoContent = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #777777;
  height: 35px;
  white-space: normal;
  width: 124px;
  line-height: 18px;
  letter-spacing: -0.24px;
`;
const WhiteSpace = styled.div`
  height: 48px;
`;

const PhoneWrap = styled.div`
  ${({ theme }) => theme.flexCenter}
`;

const NumberText = styled.span`
  font-family: ${fonts.spoqa};
`;
