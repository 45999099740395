import { ButtonBase } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "assets/styles/color";
import Flex from "../flex/Flex";

export default function Radio({ label, value, onChange, className }) {
  return (
    <ButtonBase
      onClick={() => {
        onChange(!value);
      }}
      className={className}
      style={{
        display: "flex",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <Flex
        style={{
          borderRadius: "100px",
          width: "12px",
          height: "12px",
          backgroundColor: value ? colors.black : "#dddddd",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Flex
          style={{
            width: 4,
            height: 4,
            borderRadius: 100,
            backgroundColor: "#fff",
          }}
        />
      </Flex>
      <span
        style={{
          fontFamily: "Noto Sans KR",
          fontSize: "14px",
          color: "#333",
          marginLeft: 7,
        }}
      >
        {label}
      </span>
    </ButtonBase>
  );
}
