import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { routes } from "routes";
import colors from "assets/styles/color";
import { getFormatTime } from "utils/utils";
import { selectBuyInfo, setDeliveryInfo } from "features/buy/buySlice";
import ContentBox from "./ContentBox";
import PickUpShop from "pages/goods-purchase/components/PickUpShop";

const week = ["일", "월", "화", "수", "목", "금", "토"];

const ShopInfo = ({ shopInfo, canModifyDelivery }) => {
  const { DeliveryInfo } = useSelector(selectBuyInfo);
  const { shopName, visitDate, businessHr } = shopInfo;
  const day = week[new Date(visitDate).getDay()];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEdit = () => {
    dispatch(setDeliveryInfo({ isEdit: true }));
    navigate(routes.findStore);
  };

  return (
    <>
      {DeliveryInfo?.isEdit ? (
        <PickUpShop />
      ) : (
        <ContentBox title="대리점 정보">
          <Row>
            <DetailText>지점</DetailText>
            <div>
              <DetailText bold>{shopName}</DetailText>
              {canModifyDelivery && (
                <ChangeBtn onClick={handleEdit}>변경</ChangeBtn>
              )}
            </div>
          </Row>
          {/* <Row>
            <DetailText>영업시간</DetailText>
            <DetailText className="spoqa">{businessHr}</DetailText>
          </Row> */}
          <Row>
            <DetailText>예약일</DetailText>
            <DetailText bold>
              <span className="spoqa">{getFormatTime(visitDate)}</span> ({day})
            </DetailText>
          </Row>
        </ContentBox>
      )}
    </>
  );
};

const Row = styled.div`
  ${({ theme }) => theme.flexBox("row", "space-between")};
  &:not(:first-child) {
    margin-top: 12px;
  }
`;

const DetailText = styled.span`
  font-size: 15px;
  letter-spacing: -0.3px;
  color: ${({ bold }) => (bold ? "#444444" : "#777777")};

  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;

const ChangeBtn = styled.button`
  width: 40px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid ${colors.black500};

  color: ${colors.black500};
  font-size: 12px;
  margin-left: 16px;
  cursor: pointer;
`;

export default ShopInfo;
