import React from "react";
import styled from "styled-components";

const Header = () => {
  return <Title>계좌</Title>;
};

export default Header;

const Title = styled.div`
  color: #222222;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: -0.02px;
`;
