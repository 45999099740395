import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import icon_idcheck from "assets/images/icon_idcheck.png";
import ic_naver from "assets/images/ic_naver.png";
import ic_kakao from "assets/images/ic_kakao.png";
import ic_google from "assets/images/ic_google.png";
import ic_apple from "assets/images/ic_apple.png";
import logo_black from "assets/images/logo_black.png";
import DefaultLayout from "components/layouts/DefaultLayout";
import { routes } from "routes";

const Result = () => {
  const location = useLocation();
  const locationState = location.state || {};
  const navigate = useNavigate();

  const { account = "", isAccount, isSocial, socialTypes = [] } = locationState;

  const handleClickResetPassword = () => {
    navigate(routes.authResetPassword, { replace: true });
  };
  const handleClickGoBack = () => {
    navigate(-1);
  };
  return (
    <DefaultLayout title="아이디 확인">
      <div className="login_wrap">
        <div className="idfind_top">
          <img src={icon_idcheck} alt="금방금방" />
          <h3>
            입력하신 정보와 <br />
            일치하는 아이디입니다.
          </h3>
        </div>
        <div className="idfind_box">
          <h3 className="idfind_box_tit">아이디 찾기 결과</h3>
          {isSocial && (
            <div className="idfind_inbox">
              <p className="idfind_inbox_tit">- 간편 로그인 -</p>
              <ul className="sns_button">
                {socialTypes.includes("NAVER") && (
                  <li>
                    <img src={ic_naver} alt="" /> 네이버 아이디로 가입했습니다.
                  </li>
                )}
                {socialTypes.includes("KAKAO") && (
                  <li>
                    <img src={ic_kakao} alt="" /> 카카오 아이디로 가입했습니다.
                  </li>
                )}
                {socialTypes.includes("GOOGLE") && (
                  <li>
                    <img src={ic_google} alt="" /> 구글 아이디로 가입했습니다.
                  </li>
                )}
                {socialTypes.includes("APPLE") && (
                  <li>
                    <img src={ic_apple} alt="" /> 애플 아이디로 가입했습니다.
                  </li>
                )}
              </ul>
            </div>
          )}
          {isAccount && (
            <div className="idfind_id">
              <h3>
                -<img src={logo_black} alt="금방금방" />
                에서 생성한 아이디-
              </h3>
              <p>{account}</p>
            </div>
          )}
        </div>
        <div className="idfind_bottom">
          <button
            className="org-line pointer"
            onClick={handleClickResetPassword}
          >
            비밀번호 재설정
          </button>
          <button className="org-back pointer" onClick={handleClickGoBack}>
            로그인 하기
          </button>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Result;
