import { useEffect } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import { useDispatch } from "react-redux";

import { setSessionStorageItem } from "services/storageService";

import minus from "assets/icons/icon-minus1.webp";
import minusDisabled from "assets/icons/icon-minus2.webp";
import plus from "assets/icons/icon-plus.webp";
import { showAlert } from "features/modal/modalSlice";

function Stepper({ quantity, setQuantity }) {
  const dispatch = useDispatch();

  useEffect(() => {
    setSessionStorageItem("qty", quantity);
  }, [quantity]);

  const onDecrease = () => {
    setQuantity(quantity - 1);
  };

  const onIncrease = () => {
    setQuantity(quantity + 1);
  };

  const onChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setQuantity("");
    } else {
      const parsedValue = parseInt(value, 10);
      setQuantity(parsedValue);
    }
  };

  const handleInputCheck = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value) || value < 1) {
      dispatch(showAlert({ message: "1개 이상부터 구매하실 수 있습니다." }));
      setQuantity(1);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <StepperContainer>
      <DecreaseBtn
        onClick={onDecrease}
        disabled={quantity === 1 ? true : false}
      >
        <img src={quantity === 1 ? minusDisabled : minus} alt="수량 감소" />
      </DecreaseBtn>
      <NumberInput
        className="inputText"
        type="number"
        min="0"
        pattern="[0-9]+" // 숫자만 입력 가능
        value={quantity}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        onBlur={handleInputCheck}
      />
      <IncreaseBtn onClick={onIncrease}>
        <img src={plus} alt="수량 증가" />
      </IncreaseBtn>
    </StepperContainer>
  );
}

export default Stepper;

const StepperContainer = styled.div`
  width: 128px;
  height: 32px;

  border-radius: 4px;
  border: 1px solid ${colors.stepperBorder};

  ${({ theme }) => theme.flexCenter}
`;

const NumberInput = styled.input`
  width: 64px;
  height: 100%;
  /* 스텝퍼 삭제 */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Standard Property */
  appearance: none;

  /* Firefox */
  -moz-appearance: textfield;

  &.inputText {
    text-align: center;
    font-size: 16px;
    font-family: "SpoqaHanSansNeo";
    font-weight: 500;
    background-color: #ffffff;
    color: ${colors.black200};
  }

  &:focus {
    border: 1px solid ${colors.inputFocus};
  }
`;

const DecreaseBtn = styled.button`
  width: 32px;
  height: 32px;
  border-right: 1px solid ${colors.stepperBtnBorder};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #ffffff;
  height: 100%;
  ${({ theme }) => theme.flexCenter}
  cursor: pointer;
  /* disabled가 false일 때만 되도록 */
  :active {
    ${({ disabled }) =>
      !disabled && `background-color: ${colors.stepperBorder}`};
  }

  img {
    width: 12px;
    height: 2px;
  }
`;

const IncreaseBtn = styled.button`
  width: 32px;
  height: 32px;
  border-left: 1px solid ${colors.stepperBtnBorder};
  cursor: pointer;
  background-color: #ffffff;
  height: 100%;
  ${({ theme }) => theme.flexCenter}
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  img {
    width: 12px;
    height: 12px;
  }

  :active {
    background-color: ${colors.stepperBorder};
  }
`;
