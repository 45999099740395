import NumberInput from "components/common/Input/NumberInput";
import TextInput from "components/common/Input/TextInput";
import Flex from "components/common/flex/Flex";
import DefaultLayout from "components/layouts/DefaultLayout";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { showAlert } from "features/modal/modalSlice";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { postChangePassword, getRequestKey } from "services/apis";
import styled from "styled-components";
import { encodeByAES56, decodeByAES256 } from "utils/utils";
import {Buffer} from 'buffer';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleClickSubmit = () => {
    if (canSubmit) {
      if (password === confirmPassword) {
        dispatch(showLoading());
        getRequestKey().then(({ data: { success, data, message } }) => {
          if (success) {
            const { encId, publicKey } = data;
            const requestData = {
              encId,
              data: encodeByAES56(
                Buffer.from(publicKey, "base64"),
                JSON.stringify({
                  oldPassword: Buffer.from(oldPassword).toString("base64"),
                  password: Buffer.from(password).toString("base64"),
                  confirmPassword:
                    Buffer.from(confirmPassword).toString("base64"),
                }),
              ),
            };
            postChangePassword(JSON.stringify(requestData))
              .then((encodeData) => {
                const responseData = decodeByAES256(
                  Buffer.from(publicKey, "base64"),
                  encodeData.data,
                );
                const { success, data, message } = JSON.parse(responseData);
                if (success) {
                  dispatch(
                    showAlert({ message: "비밀번호가 변경되었습니다." }),
                  );
                  navigate(-1);
                } else {
                  dispatch(showAlert({ message }));
                }
              })
              .catch((error) => {
                dispatch(showAlert({ message: error.response.data.message }));
              })
              .finally(() => {
                dispatch(hideLoading());
              });
          } else {
            dispatch(showAlert({ message }));
          }
        });
      } else {
        dispatch(showAlert({ message: "비밀번호가 일치하지 않습니다." }));
      }
    }
  };
  const canSubmit = oldPassword && password && confirmPassword;
  return (
    <DefaultLayout title="로그인 비밀번호 변경">
      <Container>
        <Header>보안</Header>
        <TitleHeader>로그인 비밀번호 변경</TitleHeader>
        <InputSection>
          <InputLabel>기존 비밀번호</InputLabel>
          <InputWrap
            type="password"
            placeholder="기존 비밀번호 입력"
            value={oldPassword}
            onChange={setOldPassword}
          />
        </InputSection>
        <InputSection>
          <InputLabel>새로운 비밀번호</InputLabel>
          <InputWrap
            type="password"
            placeholder="8자리 이상(숫자, 특수문자, 영문자 포함)"
            value={password}
            onChange={setPassword}
          />
        </InputSection>
        <InputSection>
          <InputLabel>새로운 비밀번호 확인</InputLabel>
          <InputWrap
            type="password"
            placeholder="다시 입력"
            value={confirmPassword}
            onChange={setConfirmPassword}
          />
        </InputSection>

        <Button disabled={!canSubmit} onClick={handleClickSubmit}>
          확인
        </Button>
      </Container>
    </DefaultLayout>
  );
};

const Container = styled(Flex)`
  padding: 24px;
`;

const TitleHeader = styled.span`
  font-weight: bold;
  font-size: 21px;
  letter-spacing: -0.42px;
  color: #222222;
  margin-bottom: 4px;
`;

const InputSection = styled(Flex)`
  margin-top: 24px;
`;

const InputLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #333333;
  margin-bottom: 8px;
`;

const NumberInputWrap = styled(NumberInput)`
  &:focus {
    border: 1px solid #656667;
  }
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  letter-spacing: -0.32px;
  width: 100%;
`;

const InputWrap = styled(TextInput)`
  &:focus {
    border: 1px solid #656667;
  }
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  letter-spacing: -0.32px;
  width: 100%;
`;

const Button = styled(Flex)`
  ${({ theme }) => theme.flexCenter};
  margin-top: 56px;
  height: 48px;
  background: ${({ disabled }) => (disabled ? "#dddddd" : "#ff6e0e")};
  color: ${({ disabled }) => (disabled ? "#bbbbbb" : "white")};
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? "" : "pointer")};
`;

const Header = styled.div`
  margin-bottom: 11px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.28px;
  color: #ff6e0e;
`;
export default ChangePassword;
