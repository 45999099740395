import axios from "axios";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import ReactDOM, { createRoot, hydrateRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { HelmetProvider } from "react-helmet-async";
import { ReactQueryDevtools } from "react-query/devtools";
import "assets/css/font.css";

import App from "./App";
import { store, persistor } from "./app/store"; // redux store
import theme from "./assets/styles/theme";
import reportWebVitals from "./reportWebVitals";
import { SnackBar, BasicModal, Loading } from "components";
import { PersistGate } from "redux-persist/integration/react";

// 응답 인터셉터 추가하기
axios.interceptors.response.use(
  function (response) {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 데이터가 있는 작업 수행
    return response;
  },
  function (error) {
    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 오류가 있는 작업 수행
    return Promise.reject(error);
  },
);

const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     suspense: true,
  //   },
  // },
}); // QueryClient 인스턴스 생성

// const root = ReactDOM.createRoot(document.getElementById("root"));
const root = document.getElementById("root");
const Apps = (
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <Suspense fallback={<Loading />}> */}
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <App />
              <SnackBar />
              <BasicModal />
              <Loading />
            </ThemeProvider>
          </QueryClientProvider>
          {/* </Suspense> */}
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);
if (root.hasChildNodes()) {
  hydrateRoot(root).render(Apps);
} else {
  createRoot(root).render(Apps);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
