import React from "react";
import styled from "styled-components";

const FixedRatio = ({ ratio = 1, children, ...rest }) => {
  return (
    <div
      ratio={ratio}
      style={{
        position: "relative",
        paddingTop: `${ratio * 100}%`,
      }}
    >
      <div className="goods-card-content-wrap" {...rest}>
        {children}
      </div>
    </div>
  );
};

const FixedRatioWrap = styled.div`
  position: relative;
  padding-top: ${({ ratio }) => ratio * 100}%;
`;

const ContentWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default FixedRatio;
