import moment from "moment";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import colors from "assets/styles/color";
import { getHours } from "services/apis";
import rightArrow from "assets/icons/right-arrow-gray.webp";
import { setBuyInfo, selectBuyInfo } from "features/buy/buySlice";

const DAYS_OF_WEEK = ["일", "월", "화", "수", "목", "금", "토"];

const buildCalendar = (value) => {
  const startDay = value.clone().startOf("month").startOf("week");
  const endDay = value.clone().endOf("month").endOf("week");
  const month = value.month();
  const day = startDay.clone().subtract(1, "day");
  const calendar = [];
  while (day.isBefore(endDay, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => {
          const date = day.add(1, "day").clone();
          return date.month() === month ? date : ""; // 이번 달 아니면 제외
        }),
    );
  }
  return calendar;
};

const useGetShopHours = (Month) => {
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { shopId, goodsId } = BuyInfo;

  const { data: ShopHours, isLoading } = useQuery(
    ["shopHours", shopId, Month.format("YYYY-MM")],
    () => getHours(goodsId, shopId, Month.format("YYYY-MM")),
    {
      select: (data) => data?.data?.data,
    },
  );
  return { ShopHours, isLoading };
};

function Calendar() {
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState(null);
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { visitDate, earliestVisitDate } = BuyInfo;
  const [calendar, setCalendar] = useState(
    buildCalendar(moment(earliestVisitDate)),
  );
  const Month = calendar[1][0];
  const isNextMonthDisabled =
    moment(Month).month() === moment(earliestVisitDate).add(1, "month").month();

  const { ShopHours, isLoading } = useGetShopHours(Month);

  useEffect(() => {
    if (visitDate !== "") {
      setCalendar(buildCalendar(moment(visitDate)));
      saveInfo(moment(visitDate));
    }
  }, []);

  // useEffect(() => {
  //   if (earliestVisitDate) {
  //     saveInfo(
  //       visitDate === "" ? moment(earliestVisitDate) : moment(visitDate),
  //     );
  //   }
  // }, [earliestVisitDate]);

  const prevMonth = () => {
    setCalendar(buildCalendar(moment(Month).subtract(1, "M")));
  };

  const nextMonth = () => {
    setCalendar(buildCalendar(moment(Month).add(1, "M")));
  };

  const getWeekOfMonth = (day) => {
    if (!day) return;
    const startOfMonth = day.clone().startOf("month");
    return day.clone().week() - startOfMonth.week();
  };

  const saveInfo = (day) => {
    setSelectedDay(day);
    dispatch(setBuyInfo({ visitDate: day.format("YYYY-MM-DD") }));
    const selectedDayWeek = getWeekOfMonth(day);

    if (!isLoading && ShopHours?.businessHoursList.length !== 0) {
      for (let i = 0; i < ShopHours?.businessHoursList.length; i++) {
        const businessHours = ShopHours.businessHoursList[i];
        if (businessHours.dayWeek === selectedDayWeek?.toString()) {
          dispatch(
            setBuyInfo({
              openHr: businessHours.openHr,
              closeHr: businessHours.closeHr,
            }),
          );
          break;
        }
      }
    }
  };

  const handleClickDay = (day) => {
    if (
      day &&
      !isHoliday(day, ShopHours.holidaysList) &&
      !day.isBefore(moment(earliestVisitDate), "day")
    ) {
      const selectedDayWeek = day.day();
      const isFullDayOff = ShopHours.businessHoursList.some((businessHours) => {
        return (
          businessHours.dayWeek === selectedDayWeek.toString() &&
          businessHours.isFullDayOff
        );
      });
      if (!isFullDayOff) {
        saveInfo(day);
      }
    }
  };
  const isHoliday = (day, holidays) => {
    if (!holidays) {
      return false;
    }
    const formattedDay = day.format("YYYY-MM-DD");
    return holidays.some((holiday) => holiday.manageDate === formattedDay);
  };

  return (
    <div>
      <FlexDiv>
        {moment(earliestVisitDate).isBefore(moment(Month)) ? (
          <Button onClick={prevMonth}>
            <ArrowIcon className="left" src={rightArrow} alt="rightArrow" />
          </Button>
        ) : (
          <DisabledButton />
        )}
        <MonthBox>{Month?.format("YYYY년 MM월")}</MonthBox>
        {isNextMonthDisabled ? (
          <DisabledButton />
        ) : (
          <Button onClick={nextMonth}>
            <ArrowIcon src={rightArrow} alt="rightArrow" />
          </Button>
        )}
      </FlexDiv>
      <WeekRow>
        {DAYS_OF_WEEK.map((day) => (
          <DayCol key={day}>{day}</DayCol>
        ))}
      </WeekRow>
      {calendar.map((week, index) => (
        <WeekRow key={`week-${index}`}>
          {week.map((day, index) => {
            const selectedDayWeek = day && day.day();
            const isFullDayOff = ShopHours?.businessHoursList.some(
              (businessHours) =>
                businessHours.dayWeek === selectedDayWeek?.toString() &&
                businessHours.isFullDayOff,
            );

            return (
              <DayCol
                key={day ? day.toString() : `empty-${index}`}
                onClick={() => handleClickDay(day)}
                isSelected={
                  day && selectedDay && day.isSame(selectedDay, "day")
                }
                isBefore={day && day.isBefore(moment(earliestVisitDate), "day")}
                isHoliday={
                  day && ShopHours && isHoliday(day, ShopHours.holidaysList)
                }
                isFullDayOff={isFullDayOff}
              >
                {day ? day.format("D") : ""}
              </DayCol>
            );
          })}
        </WeekRow>
      ))}
    </div>
  );
}

const FlexDiv = styled.div`
  ${({ theme }) => theme.flexCenter};
`;

const WeekRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

const DayCol = styled.button`
  text-align: center;
  font-family: "SpoqaHanSansNeo";
  font-size: 14px;
  letter-spacing: -0.28px;
  font-weight: ${(props) => (props.isSelected ? 700 : 500)};
  color: ${(props) =>
    props.isHoliday || props.isBefore || props.isFullDayOff // 오늘 이전이거나, 휴무일이거나, 비활성화된 경우
      ? "#dddddd"
      : props.isSelected // 선택된 날이면
      ? "white"
      : colors.black300}; // 방문 가능한 날이면
  background-color: ${(props) =>
    props.isSelected && !props.isHoliday && !props.isFullDayOff
      ? "#ff6e0e"
      : "transparent"};
  border-radius: ${(props) => (props.isSelected ? "50%" : "")};
  width: 40px;
  ${({ theme }) => theme.flexCenter};
  height: 40px;
  padding: 12px;
  flex-shrink: 0;
  /* 선택 가능한 날만 pointer */
  cursor: ${({ isBefore, isHoliday, isFullDayOff }) =>
    !isHoliday && !isBefore && !isFullDayOff ? "pointer" : "auto"};
`;

const Button = styled.button`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #f2f2f2;
  ${({ theme }) => theme.flexCenter};
`;

const ArrowIcon = styled.img`
  width: 4.5px;
  height: 9px;
  &.left {
    transform: rotate(180deg);
  }
`;

const MonthBox = styled.div`
  margin: 0 14px;
`;

const DisabledButton = styled(Button)`
  background-color: transparent;
  cursor: default;
`;

export default Calendar;
