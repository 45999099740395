import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import colors from "assets/styles/color";
import Phone from "assets/icons/phone-black.webp";
import Location from "assets/icons/location-black.webp";
import Calendar from "./Calendar";
import { Accordion } from "components";
import { selectBuyInfo } from "features/buy/buySlice";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

function PickUpShop() {
  const navigate = useNavigate();
  const { ShopInfo } = useSelector(selectBuyInfo);
  const { buyShopId } = ShopInfo;
  const handleSearchShop = () => {
    navigate(routes.findStore);
  };

  return (
    <>
      <Accordion marginTop="16px" title="상품 받을 곳 (대리점 선택)">
        <PaddingDiv>
          <PickUpShopInfo />
          <SearchShopBtn
            onClick={handleSearchShop}
            className={buyShopId !== 0 && "active"}
          >
            대리점 찾기
          </SearchShopBtn>
        </PaddingDiv>
      </Accordion>
      {buyShopId !== 0 && (
        <Accordion marginTop="16px" title="희망 방문 일자 선택">
          <PaddingDiv>
            <Calendar />
          </PaddingDiv>
        </Accordion>
      )}
    </>
  );
}

export default PickUpShop;

const PickUpShopInfo = () => {
  const { ShopInfo } = useSelector(selectBuyInfo);
  const {
    buyShopId,
    buyShopName,
    buyShopAddress,
    buyShopPhoneNumber,
    buyShopDistance,
  } = ShopInfo;

  return (
    buyShopId !== 0 && (
      <PickUpContainer>
        <LocationIcon>
          <img src={Location} alt="위치" />
        </LocationIcon>
        <div>
          <Title>선택한 대리점</Title>
          <Flex>
            <ShopName>{buyShopName}</ShopName>
            {buyShopDistance !== 0 && (
              <>
                <ShopDistance>{buyShopDistance}</ShopDistance>
                <Unit>km</Unit>
              </>
            )}
          </Flex>
          <ShopAddress>{buyShopAddress}</ShopAddress>
          <FlexBox>
            <PhoneIcon src={Phone} alt="전화번호" />
            <PhoneNumber>{buyShopPhoneNumber}</PhoneNumber>
          </FlexBox>
        </div>
      </PickUpContainer>
    )
  );
};

const PaddingDiv = styled.div`
  padding: 16px;
  width: 100%;
  height: auto;
`;

const SearchShopBtn = styled.button`
  width: 100%;
  height: 44px;
  background-color: ${colors.primaryOrange};
  border-radius: 4px;

  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.32px;

  &.active {
    background-color: white;
    border: 1px solid ${colors.buttonBorder};
    color: ${colors.black200};
    font-weight: 500;
  }
`;
const PickUpContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const LocationIcon = styled.div`
  margin-right: 4px;
  img {
    width: 12px;
    height: 14px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.46px;
  color: ${colors.black300};
  margin-bottom: 8px;
`;

const ShopName = styled.span`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.32px;
  color: ${colors.black300};
  margin: 0 4px 4px 0;
`;

const ShopDistance = styled.span`
  font-family: "SpoqaHanSansNeo";
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.24px;
  color: ${colors.black900};
`;

const Unit = styled.span`
  color: ${colors.black900};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 25.3px;
`;

const ShopAddress = styled.div`
  font-size: 12px;
  letter-spacing: -0.24px;
  color: ${colors.black700};
  margin-bottom: 10px;
`;

const Flex = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexBox("row", "flex-start", "center")}
`;

const FlexBox = styled.div`
  width: auto;
  height: 17px;
  ${({ theme }) => theme.flexBox("row", "flex-start", "center")}
`;

const PhoneIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

const PhoneNumber = styled.span`
  font-size: 12px;
  font-family: "SpoqaHanSansNeo";
  letter-spacing: -0.24px;
  color: ${colors.black400};
`;
