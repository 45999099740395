import React from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import GoodsCard from "components/layouts/GoodsCard";

const GoodsList = ({ goodsList }) => {
  return (
    <ul className="goods-container">
      {goodsList.map((goods, i) => (
        <li key={i.toString()} className="goods-item">
          <GoodsCard {...goods} index={i} />
        </li>
      ))}
    </ul>
  );
};

export default GoodsList;

const GoodsContainer = styled.ul`
  width: 100%;
  padding: 16px 16px 50px;
  background-color: ${colors.white};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const GoodsItem = styled.li`
  height: 100%;
  flex: 0 1 calc(50% - 4px);
  max-width: calc(50% - 4px);
  cursor: pointer;
  &:nth-child(2n) {
    margin-left: 8px;
  }
`;
