import React from "react";
import { Helmet } from "react-helmet-async";
import { S3_BASE_URL } from "./constants";

function MetaTag({
  title = "온라인 금은방 금방금방몰",
  description = "골드바, 돌반지 최저가에 도전하다! 최대 10% 더 저렴하게 금방금방몰에서 쇼핑하세요!",
  keywords,
  image,
  imageWidth = 1000,
  imageHeight = 1000,
}) {
  const imageUrl = image
    ? `${S3_BASE_URL}/${image}`
    : "https://dev-image.kumbang.co.kr/1000x1000.png";

  return (
    <Helmet>
      {/* 메타 태그 */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}

      {/* og 태그 */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />

      {/* 트위터 태그 */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    </Helmet>
  );
}

export default MetaTag;
