import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  message: "",
  time: 2500,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    // 스낵바 보이기
    showSnackBar: (state, action) => {
      state.show = true;
      const { message, time } = action.payload;
      state.message = message;
      time && (state.timer = time);
    },
    // 스낵바 숨기기
    hideSnackBar: (state) => {
      state.show = false;
      state.message = "";
    },
  },
});

export const { showSnackBar, hideSnackBar } = snackbarSlice.actions;
export const selectSnackBar = (state) => state.snackbar;

export default snackbarSlice.reducer;
