const colors = {
  primaryRed: "#ff5050", // 할인율
  priceRed: "#d3322e", // 총 금액
  primaryOrange: "#ff6e0e", // 회원가입 확인 버튼, 밑줄 border
  dropdownOrange: "#e76e26", // 정렬, 상품 카테고리 드롭다운, 탭 메뉴
  iconOrange: "#ff842b", // 하단 아이콘
  purchaseOrange: "#ff8129", // 구매하기 버튼
  textOrange: "#f76f27", // 모달 강조 텍스트
  highlightOrange: "#ffe6d5", // 모달 강조 하이라이트
  primaryYellow: "#ffb100", // 아이콘
  backgroundYellow: "#fffaf0", // 상품 정보 배경

  boxBackground: "#f5f5f5", // 최저가 대비 박스 배경
  inputBorder: "#dbdbdb", // 인풋 테두리
  inputBorderThick: "#858586", // 인풋 호버
  inputFocus: "#515151", // 인풋 포커스

  stepperBorder: "#d8d8d8", // 스테퍼 테두리
  stepperBtnBorder: "#e8e8e8", // 스테퍼 버튼 테두리

  snackbarBackground: "#383838", // 스낵바 배경
  snackbarShadow: "#33000000", // 스낵바 그림자

  headerShadow: "#29000000", // 헤더 그림자
  purchaseBackground: "#f9f9f9", // 구매하기 배경
  purchaseBorder: "#eaeaea", // 구매하기 테두리

  buttonBorder: "#bdbdbd", // 버튼 테두리

  disabledColr: "#bbbbbb", // 비활성화 글씨 색

  Bar: "#e5e5e5", // 페이지 여백
  gray: "#dddddd", // 버튼 비활성화
  ivory: "#e9ecf5", // 모달 취소 버튼

  white: "#ffffff",
  black: "#000000",

  // 텍스트 색상
  black100: "#111111",
  black200: "#222222",
  black300: "#333333",
  black400: "#444444",
  black500: "#555555",
  black600: "#666666",
  black700: "#777777",
  black800: "#888888",
  black900: "#999999",

  // 배경 색상
  white000: "#f0f0f0",
  white100: "#f1f1f1",
  white200: "#f2f2f2",
  white300: "#f3f3f3",
  white400: "#f4f4f4",
  white500: "#f5f5f5",
  white600: "#f6f6f6",
  white700: "#f7f7f7",
  white800: "#f8f8f8",
  white900: "#f9f9f9",
  whiteA: "#fafafa",
  whiteB: "#fbfbfb",
  whiteC: "#fcfcfc",
  whiteD: "#fdfdfd",
  whiteE: "#fefefe",
  whiteF: "#ffffff",

  // border 관련 색상
  commonBorder: "#dbdbdb",
  imageBorder: "#eaeaea",

  // input 관련 색상
  inputInside: "#fafafa",

  // 배경색 관련
  lightBackground: "#f7f7f7",
  lightOrangeBackground: "#fff0e6",
  disabledBackground: "#dddddd",

  // 비활성화 상태
  disabledColor: "#bbbbbb",
};

export default colors;
