import React, { useEffect, useState } from "react";
import DefaultLayout from "components/layouts/DefaultLayout";
import styled from "styled-components";
import HeaderTab from "./components/HeaderTab";
import Sort from "./components/Sort";
import { ScrollToTop } from "components";
import OrderList from "./components/OrderList";
import { getMyPageTrans } from "services/apis";
import { objToQueryString } from "utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { routes } from "routes";
import useScript from "hooks/useScript";

const OrderHistory = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationState = location.state || {};
  const [year, setYear] = useState("");
  const [selectSort, setSelectSort] = useState(locationState.status || "ALL");
  const [orderList, setOrderList] = useState([]);
  const { naverScript, googleTagScript } = useScript();
  const fetchList = () => {
    dispatch(showLoading());
    const param = {
      year: year,
      goodsTransStatus: selectSort,
    };
    if (selectSort === "ALL") {
      delete param["goodsTransStatus"];
    }
    if (year === "") {
      delete param["year"];
    }
    getMyPageTrans(objToQueryString(param))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setOrderList(data);
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  useEffect(() => {
    naverScript(1);
    googleTagScript();
  }, []);

  useEffect(() => {
    fetchList();
  }, [year, selectSort]);

  return (
    <>
      <ScrollToTop />
      <DefaultLayout
        title="주문내역"
        navigationVisible={true}
        backCallback={() => navigate(routes.myPage)}
      >
        <TobBar />
        <HeaderTab year={year} setYear={setYear} />
        <Sort selectSort={selectSort} setSelectSort={setSelectSort} />
        <OrderList orderList={orderList} />
      </DefaultLayout>
    </>
  );
};

export default OrderHistory;

const TobBar = styled.div`
  height: 16px;
  background-color: #ffffff;
`;
