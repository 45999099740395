import React, { useEffect, useState } from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import {
  closeBottomSheet,
  hideBottomSheet,
  selectBottomSheet,
} from "features/bottomsheet/bottomsheetSlice";
import { showAlert } from "features/modal/modalSlice";
import { showSnackBar, hideSnackBar } from "features/snackbar/snackbarSlice";

import Stepper from "./Stepper";
import NotRefund from "./NotRefund";
import { useModal } from "hooks/useModal";
import { getPreApproval } from "services/apis";
import { Button, CustomModal } from "components";
import { NumberFormat, objToQueryString } from "utils/utils";
import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "services/storageService";

import colors from "assets/styles/color";
import downIcon from "assets/icons/down.webp";
import optionOpen from "assets/icons/option-open.webp";
import optionClose from "assets/icons/option-close.webp";

export const useGetPrePriceByZero = (quantity) => {
  const { id } = useParams();
  const qty = getSessionStorageItem("qty");
  const {
    data: PrePriceByZero,
    isLoading,
    refetch,
  } = useQuery(
    ["pre-price", id, quantity || qty, 0],
    () =>
      getPreApproval(
        objToQueryString({
          goodsId: id,
          qty: quantity || qty,
          useGram: 0,
        }),
      ),
    { select: (data) => data?.data?.data },
  );
  return { PrePriceByZero, isLoading, refetch };
};

function DetailBottomSheet({ goodsOptionsInfos, isSoldOut }) {
  const dispatch = useDispatch();
  const [isDropDown, setIsDropDown] = useState(false);
  const [firstClick, setFirstClick] = useState(false);
  const [buyNowActive, setBuyNowActive] = useState(false); // 바로 구매 버튼 활성화 상태
  const [borderThick, setBorderThick] = useState(false); // 바로 구매 비활성화 상태에서 클릭했을 경우
  const [quantity, setQuantity] = useState(1);

  const [modalVisible, modalOpen, modalClose] = useModal(false);
  const [optionValue, setOptionValue] = useState(null);
  const { state } = useSelector(selectBottomSheet);
  const { PrePriceByZero, isLoading } = useGetPrePriceByZero(quantity);
  const { isAvailable, price, message } = PrePriceByZero || {};

  // 옵션 노출 상태가 VISIBLE인 옵션만
  const filteredGoodsOptionsInfos = goodsOptionsInfos?.filter(
    (item) => item.isVisible === "VISIBLE",
  );

  useEffect(() => {
    dispatch(hideBottomSheet());
  }, []);

  useEffect(() => {
    if (isSoldOut !== true && isSoldOut !== undefined) {
      if (isAvailable === false && message) {
        dispatch(
          showAlert({
            icon: "Warning",
            message: "<매도 물량/>이 <부족/>합니다.",
          }),
        );
      }
    }
  }, [isAvailable, isLoading, message, isSoldOut]);

  useEffect(() => {
    setSessionStorageItem("optionValue", optionValue);
  }, [optionValue]);

  useEffect(() => {
    // 옵션 리스트 없으면 바로구매 활성화
    if (filteredGoodsOptionsInfos?.length === 0) {
      setBuyNowActive(true);
    }
  }, [filteredGoodsOptionsInfos]);

  const handleClick = () => {
    setFirstClick(false);
    dispatch(closeBottomSheet());
  };

  const handleDropDown = () => {
    !firstClick && setFirstClick(true);
    setIsDropDown(!isDropDown);
    dispatch(hideSnackBar()); // 스낵바 떠져있는 도중 옵션을 선택하려고 하면 바로 꺼지도록
  };

  const handleCloseBottomSheet = () => {
    dispatch(closeBottomSheet());
  };

  const handleOpenNotRefund = () => {
    // 옵션 리스트가 있는데,
    if (buyNowActive) {
      // 옵션 선택을 했을 경우
      if (optionValue) {
        modalOpen();
      } else if (
        !optionValue &&
        filteredGoodsOptionsInfos?.length === 0 &&
        !message
      ) {
        // 옵션이 없을 경우는 바로 열리도록
        modalOpen();
      }
    } else {
      // 바로구매 버튼 비활성화되어있는데 클릭했을 경우
      setBorderThick(true);
      dispatch(showSnackBar({ message: "옵션부터 선택해주세요." }));
    }
  };

  const HeightControlled = () => {
    let className = "";
    if (state === "Open") {
      className += "slideInTop";
    } else if (state === "Close") {
      className += "slideInBottom";
    } else {
      className += "hidden";
    }

    if (state === "Open" && firstClick) {
      className += isDropDown ? " optionOpen" : " optionClose";
    }
    return className;
  };

  // Stepper 상태 끌어올리기 함수
  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  const OptionList = (option) => {
    const onSelectOption = (choice) => {
      setOptionValue(choice);
      setIsDropDown(!isDropDown);
      setBorderThick(false);
      setBuyNowActive(true);
    };

    return (
      <>
        <SearchBar onClick={handleDropDown}>
          {optionValue ? optionValue : "선택"}
          <img
            src={isDropDown ? optionClose : optionOpen}
            alt={isDropDown ? "옵션 닫기" : "옵션 열기"}
          />
        </SearchBar>
        <SelectionOptionListUl>
          {option?.map((item, index) => (
            <SelectOptionList
              key={index}
              value={item.optionValue}
              onClick={() => onSelectOption(item.optionValue)}
            >
              {item.optionValue}
            </SelectOptionList>
          ))}
        </SelectionOptionListUl>
      </>
    );
  };

  return (
    <Container>
      <DisableScroll state={state} />
      <CustomModal modalVisible={modalVisible} modalClose={modalClose}>
        <NotRefund modalClose={modalClose} />
      </CustomModal>
      <BottomSheetContainer
        className={HeightControlled()}
        filteredGoodsOptionsInfos={filteredGoodsOptionsInfos}
      >
        <RelativeFrame>
          <DownIcon onClick={handleClick}>
            <img src={downIcon} alt="아래로" />
          </DownIcon>
          <ScrollArea>
            {filteredGoodsOptionsInfos?.length > 0 && (
              <>
                <Label>옵션</Label>
                <SelectArea
                  isDropDown={isDropDown}
                  borderThick={borderThick}
                  className={isDropDown ? "optionOpen" : "optionClose"}
                >
                  {OptionList(filteredGoodsOptionsInfos)}
                </SelectArea>
              </>
            )}
            <QuantityContainer>
              <span>수량 선택</span>
              <Stepper quantity={quantity} setQuantity={handleQuantityChange} />
            </QuantityContainer>
            <TotalContainer>
              {isAvailable ? (
                <FlexRow>
                  <Total>총 금액</Total>
                  <PriceWrapper>
                    <TotalPrice>{NumberFormat(price)}</TotalPrice>
                    <Won>원</Won>
                  </PriceWrapper>
                </FlexRow>
              ) : (
                <TotalPrice>{message}</TotalPrice>
              )}
            </TotalContainer>
          </ScrollArea>
          <ButtonWrapper>
            <WhiteShadow id="그림자" />
            <Button
              text="바로 구매"
              size={"md"}
              disabled={!buyNowActive || (message && "login")}
              onClickFn={handleOpenNotRefund}
            />
          </ButtonWrapper>
        </RelativeFrame>
      </BottomSheetContainer>
      <ModalBackdrop onClick={handleCloseBottomSheet} state={state} />
    </Container>
  );
}

export default DetailBottomSheet;

const SlideInTop = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const SlideInBottom = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
`;

const MoveHeight = ({ fromHeight, toHeight }) => keyframes`
  0% {
    height: ${fromHeight};
  }
  100% {
    height: ${toHeight};
  }
`;

const DisableScroll = createGlobalStyle`
  body {
    overflow: ${({ state }) => (state === "Open" ? "hidden" : "auto")};
    height: ${({ state }) => (state === "Open" ? "100%" : "auto")};
  }
`;

const Container = styled.div`
  ${({ theme }) => theme.flexCenter};
  height: 100%;
`;

// 모달 배경
const ModalBackdrop = styled.div`
  position: fixed;
  ${({ theme }) => theme.flexCenter};

  display: ${({ state }) => (state === "Open" ? "" : "none")};
  inset: 0;
  background-color: black;
  opacity: 0.54;
  z-index: 999;
`;

const BottomSheetContainer = styled.div`
  background-color: white;
  width: 100%;
  height: ${({ filteredGoodsOptionsInfos }) =>
    filteredGoodsOptionsInfos?.length ? "410px" : "296px"};
  z-index: 1000;
  position: fixed;
  bottom: 0;

  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 0 16px 16px 16px;

  &.slideInTop {
    animation: ${SlideInTop} 0.3s ease-out forwards;
  }

  &.slideInBottom {
    animation: ${SlideInBottom} 0.2s ease-out forwards;
  }

  &.hidden {
    display: none;
  }

  &.optionOpen {
    animation: ${MoveHeight({ fromHeight: "410px", toHeight: "488px" })} 0.2s
      ease-out forwards;
  }

  &.optionClose {
    animation: ${MoveHeight({ fromHeight: "488px", toHeight: "410px" })} 0.2s
      ease-out forwards;
  }

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 480px;
  }
`;

const RelativeFrame = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const ScrollArea = styled.div`
  width: 100%;
  height: 100%;

  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const QuantityContainer = styled.div`
  width: 100%;
  height: 56px;

  background-color: ${colors.inputInside};
  border: 1px solid ${colors.inputBorder};
  color: ${colors.black200};

  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.3px;

  padding: 13px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
`;

const TotalContainer = styled(QuantityContainer)`
  background-color: ${colors.white};
  border-top: 0;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  margin-bottom: 128px;

  display: flex;
`;

const DownIcon = styled.button`
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  ${({ theme }) => theme.flexCenter}
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }
`;

const WhiteShadow = styled.div`
  width: 100%;
  height: 32px;

  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.3px;
  color: ${colors.black200};

  height: auto;
  margin-bottom: 12px;
`;

const SelectArea = styled.div`
  width: 100%;
  position: relative;
  background: transparent;

  border: ${({ borderThick }) =>
    borderThick
      ? `2px solid ${colors.inputFocus}`
      : `1px solid ${colors.inputBorder}`};
  border-radius: 4px;
  margin-bottom: 32px;
  overflow-y: auto;

  &.optionOpen {
    animation: ${MoveHeight({ fromHeight: "56px", toHeight: "216px" })} 0.2s
      ease-out forwards;
  }

  &.optionClose {
    animation: ${MoveHeight({ fromHeight: "216px", toHeight: "56px" })} 0.2s
      ease-out forwards;
  }
`;

const SearchBar = styled.button`
  width: 100%;
  height: 56px;

  color: ${colors.black200};
  font-size: 15px;
  font-weight: 500;
  font-weight: 700;
  font-family: "SpoqaHanSansNeo";
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
  padding: 0 16px;

  position: sticky;
  background-color: ${colors.white};
  top: 0;

  img {
    width: 36px;
    height: 36px;
  }
`;

const SelectionOptionListUl = styled.ul`
  width: 100%;
  height: 100%;
`;

const SelectOptionList = styled.li`
  width: 100%;
  height: 44px;
  color: ${colors.black};
  letter-spacing: -0.3px;

  border-top: 1px solid #eaeaea;

  padding: 14.5px;

  list-style: none;
  background-color: ${colors.inputInside};
  :hover {
    background-color: rgba(0, 0, 0, 0.05); // 검은색 5%
  }
`;

const Total = styled.span`
  color: ${colors.black200};
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.3px;
`;

const TotalPrice = styled.span`
  color: ${colors.priceRed};
  font-size: 20px;
  font-family: "SpoqaHanSansNeo";
  font-weight: 700;
  letter-spacing: 0;
`;

const Won = styled.span`
  color: ${colors.priceRed};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;

  margin-left: 2px;
`;

const FlexRow = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexCenter}
`;

const PriceWrapper = styled.span`
  flex: 1;
  ${({ theme }) => theme.flexBox("row", "flex-end", "center")}
`;
