import React, { useEffect } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import fonts, { fontWeight } from "assets/styles/fonts";
import Close from "assets/icons/delete-large.webp";
import ShareKakao from "assets/icons/share-kakao.webp";
import CopyUrl from "assets/icons/copy-url.webp";
import { showSnackBar } from "features/snackbar/snackbarSlice";

import { S3_BASE_URL } from "utils/constants";
import { useDispatch } from "react-redux";
const { Kakao } = window;
function ShareURL({
  SellingPrice,
  StandardPrice,
  Discount,
  GoodsImage,
  GoodsName,
  modalClose,
}) {
  const dispatch = useDispatch();

  const handleCopyClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      modalClose();
      dispatch(showSnackBar({ message: "URL 복사가 완료되었습니다." }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendMessage = () => {
    const path = window.location.pathname.substring(1);
    Kakao.Share.sendCustom({
      templateId: 97326,
      templateArgs: {
        link: path,
        goodsName: GoodsName,
        goodsImage: GoodsImage && S3_BASE_URL + "/" + GoodsImage[0],
        sellingPrice: SellingPrice,
        standardPrice: StandardPrice,
        discount: Discount,
      },
    });
    modalClose();
  };

  return (
    <Container>
      <CloseIcon src={Close} alt="닫기" onClick={() => modalClose()} />
      <Title>공유하기</Title>
      <BtnWrap>
        <KakaoBtn
          id="kakaotalk-sharing-btn"
          onClick={() => handleSendMessage()}
        >
          <img src={ShareKakao} alt="카카오 공유 아이콘" />
          카카오톡
        </KakaoBtn>
        <CopyUrlBtn
          onClick={() => handleCopyClipboard(`${window.location.href}`)}
        >
          <img src={CopyUrl} alt="URL 복사" />
          URL 복사
        </CopyUrlBtn>
      </BtnWrap>
    </Container>
  );
}

export default ShareURL;

const Container = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexCenterColumn}
  position: relative;
`;

const CloseIcon = styled.img`
  width: 48px;
  height: 48px;
  position: absolute;
  right: -16px;
  top: -16px;
`;

const Title = styled.h1`
  letter-spacing: -0.36px;
  color: ${colors.black200};
  font-weight: ${fontWeight.bold};
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 29px;
`;

const BtnWrap = styled.div`
  display: flex;
  margin-bottom: 13px;
`;
const CopyUrlBtn = styled.button`
  ${({ theme }) => theme.flexCenterColumn}
  color: ${colors.black700};
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.28px;

  img {
    width: 48px;
    height: 48px;
  }
`;
const KakaoBtn = styled(CopyUrlBtn)`
  margin-right: 48px;
`;
