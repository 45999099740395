import React from "react";
import {
  selectBottomSheet,
  closeBottomSheet,
} from "features/bottomsheet/bottomsheetSlice";
import { useDispatch, useSelector } from "react-redux";
import { BottomSheet } from "components";
import Welcome from "assets/icons/welcome.webp";
import PointCross from "assets/icons/point-cross.png";
import WelcomePoint from "assets/images/welcome-point.webp";
import styled from "styled-components";
import { NumberFormat } from "utils/utils";
import fonts, { fontWeight } from "assets/styles/fonts";
import { Button } from "components";

const BottomSheetLayout = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(selectBottomSheet);
  return (
    <BottomSheet distanceFromTop="calc(85vh - 60px)" hideGreyBar>
      <SheetContent>
        <Img>
          <ImgIcon src={Welcome} alt="welcom" />
        </Img>
        <Wrap height={"50px"} />
        <SheetTitle>회원가입이 완료되었습니다.</SheetTitle>
        <Wrap height={"2px"} />
        <SheetTitle>
          <SheetTitleNumber>{`${NumberFormat(
            data?.data?.amount,
          )} `}</SheetTitleNumber>
          <SheetTitle>웰컴 포인트 지급</SheetTitle>
        </SheetTitle>
        <Wrap height={"11px"} />
        <Info>
          지급된 포인트는 지금부터 1주일간 구매 시 <br />
          사용할 수 있습니다.
        </Info>
        <Wrap height={"28px"} />
        <PointBoxDiv>
          <PointBox>
            <PointBoxHeader>
              <PlusImg src={PointCross} />
              <PointBoxTtile>포인트 지급</PointBoxTtile>
              <PlusImg src={PointCross} />
            </PointBoxHeader>
            <PointBoxImg>
              <PointImg src={WelcomePoint} />
            </PointBoxImg>
            <PointBoxContent>
              <PointBoxNumber>{`+${NumberFormat(
                data?.data?.amount,
              )}`}</PointBoxNumber>
              <PointBoxText>point</PointBoxText>
            </PointBoxContent>
          </PointBox>
        </PointBoxDiv>
        <Wrap height={"40px"} />
        <InfoBox>
          <ul>
            <Li>지급된 포인트는 7일 후 자동 소멸됩니다.</Li>
            <Li>탈퇴 후 재가입 시 포인트는 제공되지 않습니다.</Li>
          </ul>
        </InfoBox>
        <Wrap height={"24px"} />
        <ButtonMargin>
          <Button
            text="포인트 받기"
            variant="round"
            size="md"
            onClickFn={() => dispatch(closeBottomSheet())}
          />
        </ButtonMargin>
      </SheetContent>
    </BottomSheet>
  );
};

export default BottomSheetLayout;

const Img = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -18px;
`;
const ImgIcon = styled.img`
  width: 93px;
  height: 34px;
  position: absolute;
`;

const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
  margin: ${(props) => `${props.margin}`};
  padding: ${(props) => `${props.padding}`};
`;

const SheetTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PointBox = styled.div`
  width: 154px;
  height: 154px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  background-color: #ffffff;
`;

const Li = styled.li`
  list-style: disc;
  line-height: 19px;
  color: #999999;
  font-size: 12px;
  ::marker {
    color: #999999;
    font-size: xx-small;
  }
`;

const SheetContent = styled.div`
  height: 100%;
  width: 100%;
`;

const Info = styled.div`
  color: #777777;
  font-size: 16px;
  text-align: center;
  line-height: 25.6px;
`;

const PlusImg = styled.img`
  height: 14px;
  font-size: 14px;
`;

const PointBoxTtile = styled.span`
  color: #333333;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
`;

const InfoBox = styled.div`
  background-color: #f9f9f9;
  padding: 9px 47px;
  margin: 0px 16px;
`;

const ButtonMargin = styled.div`
  margin: 0px 16px;
`;

const PointBoxNumber = styled.span`
  font-weight: ${fontWeight.bold};
  font-family: ${fonts.montserrat};
  color: #333333;
  font-size: 16px;
`;

const PointBoxText = styled.span`
  font-weight: ${fontWeight.semibold};
  font-family: ${fonts.montserrat};
  font-size: 16px;
  color: #333333;
  margin-left: 5px;
`;

const PointBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0px 9px 0px;
`;

const PointBoxImg = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
`;

const PointImg = styled.img`
  height: 68px;
`;

const PointBoxContent = styled.div`
  line-height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const PointBoxDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const SheetTitleNumber = styled.div`
  font-weight: ${fontWeight.semibold};
  font-family: ${fonts.montserrat};
  font-size: 29px;
  color: #333333;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
