import React from "react";
import styled from "styled-components";

import { S3_BASE_URL } from "utils/constants";

export default function Explanation({ goodsDetailImages }) {
  return (
    <ExplanationWrapper>
      {goodsDetailImages?.map((image, index) => (
        <DetailImage
          key={index}
          src={S3_BASE_URL + image}
          alt={image}
          loading="lazy"
        />
      ))}
    </ExplanationWrapper>
  );
}

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const DetailImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 32px;
`;
