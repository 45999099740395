const { useQuery } = require("react-query");
const { getAllGoodsList } = require("services/apis");

export const useGoodsListQuery = () => {
  const { data: goodsData = { time: "", goods: [] }, isLoading } = useQuery(
    "goodsAllList",
    () => {
      return getAllGoodsList().then(({ data: { success, data } }) => {
        if (success && Array.isArray(data)) {
          return {
            time: new Date(),
            goods: data,
          };
        }
      });
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchIntervalInBackground: true,
    },
  );

  return { goodsData, isLoading };
};
