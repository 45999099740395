import React, { useState, useEffect } from "react";
import styled, { css, createGlobalStyle } from "styled-components";
import { getMainPopup } from "services/apis";
import { useQuery } from "react-query";
import { objToQueryString } from "utils/utils";
import { S3_BASE_URL } from "utils/constants";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "services/storageService";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import colors from "assets/styles/color";
import { fontWeight } from "assets/styles/fonts";

const BottomPopup = () => {
  const navigate = useNavigate();
  const [showMainPop, setShowMainPop] = useState(false);
  const [overflow, setOverflow] = useState("");
  const HOME_VISITED = getLocalStorageItem("homeVisited");

  const { data: popup = [] } = useQuery("popup", () => {
    const param = {
      type: "POPUP",
    };
    return getMainPopup(objToQueryString(param)).then(
      ({ data: { success, data } }) => {
        if (success) {
          return !data[0] ? [] : [data[0]];
        }
      },
    );
  });

  useEffect(() => {
    const today = new Date();

    const handleMainPop = () => {
      if (HOME_VISITED === 0) {
        return;
      }
      if (HOME_VISITED && HOME_VISITED > today) {
        // 현재 date가 localStorage의 시간보다 크면 return
        return;
      }
      if (!HOME_VISITED || HOME_VISITED < today) {
        // 저장된 date가 없거나 today보다 작다면 popup 노출
        setShowMainPop(true);
      }
    };
    window.setTimeout(handleMainPop, 500);
  }, [HOME_VISITED]);

  // 닫기1
  const handleClosePopup = () => {
    setShowMainPop(false);
  };

  useEffect(() => {
    if (popup.length !== 0 && showMainPop) {
      setOverflow("hidden");
      return () => {
        setOverflow("unset");
      };
    }
  }, [showMainPop]);

  // ~ 일간 보지 않기
  const handlePeriodClose = (expireDays) => {
    if (expireDays === 0) {
      setLocalStorageItem("homeVisited", expireDays);
      setShowMainPop(false);
    } else {
      let expires = new Date();
      expires = expires.setHours(expires.getHours() + expireDays * 24);
      setLocalStorageItem("homeVisited", expires);
      // 현재 시간의 24시간 뒤의 시간을 homeVisited에 저장
      setShowMainPop(false);
    }
  };

  const handleClickPopup = (noticeId, category, content) => {
    if (category === "EVENT") {
      window.location.href = content;
    } else if (category === "NOTICE") {
      navigate(routes.noticeDetail, { state: { noticeId } });
    }
  };

  return popup.length === 0 ? (
    <></>
  ) : (
    showMainPop && (
      <Dimmed>
        <DisableScroll overflow={overflow} />
        {popup?.map((item, i) => {
          return (
            <SheetWrap key={i.toString()}>
              <SheetCont>
                <SheetImage
                  alt="팝업 이미지"
                  src={S3_BASE_URL + item?.path}
                  onClick={() =>
                    handleClickPopup(
                      item?.noticeId,
                      item?.category,
                      item?.content,
                    )
                  }
                />
              </SheetCont>
              <ButtonWrap>
                <ActButton
                  onClick={() => handlePeriodClose(item?.popupPeriod)}
                  align="left"
                >
                  {item?.popupPeriod
                    ?.toString()
                    ?.replace("1", "오늘 하루 ")
                    ?.replace("3", "3일간 ")
                    ?.replace("7", "7일간 ")
                    ?.replace("0", "다시 ")}
                  보지 않기
                </ActButton>
                <ActButton align="right" onClick={handleClosePopup}>
                  닫기
                </ActButton>
              </ButtonWrap>
            </SheetWrap>
          );
        })}
      </Dimmed>
    )
  );
};

export default BottomPopup;

const Dimmed = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.64);
  ${({ theme }) => theme.flexCenterColumn}
`;

const SheetWrap = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 480px;
  }
`;

const SheetCont = styled.div`
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 28px 28px 0 0;
  overflow: hidden;
`;

const SheetImage = styled.img`
  width: 100%;
  height: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  background-color: white;
`;

const ActButton = styled.div`
  color: ${colors.black300};
  width: 100%;
  border: none;
  padding: 22px 27px 30px;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  letter-spacing: -0.02em;
  /* text-align: left; */
  ${(props) => {
    return css`
      text-align: ${props.align};
    `;
  }}
`;

const DisableScroll = createGlobalStyle`
  body {
    overflow: ${({ overflow }) => overflow};
  }
`;
