import React from "react";
import styled from "styled-components";
import rightArrow from "assets/icons/right-arrow.webp";
import useGuide from "assets/icons/use-guide.webp";

const Guidance = () => {
  return (
    <>
      <GuidanceContainer>
        <TitleBox>안내</TitleBox>
      </GuidanceContainer>
      <InfoBox
        onClick={() => {
          window.location.href = process.env.REACT_APP_USER_GUIDE_URL;
        }}
      >
        <TitleWrap>
          <Img src={useGuide} alt="이용가이드" />
          <Title>이용가이드</Title>
        </TitleWrap>
        <div>
          <Img src={rightArrow} alt="오른쪽화살표" />
        </div>
      </InfoBox>
    </>
  );
};

export default Guidance;

const GuidanceContainer = styled.div`
  padding: 16px 16px 0px 16px;
`;

const TitleBox = styled.div`
  height: 48px;
  padding: 16px 0px;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: -0.34px;
  color: #333333;
`;

const InfoBox = styled.div`
  padding: 0px 0px 16px 6.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  cursor: pointer;
`;

const Title = styled.span`
  margin-left: 5.5px;
  font-weight: bold;
  font-size: 15px;
  color: #444444;
`;
const Img = styled.img`
  width: 44px;
  height: 44px;
`;

const TitleWrap = styled.div`
  align-items: center;
  display: flex;
`;
