import React, { useEffect } from "react";
import naver from "assets/images/naver.png";
import kakao from "assets/images/kakao.png";
import google from "assets/images/google.png";
import apple from "assets/images/apple.png";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getOauthUrls, postAdInfo } from "services/apis";
import { signInUsingToken } from "services/authService";
import { getGaClientId } from "utils/utils";

const LoginButtons = ({ onClickIdLogin }) => {
  const [searchParams] = useSearchParams();
  const redirectURI = decodeURIComponent(searchParams.get("redirectURI"));
  // const preventAdInfo = useRef(false);

  const { data: urls } = useQuery("auth-oauth-urls", () => {
    return getOauthUrls().then(({ data }) => {
      return data;
    });
  });

  const options =
    "width = 500, height = 500, left = 100, top = 100, scrollbar = no, location = no, toolbars = no, status = no";

  const navigate = useNavigate();
  const handleClickLogin = async (type) => {
    if (urls[type]) {
      window.open(urls[type], "simple 로그인", options);
    }
  };

  useEffect(() => {
    async function postMessageCatcher(event) {
      if (event.data.type === "simple_login") {
        const clientId = getGaClientId();
        let token = event.data.accessToken;
        await signInUsingToken(
          token,
          navigate,
          redirectURI,
          "로그인되었습니다.",
        );
        await postAdInfo({ ga_instance: clientId });
      }
    }
    window.addEventListener("message", postMessageCatcher, false);
    return () => {
      window.removeEventListener("message", postMessageCatcher);
    };
  }, [navigate, redirectURI]);

  return (
    <>
      <div className="login_sns">
        <p className="sns_login_title"> 간편 로그인 </p>
        <div className="sns_button">
          <button
            className="naver_bt pointer"
            onClick={() => handleClickLogin("NAVER")}
          >
            <img src={naver} alt="" /> 네이버로 로그인
          </button>
          <button
            className="kakao_bt pointer"
            onClick={() => handleClickLogin("KAKAO")}
          >
            <img src={kakao} alt="" /> 카카오톡으로 로그인
          </button>
          <button
            className="google_bt pointer"
            onClick={() => handleClickLogin("GOOGLE")}
          >
            <img src={google} alt="" /> 구글로 로그인
          </button>
          <button
            className="apple_bt pointer"
            onClick={() => handleClickLogin("APPLE")}
          >
            <img src={apple} alt="" /> 애플로 로그인
          </button>
        </div>
      </div>
      <div className="login_kb">
        <button onClick={onClickIdLogin} className="pointer">
          아이디로 로그인
        </button>
      </div>
    </>
  );
};

export default LoginButtons;
