import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import DefaultLayout from "components/layouts/DefaultLayout";
import { getSelfVerificationRequestId } from "services/apis";
import { useSelector } from "react-redux";

const SelfVerification = () => {
  const location = useLocation();
  const locationState = location.state || {};
  const { purpose } = locationState;
  const auth = useSelector((state) => state.auth);

  const handleClickVerification = () => {
    getSelfVerificationRequestId({ purpose: purpose }).then(
      ({ data: { requestId } }) => {
        window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/mall/verification/self/start?requestId=${requestId}`;
      },
    );
  };

  return (
    <DefaultLayout title="본인인증" navigationVisible={false}>
      <Container>
        {(() => {
          switch (purpose) {
            case "UPDATE_SECURE_PASSWORD":
              return (
                <Wrap>
                  <Header>개인정보변경</Header>
                  <Label>
                    결제 비밀번호 변경을 위해
                    <br />
                    본인인증이 필요합니다.
                  </Label>
                </Wrap>
              );

            case "UPDATE_PHONENUMBER":
              return (
                <Wrap>
                  <Header>개인정보변경</Header>
                  <Label>
                    휴대폰 번호 변경을 위해
                    <br />
                    본인인증이 필요합니다.
                  </Label>
                </Wrap>
              );

            default:
              return (
                <Wrap>
                  <Label>
                    금방금방은
                    <br />
                    안전한 거래를 위해
                    <br />
                    본인 인증이 필요합니다.
                  </Label>
                </Wrap>
              );
          }
        })()}
        <Button onClick={handleClickVerification}>
          <Text>본인 인증</Text>
        </Button>
      </Container>
    </DefaultLayout>
  );
};

export default SelfVerification;

const Button = styled.div`
  // position: absolute;
  // bottom: 50px;
  cursor: pointer;
  width: calc(100% - 60px);
  margin: 10px 30px 60px 30px;
  border-radius: 8px;
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: center;
  padding: 14px 16px;
  flex-direction: row;
  background-color: #ff6e0e;
`;

const Text = styled.span`
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
`;

const Label = styled.div`
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.42px;
  color: #333333;
`;

const Container = styled.div`
  background-color: white;
  width: 100%;
  max-width: 960px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  overflow-y: auto;
`;

const Header = styled.div`
  margin-bottom: 11px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.28px;
  color: #ff6e0e;
`;

const Wrap = styled.div`
  padding: 19px 25px 0;
`;
