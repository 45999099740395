import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import colors from "assets/styles/color";
import gobackIcon from "assets/icons/goback.webp";
import {
  resetBuyInfo,
  resetShopInfo,
  resetDeliveryInfo,
} from "features/buy/buySlice";
import { useScroll } from "hooks/useScroll";

function PurchaseHeader({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState = location.state;
  const isScrolled = useScroll();
  const { id } = useParams();

  const handleGoBack = () => {
    dispatch(resetBuyInfo());
    dispatch(resetShopInfo());
    dispatch(resetDeliveryInfo());
    locationState?.FromFailed
      ? navigate(`/detail/${id}`, {
          state: { FromFailed: locationState?.FromFailed },
        })
      : navigate(-1);
  };

  return (
    <HeaderContainer isScrolled={isScrolled}>
      <GoBackIcon onClick={handleGoBack}>
        <img src={gobackIcon} alt="뒤로가기" />
      </GoBackIcon>
      <span>{title}</span>
    </HeaderContainer>
  );
}

export default PurchaseHeader;

const HeaderContainer = styled.div`
  width: 100%;
  height: 56px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 10;
  border-bottom: 1px solid
    ${({ isScrolled }) => (!isScrolled ? `#e0e0e0` : `transparent`)};
  box-shadow: ${({ isScrolled }) =>
    isScrolled && `0px 2px 2px rgba(0, 0, 0, 0.04)`};

  span {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: ${colors.black300};
  }
`;

const GoBackIcon = styled.button`
  width: 48px;
  height: 48px;
  ${({ theme }) => theme.flexCenter}
  cursor: pointer;
  img {
    width: 9px;
    height: 14px;
    color: ${colors.black};
  }
`;
