import React, { useMemo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import fonts, { fontWeight } from "assets/styles/fonts";
import colors from "assets/styles/color";
import { NumberFormat } from "utils/utils";
import { S3_BASE_URL } from "utils/constants";
import FixedRatio from "components/common/FixedRatio";

const GoodsCard = ({
  goodsId,
  image,
  brand,
  goodsName,
  standardPrice,
  sellingPrice,
  isSoldOut,
}) => {
  const navigate = useNavigate();

  const handleClickItem = () => {
    navigate(`/detail/${goodsId}`);
  };

  const discountRate = useMemo(() => {
    let percent;
    if (
      typeof standardPrice === "undefined" ||
      typeof sellingPrice === "undefined"
    ) {
      return "0%";
    }
    if (standardPrice === 0) {
      if (sellingPrice === 0) {
        // 기준 가격과 판매 가격이 모두 0인 경우
        percent = 0;
      } else {
        // 기준 가격은 0이지만 판매 가격은 0이 아닌 경우
        percent = 0; // 원래 값은 Infinity
      }
    } else {
      percent = Math.round(
        ((standardPrice - sellingPrice) / standardPrice) * 100,
      );
    }
    return percent !== 0 ? percent + "%" : "0%";
  }, [sellingPrice, standardPrice]);

  const VreviewRate = React.memo(() => {
    return <div vreview-product-id={goodsId} />;
  }, [goodsId]);

  return (
    <div onClick={handleClickItem} className="goods-card-wrap pointer">
      <div style={{ width: "100%" }}>
        <FixedRatio ratio={1}>
          <img
            alt=""
            src={S3_BASE_URL + "/" + image}
            loading="lazy"
            className={"goods-card-img"}
            style={{
              opacity: isSoldOut ? 0.5 : 1,
            }}
          />
        </FixedRatio>
      </div>
      <div className="goods-card-detail">
        {brand && (
          <span
            className="goods-brand"
            style={{
              color: isSoldOut ? colors.disabledColor : colors.black700,
            }}
          >
            {brand}
          </span>
        )}
        <span
          className="goods-card-name"
          style={{
            color: isSoldOut ? colors.disabledColor : colors.black400,
          }}
        >
          {goodsName}
        </span>
        {isSoldOut ? (
          <div className="soldout-box">품절</div>
        ) : (
          <>
            <span className="goods-cost-price">
              <span className="line-through">
                <span className="spoqa">{NumberFormat(standardPrice)}</span> 원
              </span>
            </span>
            <span className="goods-price-wrap">
              <span className="discount-rate">{discountRate}</span>
              <span className="goods-price">{NumberFormat(sellingPrice)}</span>
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                원
              </span>
            </span>
            <span className="delivery-charge-wrap">
              <div className="vreview-wrap">
                <VreviewRate />
              </div>
              <span className="delivery-charge">무료배송</span>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

const GoodsWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const GoodsDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0 37px;
`;

const GoodsBrand = styled.span`
  font-size: 13px;
  color: ${(props) =>
    props.isSoldOut ? colors.disabledColor : colors.black700};
  opacity: 0.99;
  margin-bottom: 6px;
`;

const GoodsName = styled.span`
  font-weight: ${fontWeight.medium};
  font-size: 14px;
  color: ${(props) =>
    props.isSoldOut ? colors.disabledColor : colors.black400};
`;

const GoodsCostPrice = styled.span`
  margin-top: 10px;
  font-size: 13px;
  color: ${colors.black800};

  .spoqa {
    font-size: 13px;
    letter-spacing: 0.26px;
  }

  .line-through {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      background-color: ${colors.black800};
      height: 1px;
      top: 50%;
      left: 0;
      right: 0;
    }
  }
`;

const GoodsPriceWrap = styled.span`
  margin-top: 6px;
  font-size: 16px;
  color: ${colors.black300};
`;

const DeliveryChargeWrap = styled.span`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;
const DeliveryCharge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 3px 5px 3px 5px;
  color: #777777;
  font-size: 11px;
  // line-height: 25px;
  font-weight: ${fontWeight.regular};
  font-family: ${fonts.notoSansKR};
  border: 1px solid #d6d6d6;
  border-radius: 5px;
`;

const DiscountRate = styled.span`
  font-family: ${fonts.spoqa};
  font-weight: ${fontWeight.bold};
  font-size: 16px;
  color: ${colors.primaryRed};
  margin-right: 8px;
`;
const GoodsPrice = styled.span`
  font-family: ${fonts.spoqa};
  font-weight: ${fontWeight.bold};
  font-size: 16px;
  color: ${colors.black300};
`;
const Unit = styled.span`
  font-size: 14px;
`;
const SoldOut = styled.div`
  margin-top: 13px;
  width: 42px;
  height: 24px;
  background-color: ${colors.black400};
  color: #ffffff;
  font-size: 14px;
  font-weight: ${fontWeight.bold};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GoodsImage = styled.img`
  width: 100%;
  height: 100%;
  opacity: ${({ isSoldOut }) => isSoldOut && "0.5"};
  object-fit: contain;
  background-color: ${colors.white700};
  border: 1px solid ${colors.imageBorder};
`;

const VReview = styled.div`
  .vreview-review-summary {
    margin-right: 4px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    padding: 0px 5px;
    width: fit-content;
    height: 20px;
    font-size: 11px;
    line-height: 20px;
  }

  .vreview-board-popup {
    margin-top: unset !important;
  }
  .vreview-rating {
    margin-top: -3px !important;
    width: 8px;
    height: 8px;
  }
`;

export default GoodsCard;
