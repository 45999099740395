import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

import { routes } from "routes";
import colors from "assets/styles/color";
import fonts from "assets/styles/fonts";
import { S3_BASE_URL } from "utils/constants";
import { getGoodsCategory, getGoodsGroup } from "services/apis";
import useScrollControl from "../../../hooks/useScrollControl";

import Cen from "assets/images/logo-cen.webp";
import MyKumbang from "assets/images/my-kumbang.webp";
import Cancle from "assets/icons/cancle-bottomsheet.webp";
import RightArrow from "assets/icons/right-arrow-black.webp";
import KumbangKumbang from "assets/images/logo-kumbangkumbang.png";
import KumbangTextLogo from "assets/images/kumbang-text-logo.svg";

function Hamburger({
  navigationVisible,
  isHamburgerOpen,
  handleHamburgerClick,
}) {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [goodsGroup, setGoodsGroup] = useState([]);
  const auth = useSelector((state) => state.auth);
  const { isSigned } = auth;
  const scrollContainerRef = useScrollControl();

  const KumbangUrl =
    "https://play.google.com/store/apps/details?id=com.korda.goldmarket&hl=ko&gl=US";
  const CenUrl =
    "https://play.google.com/store/apps/details?id=com.cengold.korda&hl=ko&gl=US";

  useEffect(() => {
    if (isHamburgerOpen) {
      getGoodsCategory().then((res) => {
        let categoryList = res.data.data.filter(
          (goods) => goods?.goodsIdList.length !== 0,
        );
        setCategory(categoryList);
      });
      getGoodsGroup().then((res) => setGoodsGroup(res.data.data));
    }
  }, [isHamburgerOpen]);

  useEffect(() => {
    let vh = 0;
    const setVh = () => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVh();

    window.addEventListener("resize", setVh);
    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  const handleClose = () => handleHamburgerClick();
  const handleClickLogin = () => navigate(routes.login);
  const handleClickMyKumbang = () => {
    isSigned ? navigate("/my-page") : navigate("/login");
  };

  const handleClickCategory = (cate) => {
    handleHamburgerClick();
    navigate(`${routes.goodsCategory}/${cate.goodsCategoryId}`, {
      state: { goodsCategoryId: cate.goodsCategoryId },
    });
  };

  const handleClickGoodsGroup = (group) => {
    handleHamburgerClick();
    navigate(`${routes.goods}/${group.goodsGroupId}`, {
      state: { goodsGroupId: group.goodsGroupId },
    });
  };

  const CategoryComponent = category?.map((cate) => {
    return (
      <FlexColumnCenter
        key={cate.goodsCategoryId}
        onClick={() => handleClickCategory(cate)}
      >
        <CategoryCard src={S3_BASE_URL + cate?.image} alt="카테고리 이미지" />
        <CategoryKind>{cate.kind}</CategoryKind>
      </FlexColumnCenter>
    );
  });

  const GoodsGroupComponent = goodsGroup?.map((group, index) => {
    return (
      <FlexColumnCenter
        key={group.goodsGroupId}
        onClick={() => handleClickGoodsGroup(group)}
        className="GoodsGroup"
        last={index === goodsGroup.length - 1}
      >
        <div className="image-wrap">
          <GoodsGroupCard
            src={S3_BASE_URL + group?.goodsGroupImage}
            alt="상품군 이미지"
          />
          <div className="border"></div>
        </div>
        <GoodsGroupName>{group?.goodsGroupName}</GoodsGroupName>
      </FlexColumnCenter>
    );
  });

  return (
    navigationVisible &&
    createPortal(
      <Container className={isHamburgerOpen ? "open" : "close"}>
        <DisableScroll isHamburgerOpen={isHamburgerOpen} />
        <Header>
          <SignedText>
            {!isSigned && (
              <FlexCenter onClick={handleClickLogin}>
                <div>로그인 해주세요</div>
                <ArrowIcon src={RightArrow} alt="로그인" />
              </FlexCenter>
            )}
          </SignedText>
          <CancleIcon src={Cancle} alt="햄버거 닫기" onClick={handleClose} />
        </Header>
        <Content>
          <OrangeBtn onClick={handleClickMyKumbang}>
            <img src={MyKumbang} alt="마이금방" />
          </OrangeBtn>
          <PaddingDiv>
            <TextBold>카테고리</TextBold>
            <CategoryContainer>{CategoryComponent}</CategoryContainer>
          </PaddingDiv>

          <RecommendWrap>
            <TextBold>테마 추천</TextBold>
            <GoodsGroupContainer ref={scrollContainerRef}>
              {GoodsGroupComponent}
            </GoodsGroupContainer>
          </RecommendWrap>

          <GrayBar />
          <PaddingDiv className="flex">
            <TextBold last={true}>한국금거래소디지털에셋 앱 다운로드</TextBold>
            <FlexRow className="mb-28">
              <FlexColumnCenter className="mr-16">
                <LogoImg
                  src={KumbangKumbang}
                  alt="금방금방"
                  onClick={() => {
                    window.open(KumbangUrl);
                  }}
                />
                <CategoryKind>금방금방</CategoryKind>
              </FlexColumnCenter>
              <FlexColumnCenter>
                <LogoImg
                  src={Cen}
                  alt="센골드"
                  onClick={() => {
                    window.open(CenUrl);
                  }}
                />
                <CategoryKind>센골드</CategoryKind>
              </FlexColumnCenter>
            </FlexRow>
          </PaddingDiv>
          <Footer>
            <CategoryFooter>
              <LogoText src={KumbangTextLogo} alt="금방금방 로고" />
              고객센터
            </CategoryFooter>
            <FlexRow className="mb-18">
              <SubText>전화</SubText>
              <div>
                <FlexCenter
                  className="pointer"
                  onClick={() => {
                    window.location.href = "tel:16708978";
                  }}
                >
                  <SpoqaText>1670 - 8978 </SpoqaText>
                  <SmallText> (유료)</SmallText>
                </FlexCenter>
                <SmallText>
                  월~금, <span className="spoqa">09:00~17:00</span>{" "}
                  <span className="holiday">| 주말 공휴일 휴무</span>
                </SmallText>
              </div>
            </FlexRow>
            <FlexRow>
              <SubText>메일</SubText>
              <div>
                <SpoqaText>helpk@korda.im</SpoqaText>
                <SmallText>
                  · 사업 제휴 메일:{" "}
                  <span className="tab">contact@korda.im</span>
                </SmallText>
              </div>
            </FlexRow>
          </Footer>
        </Content>
      </Container>,
      document.getElementById("hamburger-root"),
    )
  );
}

export default Hamburger;

const DisableScroll = createGlobalStyle`
  body {
    overflow: ${({ isHamburgerOpen }) => (isHamburgerOpen ? "hidden" : "auto")};
    height: ${({ isHamburgerOpen }) => (isHamburgerOpen ? "100%" : "auto")};
  }  
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  left: -100%;
  background-color: ${colors.white};
  z-index: 1000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: 0.3s ease-in-out;
  &.open {
    left: 0;
  }

  &.close {
    left: -100%;
  }

  @media only screen and (min-width: 900px) {
    max-width: 480px;

    &.open {
      left: calc(50% - 240px);
    }

    &.close {
      left: calc(50% - 720px);
    }

    border-width: 0 1px 0 1px;
    border-style: solid;
    border-color: #eaeaea;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 56px;
  ${({ theme }) => theme.flexCenter}
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e0e0e0;
  background-color: ${colors.white};
  padding: 16px 24px;
  z-index: 1;
`;

const SignedText = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.32px;
  color: ${colors.black300};
`;

const ArrowIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-left: -13px;
  margin-top: 2px;
  cursor: pointer;
`;

const CancleIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-right: -16px;
`;

const Content = styled.div`
  width: 100%;
  max-height: calc(100% - 56px);
  overflow-y: auto;
`;

const OrangeBtn = styled.button`
  width: 88px;
  height: 32px;
  margin: 24px 24px 0;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
`;

const TextBold = styled.div`
  margin-top: 28px;
  margin-bottom: ${(props) => (props.last ? "14px" : "16px")};
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.34px;
  color: ${colors.black300};
`;

const CategoryFooter = styled.div`
  height: 25px;
  margin-top: 32px;
  margin-bottom: 24px;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.34px;
  color: ${colors.black300};
  display: flex;
  align-items: center;
`;

const CategoryCard = styled.img`
  width: 72px;
  height: 72px;
  background-color: #f9f9f9;
  border-radius: 16px;
  border: 1px solid #f4f4f4;
`;

const CategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(72px, 1fr));
  grid-gap: 8px;
`;

const CategoryKind = styled.div`
  font-size: 13px;
  letter-spacing: -0.26px;
  color: ${colors.black300};
  margin-top: 8px;
  margin-bottom: 16px;
`;

const FlexColumnCenter = styled.div`
  ${({ theme }) => theme.flexCenterColumn}
  cursor: pointer;
  &.mr-16 {
    margin-right: 16px;
  }

  &.GoodsGroup {
    margin-right: ${(props) => (props.last ? "0px" : "12px")};
  }

  .border {
    position: absolute;
    width: 64px;
    height: 64px;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 100%;
  }

  .image-wrap {
    position: relative;
    width: 64px;
    height: 64px;
  }
`;

const GoodsGroupContainer = styled.div`
  width: 100%;
  height: 133px;
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  margin-bottom: 16px;
  align-items: flex-start;

  :last-child {
    padding-right: 16px;
  }
`;

const GoodsGroupCard = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;

const GoodsGroupName = styled.div`
  font-size: 13px;
  color: ${colors.black300};
  margin-top: 8px;
  margin-bottom: 16px;
`;

const GrayBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: #f7f7f7;
`;

const LogoImg = styled.img`
  width: 72px;
  height: 72px;
`;

const Footer = styled.div`
  width: 100%;
  height: auto;
  background-color: ${colors.inputInside};
  padding: 1px 24px 100px 24px;
`;

const PaddingDiv = styled.div`
  width: 100%;
  padding: 0 24px 20px;
`;

const RecommendWrap = styled.div`
  width: 100%;
  padding-left: 24px;
`;

const FlexRow = styled.div`
  display: flex;

  &.mb-8 {
    margin-bottom: 8px;
  }

  &.mb-16 {
    margin-bottom: 16px;
  }

  &.mb-18 {
    margin-bottom: 18px;
  }

  &.mb-28 {
    margin-bottom: 28px;
  }
`;

const FlexCenter = styled.div`
  ${({ theme }) => theme.flexBox("row", "flex-start", "center")}
  &.pointer {
    cursor: pointer;
  }
`;

const SubText = styled.div`
  min-width: max-content;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.3px;
  line-height: 26px;
  color: ${colors.black500};
  margin-right: 52px;
`;

const SpoqaText = styled.div`
  font-family: "SpoqaHanSansNeo";
  font-size: 16px;

  font-weight: 700;

  letter-spacing: -0.32px;
  line-height: 26px;
  color: ${colors.black500};
`;

const SmallText = styled.div`
  font-size: 13px;
  color: ${colors.black700};
  line-height: 20px;

  .spoqa {
    font-family: ${fonts.spoqa};
  }

  @media (max-width: 350px) {
    .holiday::before {
      content: "\\A";
      white-space: pre;
    }

    .tab::before {
      content: "\\00a0\\00a0\\00a0\\00a0";
    }
  }
`;

const LogoText = styled.img`
  width: 69px;
  height: 17px;
  margin-right: 3px;
`;
