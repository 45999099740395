import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import watchConfirm from "features/modal/modalSaga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
});

const persistor = persistStore(store); // 영속화 설정

sagaMiddleware.run(watchConfirm);

export { store, persistor };
