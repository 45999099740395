import React, { useState, useEffect } from "react";
import styled from "styled-components";
import cancel from "assets/icons/cancel.webp";
import useUserInfoQuery from "hooks/queries/user";
import { postAccountCheck } from "services/apis";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { showAlert } from "features/modal/modalSlice";

const InsertAccount = ({
  currentStep,
  setCurrentStep,
  bankList,
  selectBank,
  accountNumber,
  setAccountNumber,
}) => {
  const dispatch = useDispatch();
  const { data } = useUserInfoQuery();
  const [validation, setValidation] = useState(false);

  const handleClickSubmit = () => {
    if (validation) {
      const data = { bankCd: selectBank, accountNumber };
      dispatch(showLoading());
      postAccountCheck(data)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setCurrentStep(currentStep + 1);
          } else {
            dispatch(
              showAlert({
                message: message,
                icon: "Warning",
              }),
            );
          }
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  const validateAccount = (number) => {
    const numberLength = String(number).length;
    let test = false;
    if (numberLength >= 10 && numberLength <= 14) {
      test = true;
    }
    return test;
  };

  useEffect(() => {
    setValidation(validateAccount(accountNumber));
  }, [accountNumber]);
  return (
    <Container>
      <div>
        <Title>
          <UnderLine>
            {bankList.find((item) => item.code === selectBank).name} 계좌번호
          </UnderLine>
          를
          <br />
          입력해주세요.
        </Title>
        <Wrap height={"28px"} />
        <InputBoxGray>
          <div>
            <InputHeader>예금주</InputHeader>
          </div>
          <UserName>{data?.name}</UserName>
        </InputBoxGray>
        <Wrap height={"18px"} />
        <InputBox>
          <div>
            <InputHeader>계좌번호</InputHeader>
          </div>
          <Flex>
            <Input
              accountNumber={accountNumber}
              placeholder="'-'없이 입력"
              value={accountNumber}
              onChange={(e) => {
                setAccountNumber(e.target.value.replace(/[^-0-9]/g, ""));
              }}
            />
            {accountNumber !== "" && (
              <CancelImg
                src={cancel}
                alt="cancel"
                onClick={() => setAccountNumber("")}
              />
            )}
          </Flex>
        </InputBox>
        <InfoText>* 본인 계좌만 가능.</InfoText>
      </div>
      <Button
        validation={validation}
        onClick={() => {
          handleClickSubmit();
        }}
      >
        ARS 인증번호 받기
      </Button>
    </Container>
  );
};

export default InsertAccount;

const Container = styled.div`
  padding: 24px 24px 64px 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const Button = styled.button`
  height: 48px;
  background-color: ${(props) => (props.validation ? "#ff6e0e" : "#dddddd")};
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => (props.validation ? "#ffffff" : "#bbbbbb")};
  cursor: ${(props) => (props.validation ? "pointer" : "")};
`;

const Title = styled.div`
  line-height: 30px;
  font-weight: bold;
  font-size: 21px;
  color: #222222;
`;

const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
`;

const InputBox = styled.div`
  padding: 12px 0px 0px 15px;
  border: 1px solid #656667;
  border-radius: 8px;
  background-color: #ffffff;
`;

const InputBoxGray = styled.div`
  padding: 12px 0px 0px 15px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  background-color: #eeeeee;
`;

const InputHeader = styled.span`
  font-weight: normal;
  font-size: 12px;
  color: #666666;
`;
const Input = styled.input`
  color: ${(props) => `${props.accountNumber && "#333333"}`};
  height: 49px;
  flex: 1;
  font-size: 16px;
`;

const CancelImg = styled.img`
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoText = styled.div`
  margin: 16px 0px 0px 13px;
  color: #777777;
  font-size: 14px;
`;

const UserName = styled.div`
  color: #98999a;
  height: 47px;
  display: flex;
  align-items: center;
`;
const UnderLine = styled.span`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 1px;
`;
