import React, { useEffect, useState } from "react";
import { getTerms, getTermsDetail } from "services/apis";
import checkon from "assets/images/checkon.png";
import checkoff from "assets/images/checkoff.png";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import Flex from "components/common/flex/Flex";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "components/layouts/DefaultLayout";
import { routes } from "routes";

const AcceptTerms = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationState = location.state || {};
  const { termsTypes = [] } = locationState;

  const { data: terms = [], isLoading } = useQuery("sign-up-terms", () => {
    return getTerms().then(({ data: { success, data } }) => {
      if (success) {
        return data.filter(({ signup }) => signup);
      }
    });
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoading());
    } else {
      dispatch(hideLoading());
    }
  }, [isLoading]);

  const handleClickConfirm = () => {
    canNext &&
      navigate(routes.authSignUpRegisterShop, { state: { ...locationState } });
  };

  const handleClickTerms = (termsType) => {
    let copied = [...termsTypes];
    if (termsTypes.includes(termsType)) {
      copied = termsTypes.filter((type) => type !== termsType);
    } else {
      copied.push(termsType);
    }

    navigate(location.pathname, {
      replace: true,
      state: { ...locationState, termsTypes: copied },
    });
  };

  const handleClickAll = () => {
    if (termsTypes.length === terms.length) {
      navigate(location.pathname, {
        replace: true,
        state: { ...locationState, termsTypes: [] },
      });
    } else {
      navigate(location.pathname, {
        replace: true,
        state: { ...locationState, termsTypes: terms.map(({ type }) => type) },
      });
    }
  };

  const handleClickTermsView = ({ type, title }) => {
    getTermsDetail(type).then(
      ({
        data: {
          data: { content },
        },
      }) => {
        navigate("/common/html-viewer", { state: { title, content } });
      },
    );
  };

  const canNext =
    terms.findIndex(
      ({ type, required }) => required && !termsTypes.includes(type),
    ) === -1;

  return (
    <DefaultLayout title="회원가입">
      <Flex className="join_wrap" flex={1}>
        <div className="all_check">
          <input
            type="checkbox"
            id="check_all"
            checked={termsTypes.length && termsTypes.length === terms.length}
            readOnly
            onClick={handleClickAll}
          />
          <label htmlFor="check_all" className="pointer"></label>
          <span onClick={handleClickAll} className="pointer">
            전체동의
          </span>
        </div>
        <ul className="checklist">
          {terms.map(({ type, title, required }) => {
            return (
              <li className="select_check">
                <input
                  type="checkbox"
                  id={type}
                  checked={termsTypes.includes(type)}
                  onClick={() => {
                    handleClickTerms(type);
                  }}
                  readOnly
                />
                <label htmlFor={type} className="pointer">
                  <img
                    src={termsTypes.includes(type) ? checkon : checkoff}
                    alt=""
                  />
                </label>
                <span
                  className="pointer"
                  onClick={() => {
                    handleClickTermsView({ type, title });
                  }}
                >{`${required ? "[필수]" : "[선택]"} ${title}`}</span>
              </li>
            );
          })}
        </ul>
        <button
          className={canNext ? "on pointer" : "off"}
          onClick={handleClickConfirm}
        >
          확인
        </button>
      </Flex>
    </DefaultLayout>
  );
};

export default AcceptTerms;
