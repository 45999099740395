import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectBuyInfo } from "features/buy/buySlice";
import PurchaseResult from "./components/PurchaseResult";

/**
 *
 * @description 외부 이동 X일 때
 */
function PurchaseUnabled() {
  const navigate = useNavigate();
  const { BuyInfo } = useSelector(selectBuyInfo);

  const handleUnabled = () => {
    navigate(`/goods-purchase/${BuyInfo?.goodsId}`, {
      state: {
        FromFailed: true,
      },
    });
  };

  return (
    <PurchaseResult
      icon="Fail"
      title="결제 실패"
      reason="결제 불가"
      id={BuyInfo?.goodsId}
      onConfirm={handleUnabled} // 확인, X버튼 콜백함수
      isSuccess={false} // 성공 실패 여부
      showCSInfo={true}
    />
  );
}

export default PurchaseUnabled;
