import React from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import { PhoneNumberFormat } from "utils/utils";
import Delete from "assets/icons/delete.webp";
import { deleteAddress, getAddressDetail } from "services/apis";
import { useDispatch } from "react-redux";
import { showAlert } from "features/modal/modalSlice";
import { setDeliveryInfo } from "features/buy/buySlice";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { useNavigate, useLocation } from "react-router-dom";

const ShippingList = ({ addressList, refetchList, setSelectTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state || {};
  const { itemId, orderId } = locationState;

  const handleClickDelete = (id) => {
    const checkAddress = addressList.find((address) => address.id === id);

    if (checkAddress.isDefault) {
      dispatch(
        showAlert({
          icon: "Error",
          message: "다른 배송지를 <기본 배송지/>로\n<변경 후/> 삭제해주세요",
        }),
      );
    } else {
      dispatch(showLoading());
      deleteAddress(id)
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(
              showAlert({
                message: "해당 배송지를 삭제 했습니다.",
                callback: () => {
                  refetchList();
                },
              }),
            );
          } else {
            dispatch(showAlert({ message }));
          }
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  };

  const handleClickEdit = (id) => {
    dispatch(showLoading());
    getAddressDetail(id)
      .then(({ data: { success, message, data } }) => {
        if (success) {
          const areaCode1 = data?.phone?.slice(0, 3);
          const areaCode2 = data?.mobile?.slice(0, 3);

          dispatch(
            setDeliveryInfo({
              ...data,
              isEdit: true,
              editId: id,
              areaCode1,
              areaCode2,
            }),
          );
          setSelectTab("INPUT");
        } else {
          dispatch(showAlert({ message }));
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const handleClickSelect = (item) => {
    dispatch(setDeliveryInfo(item));
    itemId
      ? navigate(`/goods-purchase/${itemId}`, {
          replace: true,
        })
      : navigate(-1);
  };

  return (
    <List>
      {addressList.map((item, index) => {
        const {
          id,
          receiver,
          addressName,
          isDefault,
          phone,
          mobile,
          postCode,
          address,
          addressDetail,
          memo,
        } = item;
        return (
          <ListItem key={index.toString()}>
            <ItemHeader>
              <div style={{ display: "flex" }}>
                {receiver} {addressName && " / " + addressName}
                {isDefault && <Default>(기본)</Default>}
              </div>
              <DeleteBtn
                onClick={() => {
                  handleClickDelete(id);
                }}
              >
                <CancelImg src={Delete} />
              </DeleteBtn>
            </ItemHeader>
            <ItemContent>
              <Content>
                <PhoneWrapper>
                  <DeliveryNumber>
                    {PhoneNumberFormat(phone)}
                    {mobile && " | " + PhoneNumberFormat(mobile)}
                  </DeliveryNumber>
                </PhoneWrapper>
                <DeliveryNumber>{postCode && `(${postCode})`}</DeliveryNumber>
                <DeliveryNormal> {address}</DeliveryNormal>
                <DeliveryNormal> {addressDetail}</DeliveryNormal>
                <div style={{ height: "7px" }} />
                {memo && (
                  <MemoWrapper>
                    <DeliveryNormal className="title">수령방법:</DeliveryNormal>
                    <DeliveryNormal className="content"> {memo}</DeliveryNormal>
                  </MemoWrapper>
                )}
              </Content>
              <Bar />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    handleClickEdit(id);
                  }}
                >
                  수정
                </Button>
                {(itemId || orderId) && (
                  <SelectButton
                    onClick={() => {
                      handleClickSelect(item);
                    }}
                  >
                    선택
                  </SelectButton>
                )}
              </div>
            </ItemContent>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ShippingList;

const List = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  border: 1px solid #eaeaea;
  border-radius: 0px 0px 8px 8px;
  padding: 17px 16px 16px 16px;
`;

const ListItem = styled.div`
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: unset;
  }
`;

const ItemHeader = styled.div`
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  font-weight: bold;
  font-size: 15px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 0px 9px 12px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: unset;
  justify-content: space-between;
`;

const Default = styled.div`
  font-weight: normal;
  color: #ea792b;
  font-size: 15px;
  margin-left: 5px;
`;

const ItemContent = styled.div`
  padding: 13px 16px 16px 16px;
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 0px 0px 8px 8px;
`;

const Button = styled.button`
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #666666;
  border-radius: 8px;
  color: #666666;
  font-size: 12px;
  padding: 4px 9px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Bar = styled.div`
  height: 16px;
`;

const Content = styled.div``;

const PhoneWrapper = styled.div`
  margin: 0px 0px 8px 0px;
`;

const DeliveryNormal = styled.span`
  font-size: 14px;
  color: ${colors.black700};

  &.title {
    color: #4d4d4d;
    font-weight: 500;
    letter-spacing: -0.28px;
  }

  &.content {
    color: ${colors.black400};
    font-weight: 400;
  }
`;

const DeliveryNumber = styled(DeliveryNormal)`
  font-family: "SpoqaHanSansNeo";
`;

const MemoWrapper = styled.div`
  margin-top: 4px;
`;

const DeleteBtn = styled.button`
  height: 48px;
  width: 48px;
  cursor: pointer;
`;

const CancelImg = styled.img`
  width: 48px;
  height: 48px;
`;

const SelectButton = styled.button`
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #ff6e0e;
  border-radius: 8px;
  color: #ff6e0e;
  font-size: 12px;
  padding: 4px 9px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
