import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import colors from "assets/styles/color";
import fonts, { fontWeight } from "assets/styles/fonts";
import { useDispatch, useSelector } from "react-redux";
import { selectBuyInfo, setBuyInfo } from "features/buy/buySlice";
import { showAlert } from "features/modal/modalSlice";
import checkActive from "assets/icons/check-active.webp";
import checkInactive from "assets/icons/check-disactive.webp";

/**
 *
 * @param {boolean} isPost 택배 수령 가능 여부
 * @param {boolean} isShopTaken 매장 수령 가능 여부
 */
const Tab = ({ isPost, isShopTaken }) => {
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { isDelivery } = BuyInfo;
  const dispatch = useDispatch();

  useEffect(() => {
    // isPost가 왔을 때, 기본적으로 대리점 선택되도록 함. 초반에만 자동 선택되도록 함
    if (
      isPost !== undefined &&
      isShopTaken !== undefined &&
      isDelivery === null
    ) {
      isPost
        ? dispatch(setBuyInfo({ isDelivery: true }))
        : dispatch(setBuyInfo({ isDelivery: false }));
    }
  }, [isPost, isShopTaken]);

  const handleClick = (e) => {
    const buttonElement = e.currentTarget;
    const buttonText = buttonElement.innerText.trim(); // 버튼 텍스트 가져오기

    if (buttonText === "택배 수령") {
      if (isPost) {
        dispatch(setBuyInfo({ isDelivery: true }));
      } else {
        dispatch(
          showAlert({
            icon: "Error",
            message: "대리점 방문만 가능한 상품입니다.",
          }),
        );
      }
    } else if (buttonText === "대리점 방문") {
      if (isShopTaken) {
        dispatch(setBuyInfo({ isDelivery: false }));
      } else {
        dispatch(
          showAlert({
            icon: "Error",
            message: "택배 수령만 가능한 상품입니다.",
          }),
        );
      }
    }
  };

  return (
    <TabWrap>
      <PostBtn isPost={isPost} selected={isDelivery} onClick={handleClick}>
        <CheckIcon
          src={isDelivery ? checkActive : checkInactive}
          alt="체크 아이콘"
        />
        택배 수령
      </PostBtn>
      <ShopTakenBtn
        isShopTaken={isShopTaken}
        selected={!isDelivery}
        onClick={handleClick}
      >
        <CheckIcon
          src={!isDelivery ? checkActive : checkInactive}
          alt="체크 아이콘"
        />
        대리점 방문
      </ShopTakenBtn>
    </TabWrap>
  );
};

export default Tab;

const TabWrap = styled.div`
  display: flex;
`;

const TabButton = styled.button`
  width: 100%;
  height: 44px;
  background-color: white;
  border: 1px solid ${colors.imageBorder};

  font-size: 14px;
  font-family: ${fonts.spoqa};
  letter-spacing: -0.28px;
`;

const PostBtn = styled(TabButton)`
  color: ${({ isPost }) => (isPost ? colors.black900 : colors.disabledColor)};
  background-color: ${({ isPost }) =>
    !isPost ? colors.disabledBackground : "white"};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  ${({ selected }) =>
    selected
      ? css`
          color: ${colors.primaryOrange};
          font-weight: ${fontWeight.bold};
          border: 1px solid ${colors.primaryOrange};
        `
      : css`
          color: ${colors.disabledColor};
          font-weight: ${fontWeight.medium};
        `}
`;

const ShopTakenBtn = styled(TabButton)`
  color: ${({ isShopTaken }) =>
    isShopTaken ? colors.black900 : colors.disabledColor};
  background-color: ${({ isShopTaken }) =>
    !isShopTaken ? colors.disabledBackground : "white"};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  ${({ selected }) =>
    selected
      ? css`
          color: ${colors.primaryOrange};
          font-weight: ${fontWeight.bold};
          border: 1px solid ${colors.primaryOrange};
        `
      : css`
          color: ${colors.disabledColor};
          font-weight: ${fontWeight.medium};
        `}
`;

const CheckIcon = styled.img`
  width: 9.8px;
  height: 10.5px;
  margin-right: 4px;
`;
