import fonts from "assets/styles/fonts";
import NumberInput from "components/common/Input/NumberInput";
import TextInput from "components/common/Input/TextInput";
import Flex from "components/common/flex/Flex";
import DefaultLayout from "components/layouts/DefaultLayout";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { showAlert } from "features/modal/modalSlice";
import useDebouncedEffect from "hooks/useDebouncedEffect";
import useInterval from "hooks/useInterval";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  postResetPassword,
  putResetPassword,
  postValidatePassword,
  getRequestKey,
} from "services/apis";
import styled from "styled-components";
import { encodeByAES56, decodeByAES256 } from "utils/utils";
import {Buffer} from 'buffer';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const [remainingTime, setRemainingTime] = useState("");

  const [passwordValidated, setPasswordValidated] = useState(false);
  const [passwordHint, setPasswordHint] = useState();

  useInterval(() => {
    if (expiredDate) {
      const difference = new Date(expiredDate) - new Date();
      const seconds = Math.floor(difference / 1000);

      if (seconds < 0) {
        setRemainingTime("00:00");
      } else {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        setRemainingTime(
          minutes.toString().padStart(2, "0") +
            ":" +
            remainingSeconds.toString().padStart(2, "0"),
        );
      }
    } else {
      setRemainingTime("");
    }
  }, 1000);

  useEffect(() => {
    setPasswordValidated(false);
    setPasswordHint("");
  }, [password]);

  const validatePassword = () => {
    password &&
      getRequestKey().then(({ data: { success, data, message } }) => {
        if (success) {
          const { encId, publicKey } = data;
          const requestData = {
            encId,
            data: encodeByAES56(
              Buffer.from(publicKey, "base64"),
              JSON.stringify({
                password: Buffer.from(password).toString("base64"),
              }),
            ),
          };
          postValidatePassword(JSON.stringify(requestData))
            .then(({ data: { success, message } }) => {
              if (success) {
                setPasswordHint("");
                setPasswordValidated(true);
              } else {
                setPasswordHint(message);
                setPasswordValidated(false);
              }
            })
            .catch((error) => {
              dispatch(showAlert({ message: error.response.data.message }));
            });
        } else {
          dispatch(showAlert({ message }));
        }
      });
  };
  useDebouncedEffect(validatePassword, 800, [password]);

  const handleClickSubmit = () => {
    if (canSubmit) {
      getRequestKey().then(({ data: { success, data, message } }) => {
        if (success) {
          const { encId, publicKey } = data;
          const requestData = {
            encId,
            data: encodeByAES56(
              Buffer.from(publicKey, "base64"),
              JSON.stringify({
                id: Buffer.from(id).toString("base64"),
                name: Buffer.from(name).toString("base64"),
                birthday: Buffer.from(
                  year.padStart(4, "0") +
                    month.padStart(2, "0") +
                    day.padStart(2, "0"),
                ).toString("base64"),
                password: Buffer.from(password).toString("base64"),
                rePassword: Buffer.from(rePassword).toString("base64"),
                authCode: Buffer.from(authCode).toString("base64"),
              }),
            ),
          };
          postResetPassword(JSON.stringify(requestData))
            .then((encodeData) => {
              const responseData = decodeByAES256(
                Buffer.from(publicKey, "base64"),
                encodeData.data,
              );
              const { success, data, message } = JSON.parse(responseData);
              if (success) {
                dispatch(
                  showAlert({ message: "비밀번호를 재설정하였습니다." }),
                );
                navigate(-1);
              } else {
                dispatch(showAlert({ message }));
              }
            })
            .catch((error) => {
              dispatch(showAlert({ message: error.response.data.message }));
            });
        } else {
          dispatch(showAlert({ message }));
        }
      });
    }
  };

  const handleClickRequestAuthCode = () => {
    if (canRequestAuthCode) {
      if (password === rePassword) {
        dispatch(showLoading());
        getRequestKey().then(({ data: { success, data, message } }) => {
          if (success) {
            const { encId, publicKey } = data;
            const requestData = {
              encId,
              data: encodeByAES56(
                Buffer.from(publicKey, "base64"),
                JSON.stringify({
                  id: Buffer.from(id).toString("base64"),
                  name: Buffer.from(name).toString("base64"),
                  birthday: Buffer.from(
                    year.padStart(4, "0") +
                      month.padStart(2, "0") +
                      day.padStart(2, "0"),
                  ).toString("base64"),
                }),
              ),
            };
            putResetPassword(JSON.stringify(requestData))
              .then((encodeData) => {
                const responseData = decodeByAES256(
                  Buffer.from(publicKey, "base64"),
                  encodeData.data,
                );
                const { success, data, message } = JSON.parse(responseData);
                if (success) {
                  setExpiredDate(data.expiredDate);
                } else {
                  dispatch(showAlert({ message }));
                }
              })
              .catch((error) => {
                dispatch(showAlert({ message: error.response.data.message }));
              })
              .finally(() => {
                dispatch(hideLoading());
              });
          } else {
            dispatch(showAlert({ message }));
          }
        });
      } else {
        dispatch(showAlert({ message: "비밀번호가 일치하지 않습니다." }));
      }
    }
  };
  // 확인 버튼 활성화 여부
  const canSubmit = !!authCode;

  // 인증번호 요청 버튼 활성화 여부
  const canRequestAuthCode =
    id &&
    name &&
    year &&
    month &&
    day &&
    passwordValidated &&
    password === rePassword;
  return (
    <DefaultLayout title="로그인 비밀번호 재설정">
      <Container>
        <TitleHeader>로그인 비밀번호 재설정</TitleHeader>
        <InputSection>
          <InputLabel>아이디</InputLabel>
          <TextInputWrap
            placeholder="아이디"
            value={id}
            onChange={setId}
            disabled={!!expiredDate}
          />
        </InputSection>
        <InputSection>
          <InputLabel>이름</InputLabel>
          <TextInputWrap
            placeholder="이름"
            value={name}
            onChange={setName}
            disabled={!!expiredDate}
          />
        </InputSection>
        <InputSection>
          <InputLabel>생년월일</InputLabel>
          <Flex row style={{ gap: 8 }}>
            <Flex>
              <NumberInputWrap
                placeholder="년(4자)"
                maxLength={4}
                value={year}
                onChange={setYear}
                disabled={!!expiredDate}
              />
            </Flex>
            <Flex>
              <NumberInputWrap
                placeholder="월"
                maxLength={2}
                value={month}
                onChange={setMonth}
                disabled={!!expiredDate}
              />
            </Flex>
            <Flex>
              <NumberInputWrap
                placeholder="일"
                maxLength={2}
                value={day}
                onChange={setDay}
                disabled={!!expiredDate}
              />
            </Flex>
          </Flex>
        </InputSection>
        <InputSection>
          <InputLabel>새로운 비밀번호</InputLabel>
          <TextInputWrap
            type="password"
            placeholder="8자리 이상(숫자, 특수문자, 영문자 포함)"
            value={password}
            onChange={setPassword}
            disabled={!!expiredDate}
          />
          <HintLabel>{passwordHint}</HintLabel>
        </InputSection>
        <InputSection>
          <InputLabel>새로운 비밀번호 확인</InputLabel>
          <TextInputWrap
            type="password"
            placeholder="다시 입력"
            value={rePassword}
            onChange={setRePassword}
            disabled={!!expiredDate}
          />
          <HintLabel>
            {rePassword && password !== rePassword
              ? "비밀번호가 일치하지 않습니다."
              : ""}
          </HintLabel>
        </InputSection>
        <InputSection>
          <InputLabel>본인 확인</InputLabel>
          <SubLabel>회원정보에 등록한 휴대전화로 인증</SubLabel>
          <SendMessageButton
            className={canRequestAuthCode && "pointer"}
            disabled={!canRequestAuthCode}
            onClick={handleClickRequestAuthCode}
          >
            {expiredDate ? "다시 요청" : "내 휴대폰으로 인증번호 전송"}
          </SendMessageButton>
          <AuthCodeWrap>
            <NumberInputWrap
              placeholder="인증 번호 입력"
              value={authCode}
              onChange={setAuthCode}
              disabled={!expiredDate}
            />
            <RemainingLabel>{remainingTime}</RemainingLabel>
          </AuthCodeWrap>
        </InputSection>
        <SubmitButton
          disabled={!canSubmit}
          onClick={handleClickSubmit}
          className={canSubmit && "pointer"}
        >
          확인
        </SubmitButton>
      </Container>
    </DefaultLayout>
  );
};

const Container = styled(Flex)`
  padding: 16px;
`;

const TitleHeader = styled.span`
  font-weight: bold;
  font-size: 21px;
  letter-spacing: -0.42px;
  color: #222222;
  margin-bottom: 4px;
`;

const InputSection = styled(Flex)`
  margin-top: 24px;
`;

const InputLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #333333;
  margin-bottom: 8px;
`;
const SubLabel = styled.span`
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #464646;
  margin-bottom: 16px;
`;

const NumberInputWrap = styled(NumberInput)`
  &:focus {
    border: 1px solid #656667;
  }
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  letter-spacing: -0.32px;
  width: 100%;
`;

const TextInputWrap = styled(TextInput)`
  &:focus {
    border: 1px solid #656667;
  }
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  letter-spacing: -0.32px;
  width: 100%;
`;

const SubmitButton = styled(Flex)`
  ${({ theme }) => theme.flexCenter};
  margin-top: 56px;
  height: 48px;
  background: ${({ disabled }) => (disabled ? "#dddddd" : "#ff6e0e")};
  color: ${({ disabled }) => (disabled ? "#bbbbbb" : "white")};
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
`;

const SendMessageButton = styled(Flex)`
  ${({ theme }) => theme.flexCenter};
  height: 48px;
  background: ${({ disabled }) => (disabled ? "#ffffff" : "white")};
  color: ${({ disabled }) => (disabled ? "#bbbbbb" : "#222222")};
  border: ${({ disabled }) =>
    disabled ? "1px solid #dddddd" : "1px solid #a0a0a0"};
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
`;

const AuthCodeWrap = styled.div`
  position: relative;
`;
const RemainingLabel = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  font-family: ${fonts.spoqa};
  font-weight: 500;
  color: #f80003;
  font-size: 14px;
`;

const HintLabel = styled.span`
  display: inline-block;
  margin-top: 8px;
  font-size: 12px;
  color: #f80003;
`;

export default ResetPassword;
