import React from "react";
import kumbangLogo from "assets/images/kumbang-logo.webp";
import styled from "styled-components";

const KumbangLink = () => {
  const KumbangDownloadUrl =
    "https://play.google.com/store/apps/details?id=com.korda.goldmarket&hl=ko&gl=US";
  return (
    <div
      className="kumbang-link pointer"
      onClick={() => window.open(KumbangDownloadUrl)}
    >
      <img src={kumbangLogo} alt="kumbang" />
    </div>
  );
};

const KumbangLinkWrap = styled.div`
  padding: 0 16px;
  margin-bottom: 64px;
  img {
    width: 100%;
  }
`;

export default KumbangLink;
