import React from "react";
import moment from "moment";
import styled from "styled-components";
import { useSelector } from "react-redux";

import colors from "assets/styles/color";
import DateBlack from "assets/icons/date.webp";
import Location from "assets/icons/location-black-2.webp";
import { selectBuyInfo } from "features/buy/buySlice";

function Reservation(props) {
  const { BuyInfo, ShopInfo } = useSelector(selectBuyInfo);
  const { visitDate, openHr, closeHr } = BuyInfo;
  const { buyShopName } = ShopInfo;

  const ReservationDate =
    visitDate === "" ? "-" : moment(visitDate).format("YYYY년 MM월 DD일 dddd");

  return (
    <Container>
      <Title>예약 확인</Title>
      <InfoContainer>
        <ChildContainer className="location">
          <LocationIcon src={Location} alt="위치" />
          <div>
            <Head>대리점</Head>
            <ShopName>{buyShopName}</ShopName>
            {/* <ShopHoursText>영업시간:</ShopHoursText>
            <ShopHours>
              {openHr} ~ {closeHr}
            </ShopHours> */}
          </div>
        </ChildContainer>
        <ChildContainer>
          <DateIcon src={DateBlack} alt="날짜" />
          <div>
            <Head>예약일</Head>
            <Date>{ReservationDate}</Date>
          </div>
        </ChildContainer>
      </InfoContainer>
    </Container>
  );
}

export default Reservation;

const Container = styled.div`
  width: 100%;
  background-color: #fff8f3;
  border-radius: 8px;
  border: 1px solid #ffdec7;
  margin: 16px 0;
`;

const Title = styled.div`
  color: #ff790b;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.34px;
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #ffdec7;
  padding: 16px;
`;

const InfoContainer = styled.div`
  padding: 16px;
`;

const Date = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: "SpoqaHanSansNeo";
  letter-spacing: -0.32px;
  color: ${colors.black300};
`;

const ChildContainer = styled.div`
  display: flex;

  &.location {
    margin-bottom: 16px;
  }
`;

const LocationIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

const DateIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

const Head = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const ShopName = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
  color: ${colors.black300};
  margin-bottom: 6px;
`;

const ShopHoursText = styled.span`
  font-size: 14px;
  letter-spacing: -0.28px;
  color: ${colors.black300};
  margin-right: 4px;
`;

const ShopHours = styled.span`
  font-size: 14px;
  font-family: "SpoqaHanSansNeo";
  letter-spacing: -0.28px;
  color: ${colors.black300};
`;
