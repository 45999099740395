import React, { useState } from "react";
import DefaultLayout from "components/layouts/DefaultLayout";
import styled from "styled-components";
import ShippingList from "./components/ShippingList";
import InfoIcon from "assets/icons/info-yellow.webp";
import DeliveryForm from "pages/goods-purchase/components/DeliveryForm";
import { useDispatch, useSelector } from "react-redux";
import { selectBuyInfo } from "features/buy/buySlice";
import {
  postRegisterAddress,
  getAddressList,
  putEditAddress,
} from "services/apis";
import { useMutation, useQuery } from "react-query";
import { showAlert } from "features/modal/modalSlice";
import { resetDeliveryInfo } from "features/buy/buySlice";
import { validateInfo } from "utils/utils";

const ManageMentShipping = () => {
  const dispatch = useDispatch();
  const { DeliveryInfo } = useSelector(selectBuyInfo);
  const [selectTab, setSelectTab] = useState("LIST");

  const { data: addressList = [], refetch } = useQuery("addressList", () => {
    return getAddressList().then(({ data: { success, data } }) => {
      if (success) {
        return data;
      }
    });
  });

  const handleClickTab = (e) => {
    if (e.target.value === "INPUT" && addressList.length >= 5) {
      dispatch(
        showAlert({
          message: "배송지 저장 개수가 초과했습니다.\n 삭제 후 등록해주세요.",
          icon: "Error",
        }),
      );
    } else {
      setSelectTab(e.target.value);
      dispatch(resetDeliveryInfo());
    }
  };

  const {
    postCode,
    address,
    addressDetail,
    addressName,
    receiver,
    phone,
    mobile,
    memo,
    isDefault,
    isEdit,
    editId,
  } = DeliveryInfo;

  const addressData = {
    postCode,
    address,
    addressDetail,
    isDefault,
    addressName,
    receiver,
    phone,
    mobile,
    memo,
  };

  const Register = useMutation(
    () => postRegisterAddress(addressData),
    ["register"],
    {},
  );

  const Edit = useMutation(
    () => putEditAddress(editId, addressData),
    ["edit"],
    {},
  );

  const handleMutation = (mutateAsync, successMessage) => {
    const { isValid, message } = validateInfo(true, addressData);

    if (!isValid) {
      dispatch(showAlert({ icon: "Warning", message }));
      return;
    }

    mutateAsync(addressData)
      .then((response) => {
        const { success } = response.data;
        if (!success) {
          dispatch(showAlert({ message: response.data.message }));
        } else {
          dispatch(
            showAlert({
              message: successMessage,
              callback: () => {
                refetch();
                setSelectTab("LIST");
              },
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(showAlert({ message: error?.response?.data?.message }));
      });
  };

  const handleClickRegister = () =>
    handleMutation(Register.mutateAsync, "신규 배송지 등록 완료했습니다.");
  const handleClickEdit = () =>
    handleMutation(Edit.mutateAsync, "배송지 내용을 수정했습니다.");

  return (
    <DefaultLayout title="배송지" navigationVisible={false}>
      <Container>
        <Info>
          <Img src={InfoIcon} />
          배송지는 최대 5개까지 저장 가능합니다.
        </Info>
        <Header>배송지</Header>
        <Tab>
          <TabItem
            value="LIST"
            onClick={handleClickTab}
            select={selectTab === "LIST"}
          >
            배송지 목록
          </TabItem>
          <TabItem
            value="INPUT"
            onClick={handleClickTab}
            select={selectTab === "INPUT"}
          >
            신규 배송지 입력
          </TabItem>
        </Tab>
        {selectTab === "LIST" ? (
          addressList.length === 0 ? (
            <NoList>등록된 배송지가 없습니다.</NoList>
          ) : (
            <ShippingList
              addressList={addressList}
              refetchList={refetch}
              setSelectTab={setSelectTab}
            />
          )
        ) : (
          <NewInputWrap>
            <DeliveryForm ListStatus={!!addressList.length} />
          </NewInputWrap>
        )}
        {selectTab === "LIST" ? (
          <Button
            onClick={() => {
              if (addressList.length >= 5) {
                dispatch(
                  showAlert({
                    message:
                      "배송지 저장 개수가 초과했습니다.\n 삭제 후 등록해주세요.",
                    icon: "Error",
                  }),
                );
              } else {
                setSelectTab("INPUT");
                dispatch(resetDeliveryInfo());
              }
            }}
          >
            + 배송지 등록
          </Button>
        ) : isEdit ? (
          <RegisterBtn onClick={handleClickEdit}>수정</RegisterBtn>
        ) : (
          <RegisterBtn onClick={handleClickRegister}>등록</RegisterBtn>
        )}
      </Container>
    </DefaultLayout>
  );
};

export default ManageMentShipping;

const Container = styled.div`
  min-height: 100vh;
  padding: 16px 16px 64px;
  ${({ theme }) => theme.flexBox("column", "flex-start", "center")}
  background-color: #fafafa;
`;

const Info = styled.div`
  width: 100%;
  height: 32px;
  font-size: 12px;
  color: #444444;
  background-color: #fffbef;
  border: 1px solid #ffd677;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-weight: bold;
  color: #444444;
  font-size: 16px;
  margin: 48px 0;
  cursor: pointer;
`;

const RegisterBtn = styled(Button)`
  background-color: #ff6e0e;
  color: #ffffff;
`;

const Header = styled.div`
  width: 100%;
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  font-weight: bold;
  color: #333333;
  font-size: 17px;
  height: 44px;
  padding: 9px 0px 10px 15px;
  border-radius: 8px 8px 0px 0px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  border-bottom: unset;
`;

const Tab = styled.div`
  display: flex;
  width: 100%;
`;

const TabItem = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border: 1px solid #eaeaea;
  cursor: pointer;
  background-color: ${(props) =>
    props.select
      ? props.value === "INPUT"
        ? "#ffffff"
        : "#f6f6f6"
      : props.value === "LIST"
      ? "#e1e1e1"
      : "#e5e5e5"};
  font-weight: bold;
  font-size: 17px;
  color: #333333;
  border-bottom: unset;
`;

const Img = styled.img`
  width: 18px;
  height: 18px;
  margin: 0px 4px 0px 7px;
`;

const NewInputWrap = styled.div`
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 0px 0px 8px 8px;
`;

const NoList = styled.div`
  background-color: #f6f6f6;
  border: 1px solid #eaeaea;
  border-radius: 0px 0px 8px 8px;
  padding: 17px 16px 16px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
`;
