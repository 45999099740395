import { fontWeight } from "assets/styles/fonts";
import { showSnackBar } from "features/snackbar/snackbarSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { getTermsDetail } from "services/apis";
import { signOut } from "services/authService";
import styled from "styled-components";
import colors from "assets/styles/color";
import TossEscrow from "assets/images/toss-escrow.webp";
import RightArrow from "assets/icons/right-arrow-gray-2.webp";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const handleClickSeeToss = () => {
    window.location.href =
      "https://pgweb.tosspayments.com/ms/escrow/s_escrowYn.do?mertid=kbkbmall";
  };

  const handleClickLogout = () => {
    signOut();
    window.vreviewWidget.logout();
    navigate(routes.home);
    dispatch(showSnackBar({ message: "로그아웃 되었습니다." }));
  };

  const handleClickTerms = (type, title) => {
    getTermsDetail(type).then(
      ({
        data: {
          data: { content },
        },
      }) => {
        navigate(routes.commonHtmlViewer, { state: { title, content } });
      },
    );
  };
  return (
    <div className="footer-container">
      <div className="company-name">㈜한국금거래소디지털에셋</div>
      <div className="description">
        서울특별시 종로구 돈화문로5가길 1 <br />
        (돈의동, 피카디리플러스) 8층 (03139)
        <br />
        사업자등록번호: 764-81-01460 <br />
        통신판매업신고: 2020-서울종로-0710
        <br />
        대표이사: 박성욱, 개인정보책임자: 김형균
      </div>
      <div className="toss-wrap">
        <img src={TossEscrow} alt="토스 매매보호 서비스" />
      </div>
      {/* <TossImg src={TossEscrow} alt="토스 매매보호 서비스" />
      <br /> */}
      <div className="description">
        고객님은 안전거래를 위해 현금 등으로 저희 쇼핑몰이 가입한 토스페이먼츠
        구매안전서비스를 이용하실 수 있습니다
      </div>
      <button
        className="see-entry-btn"
        alt="서비스 가입 사실 확인 버튼"
        onClick={handleClickSeeToss}
      >
        서비스 가입 사실 확인
        <img src={RightArrow} alt="화살표" />
      </button>
      <div className="terms-section">
        <div>
          <span
            className="terms-left pointer"
            onClick={() =>
              handleClickTerms("PRIVACY_POLICY", "개인정보 처리방침")
            }
          >
            개인정보 처리방침
          </span>
          <span
            className="terms-right pointer"
            onClick={() =>
              handleClickTerms("SERVICE_POLICY_MALL", "서비스 이용약관")
            }
          >
            서비스 이용약관
          </span>
        </div>
        {auth.isSigned && (
          <div className="logout-section pointer" onClick={handleClickLogout}>
            로그아웃
          </div>
        )}
      </div>
      <div className="copyright">© KOREA DA Corp.</div>
    </div>
  );
};

// const FooterContainer = styled.div`
//   padding: 32px 16px 103px 16px;
//   background: ${colors.white300};
// `;

const CompanyName = styled.div`
  color: ${colors.black400};
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  line-height: 20px;
  letter-spacing: -0.28px;
  margin-bottom: 8px;
`;
const Description = styled.div`
  font-size: 12px;
  font-weight: ${fontWeight.regular};
  line-height: 21px;
  letter-spacing: -0.24px;
  color: ${colors.black700};
`;

const TermsSection = styled.div`
  margin-top: 16px;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
`;

const TermsLeft = styled.span`
  font-weight: ${fontWeight.bold};
  font-size: 12px;
  letter-spacing: -0.24px;
  color: ${colors.black200};
  text-decoration: underline;
`;
const TermsRight = styled.span`
  margin-left: 24px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.24px;
  color: ${colors.black200};
  text-decoration: underline;
`;

const LogoutSection = styled.span`
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.24px;
  color: ${colors.black200};
`;

const Copyright = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: 10px;
  letter-spacing: -0.2px;
  color: ${colors.black800};
  margin-top: 19.6px;
`;

const TossImg = styled.img`
  margin-top: 21px;
  width: 197.6px;
  height: 16px;
`;

const SeeEntryBtn = styled.button`
  color: ${colors.black700};
  font-size: 11px;
  line-height: 21px;
  letter-spacing: -0.22px;
  width: 120px;
  height: 24px;
  border: 1px solid #e0e0e0;
  ${({ theme }) => theme.flexCenter}
  margin-top: 10px;
  margin-bottom: 64px;

  img {
    width: 18px;
    height: 18px;
    margin-left: -2px;
  }
`;

export default React.memo(Footer);
