import React, { useRef } from "react";
import styled from "styled-components";
import DefaultLayout from "components/layouts/DefaultLayout";
import MallLogoImg from "assets/images/kumbang-mall-logo.webp";
import { useLocation } from "react-router-dom";
import { getFormatTime, NumberFormat } from "utils/utils";
import useUserInfoQuery from "hooks/queries/user";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

const PurchaseReceipt = () => {
  const location = useLocation();
  const { data } = useUserInfoQuery();
  const locationStateTrans = location.state?.goodsTrans || {};
  const {
    orderNumber = "",
    createdAt = "",
    goodsName = "",
    paymentPrice = "",
  } = locationStateTrans;
  const screenRef = useRef(null);

  const handleClickImage = () => {
    const screen = screenRef.current;

    html2canvas(screen).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, "screenshot.png");
      });
    });
  };

  return (
    <DefaultLayout title="구매영수증" navigationVisible={true}>
      <Container>
        <div>
          <ReciptWrap ref={screenRef}>
            <MallLogo src={MallLogoImg} />
            <Title>구매 영수증</Title>
            <Wrap height="40px" />
            <ol>
              <Li>
                <ListName>주문번호</ListName>
                <ListValue flex={1.8}>{orderNumber}</ListValue>
              </Li>
              <Wrap height="20px" />
              <Li>
                <ListName>거래일시</ListName>
                <ListValue>{getFormatTime(createdAt)}</ListValue>
              </Li>
              <Wrap height="20px" />
              <Li>
                <ListName>상품명</ListName>
                <ListValue>{goodsName}</ListValue>
              </Li>
              <Wrap height="20px" />
              <Li>
                <ListName>회사명</ListName>
                <ListValue>한국금거래소 디지털에셋</ListValue>
              </Li>
              <Wrap height="20px" />
              <Li>
                <ListName>서명</ListName>
                <ListValue>{data?.name}</ListValue>
              </Li>
              <Wrap height="24px" />
              <Line />
              <Li height="69px" alignItems="center">
                <SumName>합계</SumName>
                <FlexEnd>
                  <SumValue>{NumberFormat(paymentPrice)}</SumValue>
                  <SumDigit>원</SumDigit>
                </FlexEnd>
              </Li>
            </ol>
          </ReciptWrap>
          <Wrap height="24px" />
          <WarningInfo>
            구매 영수증은 세무상 증빙서류로 사용할 수 없으며, 거래 내역과 거래
            금액을 확인하는 목적으로만 사용할 수 있습니다.
          </WarningInfo>
        </div>
        <Wrap height="24px" />
        <Button
          className="pointer"
          onClick={() => {
            handleClickImage();
          }}
        >
          이미지 저장
        </Button>
      </Container>
    </DefaultLayout>
  );
};

export default PurchaseReceipt;

const Container = styled.div`
  padding: 16px 16px 0px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fcfcfc;
`;
const ReciptWrap = styled.div`
  padding: 24px 16px 0px 16px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  border-radius: 8px;
`;

const MallLogo = styled.img`
  height: 10.3px;
`;

const Title = styled.div`
  margin-top: 8.9px;
  font-weight: bold;
  font-size: 20px;
  color: #222222;
  height: 29px;
`;
const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
`;

const ListName = styled.div`
  font-size: 15px;
  color: #777777;
  flex: 1;
`;

const ListValue = styled.div`
  font-weight: bold;
  font-size: 15px;
  color: #444444;
  flex: ${(props) => `${props.flex}`};
  text-align: end;
`;

const Li = styled.li`
  display: flex;
  justify-content: space-between;
  height: ${(props) => `${props.height}`};
  align-items: ${(props) => `${props.alignItems}`};
`;

const Button = styled.button`
  height: 48px;
  font-size: 16px;
  background-color: #ff6e0e;
  color: #ffffff;
  margin-bottom: 55px;
  font-weight: bold;
  border-radius: 8px;
`;

const WarningInfo = styled.div`
  height: 34px;
  color: #999999;
  font-size: 12px;
  margin: 0px 16px;
`;

const Line = styled.div`
  border: 1px solid #bdbdbd;
  border-style: dotted;
`;

const SumName = styled.div`
  font-weight: bold;
  font-size: 17px;
  color: #444444;
`;

const SumValue = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: #ff842b;
`;
const SumDigit = styled.div`
  font-size: 15px;
  color: #ff842b;
  font-weight: normal;
`;

const FlexEnd = styled.div`
  display: flex;
  align-items: end;
`;
