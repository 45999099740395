import styled from "styled-components";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  putPurchase,
  getBuyApproval,
  getPreApprovalInfo,
  postBuyEventPoint,
} from "services/apis";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { routes } from "routes";
import colors from "assets/styles/color";
import { selectBuyInfo } from "features/buy/buySlice";
import { showAlert } from "features/modal/modalSlice";
import LoadingBar from "./components/LoadingBar";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "services/storageService";
import useSavePaymentInfo from "hooks/useSavePaymentInfo";
import useScript from "hooks/useScript";
import ReactGA from "react-ga4";

function TossSuccess() {
  // 파라미터 및 쿼리 처리
  const { id, txId } = useParams(); // txId: 데이터 임시 정보 ID
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search.replace(/&amp;/gi, "&"));
  const orderId = params.get("orderId");
  const paymentKey = params.get("paymentKey");
  const qty = getSessionStorageItem("qty");
  const amount = params.get("amount");
  const [CreatePaymentInfo, UpdatePaymentInfo] = useSavePaymentInfo();
  const { BuyInfo, DeliveryInfo, ShopInfo } = useSelector(selectBuyInfo);
  const { naverScript, googleTagScript } = useScript();

  useEffect(() => {
    // 페이지가 언마운트되면 실행되는 로직
    return () => {
      setSessionStorageItem("PAY_INFO_CAN_BE_CALLED", 0);
    };
  }, []);

  // txId로 조회 가능 여부
  const PAY_INFO_CAN_BE_CALLED = getSessionStorageItem(
    "PAY_INFO_CAN_BE_CALLED",
  );

  const { data: PreApprovalInfo, isLoading } = useQuery(
    ["pre-approval-info", txId],
    () =>
      getPreApprovalInfo(txId).then((res) => {
        try {
          const receivedData = res.data;
          if (receivedData.success) {
            const { data } = receivedData;
            UpdatePaymentInfo(data, dispatch);
          }
        } catch (error) {
          // 에러 처리
          console.error(error);
        }
      }),
    {
      select: (data) => data.data.data,
      enabled: !PAY_INFO_CAN_BE_CALLED || PAY_INFO_CAN_BE_CALLED === 0, // 0이거나 null일 때만 쿼리 실행
      onSuccess: () => {
        // PreApprovalInfo 로드 성공 후 실행할 코드
        const paymentInfo = CreatePaymentInfo(
          BuyInfo,
          DeliveryInfo,
          ShopInfo,
          "",
          paymentKey,
          orderId,
          "TOSS_QUICK",
        );
        requestPurchase(paymentInfo);
      },
    },
  );

  useEffect(() => {
    if (PAY_INFO_CAN_BE_CALLED === null || PAY_INFO_CAN_BE_CALLED === 0) {
      // 세션 스토리지의 값을 1로 설정하여 다시는 실행되지 않도록 함
      setSessionStorageItem("PAY_INFO_CAN_BE_CALLED", 1);
    }
  }, []);

  // 구매 요청
  const Purchase = useMutation(
    (data) => putPurchase(id, data),
    ["purchase"],
    {},
  );

  // 결제 및 승인 요청 후 리다이렉트 처리
  const pendingConfirm = (count, id) => {
    setTimeout(() => {
      getBuyApproval(id).then(({ data: { success, data, message } }) => {
        if (success) {
          const script = document.createElement("script");
          if (data.status === "APPROVAL") {
            naverScript(1, +BuyInfo?.sellingPrice);
            googleTagScript(+BuyInfo?.sellingPrice, data.goodsMallRequestId);
            ReactGA.gtag("event", "purchase", {
              transaction_id: data.goodsMallRequestId,
              currency: "KRW",
              value: +BuyInfo?.sellingPrice,
              items: [{ item_id: id, item_name: BuyInfo?.orderName }],
            });
            if (process.env.REACT_APP_SERVICE_TYPE === "PRODUCTION") {
              script.type = "text/javascript";
              script.innerHTML =
                `window.ENP_VAR = { conversion: { product: [] } };` +
                `ENP_VAR.conversion.product.push( { productCode: '${
                  BuyInfo.goodsId
                }', productName: '${BuyInfo.orderName}', price: ${
                  BuyInfo.sellingPrice / qty
                }, qty: ${qty} } );` +
                `ENP_VAR.conversion.ordCode= '${data.tradeNumber}';` +
                `ENP_VAR.conversion.totalPrice = '${BuyInfo.sellingPrice}';` +
                `ENP_VAR.conversion.totalQty = ${qty};` +
                `window.CallMtm = window.CallMtm||function(){(window.CallMtm.q=window.CallMtm.q||[]).push(arguments)};` +
                `CallMtm();`;
              document.head.appendChild(script);
            }
            const eventData = {
              goodsMallRequestId: id,
              couponStatus: "REGISTER",
              eventName: "[금방금방몰-이벤트] 포인트 지급(구매확정시)",
            };
            postBuyEventPoint(eventData);
            if (process.env.REACT_APP_SERVICE_TYPE === "PRODUCTION") {
              document.head.removeChild(script);
            }
            navigate(`/purchase-complete`, {
              replace: true,
            });
          } else {
            if (count === 10) {
              dispatch(
                showAlert({
                  message: "결제요청 처리중 입니다.",
                  callback: () => {
                    const eventData = {
                      goodsMallRequestId: id,
                      couponStatus: "REGISTER",
                      eventName: "[금방금방몰-이벤트] 포인트 지급(구매확정시)",
                    };
                    postBuyEventPoint(eventData);
                    if (process.env.REACT_APP_SERVICE_TYPE === "PRODUCTION") {
                      document.head.removeChild(script);
                    }
                    navigate(routes.home, {
                      replace: true,
                      state: { FromSettlement: true },
                    });
                  },
                }),
              );
            } else {
              pendingConfirm(count + 1, id);
            }
          }
        } else {
          navigate("/purchase-failed", {
            replace: true,
            state: { errorMessage: message },
          });
        }
      });
    }, 1000);
  };

  // 결제 및 승인 요청
  const requestPurchase = async (paymentInfo) => {
    try {
      const response = await Purchase.mutateAsync(paymentInfo);
      const { success, data } = response.data;

      if (!success) {
        dispatch(showAlert({ message: response.data.message }));
      } else {
        pendingConfirm(1, data.goodsMallRequestId);
      }
    } catch (error) {
      dispatch(showAlert({ message: error?.response?.data?.message }));
    }
  };

  return (
    <Container>
      <LoadingBar />
      <Title>결제 진행중 입니다.</Title>
    </Container>
  );
}

export default TossSuccess;

const Container = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  position: fixed;
  top: 47.5%;
  left: 50%;
  z-index: 1020;
  transform: translate(-50%, -50%);
`;

const Title = styled.h1`
  font-size: 21px;
  font-weight: 700;
  margin-top: 14px;
  line-height: 30px;
  letter-spacing: -0.42px;
  color: ${colors.black300};
`;
