import React from "react";
import styled from "styled-components";
import mainLogo from "assets/images/main-header.webp";
import Guide from "assets/icons/info-home.webp";

const Header = () => {
  const handleClickUserGuide = () => {
    window.location.href = `${process.env.REACT_APP_USER_GUIDE_URL}`;
  };

  const handleGoHome = () => {
    window.location.href = "/";
  };

  return (
    <div className="main-header">
      <div onClick={handleGoHome} className="main-logo">
        <img src={mainLogo} alt="main-logo" />
      </div>
      <div style={{ width: 48 }}>
        <img
          className="pointer"
          src={Guide}
          alt="main-guide"
          // width={48}
          style={{
            width: "100%",
          }}
          onClick={handleClickUserGuide}
        />
      </div>
    </div>
  );
};

const HeaderWrap = styled.div`
  display: flex;
  height: 64px;
  padding: 16px 2px 16px 16px;
  align-items: center;
  justify-content: space-between;

  .pointer {
    cursor: pointer;
  }
`;

const MainLogo = styled.img`
  width: 172px;
  height: 21px;
`;

export default Header;
