import ExpireAlert from "components/common/modal/ExpireAlert";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { BrowserRouter, useLocation } from "react-router-dom";
import { postFlagCheck, getEnvConfig } from "services/apis";
import { signInUsingToken } from "services/authService";
import { getSessionStorageItem } from "services/storageService";
import { APP_VERSION, SERVICE_TYPE } from "utils/constants";
import AppRoutes from "./routes";
// import useRouteChangeTracker from "hooks/useRouteChangeTracker";
import useInterval from "hooks/useInterval";
import ReactGA from "react-ga4";
import MetaTag from "utils/MetaTag";
import { useGoodsListQuery } from "hooks/queries/goods";
import { useSelector } from "react-redux";

const VersionChecker = () => {
  const getVersion = (version) => {
    const reg = /(\d*)\.(\d*)\.(\d*)/;
    const match = version.match(reg);

    const [FULL, MAJOR, MINOR, PATCH] = match;
    return { FULL, MAJOR, MINOR, PATCH };
  };

  const [checkCycle, setCheckCycle] = useState(3); // second
  useInterval(async () => {
    // API 호출 주기
    const cycle = await getEnvConfig("MALL_FRONT_REFRESH_PERIOD").then(
      ({ data: { success, data } }) => {
        if (success) {
          return data?.value;
        }
      },
    );
    // Front 최종 버전
    const latest = await getEnvConfig("MALL_FRONT_LATEST_VERSION").then(
      ({ data: { success, data } }) => {
        if (success) {
          return data?.value;
        }
      },
    );
    cycle && setCheckCycle(cycle);

    if (latest && APP_VERSION) {
      const appVersion = getVersion(APP_VERSION);
      const latestVersion = getVersion(latest);

      if (+latestVersion.MAJOR > +appVersion.MAJOR) {
        location.reload();
      } else if (+latestVersion.MAJOR === +appVersion.MAJOR) {
        if (+latestVersion.MINOR > +appVersion.MINOR) {
          location.reload();
        } else if (+latestVersion.MINOR === +appVersion.MINOR) {
          if (+latestVersion.PATCH > +appVersion.PATCH) {
            location.reload();
          }
        }
      }
    }
  }, checkCycle * 1000);
  return null;
};

function App() {
  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  let accessToken = getSessionStorageItem("accessToken");
  const { isLoading } = useGoodsListQuery();

  const auth = useSelector((state) => state.auth);
  const { isSigned } = auth;

  useLayoutEffect(() => {
    // 윈도우가 프로젝트를 벗어났다가 돌아왔을 경우 자동로그인
    if (accessToken) {
      signInUsingToken(accessToken);
    }
  }, []);

  // Flag Server Check
  const { data: flag = "NONE" } = useQuery("flag", () => {
    return postFlagCheck({
      versionName: SERVICE_TYPE === "DEVELOPMENT" ? "999.0.0" : APP_VERSION,
      os: "web",
      appName: "kumbang-mall",
    })
      .then(({ data: { ok, response } }) => {
        if (ok) {
          return response.action;
        } else {
          return "KILL";
        }
      })
      .catch(() => {
        return "KILL";
      });
  });

  useEffect(() => {
    if (flag === "KILL") {
      window.location.href = "https://guide.kumbang.co.kr/work.html";
    }
  }, [flag]);

  // GA 설정
  useEffect(() => {
    if (!window.location.href.includes("localhost") && gaId) {
      ReactGA.initialize(gaId);
    }
  }, []);

  // // 재랜더링시에 실행되게 해준다.
  useEffect(() => {
    // init 해주기 전에 clean up 을 해준다.
    window.Kakao.cleanup();
    // 자신의 js 키를 넣어준다.
    window.Kakao.init(process.env.REACT_APP_JS_KEY);
    // 잘 적용되면 true 를 뱉는다.
    // console.log(window.Kakao.isInitialized());
  }, []);

  useLayoutEffect(() => {
    if (window.vreviewWidget) {
      // 브이리뷰 위젯 생성 함수
      window.vreviewWidget.initialize({
        uuid: process.env.REACT_APP_VREVIEW_UUID,
        originMemberId: "",
      });
      // 로그인되면 originMemberId(userId) 세팅
      // 마이페이지 위젯을 위한 함수
      if (isSigned) {
        window.vreviewWidget.login(auth?.userId);
      }
    }
  }, [window.vreviewWidget, isSigned]);

  useMemo(() => {
    // window.location.reload();
  }, []);

  if (isLoading) return null;

  return (
    <>
      <MetaTag
        keywords={
          "골드바, 순금, 24k,금값시세,금시세,순금시세,오늘금시세,골드바 가격,귀금속,금"
        }
      />
      <BrowserRouter>
        <VersionChecker />
        {/* <GoogleAnalyticsTracker /> */}
        <ExpireAlert />
        <AppRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;

// Google Analytic
// const GoogleAnalyticsTracker = () => {
//   useRouteChangeTracker();
//   return null;
// };
