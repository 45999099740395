import React, { useState, useEffect } from "react";
import DefaultLayout from "components/layouts/DefaultLayout";
import styled from "styled-components";
import SelectBank from "./components/SelectBank";
import InsertAccount from "./components/InsertAccount";
import AuthPhone from "./components/AuthPhone";
import CompleteRegister from "./components/CompleteRegister";
import { getBankList } from "services/apis";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { useNavigate } from "react-router-dom";
import { showAlert } from "features/modal/modalSlice";
import { useModal } from "hooks/useModal";
import { CustomModal } from "components";
import CancelConnectAccount from "./components/CancelConnectAccount";

const stepList = [
  { step: "SELECT-BANK" },
  { step: "ACCOUNT-NUMBER" },
  { step: "AUTH" },
  { step: "COMPLETE" },
];

const RegistrerAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectBank, setSelectBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [reclaimAuthNo, setReclaimAuthNo] = useState(false);
  const [authNo, setAuthNo] = useState(
    String(Math.floor(Math.random() * 90) + 10),
  );
  const [bankList, setBankList] = useState([]);
  const [modalVisible, modalOpen, modalClose] = useModal(false);

  const handleClickCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    setAuthNo(String(Math.floor(Math.random() * 90) + 10));
  }, [reclaimAuthNo]);

  useEffect(() => {
    dispatch(showLoading());
    getBankList()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setBankList(data);
        } else {
          dispatch(
            showAlert({
              message: message,
            }),
          );
        }
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  return (
    <DefaultLayout
      title="계좌 등록하기"
      navigationVisible={stepList[currentStep].step === "COMPLETE"}
      cancelCallback={() => {
        modalOpen();
      }}
    >
      <Container>
        {stepList[currentStep].step === "SELECT-BANK" && (
          <SelectBank
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            bankList={bankList}
            selectBank={selectBank}
            setSelectBank={setSelectBank}
          />
        )}
        {stepList[currentStep].step === "ACCOUNT-NUMBER" && (
          <InsertAccount
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            bankList={bankList}
            selectBank={selectBank}
            accountNumber={accountNumber}
            setAccountNumber={setAccountNumber}
          />
        )}
        {stepList[currentStep].step === "AUTH" && (
          <AuthPhone
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            selectBank={selectBank}
            accountNumber={accountNumber}
            authNo={authNo}
            reclaimAuthNo={reclaimAuthNo}
            setReclaimAuthNo={setReclaimAuthNo}
          />
        )}
        {stepList[currentStep].step === "COMPLETE" && <CompleteRegister />}
      </Container>
      <CustomModal modalVisible={modalVisible} modalClose={modalClose}>
        <CancelConnectAccount
          modalClose={modalClose}
          onConfirm={handleClickCancel}
        />
      </CustomModal>
    </DefaultLayout>
  );
};

export default RegistrerAccount;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
