import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import colors from "assets/styles/color";
import fonts, { fontWeight } from "assets/styles/fonts";
import Fail from "assets/icons/icon-error-large.webp";
import Success from "assets/icons/success-green.webp";
import CancleHeader from "assets/icons/cancel-header.webp";
import { NumberFormat } from "utils/utils";
import { routes } from "routes";
import Phone from "assets/icons/phone-orange-fill.webp";

const icons = {
  Success: Success,
  Fail: Fail,
};

function PurchaseResult({
  icon, // 아이콘
  colorTheme, // 색깔 테마
  title, // 결제 완료 or 결제 실패
  amount, // 결제 완료 시 가격
  reason, // 결제 실패 이유
  depositMethod, // 결제 수단
  goodsName, // 주문 상품
  id, // 라우팅 위한 상품 id
  onConfirm, // 확인, X버튼 콜백함수
  showCSInfo = false, // 고객센터 정보 노출 여부
  isSuccess, // 성공 실패 여부
}) {
  const iconSrc = icons[icon];

  const handleClickNavigate = () => {
    onConfirm();
  };

  return (
    <Container>
      <InfoWrap>
        <CancleBtn onClick={handleClickNavigate}>
          <img src={CancleHeader} alt="닫기 아이콘" />
        </CancleBtn>
        <SubContainer>
          {icon && <Icon src={iconSrc} alt="아이콘" />}
          <Title>{title}</Title>
          {amount ? (
            <SubTitleWrap>
              <SubTitle className="num">{NumberFormat(amount)}</SubTitle>
              <SubTitle>원</SubTitle>
            </SubTitleWrap>
          ) : null}
          {isSuccess ? (
            <>
              <ThickBar />
              <GreenBox className="mb-20">
                <div>결제 수단</div>
                <div>{depositMethod}</div>
              </GreenBox>
              <GreenBox>
                <div>주문 상품</div>
                <div>{goodsName}</div>
              </GreenBox>{" "}
              <ThinBar />
            </>
          ) : (
            <>
              <ThickBar />
              <RedBox>
                <div>이유</div>
                <div>{reason}</div>
              </RedBox>{" "}
              <ThinBar />
            </>
          )}
          {showCSInfo ? <CSCenterInformation /> : null}
        </SubContainer>
      </InfoWrap>
      <ConfirmBtn onClick={handleClickNavigate}>확인</ConfirmBtn>
    </Container>
  );
}

export default PurchaseResult;

const Container = styled.div`
  height: 100%;
  flex: 1;
  ${({ theme }) => theme.flexBox("column", "space-between", "center")}
`;

const SubContainer = styled.div`
  ${({ theme }) => theme.flexCenterColumn}
  padding: 0 24px;
`;

const CancleBtn = styled.button`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row-reverse;

  img {
    width: 48px;
    height: 48px;
  }
`;

const Icon = styled.img`
  width: 64px;
  height: 64px;

  margin-bottom: 10px;
`;
const Title = styled.h1`
  color: ${colors.black300};
  font-size: 21px;
  font-weight: ${fontWeight.bold};
  line-height: 27px;
  letter-spacing: -0.42px;
  height: 30px;
`;

const SubTitleWrap = styled.div`
  ${({ theme }) => theme.flexBox("row", "center", "flex-end")}
  height: 30px;
`;

const SubTitle = styled.div`
  color: ${colors.black300};
  font-size: 21px;
  font-weight: ${fontWeight.bold};
  line-height: 27px;

  &.num {
    font-family: ${fonts.spoqa};
  }
`;

const InfoWrap = styled.div`
  width: 100%;
`;

const ConfirmBtn = styled.button`
  width: calc(100% - 32px);
  height: 44px;
  border-radius: 8px;
  background-color: ${colors.primaryOrange};
  color: white;
  font-weight: ${fontWeight.medium};
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.32px;
  margin: 0 16px 64px;
`;

const ThickBar = styled.div`
  background-color: ${colors.imageBorder};
  width: 100%;
  height: 2px;
  margin: 38px 0 14px;
`;

const ThinBar = styled.div`
  background-color: ${colors.imageBorder};
  width: 100%;
  height: 1px;
  margin-top: 14px;
`;

const GreenBox = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}

  > :first-child {
    font-weight: ${fontWeight.regular};
    font-size: 14px;
    line-height: 24.5px;
    letter-spacing: -0.28px;
    color: ${colors.black600};
  }

  > :last-child {
    font-weight: ${fontWeight.medium};
    font-size: 14px;
    line-height: 24.5px;
    color: ${colors.black300};
  }

  &.mb-20 {
    margin-bottom: 20px;
  }
`;

const RedBox = styled(GreenBox)`
  > :last-child {
    font-weight: ${fontWeight.medium};
    font-size: 14px;
    line-height: 24.5px;
    color: #f80003;
  }
`;

const CSCenterInformation = () => {
  const handleCall = () => {
    window.location.href = "tel:16708978";
  };

  return (
    <>
      <OrangeBox>
        <div>결제 안내</div>
        <div>
          결제를 도와드리겠습니다. <br />
          고객센터로 연락주세요.
        </div>
      </OrangeBox>
      <CSNumberBox>
        <NumberWrap onClick={handleCall}>
          <PhoneIcon src={Phone} alt="휴대폰 아이콘" />
          <Number>
            고객센터 <span className="spoqa">1644-8978</span>
          </Number>
        </NumberWrap>
        <SubDesc>
          (월~금 / <span className="spoqa">9</span>
          am~<span className="spoqa">6</span>pm / 주말・공휴일 휴무)
        </SubDesc>
      </CSNumberBox>
    </>
  );
};

const OrangeBox = styled(GreenBox)`
  margin-top: 14px;
  > :first-child {
    color: ${colors.primaryOrange};
  }
  > :last-child {
    font-weight: ${fontWeight.medium};
    font-size: 14px;
    line-height: 22.5px;
    color: ${colors.black300};
  }
`;

const CSNumberBox = styled.div`
  width: 100%;
  height: 68px;
  border-radius: 8px;
  background-color: ${colors.whiteA};
  margin-top: 15px;
  padding: 14px 0;

  ${({ theme }) => theme.flexCenterColumn}
`;

const NumberWrap = styled.div`
  cursor: pointer;
  ${({ theme }) => theme.flexCenter}
`;

const PhoneIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const Number = styled.div`
  color: ${colors.primaryOrange};
  font-weight: ${fontWeight.medium};
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 26px;

  .spoqa {
    font-size: 16px;
    font-family: ${fonts.spoqa};
    letter-spacing: -0.32px;
  }
`;

const SubDesc = styled.div`
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -0.24px;
  color: ${colors.black800};

  .spoqa {
    font-family: ${fonts.spoqa};
  }
`;
