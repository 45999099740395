import { useState, useEffect } from "react";

/**
 * 스크롤 했을 때 헤더에 쉐도우 생기도록 하기 위해서 만든 훅
 * @returns 스크롤 되었는지 여부
 */
export function useScroll() {
  const [isScrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 0;
      if (show !== isScrolled) setScrolled(show);
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  return isScrolled;
}
