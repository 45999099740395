import React from "react";
import { useSelector } from "react-redux";

import styled, { keyframes } from "styled-components";
import colors from "assets/styles/color";

function Loading() {
  const loading = useSelector((state) => state.loading);

  if (!loading.visible) return null;
  return (
    <Dimmed>
      <Container>
        <LoadingSpinner />
      </Container>
    </Dimmed>
  );
}

export default Loading;

const Spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
`;

const Container = styled.div`
  width: 150px;
  height: 150px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  border-radius: 30px;
  ${({ theme }) => theme.flexCenter}
  @media only screen and (min-width: 900px) {
    width: 160px;
    height: 160px;
  }
`;

const LoadingSpinner = styled.div`
  width: 100px;
  height: 100px;
  border: 8px solid transparent;
  border-top: 8px solid ${colors.primaryOrange};
  border-radius: 50%;
  animation: ${Spin} 1s ease-in-out infinite;
`;
