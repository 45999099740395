import DefaultLayout from "components/layouts/DefaultLayout";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { showAlert } from "features/modal/modalSlice";
import useDebouncedEffect from "hooks/useDebouncedEffect";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getValidateLoginId, postSignUpId } from "services/apis";
import { requestSelfVerification } from "services/authService";
import styled from "styled-components";
import { postValidatePassword, getRequestKey } from "services/apis";
import { encodeByAES56, decodeByAES256 } from "utils/utils";
import { Buffer } from "buffer";

const SignUpId = () => {
  const dispatch = useDispatch();
  const [loginId, setLoginId] = useState("");
  const [validateId, setValidateId] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordValidated, setPasswordValidated] = useState(false);
  const [passwordHint, setPasswordHint] = useState();

  useEffect(() => {
    setPasswordValidated(false);
    setPasswordHint("");
  }, [password]);

  const validatePassword = () => {
    password &&
      getRequestKey().then(({ data: { success, data, message } }) => {
        if (success) {
          const { encId, publicKey } = data;
          const requestData = {
            encId,
            data: encodeByAES56(
              Buffer.from(publicKey, "base64"),
              JSON.stringify({
                password: Buffer.from(password).toString("base64"),
              }),
            ),
          };
          postValidatePassword(JSON.stringify(requestData))
            .then(({ data: { success, message } }) => {
              if (success) {
                setPasswordHint("");
                setPasswordValidated(true);
              } else {
                setPasswordHint(message);
                setPasswordValidated(false);
              }
            })
            .catch((error) => {
              dispatch(showAlert({ message: error.response.data.message }));
            });
        } else {
          dispatch(showAlert({ message }));
        }
      });
  };
  useDebouncedEffect(validatePassword, 800, [password]);

  const handleClickConfirm = () => {
    if (!validateId) {
      if (!loginId) {
        dispatch(showAlert({ message: "아이디를 입력해주세요." }));
      } else {
        dispatch(showLoading());
        getValidateLoginId(loginId)
          .then(({ data: { success, message } }) => {
            if (success) {
              setValidateId(true);
            } else {
              dispatch(showAlert({ message }));
            }
          })
          .finally(() => {
            dispatch(hideLoading());
          });
      }
    } else {
      if (!password) {
        dispatch(showAlert({ message: "비밀번호를 입력해주세요." }));
      } else if (password !== confirmPassword || !passwordValidated) {
        dispatch(showAlert({ message: "비밀번호를 확인해주세요." }));
      } else {
        getRequestKey().then(({ data: { success, data, message } }) => {
          if (success) {
            const { encId, publicKey } = data;
            const requestData = {
              encId,
              data: encodeByAES56(
                Buffer.from(publicKey, "base64"),
                JSON.stringify({
                  loginId: Buffer.from(loginId).toString("base64"),
                  password: Buffer.from(password).toString("base64"),
                  confirmPassword:
                    Buffer.from(confirmPassword).toString("base64"),
                }),
              ),
            };
            dispatch(showLoading());
            postSignUpId(JSON.stringify(requestData))
              .then((encodeData) => {
                const responseData = decodeByAES256(
                  Buffer.from(publicKey, "base64"),
                  encodeData.data,
                );
                const {
                  success,
                  data: authToken,
                  message,
                } = JSON.parse(responseData);
                if (success) {
                  requestSelfVerification(authToken);
                } else {
                  dispatch(showAlert({ message }));
                }
              })
              .catch((error) => {
                dispatch(showAlert({ message: error.response.data.message }));
              })
              .finally(() => {
                dispatch(hideLoading());
              });
          } else {
            dispatch(showAlert({ message }));
          }
        });
      }
    }
  };

  const canSubmit =
    (!validateId && loginId) ||
    (passwordValidated && password === confirmPassword);

  return (
    <DefaultLayout title="회원가입">
      <Container className="join_wrap">
        <div className="join_email">
          <h3>
            로그인에 사용할
            <br />
            <span>{validateId ? "비밀번호" : "아이디"}</span>를 입력해주세요.
          </h3>
          <div className="email_box">
            <p>아이디</p>
            <input
              type="text"
              placeholder="아이디를 입력하세요."
              value={loginId}
              onChange={({ target: { value } }) => {
                setLoginId(value);
                setValidateId(false);
                setPassword("");
                setConfirmPassword("");
              }}
            />
          </div>

          {validateId && (
            <>
              <div className="email_box">
                <p>비밀번호</p>
                <input
                  type="password"
                  placeholder="비밀번호를 입력하세요."
                  value={password}
                  onChange={({ target: { value } }) => {
                    setPassword(value);
                  }}
                />
                <HintLabel>{passwordHint}</HintLabel>
              </div>
              <div className="email_box">
                <p>비밀번호 재확인</p>
                <input
                  type="password"
                  placeholder="비밀번호를 입력하세요."
                  value={confirmPassword}
                  onChange={({ target: { value } }) => {
                    setConfirmPassword(value);
                  }}
                />
                <HintLabel>
                  {confirmPassword && password !== confirmPassword
                    ? "비밀번호가 일치하지 않습니다."
                    : ""}
                </HintLabel>
              </div>
            </>
          )}
        </div>
        <button
          className={canSubmit ? "on pointer" : "off"}
          onClick={handleClickConfirm}
        >
          확인
        </button>
      </Container>
    </DefaultLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const HintLabel = styled.span`
  display: inline-block;
  margin-top: 8px;
  font-size: 12px;
  color: #f80003;
`;

export default SignUpId;
