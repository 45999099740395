import React from "react";
import styled from "styled-components";
import colors from "../../../assets/styles/color";

function BasicInfo({ goodsName, goodsInfos }) {
  const LabelList = () =>
    goodsInfos?.map((item) => <Label key={item.label}>{item.label}</Label>);

  const TextList = () =>
    goodsInfos?.map((item) => <Text key={item.id}>{item.text}</Text>);

  return (
    <BasicInfoWrapper>
      <GoodsName>{goodsName}</GoodsName>
      <GrayBar />
      <InfoWrapper>
        <LabelWrapper>
          <LabelList />
        </LabelWrapper>
        <TextWrapper>
          <TextList />
        </TextWrapper>
      </InfoWrapper>
    </BasicInfoWrapper>
  );
}

export default BasicInfo;

const BasicInfoWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #fffaf0;
  padding: 24px;
  margin: 0 16px;
`;

const GrayBar = styled.div`
  background-color: ${colors.Bar};
  width: 100%;
  height: 1px;
  margin: 15px 0;
`;

const GoodsName = styled.h1`
  color: ${colors.black200};
  font-size: 16px;
  font-weight: 700;
`;

const InfoWrapper = styled.div`
  display: flex;
`;

const LabelWrapper = styled.div`
  ${({ theme }) => theme.flexBox("column", "flex-start", "flex-start")}
  margin-right: 24px;
`;

const TextWrapper = styled.div`
  ${({ theme }) => theme.flexBox("column", "flex-start", "flex-start")}
`;

const Label = styled.div`
  color: ${colors.black700};
  letter-spacing: -0.28px;
  margin-bottom: 10px;
  font-weight: 400;
`;

const Text = styled(Label)`
  color: ${colors.black400};
`;
