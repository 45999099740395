import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setBuyInfo, selectBuyInfo } from "features/buy/buySlice";

import styled from "styled-components";
import colors from "assets/styles/color";
import CheckboxClicked from "assets/icons/checkbox-clicked.webp";
import CheckboxDisabled from "assets/icons/checkbox-disabled.webp";

import { Accordion } from "components";
import { NumberFormat } from "utils/utils";
import { getAvailable } from "services/apis";
import AvailableAssets from "./AvailableAssets";

export const useGetAvailable = () => {
  const { data: AvailableData, isLoading } = useQuery(
    ["available"],
    getAvailable,
    {
      select: (data) => data?.data?.data,
    },
  );
  return { AvailableData, isLoading };
};

function Available({ assetType, goodsGram }) {
  const dispatch = useDispatch();
  const { AvailableData } = useGetAvailable();
  const { point, krw, gold, silver } = AvailableData || {};
  const [isChecked, setIsChecked] = useState(false);
  const { BuyInfo } = useSelector(selectBuyInfo);

  useEffect(() => {
    BuyInfo?.isUseKrw && setIsChecked(true);
  }, []);

  const checkingCheckBox = () => {
    setIsChecked(!isChecked);
    dispatch(setBuyInfo({ isUseKrw: !isChecked }));
  };

  const Weight = assetType === "GOLD" ? gold : silver;

  useEffect(() => {
    if (point !== undefined) dispatch(setBuyInfo({ buyPoint: point }));
  }, [dispatch, point,]);

  useEffect(() => {
    if (point !== undefined)
      dispatch(setBuyInfo({ buyKrw: isChecked ? krw : 0 }));
  }, [dispatch, isChecked, krw]);

  return (
    <>
      <Accordion marginTop="16px" title="가용 자산">
        <PaddingDiv>
          <GrayBar>
            <CashWrapper className="gold">
              <GrayText>{assetType === "GOLD" ? "금" : "은"}</GrayText>
              <div>
                <Price>{NumberFormat(Weight)}</Price>
                <Gram> g</Gram>
              </div>
            </CashWrapper>
          </GrayBar>
          <TitleWrapper>
            <Title>{assetType === "GOLD" ? "금" : "은"} 사용</Title>
            <AvailableAssets
              label="원하는 중량 입력"
              unit="g"
              maxWeight={Weight}
              goodsGram={goodsGram}
            />
          </TitleWrapper>
        </PaddingDiv>
      </Accordion>

      {/* 포인트 */}
      <Accordion marginTop="16px" title="포인트">
        <PaddingDiv>
          <InstantDiscount>
            <GrayText>즉시 할인</GrayText>
            <div>
              <Price>- {NumberFormat(point)}</Price>
              <Won> 원</Won>
            </div>
          </InstantDiscount>
        </PaddingDiv>
      </Accordion>

      {/* 보유 현금 */}
      <Accordion marginTop="16px" title="보유 현금">
        <PaddingDiv>
          <CashWrapper>
            <GrayBar>
              <GrayText>KRW</GrayText>
            </GrayBar>
            <div>
              <Price>{NumberFormat(krw)}</Price>
              <Won> 원</Won>
            </div>
          </CashWrapper>
          <TitleWrapper>
            <Title>사용</Title>
            <Container isChecked={isChecked}>
              <CheckboxIcon
                src={isChecked ? CheckboxClicked : CheckboxDisabled}
                alt="체크박스"
                onClick={checkingCheckBox}
              />
              <Label>보유한 현금</Label>
            </Container>
          </TitleWrapper>
        </PaddingDiv>
      </Accordion>
    </>
  );
}

export default Available;

const InstantDiscount = styled.div`
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
`;

const Price = styled.span`
  font-size: 15px;
  font-weight: 700;
  font-family: "SpoqaHanSansNeo";
  color: ${colors.black400};
`;

const Won = styled.span`
  color: ${colors.black400};
  font-size: 15px;
  font-weight: 400;
  font-family: "SpoqaHanSansNeo";
  letter-spacing: -0.3px;
`;

const GrayText = styled.span`
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: ${colors.black700};
`;

const GrayBar = styled.div`
  height: auto;
  border-left: 4px solid ${colors.inputBorder};
  padding-left: 4px;
`;

const CashWrapper = styled.div`
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}

  &.gold {
    margin-bottom: 8px;
  }
`;

const Gram = styled.span`
  font-family: "SpoqaHanSansNeo";
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: ${colors.black400};
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-top: 16px;

  span:first-child {
    min-width: 68px;
    margin-top: 7px;
  }
`;

const Title = styled.span`
  color: ${colors.black400};
  font-family: "SpoqaHanSansNeo";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.28px;
`;

const CheckboxIcon = styled.img`
  margin-right: 11px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
const Container = styled.div`
  width: 100%;
  height: 32px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.isChecked ? "#ffb382" : `${colors.purchaseBorder}`)};
  background-color: ${colors.inputInside};

  display: flex;
`;
const Label = styled.span`
  color: ${colors.black400};
  height: 24px;
  font-size: 14px;
  font-family: "SpoqaHanSansNeo";
  letter-spacing: -0.73px;
  line-height: 24px;
  vertical-align: center;
`;

const PaddingDiv = styled.div`
  padding: 16px;
`;
