import React from "react";
import styled from "styled-components";
import iconLeftArrow from "assets/icons/icon-left-arrow.webp";
import cancel from "assets/icons/cancel-header.webp";
import { useNavigate } from "react-router-dom";
import { useScroll } from "hooks/useScroll";

const TextHeader = ({ title, cancelCallback, backCallback }) => {
  const navigate = useNavigate();
  const handleClickGoBack = () => {
    backCallback ? backCallback() : navigate(-1);
  };
  const isScrolled = useScroll();
  return (
    <Container isScrolled={isScrolled}>
      <TitleDiv>
        <IconImage
          className="pointer"
          src={iconLeftArrow}
          alt="뒤로가기"
          onClick={handleClickGoBack}
        />
        <Title>{title}</Title>
      </TitleDiv>
      {cancelCallback && (
        <CancelImg
          className="pointer"
          src={cancel}
          alt="취소"
          onClick={() => {
            cancelCallback();
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 56px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  border-bottom: 1px solid
    ${({ isScrolled }) => (!isScrolled ? `#e0e0e0` : `transparent`)};
  box-shadow: ${({ isScrolled }) =>
    isScrolled && `0px 2px 2px rgba(0, 0, 0, 0.04)`};
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
`;

const IconImage = styled.img`
  width: 48px;
  height: 48px;
  margin-left: -4px;
`;

const Title = styled.span`
  font-weight: bold;
  margin-left: -12px;
`;

const CancelImg = styled.img`
  width: 48px;
  height: 48px;
`;

export default TextHeader;
