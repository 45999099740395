import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { postSignInId, getRequestKey, postAdInfo } from "services/apis";
import {
  decodeJwt,
  encodeByAES56,
  decodeByAES256,
  getGaClientId,
} from "utils/utils";
import {
  requestSelfVerification,
  signInUsingToken,
} from "services/authService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showAlert } from "features/modal/modalSlice";
import { Buffer } from "buffer";

const IdLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();
  const redirectURI = decodeURIComponent(searchParams.get("redirectURI"));

  const handleClickLogin = () => {
    getRequestKey().then(
      ({ data: { success, data: successData, message } }) => {
        if (success) {
          const { encId, publicKey } = successData;
          const requestData = {
            encId,
            data: encodeByAES56(
              Buffer.from(publicKey, "base64"), // 서버에서 받은 key
              JSON.stringify({
                loginId: Buffer.from(loginId).toString("base64"),
                password: Buffer.from(password).toString("base64"),
              }),
            ),
          };
          postSignInId(JSON.stringify(requestData))
            .then((encodeData) => {
              const responseData = decodeByAES256(
                Buffer.from(publicKey, "base64"),
                encodeData.data,
              );
              const {
                success,
                data: authToken,
                message,
              } = JSON.parse(responseData);
              if (success) {
                const clientId = getGaClientId();
                const {
                  name,
                  roles = "NONE",
                  sns,
                  sub = "",
                  version = "",
                } = decodeJwt(authToken);

                const ids = sub.split(":");
                // 본인인증 완료한 회원
                if (+ids[1]) {
                  signInUsingToken(
                    authToken,
                    navigate,
                    redirectURI,
                    "로그인 되었습니다.",
                  );
                  postAdInfo({ ga_instance: clientId });
                } else {
                  requestSelfVerification(authToken);
                }
              } else {
                dispatch(showAlert({ message }));
              }
            })
            .catch((error) => {
              dispatch(showAlert({ message: error?.response?.data?.message }));
            });
        } else {
          dispatch(showAlert({ message }));
        }
      },
    );
  };
  return (
    <>
      <div className="login_id">
        <div className="login_text_input">
          <input
            type="text"
            placeholder="아이디 입력"
            value={loginId}
            onChange={({ target: { value } }) => {
              setLoginId(value);
            }}
            autoComplete="new-password"
          />
        </div>
        <div className="login_text_input">
          <input
            type="password"
            placeholder="비밀번호 입력"
            value={password}
            onChange={({ target: { value } }) => {
              setPassword(value);
            }}
            autoComplete="new-password"
          />
        </div>
      </div>
      <div className="login_id_kb">
        <button className="pointer" onClick={handleClickLogin}>
          로그인
        </button>
      </div>
    </>
  );
};

export default IdLogin;
