import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import colors from "assets/styles/color";
import { S3_BASE_URL } from "utils/constants";
import { useTossPayment } from "hooks/useTossPayment";
import useResponsiveWidth from "hooks/useResponsiveWidth";
import { getEnvConfig } from "services/apis";
import { routes } from "routes";

const SelectBank = ({
  currentStep,
  setCurrentStep,
  bankList,
  selectBank,
  setSelectBank,
}) => {
  const { calculateWidth } = useResponsiveWidth();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state || {}; // 주문/결제, 마이페이지 구분하기
  const FromSettlement = locationState.FromSettlement;

  const handleNextStep = (item) => {
    setSelectBank(item.code);
    setCurrentStep(currentStep + 1);
  };

  const { TossProcess } = useTossPayment();

  const handleClickTossBtn = async () => {
    getEnvConfig("TOSS_DEPOSIT_ONCE").then((res) => {
      if (Number(res.data.data.value) === 0) {
        navigate(routes.PurchaseUnabled);
      } else {
        TossProcess();
      }
    });
  };

  return (
    <Container>
      <ContentWrap>
        <div>
          <Title>결제할 은행을 선택해주세요.</Title>
          <SubTitle>
            아래 은행 중 계좌가 있는 은행을 선택해주세요.
            <br />
            없다면 다른 결제 방법을 눌러주세요.
          </SubTitle>
          <BankCardWrap>
            {bankList.map((item) => {
              return (
                <BankCard
                  key={item?.name}
                  onClick={() => handleNextStep(item)}
                  calculatedWidth={calculateWidth(98)}
                  calculatedHeight={calculateWidth(80)}
                >
                  <BankIcon
                    src={`${S3_BASE_URL}${item?.path}`}
                    alt={item?.name}
                    calculatedWidth={calculateWidth(48)}
                    calculatedHeight={calculateWidth(80)}
                  />
                  <BankName
                    calculatedWidth={calculateWidth(98)}
                    calculatedHeight={calculateWidth(80)}
                  >
                    {item?.name}
                  </BankName>
                </BankCard>
              );
            })}
          </BankCardWrap>
        </div>
        {FromSettlement && (
          <OtherMethodBtn onClick={handleClickTossBtn}>
            위의 은행이 없으신가요?
          </OtherMethodBtn>
        )}
      </ContentWrap>
    </Container>
  );
};

export default SelectBank;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentWrap = styled.div`
  padding: 18px 24px 79px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${colors.black200};
  font-weight: bold;
  font-size: 21px;
  letter-spacing: -0.42px;
  line-height: 36px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  line-height: 25.3px;
  color: ${colors.black700};
  margin-top: 10px;
`;

const BankCardWrap = styled.div`
  flex-wrap: wrap;
  ${({ theme }) => theme.flexBox("row", "center", "center")}
  margin-top: 37px;
`;

const BankCard = styled.button`
  width: calc((100% - 16px) / 3);
  height: ${(props) => props.calculatedHeight}px;
  border-radius: 8px;
  background-color: ${colors.white900};
  border: 1px solid ${colors.purchaseBorder};
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: pointer;
  ${({ theme }) => theme.flexBox("column", "flex-start", "center")}

  &:nth-child(3n) {
    margin-right: 0;
  }
`;

const OtherMethodBtn = styled.button`
  font-size: 14px;
  line-height: 24.5px;
  letter-spacing: -0.28px;
  text-decoration: underline;
  color: #0973f9;
  width: 150px;
  height: 20px;
  margin-top: 56px;
`;

const BankIcon = styled.img`
  width: ${({ calculatedWidth }) => calculatedWidth}px;
  height: ${({ calculatedWidth }) => calculatedWidth}px;
  margin-top: 5px;
  margin-bottom: -7px;
`;

const BankName = styled.div`
  color: ${colors.black300};
  font-size: 14px;
  height: 20px;
  line-height: 25px;
  letter-spacing: -0.35px;
`;
