import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";

import optionOpen from "assets/icons/option-open.webp";

function Accordion({ title, children, defaultOpen = true, marginTop = 0 }) {
  // 기본 상태 열림
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [contentHeight, setContentHeight] = useState(0);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const childRef = useRef(null);

  useEffect(() => {
    if (!childRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setContentHeight(childRef.current?.offsetHeight);
    });
    resizeObserver.observe(childRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <Container marginTop={marginTop}>
      <Header isOpen={isOpen} onClick={handleOpen}>
        {title}
        <OptionIcon>
          <img
            className={isOpen ? "isRotated" : "inverse"}
            src={optionOpen}
            alt="아이콘"
          />
        </OptionIcon>
      </Header>

      <ContentWrapper isOpen={isOpen} height={contentHeight}>
        <Content ref={childRef}>{children && children}</Content>
      </ContentWrapper>
    </Container>
  );
}

export default Accordion;

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: white;
  border: 1px solid ${colors.purchaseBorder};
  margin-top: ${(props) => props.marginTop || "0px"};
`;

const Header = styled.div`
  width: 100%;
  height: 44px;
  padding: 10px 16px;

  border-radius: ${(props) => (props.isOpen ? "8px 8px 0 0" : "8px")};

  color: ${colors.black300};
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.34px;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
  cursor: pointer;
`;

const OptionIcon = styled.button`
  width: 36px;
  height: 36px;
  img {
    margin-right: -16px;
    width: 100%;
    height: 100%;

    &.isRotated {
      transform: rotate(180deg);
      transition: 0.3s;
    }

    &.inverse {
      transform: rotate(0deg);
      transition: 0.3s;
    }
  }
`;

const Content = styled.div``;

const ContentWrapper = styled.div`
  height: ${({ isOpen, height }) => (isOpen ? height : 0)}px;
  overflow: hidden;
  border-style: solid;
  border-color: #eaeaea;
  border-top-width: ${({ isOpen }) => (isOpen ? "1px" : "0")};
  transition: height 0.3s ease-out, border-top-width 0.3s ease-out;
`;
