import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";
import { getGoodsGroup } from "services/apis";
import { ScrollToTop } from "components";
import Header from "../goods-detail/components/DetailHeader";
import TabArea from "./components/TabArea";
import SortArea from "./components/SortArea";
import GoodsList from "./components/GoodsList";
import { objToQueryString } from "utils/utils";
import MetaTag from "utils/MetaTag";
import { useInView } from "react-intersection-observer";
import DefaultLayout from "components/layouts/DefaultLayout";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { useGoodsListQuery } from "hooks/queries/goods";
import { routes } from "routes";

const Goods = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectSort, setSelectSort] = useState("LATEST");
  const [goodsGroupId, setGoodsGroupId] = useState(parseInt(id) || "ALL");
  const [page, setPage] = useState(0);
  const [goodsList, setGoodsList] = useState([]);
  const [ingFetch, setIngFetch] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [moreRef, inView] = useInView();
  const isFirstRender = useRef(true);

  const queryClient = useQueryClient();
  const goodsData = queryClient.getQueryData("goodsAllList");

  const { data: goodsGroup = [] } = useQuery(
    ["goodsGroup"],
    () => {
      return getGoodsGroup().then(({ data: { success, data } }) => {
        if (success) {
          // let totalList = goodsData?.goods;
          // let groupId = goodsGroupId || data[0].goodsGroupId;
          // /** category = ALL */
          // if (totalList.length !== 0) {
          //   if (groupId === "ALL") setGoodsList(setSort(totalList));
          //   else {
          //     if (
          //       data.find((item) => item.goodsGroupId === groupId) !== undefined
          //     ) {
          //       setGoodsList(
          //         setSort(
          //           data
          //             .find((item) => item.goodsGroupId === groupId)
          //             .goodsIdList.map((id) => {
          //               return totalList.find((item) => item.goodsId === id);
          //             }),
          //         ),
          //       );
          //     }
          //   }
          // }
          data.unshift({
            goodsGroupId: "ALL",
            goodsGroupName: "전체",
            type: "ALL",
            goodsGroupImage: null,
          });
          return data;
        }
      });
    },
    {
      initialData: [],
      enabled: goodsData?.goods.length !== 0,
    },
  );

  const handleSetGoodsList = useCallback(
    (groupId) => {
      let totalList = goodsData?.goods;
      /** category = ALL */
      if (totalList.length !== 0) {
        if (groupId === "ALL") setGoodsList(setSort(totalList));
        else {
          if (
            goodsGroup.find((item) => item.goodsGroupId === groupId) !==
            undefined
          ) {
            let arr = [];
            let goodsIdList = goodsGroup.find(
              (item) => item.goodsGroupId === groupId,
            ).goodsIdList;
            goodsIdList.forEach((id) => {
              let goods = totalList.find((item) => item.goodsId === id);
              if (goods)
                arr.push(totalList.find((item) => item.goodsId === id));
            });
            setGoodsList(setSort(arr));
          }
        }
      }
    },
    [goodsData?.goods, goodsGroup, goodsGroupId, selectSort],
  );

  const setSort = useCallback(
    (data) => {
      if (!data || !Array.isArray(data) || data.length === 0) return [];
      let func = () => {};
      if (selectSort === "LATEST") {
        func = (x, y) => new Date(y.updatedAt) - new Date(x.updatedAt);
      } else if (selectSort === "LOWPRICE") {
        func = (x, y) => x.sellingPrice - y.sellingPrice;
      } else if (selectSort === "HIGHPRICE") {
        func = (x, y) => y?.sellingPrice - x?.sellingPrice;
      }
      return data.sort((x, y) => func(x, y));
    },
    [selectSort],
  );

  useEffect(() => {
    setSelectSort(state?.selectSort || "LATEST");
    handleSetGoodsList(goodsGroupId);
  }, [state?.selectSort]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  useMemo(() => {
    if (goodsGroup.length !== 0) {
      handleSetGoodsList(goodsGroupId);
    }
  }, [goodsGroupId, goodsGroup, selectSort]);

  const groupName = useMemo(() => {
    if (
      Array.isArray(goodsGroup) &&
      goodsGroup.find(
        (categoryinfo) => categoryinfo.goodsGroupId === goodsGroupId,
      ) !== undefined
    ) {
      return goodsGroup.find(
        (categoryinfo) => categoryinfo.goodsGroupId === goodsGroupId,
      )?.goodsGroupName;
    }
  }, [goodsGroup, goodsGroupId]);

  return (
    <>
      <MetaTag
        title={groupName && `${groupName} | 금방금방몰`}
        description={`${groupName}`}
        keywords={`${groupName}`}
      />
      <DefaultLayout headerVisible={false} navigationVisible={true}>
        <ScrollToTop />
        <Header />
        {Array.isArray(goodsGroup) && goodsGroup.length !== 0 && (
          <TabArea
            goodsGroup={goodsGroup}
            categoryId={goodsGroupId}
            setCategoryId={(id) => {
              if (goodsGroupId !== id) setGoodsGroupId(id);
            }}
          />
        )}
        <SortArea selectSort={selectSort} time={goodsData?.time} />
        {(parseInt(id) || "ALL") === goodsGroupId ? (
          <GoodsList goodsList={goodsList} />
        ) : null}
        <div ref={moreRef} style={{ height: 10 }} />
      </DefaultLayout>
    </>
  );
};

export default Goods;
