import jwtDecode from "jwt-decode";
import moment from "moment";
import "moment/locale/ko";
import { PHONE_CODE } from "./constants";
import cryptoJs from "crypto-js";

export function NumberFormat(number) {
  if (number) {
    const part = number.toString().split(".");
    part[0] = part[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return part.join(".");
  } else {
    return 0;
  }
}

export const decodeJwt = (jwt) => {
  if (jwt) {
    return jwtDecode(jwt);
  } else {
    return {};
  }
};

export const dateToString = (date = new Date(), format = "YYYY-MM-DD") => {
  if (date) {
    return moment(date, "YYYYMMDDHHmmss").locale("ko").format(format);
  } else {
    return "";
  }
};

export const validateEmail = (email) => {
  const regex =
    /^[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+@[-0-9A-Za-z!#$%&'*+/=?^_`{|}~]+[.]{1}[0-9A-Za-z]/;

  return regex.test(email);
};

export function objToQueryString(obj) {
  var keyValuePairs = [];
  let result = "";

  for (var key in obj) {
    if (obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    } else {
      if (obj.hasOwnProperty(key)) {
        keyValuePairs.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]),
        );
      }
      result = "?" + keyValuePairs.join("&");
    }
  }
  return result;
}

export function PhoneNumberFormat(phone) {
  return phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

export const getFormatTime = (dateData) => {
  const date = new Date(dateData);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}.${String(month).padStart(2, "0")}.${String(day).padStart(
    2,
    "0",
  )}`;
};

export const validatePhone = (phoneNumber) => {
  const phoneCodePattern = PHONE_CODE.join("|");
  const regex = new RegExp(`^(${phoneCodePattern})[0-9]{4}[0-9]{4}$`);
  return regex.test(phoneNumber);
};

export const validateInfo = (isDelivery, info) => {
  const checks = isDelivery
    ? [
        { condition: !info?.receiver, message: "<수령인/>을 <입력/>해주세요" },
        { condition: !info?.phone, message: "<연락처1/>을 <입력/>해주세요" },
        {
          condition: !validatePhone(info.phone),
          message: "<연락처1/>을 <정확히 입력/>해주세요",
        },
        {
          condition: !info?.postCode,
          message: "<우편번호/>를 <입력/>해주세요",
        },
        { condition: !info?.address, message: "<주소/>를 <입력/>해주세요" },
        {
          condition: !info?.addressDetail,
          message: "<상세 주소/>를 <입력/>해주세요",
        },
      ]
    : [
        {
          condition: info?.shopId === 0,
          message: "<방문할 대리점/>을 <선택/>해주세요",
        },
        {
          condition: info?.visitDate === null || info?.visitDate === "",
          message: "<희망 방문 일자/>를 <선택/>해주세요",
        },
      ];

  for (let i = 0; i < checks.length; i++) {
    if (checks[i].condition) {
      return { isValid: false, message: checks[i].message };
    }
  }
  return { isValid: true };
};

// 암호화
export const encodeByAES56 = (key, data) => {
  const secretKey = key; // 32자리 비밀키
  const iv = "";
  const cipher = cryptoJs.AES.encrypt(
    data,
    cryptoJs.enc.Utf8.parse(secretKey),
    {
      iv: cryptoJs.enc.Utf8.parse(iv),
      padding: cryptoJs.pad.Pkcs7,
      mode: cryptoJs.mode.CBC,
    },
  );
  return cipher.toString();
};

// 복호화
export const decodeByAES256 = (key, data) => {
  const secretKey = key; // 32자리 비밀키
  const iv = "";
  const cipher = cryptoJs.AES.decrypt(
    data,
    cryptoJs.enc.Utf8.parse(secretKey),
    {
      iv: cryptoJs.enc.Utf8.parse(iv),
      padding: cryptoJs.pad.Pkcs7,
      mode: cryptoJs.mode.CBC,
    },
  );
  return cipher.toString(cryptoJs.enc.Utf8);
};

export const getGaClientId = () => {
  const cookie = {};
  document.cookie?.split(";")?.forEach((el) => {
    const splitCookie = el?.split("=");
    const key = splitCookie?.[0]?.trim();
    const value = splitCookie?.[1];
    cookie[key] = value;
  });
  return cookie?.["_ga"]?.substring(6);
};

export function getOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod|iOS/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  if (/^((?!chrome|android).)*safari/i.test(userAgent)) {
    return "Safari";
  }

  return "unknown";
}

export const getDistanceFromLatLonInKm = (lat1, lng1, lat2, lng2) => {
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lng2 - lng1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};
