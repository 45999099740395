import { useRef, useState } from "react";

export default function usePlainKeyboard() {
  const [items, setItems] = useState([]);
  const pressFlag = useRef(false);

  const pressKey = (key) => {
    if (pressFlag.current || items.length >= 6) {
      return false;
    }

    pressFlag.current = true;

    setItems([...items, key]);
    pressFlag.current = false;

    return true;
  };

  const removeOne = () => {
    if (!items.length) {
      return false;
    }

    setItems((items) => {
      return items.slice(0, -1);
    });

    return true;
  };

  const clearAll = () => {
    setItems([]);
  };

  const getResult = () => {
    if (items.length !== 6) {
      return null;
    }

    return items.join("");
  };

  const result = getResult();
  const count = items.length;

  const secured = { result, count };
  const actions = { pressKey, removeOne, clearAll };

  return [secured, actions];
}
