import React from "react";
import styled, { css } from "styled-components";

import colors from "assets/styles/color";

const SIZES = {
  // 모달 버튼
  sm: css`
    height: 44px;
    color: ${colors.white};

    font-weight: 400;
    padding: 10px 0;
  `,
  // 옵션
  md: css`
    height: 48px;
    color: ${colors.white};

    border-radius: 4px;
    font-weight: 700;
    padding: 12px 0;
  `,
  // 로그인
  lg: css`
    height: 56px;
    color: ${colors.white};

    padding: 15px 0;
  `,
};

const VARIANTS = {
  round: css`
    border-radius: 8px;
  `,
};

const DISABLED = {
  modal: css`
    background-color: ${colors.ivory};
    color: ${colors.black200};
  `,
  login: css`
    background-color: ${colors.gray};
    color: #bbbbbb;
  `,
};

function Button({ onClickFn, text, size, variant, disabled, addStyle }) {
  const sizeStyle = SIZES[size];
  const variantStyle = VARIANTS[variant];
  const disabledStyle = DISABLED[disabled];

  return (
    <StyledButton
      sizeStyle={sizeStyle}
      variantStyle={variantStyle}
      disabledStyle={disabledStyle}
      onClick={onClickFn}
      addStyle={addStyle}
    >
      {text}
    </StyledButton>
  );
}

export default Button;

const StyledButton = styled.button`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.32px;

  cursor: pointer;
  background-color: ${colors.primaryOrange};
  color: ${colors.white};
  cursor: pointer;

  ${(p) => p.sizeStyle};
  ${(p) => p.variantStyle};
  ${(p) => p.disabledStyle};
  ${(p) =>
    p.addStyle &&
    css`
      ${p.addStyle}
    `};
`;
