import React from "react";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  flex: ${({ flex }) => flex || "0 1 auto"};
`;

export default Flex;
