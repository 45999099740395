import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Zoom } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { S3_BASE_URL } from "utils/constants";
import colors from "assets/styles/color";
import fonts, { fontWeight } from "assets/styles/fonts";
import timeIcon from "assets/icons/icon-time.webp";
import { dateToString, NumberFormat } from "utils/utils";
import { routes } from "routes";
import { useNavigate } from "react-router-dom";
import Timezone from "components/common/Timezone";

const GoodsWatch = ({ goodsData }) => {
  const navigate = useNavigate();
  const { goods = [], goodsGroupName = "", time = "" } = goodsData;

  const handleClickItem = (goodsId) => {
    navigate(routes.goodsDetail(goodsId));
  };

  return (
    <div
      style={{
        margin: "0px 16px 32px 16px",
      }}
    >
      <div className="home-header">
        <span className="home-header-title color-200 non-padding">
          {goodsGroupName}
        </span>
      </div>
      <div
        className="aspect-ratioBox"
        style={{
          paddingTop: goods.length === 1 ? "53.5%" : "58.5%",
        }}
        count={goods.length}
      >
        <Swiper
          className="home-styled-swiper"
          spaceBetween={0}
          zoom={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Zoom]}
        >
          {goods?.map((item, index) => {
            const {
              goodsId,
              image,
              brand,
              goodsName,
              standardPrice,
              sellingPrice,
              isSoldOut,
            } = item;
            return (
              <SwiperSlide key={index} onClick={() => handleClickItem(goodsId)}>
                <SlideContent>
                  <GoodsInfo>
                    <TopWrap />
                    <InfoWrap>
                      <SideWrap />
                      <BrandName isSoldOut={isSoldOut}>{brand}</BrandName>
                      <GoodsName isSoldOut={isSoldOut}>{goodsName}</GoodsName>
                      {isSoldOut ? (
                        <SoldOut>품절</SoldOut>
                      ) : (
                        <>
                          <RecentPrice>
                            {NumberFormat(sellingPrice)}
                            <Won>원</Won>
                          </RecentPrice>
                          <OriginalPrice>
                            <span className="line-through">
                              <span className="spoqa">
                                {NumberFormat(standardPrice)}
                              </span>
                              원
                            </span>
                          </OriginalPrice>
                          <VReview>
                            <div vreview-product-id={goodsId}></div>
                          </VReview>
                        </>
                      )}
                      <Timezone isSoldOut={isSoldOut} />
                    </InfoWrap>
                  </GoodsInfo>
                  <GoodsImgWrap>
                    <GoodsImg
                      src={S3_BASE_URL + "/" + image}
                      alt="상품이미지"
                    />
                  </GoodsImgWrap>
                </SlideContent>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default GoodsWatch;

const Wrap = styled.div`
  margin: 0px 16px 32px 16px;
`;

const HeaderTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const HeaderTitle = styled.span`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: -0.48px;
  color: ${colors.black200};
  margin-top: 2px;
`;
const AspectRatioBox = styled.div`
  overflow: hidden;
  padding-top: ${(props) => (props.count === 1 ? "53.5%" : "58.5%")};
  position: relative;
  border-radius: 8px;
`;

const StyledSwiper = styled(Swiper)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;

  .swiper-slide {
    height: auto;
    object-fit: contain;
    background-image: linear-gradient(to bottom, #ffffff, #e0e0e0);
  }

  /* 버튼 커스텀 */
  .swiper-pagination {
    height: 24px;
  }

  .swiper-pagination-bullet {
    border-radius: 0;
    width: 8px;
    height: 2px;
    text-align: center;
    font-size: 12px;
    color: #000;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.2);
  }

  .swiper-pagination-bullet-active {
    width: 24px;
    height: 4px;
    color: ${colors.white};
    background: #616161;
  }
`;

const SlideContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* margin-bottom: 12px; */
`;

const InfoWrap = styled.div`
  flex: 1;

  @media only screen and (min-width: 900px) {
    align-self: center;
  }
`;

const BrandName = styled.div`
  color: ${(props) =>
    props.isSoldOut ? colors.disabledColor : colors.black700};
  font-size: 13px;
  font-weight: ${fontWeight.regular};
  font-family: ${fonts.notoSansKR};
  opacity: 0.99;
  margin-bottom: 6px;
`;

const GoodsName = styled.div`
  color: ${(props) =>
    props.isSoldOut ? colors.disabledColor : colors.black400};
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  font-family: ${fonts.notoSansKR};
  margin-bottom: 14px;
`;

const RecentPrice = styled.div`
  color: ${colors.black300};
  font-size: 16px;
  font-weight: ${fontWeight.bold};
  font-family: ${fonts.spoqa};
  margin-bottom: 2px;
`;

const OriginalPrice = styled.span`
  font-size: 13px;
  color: ${colors.black800};
  .spoqa {
    font-weight: 500;
    font-size: 13px;
  }

  .line-through {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      background-color: ${colors.black800};
      height: 1px;
      top: 50%;
      left: 0;
      right: 0;
    }
  }
`;

const TimeWrap = styled.div`
  margin-top: ${(props) => (props.isSoldOut ? "49px" : "10px")};
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
`;

const TimeIcon = styled.img`
  width: 11px !important;
  height: 11px;
  margin-right: 4px;
  margin-bottom: 1px;
  img {
    color: ${colors.black900};
  }
`;

const TimeString = styled.span`
  color: ${colors.black900};
  font-family: ${fonts.notoSansKR};
  font-size: 12px;
  letter-spacing: -0.24px;
  .spoqa {
    font-family: ${fonts.spoqa};
    font-size: 13px;
    letter-spacing: -0.26px;
  }
`;

const SoldOut = styled.div`
  margin-top: 13px;
  width: 42px;
  height: 24px;
  background-color: ${colors.black400};
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Won = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.black300};
`;

const TopWrap = styled.div`
  width: 16px;
`;

const SideWrap = styled.div`
  height: 16px;
`;

const GoodsImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const GoodsImgWrap = styled.div`
  flex: 1;
  aspect-ratio: 1 / 1;
  min-width: 0;
  display: flex;
  overflow: hidden;
`;

const GoodsInfo = styled.div`
  display: flex;
  flex: 1;
  aspect-ratio: 1 / 1;
  min-width: 0;
  position: relative;
`;

const VReview = styled.div`
  .vreview-review-summary {
    margin-top: 7px;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    background: rgba(255, 255, 255, 0);
    padding: 0px 5px;
    width: fit-content;
    line-height: 20px;
    font-size: 11px;
  }
  .vreview-board-popup {
    margin-top: unset !important;
  }
  .vreview-rating {
    margin-top: -3px !important;
    width: 8px;
    height: 8px;
  }
`;
