import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout";
import styled from "styled-components";
import Header from "./components/Header";
import Content from "./components/Content";

const ManageAccount = () => {
  return (
    <DefaultLayout title=" 입금 계좌관리" navigationVisible={false}>
      <Container>
        <Header />
        <Wrap height="28px" />
        <Content />
      </Container>
    </DefaultLayout>
  );
};

export default ManageAccount;

const Container = styled.div`
  padding: 24px 24px 64px 24px;
  flex: 1;
`;

const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
`;
