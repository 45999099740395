import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "features/loading/loadingSlice";

export default function useGetUserLocation() {
  const dispatch = useDispatch();
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { latitude: 0, longitude: 0 },
  });

  const geolocationOptions = {
    timeout: 1000 * 10, // 캐시 사용
    maximumAge: 1000 * 3600 * 24, // 캐시값 사용 가능한 시간
  };

  const OnSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        latitude: Number(location.coords.latitude),
        longitude: Number(location.coords.longitude),
      },
    });
    dispatch(hideLoading());
  };

  const OnError = (error) => {
    setLocation({
      ...location,
      loaded: true,
      error,
    });
    dispatch(hideLoading());
  };

  useEffect(() => {
    dispatch(showLoading());
    if (!("geolocation" in navigator)) {
      OnError({
        code: 0,
        message: "Geolocation not supported",
      });
    }
    navigator.geolocation.getCurrentPosition(
      OnSuccess,
      OnError,
      geolocationOptions,
    );
  }, []);

  return location;
}
