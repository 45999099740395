import { useSelector } from "react-redux";
import usePlainKeyboard from "./usePlainKeyboard";
import useCryptoKeyboard from "./useCryptoKeyboard";

export default function usePinKeyboard() {
  const auth = useSelector((state) => state.auth);
  const { version } = auth;

  const useKeyboard = version === "V1" ? usePlainKeyboard : useCryptoKeyboard;

  return useKeyboard();
}
