import React from "react";
import { useNavigate } from "react-router-dom";

import DetailHeader from "pages/goods-detail/components/DetailHeader";
import DefaultLayout from "components/layouts/DefaultLayout";
import { routes } from "routes";

function ReviewWidget() {
  const navigate = useNavigate();
  return (
    <>
      <DefaultLayout
        title="상품 리뷰"
        navigationVisible={true}
        backCallback={() => navigate(routes.myPage)}
      >
        <div className="mypage">
          <div className="vreview-myreview-widget"></div>
        </div>
        <div style={{ height: "30px" }} />
      </DefaultLayout>
    </>
  );
}

export default ReviewWidget;
