import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useInView } from "react-intersection-observer";

import MetaTag from "utils/MetaTag";
import { objToQueryString } from "utils/utils";
import DefaultLayout from "components/layouts/DefaultLayout";
import { getGoodsCategory } from "services/apis";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { ScrollToTop } from "components";
import TabArea from "./components/TabArea";
import SortArea from "../goods/components/SortArea";
import Header from "../goods-detail/components/DetailHeader";
import GoodsCategoryList from "./components/GoodsCategoryList";
import { useGoodsListQuery } from "hooks/queries/goods";

const GoodsCategory = () => {
  const { state } = useLocation();
  let params = useParams();
  const dispatch = useDispatch();
  const [selectSort, setSelectSort] = useState("LATEST");
  const [goodsCategory, setGoodsCategory] = useState(parseInt(params?.id));
  const [page, setPage] = useState(0);
  const [goodsList, setGoodsList] = useState([]);
  const [ingFetch, setIngFetch] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [moreRef, inView] = useInView();
  const isFirstRender = useRef(true);

  useEffect(() => {
    setGoodsCategory(parseInt(params?.id));
    setSelectSort(state?.selectSort || "LATEST");
  }, [state]);

  const queryClient = useQueryClient();
  const goodsData = queryClient.getQueryData("goodsAllList");

  const { data: categoryList = [] } = useQuery(
    ["categoryList", goodsCategory, selectSort],
    () => {
      return getGoodsCategory().then(({ data: { success, data } }) => {
        if (success) {
          let totalList = goodsData?.goods;
          data = data.filter((goods) => goods?.goodsIdList.length !== 0);
          if (
            totalList.length !== 0 &&
            data.find((item) => item.goodsCategoryId === goodsCategory) !==
              undefined
          ) {
            setGoodsList(
              setSort(
                data
                  .find((item) => item.goodsCategoryId === goodsCategory)
                  .goodsIdList.map((id) => {
                    return totalList.find((item) => item.goodsId === id);
                  }),
              ),
            );
          }
          return data;
        }
      });
    },
    {
      initialData: [],
      enabled: goodsData?.goods.length !== 0,
    },
  );

  const setSort = useCallback(
    (data) => {
      if (!Array.isArray(data) || data.length === 0) return [];

      let func = () => {};
      if (selectSort === "LATEST") {
        func = (x, y) => new Date(y.updatedAt) - new Date(x.updatedAt);
      } else if (selectSort === "LOWPRICE") {
        func = (x, y) => x.sellingPrice - y.sellingPrice;
      } else if (selectSort === "HIGHPRICE") {
        func = (x, y) => y?.sellingPrice - x?.sellingPrice;
      }
      return data.sort((x, y) => func(x, y));
    },
    [selectSort],
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  const categoryName = useMemo(() => {
    if (
      Array.isArray(categoryList) &&
      categoryList.find(
        (categoryinfo) => categoryinfo.goodsCategoryId === goodsCategory,
      )
    ) {
      return categoryList.find(
        (categoryinfo) => categoryinfo.goodsCategoryId === goodsCategory,
      )?.kind;
    }
  }, [categoryList, goodsCategory]);

  return (
    <>
      <MetaTag
        title={categoryName && `${categoryName} | 금방금방몰`}
        description={`${categoryName}`}
        keywords={`${categoryName}`}
      />
      <DefaultLayout headerVisible={false} navigationVisible={true}>
        <ScrollToTop />
        <Header />
        <TabArea
          goodsCategoryList={categoryList}
          goodsCategory={goodsCategory}
          setGoodsCategory={(id) => {
            setGoodsCategory(parseInt(id));
          }}
        />
        <SortArea selectSort={selectSort} time={goodsData?.time} />
        <GoodsCategoryList goodsList={goodsList} />
        <div ref={moreRef} style={{ height: 10 }} />
      </DefaultLayout>
    </>
  );
};

export default GoodsCategory;
