import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconCategoryOn from "assets/icons/icon-category-on.webp";
import iconCategoryOff from "assets/icons/icon-category-off.webp";
import iconHomeOn from "assets/icons/icon-home-on.webp";
import iconHomeOff from "assets/icons/icon-home-off.webp";
import iconMyPageOn from "assets/icons/icon-my-page-on.webp";
import iconMyPageOff from "assets/icons/icon-my-page-off.webp";
import { routes } from "routes";
import colors from "assets/styles/color";

const ICONS = {
  CATEGORY: { ON: iconCategoryOn, OFF: iconCategoryOff },
  HOME: { ON: iconHomeOn, OFF: iconHomeOff },
  MY_PAGE: { ON: iconMyPageOn, OFF: iconMyPageOff },
};

const BottomNavigation = ({
  isHamburgerOpen,
  setIsHamburgerOpen,
  handleHamburgerClick,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const isHome = pathname.startsWith(routes.home);
  const isMyPage = pathname === routes.myPage;

  const handleClickHome = () => {
    setIsHamburgerOpen(false);
    !isHome && navigate(routes.home);
  };

  const handleClickMyPage = () => {
    setIsHamburgerOpen(false);
    if (!isMyPage) {
      if (!auth.isSigned) {
        navigate(routes.login);
      } else {
        navigate(routes.myPage);
      }
    }
  };

  return (
    <Container>
      <IconWrap
        className={isHamburgerOpen && "open"}
        show={isHamburgerOpen}
        labelLength={4}
      >
        <IconImage
          className="pointer"
          src={ICONS["CATEGORY"][isHamburgerOpen ? "ON" : "OFF"]}
          onClick={handleHamburgerClick}
        />
        <span>카테고리</span>
      </IconWrap>
      <IconWrap
        className={!isHamburgerOpen && isHome ? "open" : ""}
        labelLength={1}
      >
        <IconImage
          src={ICONS["HOME"][!isHamburgerOpen && isHome ? "ON" : "OFF"]}
          className="pointer"
          onClick={handleClickHome}
        />
        <span>홈</span>
      </IconWrap>
      <IconWrap
        className={!isHamburgerOpen && isMyPage ? "open" : ""}
        labelLength={3}
      >
        <IconImage
          src={ICONS["MY_PAGE"][!isHamburgerOpen && isMyPage ? "ON" : "OFF"]}
          className="pointer"
          onClick={handleClickMyPage}
        />
        <span> 내정보</span>
      </IconWrap>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background: white;
  border-radius: 24px 24px 0 0;
  /* margin-top: -16px; */
  padding: 10px 24px;
  box-shadow: 0 0 6px #29000020;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1001;

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 480px;
  }
`;

const IconImage = styled.img`
  width: 40px;
  height: 40px;
`;

const IconWrap = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;

  & > span {
    display: none;
    letter-spacing: -0.28px;
    color: ${colors.primaryOrange};
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    margin-left: -6px;
  }

  &.open {
    ${({ theme }) => theme.flexCenter}
    border: 1px solid #ffe6d6;
    background-color: #fff5ee;
    padding-right: 10px;
    /* 메뉴에 따라 다른 원 크기가 되도록 */
    width: ${({ labelLength }) => {
      switch (labelLength) {
        case 1:
          return 68;
        case 2:
          return 80;
        case 3:
          return 92;
        case 4:
          return 104;
        default:
          return 40;
      }
    }}px;

    & > span {
      display: block;
    }
  }
`;

export default BottomNavigation;
