import React, { useEffect, useState } from "react";

const TextInput = ({ value: propValue = "", onChange, ...rest }) => {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const handleChangeInput = (e) => {
    const value = e.target.value;
    onChange && onChange(value);
    setValue(value);
  };
  return (
    <input
      type="text"
      value={value}
      onChange={handleChangeInput}
      autoComplete="new-password"
      {...rest}
    />
  );
};

export default TextInput;
