import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getUserAsset, getLeaveAsset } from "services/apis";
import Header from "./Header";
import WarningInfo from "./WarningInfo";
import MyAsset from "./MyAsset";
import CheckArea from "./CheckArea";
import { CustomModal } from "components";
import UnableLeave from "./UnableLeave";
import { useModal } from "hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "features/modal/modalSlice";
import { hideLoading, showLoading } from "features/loading/loadingSlice";

const headerName = "탈퇴하기";
const subHeaderName = "탈퇴 전 확인하세요!";

const LeaveFirst = ({ currentStep, setCurrentStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [unAbleLeaveMessage, setUnAbleLeaveMessage] = useState("");
  const [modalVisible, modalOpen, modalClose] = useModal(false);
  const auth = useSelector((state) => state.auth);
  const { roles = [] } = auth;

  const isAppUser = roles.includes("ROLE_SELF_VERIFICATION");

  const { data: userAsset = [] } = useQuery("userAsset", () => {
    return getUserAsset().then(({ data: { success, data } }) => {
      if (success) {
        return data;
      }
    });
  });

  const handleClickCheck = () => {
    if (isChecked) {
      if (isAppUser) {
        dispatch(
          showAlert({
            message: "탈퇴할 수 없습니다.\n금방금방 앱을 통해 탈퇴해주세요.",
            icon: "Error",
          }),
        );
      } else {
        dispatch(showLoading());
        getLeaveAsset()
          .then(({ data: { success, message } }) => {
            if (success) {
              setCurrentStep(currentStep + 1);
            } else {
              setUnAbleLeaveMessage(message);
              modalOpen();
            }
          })
          .finally(() => dispatch(hideLoading()));
      }
    }
  };
  return (
    <Container>
      <div>
        <Header header={headerName} subHeader={subHeaderName} />
        <Wrap height={"28px"} />
        <WarningInfo />
        <Wrap height={"32px"} />
        <MyAsset userAsset={userAsset} />
        <Wrap height={"40px"} />
        <CheckArea isChecked={isChecked} setIsChecked={setIsChecked} />
        <Wrap height={"54px"} />
      </div>
      <Flex>
        <Button
          className="pointer"
          type="CANCEL"
          onClick={() => {
            navigate(-1);
          }}
        >
          취소
        </Button>
        <Button
          disabled={!isChecked}
          type="LEAVE"
          isChecked={isChecked}
          onClick={() => {
            handleClickCheck();
          }}
        >
          다음
        </Button>
      </Flex>
      <CustomModal modalVisible={modalVisible} modalClose={modalClose}>
        <UnableLeave modalClose={modalClose} message={unAbleLeaveMessage} />
      </CustomModal>
    </Container>
  );
};

export default LeaveFirst;

const Container = styled.div`
  padding: 0px 0px 64px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
  margin: ${(props) => `${props.margin}`};
  padding: ${(props) => `${props.padding}`};
`;

const Button = styled.button`
  flex: 1;
  height: 48px;
  cursor: ${(props) =>
    props.type === "LEAVE" ? (props.isChecked ? "pointer" : "") : ""};
  background-color: ${(props) =>
    props.type === "LEAVE"
      ? props.isChecked
        ? "#ff6e0e"
        : "#dddddd"
      : "#e9ecf5"};
  color: ${(props) =>
    props.type === "LEAVE"
      ? props.isChecked
        ? "#ffffff"
        : "#bbbbbb"
      : "#222222"};
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
`;

const Flex = styled.div`
  display: flex;
  margin: 0px 24px;
  gap: 8px;
`;
