import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { routes } from "routes";
import { putPurchase, getBuyApproval, postBuyEventPoint } from "services/apis";
import { selectBuyInfo } from "features/buy/buySlice";
import { showAlert } from "features/modal/modalSlice";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { getSessionStorageItem } from "services/storageService";
import DefaultLayout from "components/layouts/DefaultLayout";
import usePinKeyboard from "hooks/usePinKeyboard";
import Flex from "components/common/flex/Flex";
import ic_del from "assets/images/ic_del.png";
import ReactGA from "react-ga4";
import useScript from "hooks/useScript";

const Settlement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id, goodsName } = location.state;
  const [secured, secureActions] = usePinKeyboard();
  const [keyArr, setKeyArr] = useState([]);
  const { naverScript, googleTagScript } = useScript();
  const { BuyInfo, DeliveryInfo } = useSelector(selectBuyInfo);
  const qty = getSessionStorageItem("qty");
  const optionValue = getSessionStorageItem("optionValue");

  const {
    buyShopCode,
    shopId,
    useGram,
    useKrw,
    usePoint,
    buyPrice,
    sellingPrice,
    depositPrice,
    standardPrice,
    visitDate,
    isDelivery,
  } = BuyInfo;

  const {
    postCode,
    address,
    addressDetail,
    addressName,
    receiver,
    phone,
    mobile,
    memo,
    isDefault,
  } = DeliveryInfo;

  const Data = {
    useGram, // 가용 자산 중량
    useKrw, // 사용할 현금
    usePoint, // 사용할 포인트
    optionValue, // 선택한 옵션
    qty, // 수량
    buyShopCode, // 구매한 대리점 코드
    visitDate, // 방문 날짜
    visitTime: null,
    shopId, // 방문할 대리점 ID
    buyPrice,
    sellingPrice, // 상품 현재가
    depositPrice, // 결제 예상 금액
    standardPrice, // 상품 기준가
    // 헥토 간편 결제
    depositMethod: "HECTO_SIMPLE",
    tossPaymentKey: "",
    tossOrderId: "",
    delivery: {
      // 택배 배송지 정보
      postCode, // 우편번호
      address, // 주소
      addressDetail, // 상세주소
      addressName, // 배송지명
      receiver, // 수령인
      phone, // 연락처1
      mobile, // 연락처2
      memo, // 수령메모
      isDefault, // 기본 배송지로 추가
    },
    isDelivery, // 대리점 방문, 택배 수령 여부
    finPassword: secured.result, // 비밀 번호
  };

  const Purchase = useMutation(() => putPurchase(id, Data), ["purchase"], {});
  useLayoutEffect(() => {
    shuffle();
  }, []);

  const shuffle = () => {
    const newArr = Array.from({ length: 10 }, (_, i) => i).sort(
      () => Math.random() - 0.5,
    );

    setKeyArr(Array.from({ length: 4 }).map(() => newArr.splice(0, 3)));
  };

  useEffect(() => {
    if (secured.count === 6) {
      handleClickEnter();
    }
  }, [secured.count]);

  const handleClickEnter = async () => {
    try {
      const response = await Purchase.mutateAsync();
      const { success, data } = response.data;

      if (!success) {
        dispatch(showAlert({ message: response.data.message }));
        secureActions.clearAll();
        shuffle();
      } else {
        dispatch(showLoading());
        pendingConfirm(1, data.goodsMallRequestId);
      }
    } catch (error) {
      dispatch(showAlert({ message: error?.response?.data?.message }));
    }
  };

  const pendingConfirm = (count, id) => {
    setTimeout(() => {
      getBuyApproval(id).then(({ data: { success, data, message } }) => {
        if (success) {
          const script = document.createElement("script");
          if (data.status === "APPROVAL") {
            naverScript(1, +sellingPrice);
            googleTagScript(+sellingPrice, data.goodsMallRequestId);
            ReactGA.gtag("event", "purchase", {
              transaction_id: data.goodsMallRequestId,
              currency: "KRW",
              value: +sellingPrice,
              items: [{ item_id: id, item_name: goodsName }],
            });
            if (process.env.REACT_APP_SERVICE_TYPE === "PRODUCTION") {
              script.type = "text/javascript";
              script.innerHTML =
                `window.ENP_VAR = { conversion: { product: [] } };` +
                `ENP_VAR.conversion.product.push( { productCode: '${
                  BuyInfo.goodsId
                }', productName: '${BuyInfo.orderName}', price: ${
                  BuyInfo.sellingPrice / qty
                }, qty: ${qty} } );` +
                `ENP_VAR.conversion.ordCode= '${data.tradeNumber}';` +
                `ENP_VAR.conversion.totalPrice = '${BuyInfo.sellingPrice}';` +
                `ENP_VAR.conversion.totalQty = ${qty};` +
                `window.CallMtm = window.CallMtm||function(){(window.CallMtm.q=window.CallMtm.q||[]).push(arguments)};` +
                `CallMtm();`;
              document.head.appendChild(script);
            }
            dispatch(
              showAlert({
                message: "결제완료 되었습니다.",
                callback: () => {
                  const eventData = {
                    goodsMallRequestId: id,
                    couponStatus: "REGISTER",
                    eventName: "[금방금방몰-이벤트] 포인트 지급(구매확정시)",
                  };
                  postBuyEventPoint(eventData);
                  if (process.env.REACT_APP_SERVICE_TYPE === "PRODUCTION") {
                    document.head.removeChild(script);
                  }
                  navigate(`/my-page/order-history`, {
                    replace: true,
                    state: { FromSettlement: true },
                  });
                },
              }),
            );
            dispatch(hideLoading());
          } else {
            if (count === 10) {
              dispatch(
                showAlert({
                  message: "결제요청 처리중 입니다.",
                  callback: () => {
                    const eventData = {
                      goodsMallRequestId: id,
                      couponStatus: "REGISTER",
                      eventName: "[금방금방몰-이벤트] 포인트 지급(구매확정시)",
                    };
                    postBuyEventPoint(eventData);
                    if (process.env.REACT_APP_SERVICE_TYPE === "PRODUCTION") {
                      document.head.removeChild(script);
                    }
                    navigate(routes.home, {
                      replace: true,
                      state: { FromSettlement: true },
                    });
                  },
                }),
              );
              dispatch(hideLoading());
            } else {
              pendingConfirm(count + 1, id);
            }
          }
        } else {
          dispatch(
            showAlert({
              message: message,
              callback: () => {
                navigate(-1);
              },
            }),
          );
          dispatch(hideLoading());
        }
      });
    }, 1000);
  };

  return (
    <DefaultLayout title="주문/결제">
      <Flex className="keypassword_wrap" flex={1}>
        <Flex className="password_view">
          <h3>결제 비밀번호</h3>
          <p>결제 시 입력할 비밀번호를 설정해주세요.</p>
          <div className="password_num">
            <ul>
              {Array.from({ length: 6 }).map((_, i) => (
                <li
                  className={secured.count > i ? "on" : ""}
                  key={i.toString()}
                />
              ))}
            </ul>
          </div>
        </Flex>
        <div className="password_key_wrap">
          <div className="password_key">
            {keyArr.map((row, i) => {
              return (
                <ul key={i.toString()}>
                  {i === 3 && (
                    <li
                      className="ft15 on pointer"
                      onClick={() => {
                        secureActions.clearAll();
                      }}
                    >
                      전체 삭제
                    </li>
                  )}

                  {row.map((key, j) => {
                    return (
                      <li
                        key={j.toString()}
                        onClick={() => {
                          secureActions.pressKey(key);
                        }}
                      >
                        {key}
                      </li>
                    );
                  })}

                  {i === 3 && (
                    <li
                      onClick={() => {
                        secureActions.removeOne();
                      }}
                    >
                      <img src={ic_del} alt="삭제" />
                    </li>
                  )}
                </ul>
              );
            })}
          </div>
        </div>
      </Flex>
    </DefaultLayout>
  );
};

export default Settlement;
