import React from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import CheckboxClicked from "assets/icons/checkbox-clicked.webp";
import CheckboxDisabled from "assets/icons/checkbox-disabled.webp";

const CheckArea = ({ isChecked, setIsChecked }) => {
  const checkingCheckBox = () => {
    setIsChecked(!isChecked);
  };
  return (
    <CheckContainer>
      <CheckboxWrapper>
        <CheckboxIcon
          src={isChecked ? CheckboxClicked : CheckboxDisabled}
          alt="체크박스"
          onClick={checkingCheckBox}
        />
      </CheckboxWrapper>
      <CheckText>
        남은 자산은 (주)한국금거래소디지털에셋으로 귀속됨을 확인합니다.
        <HighLight>(필수)</HighLight>
      </CheckText>
    </CheckContainer>
  );
};

export default CheckArea;

const CheckContainer = styled.div`
  margin: 0px 24px;
  display: flex;
  height: 49px;
`;

const CheckboxWrapper = styled.div`
  margin: 3px 16px 0px 0px;
`;

const CheckboxIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CheckText = styled.div`
  color: ${colors.black200};
  font-size: 16px;
  letter-spacing: -0.32px;
  font-weight: 500;
  line-height: 22px;
`;

const HighLight = styled.span`
  color: ${colors.primaryOrange};
  font-size: 16px;
  margin-left: 2px;
`;
