import React from "react";
import styled, { css } from "styled-components";
import fonts from "assets/styles/fonts";

const HeaderTab = ({ year, setYear }) => {
  let date = new Date();
  const thisYear = date.getFullYear();
  const oneYearAgo = date.getFullYear() - 1;
  const twoYearAgo = date.getFullYear() - 2;
  const years = [
    {
      text: "최근 3년",
      value: "",
    },
    {
      text: (
        <>
          <NumberText>{thisYear}</NumberText>년
        </>
      ),
      value: thisYear,
    },
    {
      text: (
        <>
          <NumberText>{oneYearAgo}</NumberText>년
        </>
      ),
      value: oneYearAgo,
    },
    {
      text: (
        <>
          <NumberText>{twoYearAgo}</NumberText>년
        </>
      ),
      value: twoYearAgo,
    },
  ];

  return (
    <Container>
      <Wrap>
        {years.map((item, index) => {
          const isItemSelected = year === item.value;
          const isFirst = index === 0;
          const isLast = index === years.length - 1;

          return isItemSelected ? (
            <SelectedTab
              className="pointer"
              key={item.value}
              onClick={() => {
                setYear(item.value);
              }}
              isFirst={isFirst}
              isLast={isLast}
            >
              <SelectedText>{item.text}</SelectedText>
            </SelectedTab>
          ) : (
            <Tab
              className="pointer"
              key={item.value}
              onClick={() => {
                setYear(item.value);
              }}
              isFirst={isFirst}
              isLast={isLast}
            >
              <Text>{item.text}</Text>
            </Tab>
          );
        })}
      </Wrap>
    </Container>
  );
};

export default HeaderTab;

const Container = styled.div`
  padding: 0px 16px;
`;

const Wrap = styled.div`
  border-radius: 5px;
  display: flex;
`;

const baseTabStyles = css`
  flex: 1;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tab = styled.button`
  ${baseTabStyles}
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;

  ${(props) =>
    props.isFirst &&
    css`
      border-left: 1px solid #e6e6e6;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    `}

  ${(props) =>
    props.isLast &&
    css`
      border-right: 1px solid #e6e6e6;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    `}
`;

const SelectedTab = styled.button`
  ${baseTabStyles}
  border: 1px solid #d54d00;

  ${(props) =>
    props.isFirst &&
    css`
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    `}

  ${(props) =>
    props.isLast &&
    css`
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    `}
`;

const Text = styled.span`
  color: #999999;
  font-size: 15px;
  font-weight: normal;
`;
const SelectedText = styled.span`
  color: #f76f27;
  font-size: 15px;
  font-weight: normal;
`;

const NumberText = styled.span`
  font-family: ${fonts.spoqa};
`;
