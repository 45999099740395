import React from "react";

const NotFound = () => {
  return (
    <div>
      <h1>페이지를 찾을 수 없습니다.</h1>
    </div>
  );
};

export default NotFound;
