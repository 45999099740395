import React, { useEffect } from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import colors from "assets/styles/color";
import Cancle from "assets/icons/cancle-bottomsheet-small.webp";
import { fontWeight } from "assets/styles/fonts";
import { selectBuyInfo } from "features/buy/buySlice";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";
import { useTossPayment } from "hooks/useTossPayment";

/**
 * distanceFromTop: top으로부터의 거리
 * title: 바텀시트 상단 제목
 */
function ChoosePaymentMethod({
  state,
  closeChoosePaymentMethod,
  hideChoosePaymentMethod,
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { TossProcess } = useTossPayment();

  const HeightControlled = () => {
    let className = "";
    if (state === "Open") {
      className = "slideInTop";
    } else if (state === "Close") {
      className = "slideInBottom";
    } else {
      className = "hidden";
    }
    return className;
  };

  const handleClickHecto = () => {
    if (BuyInfo?.depositPrice === 0) {
      return navigate(routes.settlement, { state: { id: id } });
    } else {
      return navigate(routes.registerAccount, {
        state: { FromSettlement: true, id: id },
      });
    }
  };

  const handleTossProcess = () => {
    TossProcess();
  };

  return (
    <>
      {state === "Open" ? (
        <Container>
          <DisableScroll state={state} />
          <BottomSheetContainer className={HeightControlled()}>
            <Header>
              <Title>결제 수단 선택</Title>
              <CancleIcon
                onClick={closeChoosePaymentMethod}
                src={Cancle}
                alt="바텀시트 닫기"
              />
            </Header>
            <ScrollArea>
              <MainDesc>
                해당 상품은 다른 결제 수단으로도 <br /> 쉽게 결제가 가능합니다.
                <br /> 미리 결제 수단을 추가 하시겠습니까?
              </MainDesc>
              <BtnWrap>
                <PurchaseBtn onClick={handleClickHecto}>
                  간편 입금 결제
                </PurchaseBtn>
                <TossBtn onClick={handleTossProcess}>
                  기존 결제 수단으로 결제
                </TossBtn>
              </BtnWrap>
            </ScrollArea>
          </BottomSheetContainer>
          <ModalBackdrop state={state} />
        </Container>
      ) : null}
    </>
  );
}

export default ChoosePaymentMethod;

const SlideInTop = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const SlideInBottom = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
`;

const DisableScroll = createGlobalStyle`
  body {
    overflow: ${({ state }) => (state === "Open" ? "hidden" : "auto")};
    height: ${({ state }) => (state === "Open" ? "100%" : "auto")};
  }
`;

const Container = styled.div`
  ${({ theme }) => theme.flexCenter};
  height: 100%;
`;

// 모달 배경
const ModalBackdrop = styled.div`
  position: fixed;
  ${({ theme }) => theme.flexCenter};

  display: ${({ state }) => (state === "Open" ? "" : "none")};
  inset: 0;
  background-color: black;
  opacity: 0.54;
  z-index: 999;
`;

const BottomSheetContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 337px;
  z-index: 1000;
  position: fixed;
  bottom: 0;

  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  &.slideInTop {
    animation: ${SlideInTop} 0.3s ease-out forwards;
  }

  &.slideInBottom {
    animation: ${SlideInBottom} 0.2s ease-out forwards;
  }

  &.hidden {
    display: none;
  }

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 480px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 44px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  padding: 0 24px;
  ${({ theme }) => theme.flexCenter}
`;

const ScrollArea = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 24px;
  overflow-y: auto;
`;

const CancleIcon = styled.img`
  position: absolute;
  top: 0;
  right: -3px;
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

const Title = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: ${colors.primaryOrange};
`;

const MainDesc = styled.div`
  font-size: 18px;
  font-weight: ${fontWeight.bold};
  line-height: 27.2px;
  color: ${colors.black300};
`;

const BtnWrap = styled.div`
  ${({ theme }) => theme.flexColumnCenter}
  margin-top: 40px;
`;

const PurchaseBtn = styled.button`
  width: 100%;
  height: 48px;
  background-color: ${colors.primaryOrange};
  border-radius: 8px;
  color: white;
  font-weight: ${fontWeight.medium};
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.32px;
`;

const TossBtn = styled(PurchaseBtn)`
  background-color: #e9ecf5;
  color: ${colors.black200};
  font-weight: ${fontWeight.regular};
  margin-top: 8px;
`;
