import React, { useEffect, useState } from "react";
import timeIcon from "assets/icons/icon-time.webp";
import { dateToString } from "utils/utils";

function Timezone({ isSoldOut, timeData, isMinute }) {
  const [date, setDate] = useState("");

  const getFormatTime = (dateData) => {
    const date = new Date(dateData);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return `${year}.${month}.${day}, ${hour}시 ${minutes}분 기준`;
  };

  const formatDate = () => {
    if (isMinute) return getFormatTime(date);
    else return dateToString(date, "YYYY.M.D. HH");
  };

  useEffect(() => {
    let time = timeData || new Date();
    if (time && window) {
      const date = new Date(time);
      setDate(date);
    }
  }, []);
  return (
    <div
      className="time-wrap"
      style={
        isSoldOut !== undefined
          ? {
              marginTop: isSoldOut ? "49px" : "10px",
            }
          : {}
      }
    >
      <img src={timeIcon} alt="시세 기준 아이콘" className="time-icon" />
      <span className="time-string">
        <span className="spoqa">{formatDate()}</span>시 기준
      </span>
    </div>
  );
}

export default Timezone;
