import React, { useEffect, useState } from "react";
import CustomModal from "./CustomModal";
import styled from "styled-components";
import Button from "../button/Button";
import colors from "assets/styles/color";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { hideExpireAlert } from "features/expireAlert/expireAlertSlice";
import { showSnackBar } from "features/snackbar/snackbarSlice";

const ExpireAlert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { visible } = useSelector((state) => state.expireAlert);

  useEffect(() => {
    visible && navigate(routes.home);
  }, [visible]);

  const handleClickExpire = () => {
    dispatch(hideExpireAlert());
    dispatch(showSnackBar({ message: "로그아웃 되었습니다." }));
  };

  return (
    <CustomModal modalVisible={visible} modalClose={handleClickExpire}>
      <MessageWrapper>
        <Message>{"인증정보가 만료되었습니다.\n다시 로그인해주세요."}</Message>
      </MessageWrapper>
      <ButtonWrapper>
        <Button
          text="확인"
          size="sm"
          variant="round"
          onClickFn={handleClickExpire}
        />
      </ButtonWrapper>
    </CustomModal>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;

  button:last-child {
    font-weight: 700;
  }
`;

const MessageWrapper = styled.span`
  text-align: center;
  line-height: 1.5;
  white-space: pre-line;
`;

const Message = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.black200};
  letter-spacing: -0.36px;
`;

export default ExpireAlert;
