import styled from "styled-components";
import colors from "assets/styles/color";
import { useNavigate, useParams, createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideBottomSheet } from "features/bottomsheet/bottomsheetSlice";

import { Button } from "components";
import ExclamationMarkIcon from "assets/icons/icon-warning.webp";
import {
  resetBuyInfo,
  resetShopInfo,
  resetDeliveryInfo,
} from "features/buy/buySlice";
import { objToQueryString } from "utils/utils";
import { getAvailable } from "services/apis";
import { useEffect, useState } from "react";
import { routes } from "routes";
import StorageFeePayModal from "pages/storageFee/StorageFeePayModal";

const NotRefund = ({ modalClose }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [asset, setAsset] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useSelector((state) => state.auth);

  const checkAvailable = () => {
    getAvailable().then(({ data: { data, success, message } }) => {
      if (success) {
        success && setAsset(data);
      }
    });
  };

  // 보관료 납부
  const onPayStorageFee = () => {
    navigate(routes.commonSecureKeypad, {
      state: {
        purpose: "STORAGE_FEE",
        payload: {
          totalFeeKrw: `${asset?.storageFee}`,
        },
        title: "납부하기",
      },
    });
  };

  const handleClick = () => {
    dispatch(hideBottomSheet());
    dispatch(resetBuyInfo());
    dispatch(resetShopInfo());
    dispatch(resetDeliveryInfo());
    if (auth.isSigned) {
      if (asset?.storageFee > 0) {
        setModalVisible(true);
        return;
      } else {
        navigate(`/goods-purchase/${id}`);
      }
    } else {
      navigate(
        `/login${objToQueryString({ redirectURI: `/detail/${id}` })}`,
      );
    }
  };

  useEffect(() => {
    if (auth.isSigned) checkAvailable();
  }, []);

  return (
    <>
      <StorageFeePayModal
        isVisible={modalVisible}
        payType={"withdrawal"}
        totalFee={asset?.storageFee}
        onClose={() => {
          setModalVisible(false);
        }}
        onConfirm={() => {
          onPayStorageFee();
        }}
      />
      <MarkIcon src={ExclamationMarkIcon} alt="icon" />
      <MainTitle>
        구매 후 <HighLight>환불</HighLight>이 <HighLight>불가</HighLight>
        합니다.
        <br />
        거래 내용에 동의하십니까?
      </MainTitle>
      <SubText>
        금방금방몰은 매도자와 매수자의 직거래를 기반으로 합니다. 직거래 시스템에
        따라 거래취소가 불가하며 환불할 수 없습니다.
      </SubText>
      <ButtonWrapper>
        <Button
          text="취소"
          size="sm"
          variant="round"
          disabled="modal"
          onClickFn={() => modalClose()}
        />
        <Button text="동의" size="sm" variant="round" onClickFn={handleClick} />
      </ButtonWrapper>
    </>
  );
};

export default NotRefund;

const MarkIcon = styled.img`
  width: 48px;
  height: 48px;

  margin-top: 16px;
  margin-bottom: 24px;
`;

const MainTitle = styled.div`
  color: ${colors.black200};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.36px;
  line-height: 28px;
  text-align: center;
  white-space: pre-line;

  margin-bottom: 13px;
`;

const SubText = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${colors.black600};
  line-height: 24.5px;
  letter-spacing: -0.3px;
  text-align: center;
  padding: 0 18px;
  margin-bottom: 32px;
  white-space: pre-line;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexBox("row", "flex-end", "center")};

  /* 첫 버튼 margin 나누기 */
  button:first-child {
    margin-right: 8px;
  }
  /* 동의 버튼 font-weight 굵게 */
  button:last-child {
    font-weight: 700;
  }
`;

const HighLight = styled.span`
  position: relative;
  color: ${colors.primaryOrange};

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 4.5px;
    width: 100%;
    height: 9px; // 폰트 절반
    background-color: ${colors.highlightOrange};
    z-index: -1;
  }
`;
