import React from "react";
import styled from "styled-components";
import check from "assets/icons/check-green.webp";
import { useNavigate } from "react-router-dom";

const CompleteRegister = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div>
        <FlexCenter>
          <CheckIcon>
            <Image src={check} />
          </CheckIcon>
        </FlexCenter>
        <Wrap height={"24px"} />
        <FlexCenter>
          <Text>계좌가 등록되었습니다.</Text>
        </FlexCenter>
      </div>
      <Button
        onClick={() => {
          navigate(-1);
        }}
      >
        확인
      </Button>
    </Container>
  );
};

export default CompleteRegister;

const Container = styled.div`
  padding: 24px 24px 64px 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const Button = styled.button`
  height: 48px;
  background-color: #ff6e0e;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
`;

const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
`;

const CheckIcon = styled.div`
  border-radius: 50%;
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #daf2e8;
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const Text = styled.div`
  color: #222222;
  font-weight: bold;
  font-size: 21px;
`;

const Image = styled.img`
  height: 20px;
  width: 30px;
`;
