import fonts, { fontWeight } from "assets/styles/fonts";
import Flex from "components/common/flex/Flex";
import DefaultLayout from "components/layouts/DefaultLayout";
import LogoHeader from "components/templates/header/LogoHeader";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import useLocationState from "hooks/useLocationState";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getNoticeDetail } from "services/apis";
import styled from "styled-components";
import { dateToString } from "utils/utils";

const NoticeDetail = () => {
  const { noticeId } = useLocationState();
  const dispatch = useDispatch();

  const { data = {}, isLoading } = useQuery(
    ["notice", "detail", noticeId],
    () => getNoticeDetail(noticeId),
    {
      select: ({ data: { data } }) => data,
    },
  );

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoading());
    } else {
      dispatch(hideLoading());
    }
  }, [isLoading]);

  const { subject = "", content = "", createdAt } = data;

  return (
    <DefaultLayout headerVisible={false} navigationVisible={true}>
      <LogoHeader />
      <Container>
        <TitleWrap>{subject}</TitleWrap>
        <DateWrap>{dateToString(createdAt, "YYYY.MM.DD")}</DateWrap>
        <ContentWrap>
          {content.split("\n").map((s) => (
            <>
              {s}
              <br />
            </>
          ))}
        </ContentWrap>
      </Container>
    </DefaultLayout>
  );
};

const Container = styled(Flex)`
  padding: 16px;
`;
const TitleWrap = styled(Flex)`
  font-weight: bold;
  font-size: 21px;
  color: #222222;
  letter-spacing: -0.42px;
`;
const DateWrap = styled(Flex)`
  margin-top: 16px;
  font-family: ${fonts.spoqa};
  font-weight: ${fontWeight.regular};
  font-size: 14px;
  color: #999999;
`;
const ContentWrap = styled(Flex)`
  margin-top: 32px;
  font-weight: ${fontWeight.regular};
  font-size: 17px;
  letter-spacing: -0.34px;
  color: #333333;
  line-height: 29.8px;
`;

export default NoticeDetail;
