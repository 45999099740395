import React from "react";
import styled from "styled-components";
import Warning from "assets/icons/icon-warning.webp";

const WarningInfo = () => {
  return (
    <WarningInfoWrap>
      <Flex height={"25px"} margin={"12px 16px 19px 16px"}>
        <Img src={Warning} />
        <WarningHeader>보유자산에 대한 주의사항</WarningHeader>
      </Flex>
      <Wrap margin={"0px 16px"}>
        <MemberCanLeaveBox
          backColor={"#fffafa"}
          borderColor={"#fee4e4"}
          height={"78px"}
        >
          <div>
            <MainTitle color={"#f80003"}>회원탈퇴 불가능</MainTitle>
          </div>
          <Wrap height={"14px"} />
          <div>
            <SubTitle>평가(보유)금액</SubTitle>
            <HighLight backColor={"#fee4e4"}>10,000원 이상</HighLight>
          </div>
        </MemberCanLeaveBox>
        <MemberCanLeaveBox
          backColor={"#edfff7"}
          borderColor={"#e2efea"}
          height={"100px"}
        >
          <div>
            <MainTitle color={"#17955e"}>회원탈퇴 가능</MainTitle>
          </div>
          <Wrap height={"14px"} />
          <div style={{ lineHeight: "21px" }}>
            <SubTitle>평가(보유)금액</SubTitle>
            <HighLight backColor={"rgb(175 236 209)"}>10,000원 미만</HighLight>
            <SubTitle>
              은 <br /> (주)한국금거래소디지털에셋에
            </SubTitle>
            <HighLight backColor={"rgb(175 236 209)"}>양도</HighLight>
          </div>
        </MemberCanLeaveBox>
        <ListCase>
          <Li style={{ listStyle: "disc" }}>
            <MainTitle color={"#777777"}>
              보유 자산이 있는 경우는 탈퇴가 불가능합니다.
            </MainTitle>
          </Li>
          <Wrap height={"20px"} />
          <Li>
            <MainTitle color={"#777777"}>탈퇴를 희망하실 경우</MainTitle>
            <br />
            <SubTitle>
              모든 자산을 매도 및 출금 하신 후 탈퇴해 주십시오.
            </SubTitle>
          </Li>
          <Wrap height={"20px"} />
          <Li>
            <MainTitle color={"#777777"}>
              보유자산 평가금액이 10,000원 미만일 경우
            </MainTitle>
            <br />
            <SubTitle>
              (주)한국금거래소디지털에셋에 자동으로 양도 후 <br />
              탈퇴 처리 됩니다.
            </SubTitle>
          </Li>
          <Wrap height={"20px"} />
          <Li>
            <MainTitle color={"#777777"}>
              회원 탈퇴 시 등록된 개인 정보 및 거래 정보
            </MainTitle>
            <SubTitle>
              는 <br /> 관계 법령에 따라 일정 기간 보관 후 삭제됩니다.
            </SubTitle>
          </Li>
        </ListCase>
      </Wrap>
    </WarningInfoWrap>
  );
};

export default WarningInfo;
const WarningInfoWrap = styled.div`
  background-color: #fafafa;
  border: 1px solid #eaeaea;
  padding: 0px 0px 26px 0px;
  margin: 0px 24px;
  border-radius: 8px;
  //padding: 5px 16px 26px 16px;
`;
const Img = styled.img`
  height: 18px;
  width: 18px;
`;
const Flex = styled.div`
  display: flex;
  height: ${(props) => `${props.height}`};
  margin: ${(props) => `${props.margin}`};
  align-items: center;
`;
const WarningHeader = styled.span`
  color: #444444;
  margin-left: 5px;
  font-weight: bold;
  font-size: 17px;
`;

const MemberCanLeaveBox = styled.div`
  margin: 8px 0px 0px 0px;
  height: ${(props) => `${props.height}`};
  padding: 13px 0px 15px 16px;
  border: 1px solid ${(props) => `${props.borderColor}`};
  background-color: ${(props) => `${props.backColor}`};
  border-radius: 8px;
`;

const ListCase = styled.ul`
  margin: 40px 16px 0px 16px;
`;

const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
  margin: ${(props) => `${props.margin}`};
  padding: ${(props) => `${props.padding}`};
`;

const HighLight = styled.span`
  position: relative;
  color: #444444;
  font-weight: bold;
  margin-left: 3px;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 4.5px;
    width: 100%;
    height: 9px;
    background-color: ${(props) => `${props.backColor}`};
    z-index: -1;
  }
`;

const MainTitle = styled.span`
  color: ${(props) => `${props.color}`};
  font-size: 15px;
  font-weight: bold;
`;
const SubTitle = styled.span`
  font-size: 15px;
  color: #777777;
`;

const Li = styled.li`
  list-style: disc;
  line-height: 20px;

  ::marker {
    color: #777777;
  }
`;
