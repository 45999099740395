import PurchaseResult from "pages/purchase-result/components/PurchaseResult";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import { selectBuyInfo } from "features/buy/buySlice";
import { getPreApprovalInfo } from "services/apis";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "services/storageService";
import useSavePaymentInfo from "hooks/useSavePaymentInfo";

function TossFail() {
  const location = useLocation();
  const locationState = location.state;
  // 파라미터 및 쿼리 처리
  const { id, txId } = useParams(); // txId: 데이터 임시 정보 ID
  const dispatch = useDispatch();
  const [CreatePaymentInfo, UpdatePaymentInfo] = useSavePaymentInfo();
  const { BuyInfo, DeliveryInfo, ShopInfo } = useSelector(selectBuyInfo);

  useEffect(() => {
    // 페이지가 언마운트되면 실행되는 로직
    return () => {
      setSessionStorageItem("PAY_INFO_CAN_BE_CALLED", 0);
    };
  }, []);

  // txId로 조회 가능 여부
  const PAY_INFO_CAN_BE_CALLED = getSessionStorageItem(
    "PAY_INFO_CAN_BE_CALLED",
  );

  const { data: PreApprovalInfo, isLoading } = useQuery(
    ["pre-approval-info", txId],
    () =>
      getPreApprovalInfo(txId).then((res) => {
        try {
          const receivedData = res.data;
          if (receivedData.success) {
            const { data } = receivedData;
            UpdatePaymentInfo(data, dispatch);
          }
        } catch (error) {
          // 에러 처리
          console.error(error);
        }
      }),
    {
      select: (data) => data.data.data,
      enabled: !PAY_INFO_CAN_BE_CALLED || PAY_INFO_CAN_BE_CALLED === 0, // 0이거나 null일 때만 쿼리 실행
      onSuccess: () => {
        // PreApprovalInfo 로드 성공 후 실행할 코드
        const paymentInfo = CreatePaymentInfo(
          BuyInfo,
          DeliveryInfo,
          ShopInfo,
          "",
          "",
          "",
          "TOSS_QUICK",
        );
      },
    },
  );

  useEffect(() => {
    if (PAY_INFO_CAN_BE_CALLED === null || PAY_INFO_CAN_BE_CALLED === 0) {
      // 세션 스토리지의 값을 1로 설정하여 다시는 실행되지 않도록 함
      setSessionStorageItem("PAY_INFO_CAN_BE_CALLED", 1);
    }
  }, []);

  return (
    <PurchaseResult
      icon="Fail"
      title="결제 취소"
      reason={locationState?.errorMessage}
      id={id}
    />
  );
}

export default TossFail;
