import React from "react";
import ContentBox from "./ContentBox";
import styled from "styled-components";
import fonts from "assets/styles/fonts";
import { NumberFormat } from "utils/utils";

const INIT = { gram: "", krw: "", paymentPrice: "", point: "" };

const PaymentInfo = ({ payment }) => {
  const {
    gram = "",
    krw = "",
    paymentPrice = "",
    point = "",
  } = payment || INIT;
  return (
    <ContentBox title="결제 정보">
      <TitleText>사용 자산</TitleText>
      <Row>
        <Subject>포인트</Subject>
        <ValueText unit="pt">{NumberFormat(point)}</ValueText>
      </Row>
      <Row>
        <Subject>KRW</Subject>
        <ValueText unit="원">{NumberFormat(krw)}</ValueText>
      </Row>
      <Row>
        <Subject>금/은</Subject>
        <ValueText unit="g">{NumberFormat(gram)}</ValueText>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <TitleText>결제금액</TitleText>
        <ValueText unit="원">{NumberFormat(paymentPrice)}</ValueText>
      </Row>
    </ContentBox>
  );
};

const TitleText = styled.span`
  font-weight: bold;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #777777;
`;

const Row = styled.div`
  ${({ theme }) => theme.flexBox("row", "space-between")};
  margin-top: 12px;
`;

const Subject = styled.div`
  display: flex;
  font-size: 15px;
  color: #777777;
  &:before {
    display: inline-block;
    content: "";
    height: 7px;
    width: 7px;
    border: 2px solid #eaeaea;
    border-top: none;
    border-right: none;
    margin-right: 6px;
  }
`;

const ValueText = styled.span`
  font-family: ${fonts.spoqa};
  font-size: 16px;
  font-weight: bold;
  color: #444444;

  &:after {
    content: "${({ unit }) => unit}";
    display: inline-block;
    font-family: ${fonts.notoSansKR};
    font-weight: 400;
    min-width: 16px;
    margin-left: 2px;
    font-size: 15px;
  }
`;

export default PaymentInfo;
