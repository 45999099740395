import {
  setBuyInfo,
  setDeliveryInfo,
  setShopInfo,
} from "features/buy/buySlice";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "services/storageService";

const useSavePaymentInfo = () => {
  const CreatePaymentInfo = (
    BuyInfo,
    DeliveryInfo,
    ShopInfo,
    finPassword,
    tossPaymentKey,
    tossOrderId,
    depositMethod,
  ) => {
    const qty = getSessionStorageItem("qty");
    const optionValue = getSessionStorageItem("optionValue");

    const {
      goodsId,
      orderName,
      buyShopCode,
      shopId,
      useGram,
      useKrw,
      usePoint,
      buyPrice,
      sellingPrice,
      depositPrice,
      standardPrice,
      visitDate,
      isDelivery,
    } = BuyInfo;

    const {
      postCode,
      address,
      addressDetail,
      addressName,
      receiver,
      phone,
      mobile,
      memo,
      isDefault,
    } = DeliveryInfo;

    const {
      buyShopId,
      buyShopName,
      buyShopAddress,
      buyShopPhoneNumber,
      buyShopDistance,
    } = ShopInfo;

    return {
      orderName, // 상품 이름
      goodsId, // 상품 아이디
      useGram, // 가용 자산 중량
      useKrw, // 사용할 현금
      usePoint, // 사용할 포인트
      optionValue, // 선택한 옵션
      qty, // 수량
      buyShopCode, // 구매한 대리점 코드
      visitDate, // 방문 날짜
      visitTime: null,
      shopId, // 방문할 대리점 ID
      buyPrice,
      sellingPrice, // 상품 현재가
      depositPrice, // 결제 예상 금액
      standardPrice, // 상품 기준가
      depositMethod,
      tossPaymentKey,
      tossOrderId,
      delivery: {
        // 택배 배송지 정보
        postCode, // 우편번호
        address, // 주소
        addressDetail, // 상세주소
        addressName, // 배송지명
        receiver, // 수령인
        phone, // 연락처1
        mobile, // 연락처2
        memo, // 수령메모
        isDefault, // 기본 배송지로 추가
      },
      shop: {
        // 대리점 정보
        buyShopId, // 대리점 Id
        buyShopName, // 대리점 이름
        buyShopAddress, // 대리점 거리
        buyShopPhoneNumber, // 대리점 번호
        buyShopDistance, // 대리점 거리
      },
      isDelivery, // 대리점 방문, 택배 수령 여부
      finPassword, // 비밀 번호
    };
  };

  const UpdatePaymentInfo = (receivedData, dispatch) => {
    const {
      orderName,
      goodsId,
      useGram,
      usePoint,
      useKrw,
      optionValue,
      qty,
      buyShopCode,
      visitDate,
      visitTime,
      shopId,
      sellingPrice,
      depositPrice,
      standardPrice,
      buyPrice,
      isDelivery,
      depositMethod,
      delivery, // 배송 정보
      shop, // 대리점 정보
    } = receivedData;

    // 옵션 정보는 세션으로 저장
    setSessionStorageItem("qty", qty);
    setSessionStorageItem("optionValue", optionValue);

    dispatch(
      setBuyInfo({
        orderName,
        goodsId,
        useGram,
        usePoint,
        useKrw,
        buyShopCode,
        visitDate,
        visitTime,
        shopId,
        sellingPrice,
        depositPrice,
        standardPrice,
        buyPrice,
        isDelivery,
        depositMethod,
      }),
    );
    dispatch(
      setDeliveryInfo({
        postCode: delivery.postCode,
        address: delivery.address,
        addressDetail: delivery.addressDetail,
        addressName: delivery.addressName,
        receiver: delivery.receiver,
        phone: delivery.phone,
        mobile: delivery.mobile,
        memo: delivery.memo,
      }),
    );
    dispatch(
      setShopInfo({
        buyShopId: shop.buyShopId,
        buyShopName: shop.buyShopName,
        buyShopAddress: shop.buyShopAddress,
        buyShopPhoneNumber: shop.buyShopPhoneNumber,
        buyShopDistance: shop.buyShopDistance,
      }),
    );
  };

  return [CreatePaymentInfo, UpdatePaymentInfo];
};

export default useSavePaymentInfo;
