import BottomNavigation from "components/templates/BottomNavigation";
import TextHeader from "components/templates/header/TextHeader";
import Hamburger from "pages/home/components/Hamburger";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const DefaultLayout = ({
  children,
  title = "",
  headerVisible = true,
  navigationVisible = false,
  cancelCallback,
  backCallback,
}) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const handleHamburgerClick = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  // 바텀 네비게이션 없는 곳에서는 hamburger 안 보이도록 함
  useEffect(() => {
    !navigationVisible && setIsHamburgerOpen(false);
  }, [navigationVisible]);

  return (
    <Container>
      {headerVisible && (
        <TextHeader
          title={title}
          cancelCallback={cancelCallback}
          backCallback={backCallback}
        />
      )}
      <Hamburger
        navigationVisible={navigationVisible}
        isHamburgerOpen={isHamburgerOpen}
        handleHamburgerClick={handleHamburgerClick}
      />
      <ContentWrapper>{children}</ContentWrapper>
      {navigationVisible && (
        <BottomNavigation
          isHamburgerOpen={isHamburgerOpen}
          setIsHamburgerOpen={setIsHamburgerOpen}
          handleHamburgerClick={handleHamburgerClick}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default DefaultLayout;
