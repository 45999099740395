import React from "react";
import styled from "styled-components";
import rightArrow from "assets/icons/right-arrow.webp";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

const AuthenticationSecurity = () => {
  const navigate = useNavigate();
  return (
    <>
      <Title>인증/보안</Title>
      <Wrap
        onClick={() => {
          navigate(routes.selfVerification, {
            state: { purpose: "UPDATE_SECURE_PASSWORD" },
          });
        }}
      >
        <div>
          <WrapTitle>결제 비밀번호 변경</WrapTitle>
        </div>
        <div>
          <Img src={rightArrow} alt="오른쪽화살표" />
        </div>
      </Wrap>
      <Line />
      <Wrap
        onClick={() => {
          navigate(routes.changePassword);
        }}
      >
        <div>
          <WrapTitle>로그인 비밀번호 변경</WrapTitle>
        </div>
        <div>
          <Img src={rightArrow} alt="오른쪽화살표" />
        </div>
      </Wrap>
      <Bar />
    </>
  );
};

export default AuthenticationSecurity;
const Title = styled.div`
  padding: 11px 0px 3px 0px;
  height: 24px;
  font-weight: normal;
  font-size: 13px;
  color: #666666;
`;

const Wrap = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const WrapTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #222222;
`;
const Img = styled.img`
  width: 44px;
  height: 44px;
  align-self: center;
`;
const Bar = styled.div`
  height: 24px;
  background-color: #ffffff;
`;
const Line = styled.div`
  border-bottom: 1px solid #f2f2f2;
  margin: 0px 16px 0px 0px;
`;
