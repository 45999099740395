import styled from "styled-components";

import colors from "assets/styles/color";
import { NumberFormat } from "utils/utils";
import { S3_BASE_URL } from "utils/constants";
import { getSessionStorageItem } from "services/storageService";
import { useGetPrePriceByZero } from "pages/goods-detail/components/DetailBottomSheet";
import { useEffect } from "react";
import { setBuyInfo } from "features/buy/buySlice";
import { useDispatch } from "react-redux";

function OrderProduct({ detail }) {
  const optionValue = getSessionStorageItem("optionValue");
  const qty = getSessionStorageItem("qty");
  const { PrePriceByZero } = useGetPrePriceByZero();
  const { price } = PrePriceByZero || {};
  const dispatch = useDispatch();

  useEffect(() => {
    detail?.goodsName && dispatch(setBuyInfo({ orderName: detail?.goodsName }));
  }, [detail?.goodsName]);

  return (
    <Container>
      <Header>주문 상품</Header>
      <Content>
        <GoodsImage
          src={
            S3_BASE_URL +
            "/" +
            (detail?.goodsImages ? detail.goodsImages[0] : "")
          }
          alt="상품 이미지"
        />
        <InfoContainer>
          <div className="brand">{detail?.brand}</div>
          <div className="goods">{detail?.goodsName}</div>
          <OptionContainer>
            <ListItem>
              ·제품 중량
              <span>{detail?.goodsGram}g</span>
            </ListItem>
            <ListItem>
              ·제품 수량
              <span>{qty}개</span>
            </ListItem>
            {optionValue && (
              <ListItem>
                ·선택 옵션
                <span>{optionValue}</span>
              </ListItem>
            )}
          </OptionContainer>
        </InfoContainer>
      </Content>
      <Footer>
        <div>주문 금액</div>
        <div>
          <Price>{NumberFormat(price)}</Price>
          <Won>원</Won>
        </div>
      </Footer>
    </Container>
  );
}

export default OrderProduct;

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: white;
  border: 1px solid ${colors.purchaseBorder};

  margin: 16px 0;
`;

const Header = styled.div`
  width: 100%;
  height: 44px;
  padding: 10px 16px;

  border-radius: 8px 8px 0 0;

  color: ${colors.black300};
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.34px;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  border-top: 1px solid ${colors.purchaseBorder};
  padding: 16px;

  display: flex;
`;

const Footer = styled.div`
  width: 100%;
  height: 48px;
  background-color: RGB(255, 110, 14, 0.1);
  border-top: 1px solid ${colors.purchaseBorder};
  padding: 14px 16px;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
`;

const Price = styled.span`
  font-size: 18px;
  font-weight: 700;
  font-family: "SpoqaHanSansNeo";
  color: ${colors.black400};
  line-height: 0;
`;

const Won = styled.span`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
  color: ${colors.black400};
`;

const GoodsImage = styled.img`
  width: 72px;
  height: 72px;
  margin-right: 22px;
  border: 1px solid ${colors.imageBorder};
`;

const InfoContainer = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexBox("column", "flex-start", "start")}

  .brand {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.24px;
    color: ${colors.black900};
    margin-bottom: 6px;
  }

  .goods {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    color: ${colors.black300};
    margin-bottom: 13px;
  }
`;

const OptionContainer = styled.ul`
  width: 100%;
  height: auto;
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 10px 10px 0;
`;

const ListItem = styled.li`
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}

  font-size: 12px;
  letter-spacing: -0.24px;
  color: ${colors.black600};
  margin-bottom: 10px;
`;
