import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "features/modal/modalSlice";
import styled from "styled-components";
import phone from "assets/icons/phone-orange.webp";
import {
  postArsRequest,
  postArsConfirm,
  postAccountRegister,
} from "services/apis";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { signInUsingToken } from "services/authService";
import useInterval from "hooks/useInterval";

const authTimeLeft = 240;
const AuthPhone = ({
  currentStep,
  setCurrentStep,
  selectBank,
  accountNumber,
  authNo,
  reclaimAuthNo,
  setReclaimAuthNo,
}) => {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(authTimeLeft);
  const [stopInterval, setStopInterval] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [tradeNo, setTradeNo] = useState("");

  const handleClickSubmit = () => {
    confirmArs();
  };

  const handleClickReclaim = () => {
    setReclaimAuthNo(!reclaimAuthNo);
    setTimeLeft(authTimeLeft);
    setStopInterval(false);
    setAuthError(false);
  };

  const requestArs = (reClaimAuthNo) => {
    const data = {
      bankCd: selectBank,
      authNo: reClaimAuthNo ? reClaimAuthNo : authNo,
    };
    dispatch(showLoading());
    postArsRequest(data)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setTradeNo(data.trdNo);
        } else {
          dispatch(
            showAlert({
              message: message,
              icon: "Warning",
            }),
          );
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  const confirmArs = () => {
    const data = { trdNo: tradeNo };
    postArsConfirm(data).then(({ data: { success, data, message } }) => {
      if (success) {
        if (data.retCd === "00") {
          //성공일 때
          const registerData = {
            bankCd: selectBank,
            accountNumber: accountNumber,
            trdNo: data.trdNo,
          };
          dispatch(showLoading());
          postAccountRegister(registerData)
            .then(({ data: { success, data, message } }) => {
              setStopInterval(true);
              if (success) {
                signInUsingToken(data);
                setCurrentStep(currentStep + 1);
              } else {
                setAuthError(true);
                dispatch(
                  showAlert({
                    message: message,
                    icon: "Warning",
                  }),
                );
              }
            })
            .finally(() => dispatch(hideLoading()));
        } else {
          //인증 진행중 일때
          dispatch(showAlert({ message: data.outRsltMsg, icon: "Warning" }));
        }
      } else {
        setStopInterval(true);
        setAuthError(true);
        dispatch(
          showAlert({
            message: message,
            icon: "Warning",
          }),
        );
      }
    });
  };

  useInterval(
    () => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    },
    stopInterval ? null : 1000,
  );

  useEffect(() => {
    if (timeLeft === 0) {
      dispatch(
        showAlert({
          message: "인증번호가 만료되었습니다.\n 인증번호를 다시 전송해주세요.",
          icon: "Warning",
        }),
      );
    }
  }, [timeLeft]);

  useEffect(() => {
    requestArs();
  }, [authNo]);

  return (
    <Container>
      <div>
        <Info>ARS 인증</Info>
        <Title>
          전화를 받고
          <br />
          <HighLight>안내받은 번호 2자리</HighLight>를
          <br />
          입력해주세요
        </Title>
        <Wrap height={"36px"} />
        <FlexCenter>
          {/* <AuthNumber>{authNo}</AuthNumber> */}
          <Img src={phone} />
        </FlexCenter>
        <Wrap height={"16px"} />
        <FlexCenter>
          <WatingTime>대기시간</WatingTime>
          {timeLeft > -1 && (
            <Time>
              {String(parseInt(timeLeft / 60)).padStart(2, "0")} :{" "}
              {String(timeLeft % 60).padStart(2, "0")}
            </Time>
          )}
        </FlexCenter>
      </div>
      {timeLeft === 0 || authError === true ? (
        <Button
          onClick={() => {
            handleClickReclaim();
          }}
        >
          인증번호 재요청
        </Button>
      ) : (
        <Button
          onClick={() => {
            handleClickSubmit();
          }}
        >
          인증 완료
        </Button>
      )}
    </Container>
  );
};

export default AuthPhone;

const Container = styled.div`
  padding: 24px 24px 64px 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  line-height: 30px;
  font-weight: bold;
  font-size: 21px;
  color: #222222;
`;

const HighLight = styled.span`
  color: #ff6e0e;
  font-size: 21px;
`;

const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
`;

const AuthNumber = styled.div`
  border-radius: 50%;
  border: 4px solid #e0e0e0;
  height: 128px;
  width: 128px;
  color: #222222;
  font-size: 64px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const WatingTime = styled.div`
  font-weight: bold;
  font-size: 13px;
  color: #222222;
`;

const Time = styled.div`
  margin-left: 7px;
  color: #f80003;
  font-size: 14px;
  font-weight: bold;
`;

const Button = styled.button`
  height: 48px;
  background-color: #ff6e0e;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
`;

const Img = styled.img`
  width: 80px;
  height: 80px;
`;

const Info = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #ff6e0e;
  margin-bottom: 10px;
`;
