import { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectBuyInfo, setDeliveryInfo } from "features/buy/buySlice";

import { routes } from "routes";
import fonts, { fontWeight } from "assets/styles/fonts";
import Flex from "components/common/flex/Flex";
import { PhoneNumberFormat } from "utils/utils";

const DefaultDelivery = ({ AddressList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { DeliveryInfo } = useSelector(selectBuyInfo);

  const defaultAddress =
    DeliveryInfo.postCode === ""
      ? AddressList.find((el) => el.isDefault === true)
      : DeliveryInfo;

  const {
    receiver,
    address,
    addressDetail,
    addressName,
    postCode,
    phone,
    mobile,
    memo,
  } = defaultAddress;

  useEffect(() => {
    dispatch(setDeliveryInfo(defaultAddress));
  }, []);

  return (
    <Container>
      <ContentWrap>
        <NameWrap>
          {receiver}
          {addressName && " / " + addressName}
          <ChangeBtn
            onClick={() => {
              navigate(routes.shippingManagement, {
                state: { orderId: id },
              });
            }}
          >
            변경
          </ChangeBtn>
        </NameWrap>
        <Flex row>
          {!!phone && <PhoneWrap>{PhoneNumberFormat(phone)}</PhoneWrap>}
          {mobile && phone && <RowSeparator />}
          {!!mobile && <PhoneWrap>{PhoneNumberFormat(mobile)}</PhoneWrap>}
        </Flex>
        <AddressWrap>
          ({postCode}) {address} {addressDetail}
        </AddressWrap>
        <MemoWrap>
          수령방법: <span className="memo">{memo}</span>
        </MemoWrap>
      </ContentWrap>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const ContentWrap = styled(Flex)`
  padding: 16px;
`;

const NameWrap = styled.span`
  font-weight: bold;
  font-size: 15px;
  color: #333333;
  letter-spacing: -0.3px;

  ${({ theme }) => theme.flexBox("row", "space-between", "center")};
`;

const PhoneWrap = styled.span`
  margin-top: 8px;
  font-family: ${fonts.spoqa};
  font-size: 14px;
  color: #777777;
`;
const AddressWrap = styled.span`
  margin-top: 16px;
  font-size: 14px;
  color: #777777;
  line-height: 23px;
  letter-spacing: -0.28px;
`;

const MemoWrap = styled.span`
  margin-top: 16px;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #4d4d4d;
  line-height: 23px;
  letter-spacing: -0.28px;

  .memo {
    font-weight: ${fontWeight.regular};
    color: #444444;
    line-height: 22px;
  }
`;

const RowSeparator = styled.div`
  position: relative;
  width: 17px;
  &:after {
    content: "";
    position: absolute;
    border-right: 1px solid #999999;
    height: 11px;
    left: 8px;
    bottom: 2px;
  }
`;

const ChangeBtn = styled.button`
  width: 40px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #666666;

  color: #666666;
  font-size: 12px;
`;
export default DefaultDelivery;
