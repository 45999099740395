import React from "react";
import DefaultLayout from "components/layouts/DefaultLayout";
import styled from "styled-components";
import MyInfo from "./components/MyInfo";
import PaymentMethod from "./components/PaymentMethod";
import AuthenticationSecurity from "./components/AuthenticationSecurity";
import MemberWithdrawal from "./components/MemberWithdrawal";
import { useLocation } from "react-router-dom";

const ChangeUserInfo = () => {
  const location = useLocation();
  const locationState = location.state || {};
  const { myInfo } = locationState;
  return (
    <DefaultLayout title="내정보 변경" navigationVisible={true}>
      <Container>
        <MyInfo myInfo={myInfo} />
        <PaymentMethod />
        <AuthenticationSecurity />
        <MemberWithdrawal />
      </Container>
    </DefaultLayout>
  );
};

export default ChangeUserInfo;

const Container = styled.div`
  padding: 24px 0px 24px 16px;
`;
