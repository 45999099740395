import { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import { useDispatch, useSelector } from "react-redux";
import { getTermsDetail } from "services/apis";

import optionOpen from "assets/icons/option-open.webp";
import CheckboxClicked from "assets/icons/checkbox-clicked.webp";
import CheckboxDisabled from "assets/icons/checkbox-disabled.webp";
import SubDirectory from "assets/icons/sub-directory.webp";
import { setBuyInfo, selectBuyInfo } from "features/buy/buySlice";
import { openBottomSheet } from "features/bottomsheet/bottomsheetSlice";
import { BottomSheet } from "components";

function Terms() {
  const dispatch = useDispatch();
  const { BuyInfo } = useSelector(selectBuyInfo);
  const { isTermsChecked } = BuyInfo;
  const [isOpen, setIsOpen] = useState(false);
  const [term, setTerm] = useState(null);

  const checkingCheckBox = () => {
    dispatch(setBuyInfo({ isTermsChecked: !isTermsChecked }));
  };

  const handleOpenBottomSheet = () => {
    dispatch(openBottomSheet());
  };

  const TYPE = "MALL_PURCHASE";
  useEffect(() => {
    getTermsDetail(TYPE)
      .then((res) => res.data.data.content)
      .then((termData) => setTerm(termData));
  }, []);

  return (
    <AccordionContainer>
      <BottomSheet title="약관 보기" distanceFromTop="calc(100vh - 80px)">
        <PaddingSide dangerouslySetInnerHTML={{ __html: term }} />
      </BottomSheet>
      <Header>
        <CheckboxIcon
          src={isTermsChecked ? CheckboxClicked : CheckboxDisabled}
          alt="체크박스"
          onClick={checkingCheckBox}
        />
        <Text onClick={() => setIsOpen(!isOpen)}>
          쇼핑몰 구매약관에 동의합니다
        </Text>
        <OptionIcon onClick={() => setIsOpen(!isOpen)}>
          <img
            className={isOpen ? "isRotated" : "inverse"}
            src={optionOpen}
            alt="아이콘"
          />
        </OptionIcon>
      </Header>
      <Content className={isOpen ? "open" : "close"}>
        <PaddingDiv>
          <SubIcon src={SubDirectory} alt="약관" />
          <SmallText>쇼핑몰 구매약관</SmallText>
          <ViewTerms onClick={handleOpenBottomSheet}>약관보기</ViewTerms>
        </PaddingDiv>
      </Content>
    </AccordionContainer>
  );
}

export default Terms;

const CheckboxIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const Text = styled.span`
  color: ${colors.black200};
  font-size: 16px;
  letter-spacing: -0.32px;
  font-weight: 500;
  flex: 1;
  @media (max-width: 335px) {
    font-size: 15px;
  }
`;

const AccordionContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: white;
  border: 1px solid ${colors.purchaseBorder};
  margin-bottom: 48px;
`;

const Header = styled.div`
  width: 100%;
  height: 44px;
  padding: 10px 8px;

  border-radius: ${(props) => (props.isOpen ? "8px 8px 0 0" : "8px")};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const OptionIcon = styled.button`
  width: 36px;
  height: 36px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;

    &.isRotated {
      transform: rotate(180deg);
      transition: 0.3s;
    }

    &.inverse {
      transform: rotate(0deg);
      transition: 0.3s;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  border-radius: 0 0 8px 8px;
  max-height: 0;
  overflow: hidden;
  border-top: 0px solid ${colors.purchaseBorder};
  transition: max-height ease-in 0.2s, border-top ease-in 0.2s;

  &.open {
    max-height: 350px;
    border-top: 1px solid ${colors.purchaseBorder};
  }
`;

const SubIcon = styled.img`
  width: 7px;
  height: 7px;
`;

const SmallText = styled.div`
  font-size: 13px;
  letter-spacing: -0.26px;
  color: ${colors.black700};
  margin-left: 6px;
  flex: 1;
`;

const ViewTerms = styled.button`
  font-size: 12px;
  font-family: "SpoqaHanSansNeo";
  color: ${colors.black700};
  text-decoration-line: underline;
  line-height: 13px;
`;

const PaddingDiv = styled.div`
  width: 100%;
  padding: 12px 36px;
  ${({ theme }) => theme.flexBox("row", "flex-start", "center")}
`;

const PaddingSide = styled.div`
  width: 100%;
  padding: 0 16px;
`;
