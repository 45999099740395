import styled from "styled-components";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState } from "react";

import { routes } from "routes";
import { getMainBanner } from "services/apis";
import { S3_BASE_URL } from "utils/constants";
import FixedRatio from "components/common/FixedRatio";
import iconPlay from "assets/icons/icon-play.webp";
import iconPause from "assets/icons/icon-pause.webp";

const EventBanner = () => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const { data: banners = [] } = useQuery(
    "/main/banner",
    () => {
      return getMainBanner().then(({ data: { success, data } }) => {
        if (success) {
          return data;
        }
      });
    },
    { keepPreviousData: true, cacheTime: Infinity, staleTime: Infinity },
  );

  const [swiper, setSwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [pause, setPause] = useState(false);

  const handleClickPause = () => {
    setPause(!pause);
  };

  useEffect(() => {
    if (swiperRef.current !== null) {
      if (pause) {
        swiperRef.current.swiper.autoplay.stop();
      } else {
        swiperRef.current.swiper.autoplay.start();
      }
    }
  }, [pause]);

  const handleClickBanner = ({ noticeId, category, content }) => {
    if (category === "EVENT") {
      window.location.href = content;
    } else if (category === "NOTICE") {
      navigate(routes.noticeDetail, { state: { noticeId } });
    }
  };

  return (
    // <FixedRatio ratio={0.35} style={{ margin: "8px 16px 0px 16px" }}>
    <div style={{ margin: "8px 16px 0px 16px" }}>
      <Swiper
        style={{
          // position: "absolute",
          width: "100%",
        }}
        ref={swiperRef}
        onSwiper={setSwiper}
        modules={[Autoplay, Pagination]}
        loop
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        onRealIndexChange={(swiper) => {
          setSwiperIndex(swiper.realIndex);
        }}
      >
        {banners.map(({ path, ...rest }, i) => {
          return (
            <SwiperSlide
              className="pointer"
              key={i.toString()}
              onClick={() => handleClickBanner({ ...rest })}
              style={{
                objectFit: "contain",
                width: "100%",
              }}
            >
              <img
                className="banner-img"
                src={S3_BASE_URL + path}
                alt="banner"
              />
            </SwiperSlide>
          );
        })}
        {banners.length > 1 && (
          <div
            style={{
              width: "100%",
            }}
          >
            <div className="home-slider-wrap">
              <div className="home-slider-paging">
                {banners.map((_, i) => {
                  return (
                    <PagingBar
                      className="pointer"
                      active={swiperIndex === i}
                      key={i.toString()}
                      onClick={() => {
                        swiper.slideTo(i);
                      }}
                    />
                  );
                })}
              </div>
              <PauseButton
                src={pause ? iconPlay : iconPause}
                onClick={handleClickPause}
                className="pointer"
              />
            </div>
          </div>
        )}
      </Swiper>
    </div>
    // </FixedRatio>
  );
};

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: block;
`;

const PagingWrap = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: center;
  align-items: center;
`;

const PagingBar = styled.div`
  margin-right: 2.22vw;
  background: ${({ active }) => (active ? "#616161" : "#9e9e9e")};
  opacity: ${({ active }) => (active ? 1 : 0.8)};
  transition: all 0.3s ease-out;

  width: calc(100vw * ${({ active }) => (active ? 24 : 8)} / 360);
  height: calc(100vw * ${({ active }) => (active ? 4 : 2)} / 360);

  @media only screen and (min-width: 900px) {
    width: ${({ active }) => (active ? "32px" : "10.65px")};
    height: ${({ active }) => (active ? "5.3px" : "2.7px")};
    margin-right: 8px;
  }
`;

const PauseButton = styled.img`
  z-index: 1;
  width: calc(100% * 44 / 360);
`;

const Wrap = styled.div`
  width: 100%;
`;

const WrapContent = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;
export default React.memo(EventBanner);
