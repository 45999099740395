import Flex from "components/common/flex/Flex";
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import ContentBox from "./ContentBox";
import fonts, { fontWeight } from "assets/styles/fonts";
import colors from "assets/styles/color";
import { S3_BASE_URL } from "utils/constants";
import {
  putBuyConfirm,
  getBuyConfirm,
  postBuyEventPoint,
  getServiceBreak,
} from "services/apis";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { showAlert } from "features/modal/modalSlice";
import { NumberFormat } from "utils/utils";
import { useNavigate } from "react-router-dom";
import CashReceipt from "./CashReceipt";
import { CustomModal } from "components";
import { useModal } from "hooks/useModal";
import AssetPoint from "assets/icons/asset-point-large.webp";

const ProductInfo = ({ goodsTrans, refetch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    brand,
    assetType,
    goodsName,
    goodsImage,
    qty,
    totalGram,
    optionName,
    goodsStatusDescription,
    goodsTransStatus,
    goodsTransId,
    goodsId,
    paymentPrice,
  } = goodsTrans;
  const isClick = useRef(false);

  const [receiptRequest, setReceiptRequest] = useState("NOT-APPLY");
  const [modalVisible, modalOpen, modalClose] = useModal(false);
  const [transaction, setTransaction] = useState({ amount: 0, total: 0 });

  const handleClickConfirm = async () => {
    if (!isClick.current) {
      isClick.current = true;
      dispatch(showLoading());

      const serviceBreak = await getServiceBreak();
      if (serviceBreak) {
        dispatch(showAlert({ message: serviceBreak }));
        dispatch(hideLoading());
        isClick.current = false;
        return;
      }

      const data = { isCashReceipt: receiptRequest === "APPLY" ? true : false };
      putBuyConfirm(goodsTransId, data).then(
        ({ data: { success, message } }) => {
          if (success) {
            pendingConfirm(1);
          } else {
            dispatch(showAlert({ message }));
            dispatch(hideLoading());
            isClick.current = false;
          }
        },
      );
    }
  };
  const eventData = {
    transId: goodsTransId,
    couponStatus: "PAYMENT",
    eventName: "[금방금방몰-이벤트] 포인트 지급(구매확정시)",
  };

  const handlePostBuyEventPoint = (res) => {
    if (res.data.success) {
      const { amount, total } = res.data.data;
      setTransaction({ amount, total });
      modalOpen();
    }
    refetch();
    isClick.current = false;
  };
  const handleShowAlert = (message) => {
    dispatch(hideLoading());
    dispatch(
      showAlert({
        message,
        callback: () => {
          postBuyEventPoint(eventData).then(handlePostBuyEventPoint);
        },
      }),
    );
  };

  const pendingConfirm = (count) => {
    setTimeout(() => {
      getBuyConfirm(goodsTransId).then(({ data: { success } }) => {
        if (success) {
          handleShowAlert("구매확정 되었습니다.");
        } else if (count === 10) {
          handleShowAlert("구매확정 처리 중 입니다.");
        } else {
          pendingConfirm(count + 1);
        }
      });
    }, 1000);
  };

  const PurchaseConfirm = () => {
    return (
      <>
        <PointIcon src={AssetPoint} alt="포인트 아이콘" />
        <Title>구매확정 완료</Title>
        <PointTitle>
          <div className="subtitle">포인트 지급</div>
          <div className="amount">
            +{NumberFormat(transaction.amount)}
            <span className="currency">pt</span>
          </div>
        </PointTitle>
        <HoldPoint>
          <PointWrap>
            <img src={AssetPoint} alt="포인트 아이콘" />
            <div className="subtitle">사용 가능 포인트</div>
          </PointWrap>
          <div className="point">
            {NumberFormat(transaction.total)}
            <span className="currency">pt</span>
          </div>
        </HoldPoint>
        <OkBtn onClick={() => modalClose()}>확인</OkBtn>
      </>
    );
  };

  return (
    <ContentBox title={goodsStatusDescription} style={{ marginTop: 12 }}>
      <CustomModal modalVisible={modalVisible} modalClose={modalClose}>
        <PurchaseConfirm />
      </CustomModal>
      <Flex>
        <Flex row>
          <ProductImage
            className="pointer"
            src={S3_BASE_URL + "/" + goodsImage}
            alt=""
            onClick={() => {
              navigate(`/detail/${goodsId}`);
            }}
          />
          <ProductDetailWrap>
            <BrandName>{brand}</BrandName>
            <ProductName>{goodsName}</ProductName>
            {optionName && (
              <RowBetween style={{ marginTop: 16 }}>
                <DetailText>옵션명</DetailText>
                <DetailText>{optionName}</DetailText>
              </RowBetween>
            )}
            <RowBetween style={{ marginTop: !optionName ? 16 : 11 }}>
              <DetailText>결제가</DetailText>
              <DetailText>
                <Price>{NumberFormat(paymentPrice)}</Price>
                <Digit>원</Digit>
              </DetailText>
            </RowBetween>
            <RowBetween>
              <DetailText>자산</DetailText>
              <DetailText>{assetType}</DetailText>
            </RowBetween>
            <RowBetween>
              <DetailText>중량</DetailText>
              <DetailText>
                <span className="spoqa">{totalGram}</span>g
              </DetailText>
            </RowBetween>
            <RowBetween>
              <DetailText>수량</DetailText>
              <DetailText className="spoqa">{qty}</DetailText>
            </RowBetween>
          </ProductDetailWrap>
        </Flex>
        <CashReceipt
          goodsTrans={goodsTrans}
          receiptRequest={receiptRequest}
          setReceiptRequest={setReceiptRequest}
        />
        <ConfirmButton
          status={goodsTransStatus}
          onClick={() => {
            if (goodsTransStatus === "RECEIVED") {
              handleClickConfirm();
            }
          }}
        >
          구매확정
        </ConfirmButton>
      </Flex>
    </ContentBox>
  );
};

const ProductImage = styled.img`
  width: 72px;
  height: 72px;
  border: 1px solid #d6d6d6;
`;

const ProductDetailWrap = styled(Flex)`
  flex: 1;
  margin-left: 32px;
`;

const BrandName = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: #777777;
`;

const ProductName = styled.span`
  font-weight: bold;
  font-size: 15px;
  color: #444444;
  line-height: 28px;
`;

const RowBetween = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 11px;
`;

const DetailText = styled.span`
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #777777;
`;

const ConfirmButton = styled(Flex)`
  margin-top: 24px;
  height: 32px;
  cursor: ${({ status }) => (status === "RECEIVED" ? "pointer" : "")};
  background: ${({ status }) =>
    status === "RECEIVED" ? "#ffffff" : "#dddddd"};
  justify-content: center;
  align-items: center;
  color: ${({ status }) => (status === "RECEIVED" ? "#222222" : "#bbbbbb")};
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid
    ${({ status }) => (status === "RECEIVED" ? "#bdbdbd" : "#dddddd")};
`;

const Price = styled.span`
  font-family: ${fonts.spoqa};
  font-weight: bold;
  color: #444444;
`;

const Digit = styled.span`
  color: #333333;
  margin-left: 6px;
`;

// 구매확정 모달
const PointIcon = styled.img`
  width: 55px;
  height: 55px;
  margin-top: 13px;
`;

const Title = styled.h1`
  margin-top: 14px;
  font-weight: ${fontWeight.bold};
  font-size: 18px;
  line-height: 36px;
  color: ${colors.black300};
`;

const PointTitle = styled.div`
  color: ${colors.primaryOrange};
  ${({ theme }) => theme.flexCenterColumn}
  .subtitle {
    font-weight: ${fontWeight.bold};
    font-size: 14px;
    line-height: 36px;
    margin-bottom: -2px;
  }

  .amount {
    font-family: ${fonts.montserrat};
    font-weight: ${fontWeight.semibold};
    font-size: 27px;
    line-height: 28px;
    letter-spacing: -0.54px;
  }
  .currency {
    font-family: ${fonts.montserrat};
    font-weight: ${fontWeight.bold};
    font-size: 18px;
    line-height: 28px;
  }
`;

const HoldPoint = styled.div`
  background-color: ${colors.white900};
  border: 1px solid ${colors.imageBorder};
  border-radius: 8px;
  width: 100%;
  height: 60px;
  padding: 0 16px;
  margin-top: 25px;

  ${({ theme }) => theme.flexBox("row", "space-between", "center")}

  img {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }

  .subtitle {
    line-height: 22px;
    letter-spacing: 0.11px;
    color: ${colors.black500};
  }

  .point {
    font-family: ${fonts.montserrat};
    font-weight: ${fontWeight.bold};
    font-size: 15px;
    line-height: 22px;
    color: ${colors.primaryOrange};
  }

  .currency {
    font-family: ${fonts.notoSansKR};
    font-weight: ${fontWeight.regular};
    font-size: 13px;
    letter-spacing: 0.09px;
  }
`;

const PointWrap = styled.div`
  ${({ theme }) => theme.flexCenter}
`;

const OkBtn = styled.button`
  background-color: ${colors.primaryOrange};
  border-radius: 8px;
  width: 100%;
  height: 48px;
  font-weight: ${fontWeight.medium};
  font-size: 16px;
  line-height: 24.5px;
  letter-spacing: -0.32px;
  color: white;
  margin-top: 32px;
`;

export default ProductInfo;
