import axios from "axios";
import { expireAuth } from "./authService";
import { objToQueryString, dateToString } from "utils/utils";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.timeout = 5000; // 모든 요청에 대해 기본 타임아웃을 5초로 설정

// 응답 인터셉터 추가
axios.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    // ...
    return response;
  },
  (error) => {
    const status = error?.response?.status;

    if (status === 401 || status === 403) {
      expireAuth();
    }

    console.log("api error", error);
    return Promise.reject(error);
  },
);

const commonInstance = axios.create({ headers: { Authorization: "" } });

// const encryptInstance = axios.create({ headers: { Authorization: "" } });

export const setAuthorization = (authToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
};

export const initAuthorization = () => {
  axios.defaults.headers.common["Authorization"] = "";
};

export const getMarketGoods = (param) => {
  return axios.get(`/api/v1/mall/market/goods/${param}`);
};

export const getMainBanner = () => {
  return commonInstance.get("/api/v1/mall/board/banner");
};

export const getMainPopup = (param) => {
  return commonInstance.get(`/api/v1/mall/board/popup${param}`);
};

export const getMainGoodsGroups = () => {
  return commonInstance.get("/api/v1/mall/market/goods-group/top");
};

export const getMainGoodsList = () => {
  return commonInstance.get("/api/v1/mall/market/goods-group/bottom");
};

export const postFlagCheck = (param) => {
  return commonInstance.post("https://pennygold.kr/api/flag/app", param);
};

// 암호키 요청
export const getRequestKey = () => {
  return axios.get(`/api/v1/mall/e2e/request_key`, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
};

//GA Instance로 회원번호 취득
export const getUserId = (param) => {
  return axios.get(`/api/v1/mall/auth/user/mobile/adinfo${param}`, {
    headers: {
      "Content-Type": `application/json`,
    },
  });
};

/**************************************************
 * 아이디 로그인 / 회원가입
 **************************************************/

// 아이디 체크
export const getValidateLoginId = (loginId = "") => {
  return axios.get(`/api/v1/mall/auth/id/validate?loginId=${loginId}`);
};

// 비밀번호 체크
export const postValidatePassword = (param) => {
  return axios.post(`/api/v1/mall/auth/id/e2e/validate_password`, param, {
    headers: {
      "Content-Type": `application/json`,
    },
  });
};

// 아이디로 회원가입
export const postSignUpId = (param) => {
  return axios.post(`/api/v1/mall/auth/id/e2e/signup`, param, {
    headers: {
      "Content-Type": `application/json`,
    },
  });
};

// 아이디로 로그인
export const postSignInId = (param) => {
  return axios.post(`/api/v1/mall/auth/id/e2e/signin`, param, {
    headers: {
      "Content-Type": `application/json`,
    },
  });
};

// ga_instance 서버로 전송
export const postAdInfo = (param) => {
  return axios.post(`/api/v1/mall/auth/user/mobile/adinfo`, param);
};

// 본인인증 URL 조회
export const getSelfVerificationRequestId = (param) => {
  return axios.post("/api/v1/mall/verification/self/prepare", param);
};

// 본인인증 URL 조회
export const getSelfVerificationRequestIdUsingToken = (param, token) => {
  return axios.post("/api/v1/mall/verification/self/prepare", param, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// 약관 조회
export const getTerms = () => {
  return axios.get(`/api/v1/mall/shared/terms`);
};

// 약관 상세 조회
export const getTermsDetail = (type) => {
  return axios.get(`/api/v1/mall/shared/terms/${type}`);
};

// 가입 완료
export const postSelfVerificationComplete = (param) => {
  return axios.post(`/api/v1/mall/verification/self/complete`, param);
};

// token 조회
export const getAuthToken = (code) => {
  return axios.post(
    `/api/v1/mall/auth/oauth/token`,
    { code },
    { headers: { Authorization: "" } },
  );
};

// Oauth Url 조회
export const getOauthUrls = () => {
  return commonInstance.get("/api/v1/mall/auth/oauth/urls");
};

// 아이디 찾기
export const getFindAccount = (param) => {
  return axios.post(`/api/v1/mall/auth/id/e2e/find_account`, param, {
    headers: {
      "Content-Type": `application/json`,
    },
  });
};

// 비밀번호 재설정 인증번호 요청
export const putResetPassword = (param) => {
  return axios.put(`/api/v1/mall/auth/id/e2e/reset_password`, param, {
    headers: {
      "Content-Type": `application/json`,
    },
  });
};

// 비밀번호 재설정
export const postResetPassword = (param) => {
  return axios.post(`/api/v1/mall/auth/id/e2e/reset_password`, param, {
    headers: {
      "Content-Type": `application/json`,
    },
  });
};

// 비밀번호 변경
export const postChangePassword = (param) => {
  return axios.post(`/api/v1/mall/auth/id/e2e/change_password`, param, {
    headers: {
      "Content-Type": `application/json`,
    },
  });
};

/**************************************************
 * 상품 리스트
 **************************************************/

export const getGoodsCategory = () => {
  return commonInstance.get("/api/v1/mall/market/goods/category");
};

export const getGoodsCategorySearch = (param) => {
  return commonInstance.get(
    `/api/v1/mall/market/goods/category/search/${param}`,
  );
};

export const getGoodsGroup = () => {
  return commonInstance.get("/api/v1/mall/market/goods-group");
};

export const getGoodsGroupSearch = (param) => {
  return commonInstance.get(`/api/v1/mall/market/goods-group/list/${param}`);
};

export const getAllGoodsList = () => {
  return commonInstance.get("/api/v1/mall/market/goods", {
    headers: {
      "Cache-Control": 5 * 60 * 1000,
    },
  });
};

/**************************************************
 * 마이 페이지
 **************************************************/

// 주문현황 조회
export const getMypageTransSummary = () => {
  return axios.get("/api/v1/mall/my-page/trans/summary");
};

// 내 정보 조회
export const getUserInfo = () => {
  return axios.get("/api/v1/mall/my-page/info");
};

// 주문내역
export const getMyPageTrans = (param) => {
  return axios.get(`/api/v1/mall/my-page/trans/${param ? `${param}` : ""}`);
};

// 주문내역 상세
export const getMyPageTransDetail = (id) => {
  return axios.get(`/api/v1/mall/my-page/trans/${id}`);
};

// 주문내역 상세 배송지 변경
export const putMyPageTransDelivery = (id, data) => {
  return axios.put(`/api/v1/mall/my-page/trans/${id}/delivery`, data);
};

// 이메일 등록 및 수정
export const postEmail = (data) => {
  return axios.post("api/v1/mall/my-page/email", data);
};

// 보유 자산 조회
export const getUserAsset = () => {
  return axios.get("api/v1/mall/asset");
};

// 탈퇴 가능 자산 여부 조회
export const getLeaveAsset = () => {
  return axios.get(`/api/v1/mall/auth/leave/asset`);
};

// 탈퇴 사유 조회
export const getLeaveReason = () => {
  return axios.get(`/api/v1/mall/auth/leave/reason`);
};

// 탈퇴 본인 인증 sms 발송
export const postLeaveVerifyCode = () => {
  return axios.post(`/api/v1/mall/auth/leave/verifyCode`);
};

// 탈퇴 본인 인증 sms 검증
export const patchLeavePreviousVerify = (smsVerifyCode) => {
  return axios.patch(`/api/v1/mall/auth/leave/previousVerify`, {
    smsVerifyCode,
  });
};

// 탈퇴 후 재가입 불가일
// export const getEnvConfig = (param) => {
//   return axios.get(`/api/v1/mall/shared/code/envconfig/${param}`);
// };

// 인증 회원 탈퇴 요청
export const patchLeaveUser = (leaveReason) => {
  return axios.patch(`/api/v1/mall/auth/leave`, { leaveReason });
};

// 구매확정
export const putBuyConfirm = (id, data) => {
  return axios.put(`/api/v1/mall/market/buy/${id}/confirm`, data);
};

// 구매확정 조회
export const getBuyConfirm = (id) => {
  return axios.get(`/api/v1/mall/market/buy/${id}/confirm`);
};

// 이벤트 포인트 지급 (회원가입 완료)
export const postEventPoint = (data, token) => {
  return axios.post(`/api/v1/mall/market/buy/point-reg`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// 이벤트 포인트 지급 (결제요청, 구매확정)
export const postBuyEventPoint = (data) => {
  return axios.post(`/api/v1/mall/market/buy/point-reg`, data);
};

// 보관료 납부
export const payStorageFee = (data) => {
  return axios.post("/api/v1/mall/storagefee/pay", data);
};

/**************************************************
 * 구매하기 페이지
 **************************************************/

// 추천 매장 조회
export const getMyShop = () => {
  return axios.get(`/api/v1/mall/market/buy/myshop`);
};

// 가용자산 조회
export const getAvailable = () => {
  return axios.get("api/v1/mall/market/buy/available");
};

// 결제 예상금액 조회
export const getPreApproval = (data) => {
  return axios.get(`api/v1/mall/market/buy/pre-price${data}`);
};

// 매장 조회
export const getShop = (goodsId, param) => {
  return axios.get(`api/v1/mall/market/buy/${goodsId}/shop${param}`);
};

// 가입 시 추천 대리점 목록 조회
export const getNearbyShop = (shopSearchIf) => {
  const query = shopSearchIf || "";
  return axios.get(`api/v1/mall/shared/shop`);
};

// 결제 요청
export const putPurchase = (param, data) => {
  return axios.put(`/api/v1/mall/market/buy/approval/${param}`, data);
};

// 운영시간 조회
export const getHours = (goodsId, shopId, date) => {
  return axios.get(
    `api/v1/mall/market/buy/${goodsId}/shop/hours/${shopId}?workYm=${date}`,
  );
};

export const getEnvConfig = (config, asset) => {
  return commonInstance.get(
    `/api/v1/mall/shared/code/envconfig${objToQueryString({
      config,
      asset,
    })}`,
  );
};

export const getServiceBreak = async () => {
  const from = await getEnvConfig("FROM_STOP_TIME").then(
    ({
      data: {
        success,
        data: { value },
      },
    }) => {
      if (success) {
        return `${value.substring(0, 2)}:${value.substring(
          3,
          5,
        )}:${value.substring(6, 8)}`;
      } else {
        return "00:00:00";
      }
    },
  );
  const to = await getEnvConfig("TO_STOP_TIME").then(
    ({
      data: {
        success,
        data: { value },
      },
    }) => {
      if (success) {
        return `${value.substring(0, 2)}:${value.substring(
          3,
          5,
        )}:${value.substring(6, 8)}`;
      } else {
        return "00:00:00";
      }
    },
  );
  const now = dateToString(new Date(), "HH:mm:SS");

  // 브레이크 중 날이 바뀔 경우 (자정을 사이에 둘 경우)
  if (from > to) {
    if (from <= now || now < to) {
      return `지금은 서비스 점검 시간 입니다.\n(서비스 점검 : ${from} ~ ${to})`;
    }
  }

  // 동일한 날 브레이크가 끝날 경우
  if (from < to) {
    if (from <= now && now < to) {
      return `지금은 서비스 점검 시간 입니다.\n(서비스 점검 : ${from} ~ ${to})`;
    }
  }

  return "";
};

//결제확인
export const getBuyApproval = (id) => {
  return axios.get(`api/v1/mall/market/buy/approval/${id}`);
};

// 주문 정보 임시 저장
export const putPreApproval = (data) => {
  return axios.put(`api/v1/mall/market/buy/pre-approval`, data);
};

// 임시 주문 정보 조회
export const getPreApprovalInfo = (txId) => {
  return axios.get(`api/v1/mall/market/buy/pre-approval/${txId}`);
};

// 몰 결제 방식 조회
export const getMallDepositDir = (amount) => {
  return axios.get(`api/v1/mall/market/buy/approval/dir/${amount}`);
};

/**************************************************
 * 공지사항 조회
 **************************************************/

// 공지사항 상세 조회
export const getNoticeDetail = (noticeId) => {
  return axios.get(`/api/v1/mall/board/notice/${noticeId}`);
};

/**************************************************
 * 간편결제
 **************************************************/

// ARS인증 요청
export const postArsRequest = (data) => {
  return axios.post(`/api/v1/mall/payment/ars/request`, data);
};

// ARS인증 확인
export const postArsConfirm = (data) => {
  return axios.post(`/api/v1/mall/payment/ars/confirm`, data);
};

// 계좌등록
export const postAccountRegister = (data) => {
  return axios.post(`/api/v1/mall/payment/account/register`, data);
};

// 계좌해지
export const postAccountCancel = (data) => {
  return axios.post(`/api/v1/mall/payment/account/cancel`, data);
};

// 계좌점유확인
export const postAccountCheck = (data) => {
  return axios.post(`/api/v1/mall/payment/account/check`, data);
};

// 계좌 은행 조회
export const getBankList = () => {
  return axios.get(`/api/v1/mall/payment/bank`);
};

/**************************************************
 * 주소록 관리
 **************************************************/

// 유저 주소록 목록 조회
export const getAddressList = () => {
  return axios.get(`/api/v1/mall/my-page/address`);
};

// 유저 주소록 조회
export const getAddressDetail = (id) => {
  return axios.get(`/api/v1/mall/my-page/address/${id}`);
};

// 유저 주소록 등록
export const postRegisterAddress = (data) => {
  return axios.post(`/api/v1/mall/my-page/address`, data);
};

// 유저 주소록 수정
export const putEditAddress = (id, data) => {
  return axios.put(`/api/v1/mall/my-page/address/${id}`, data);
};

// 유저 주소록 삭제
export const deleteAddress = (id) => {
  return axios.delete(`/api/v1/mall/my-page/address/${id}`);
};
