import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { routes } from "routes";
import { Accordion } from "components";
import Flex from "components/common/flex/Flex";
import { PhoneNumberFormat } from "utils/utils";
import fonts, { fontWeight } from "assets/styles/fonts";
import { selectBuyInfo, setDeliveryInfo } from "features/buy/buySlice";

const DeliveryInfo = ({ delivery, canModifyDelivery }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { DeliveryInfo } = useSelector(selectBuyInfo);

  const defaultDeliveryInfo =
    DeliveryInfo?.postCode === "" ? delivery : DeliveryInfo;
  const {
    receiver,
    addressName,
    postCode,
    address,
    addressDetail,
    phone,
    mobile,
    memo,
    isDefault,
  } = defaultDeliveryInfo;

  const dispatch = useDispatch();
  const handleEdit = () => {
    dispatch(setDeliveryInfo({ isEdit: true }));
    navigate(routes.shippingManagement, {
      state: { orderId: id },
    });
  };

  return (
    <Container>
      <Accordion title="배송지">
        <ContentWrap>
          <NameWrap>
            <div>
              {receiver}
              {addressName && " / " + addressName}
              {isDefault && <DefaultTag> (기본)</DefaultTag>}
            </div>
            {canModifyDelivery && (
              <ChangeBtn onClick={handleEdit}>변경</ChangeBtn>
            )}
          </NameWrap>
          <Flex row>
            {!!phone && <PhoneWrap>{PhoneNumberFormat(phone)}</PhoneWrap>}
            {mobile && phone && <RowSeparator />}
            {!!mobile && <PhoneWrap>{PhoneNumberFormat(mobile)}</PhoneWrap>}
          </Flex>
          <AddressWrap>
            ({postCode}) {address} {addressDetail}
          </AddressWrap>
          {!!memo && (
            <MemoWrap>
              수령방법: <span className="memo">{memo}</span>
            </MemoWrap>
          )}
        </ContentWrap>
      </Accordion>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 16px;
`;

const ContentWrap = styled(Flex)`
  padding: 16px;
`;

const NameWrap = styled.span`
  font-weight: bold;
  font-size: 15px;
  color: #333333;
  letter-spacing: -0.3px;

  ${({ theme }) => theme.flexBox("row", "space-between", "center")};
`;

const DefaultTag = styled.span`
  font-weight: ${fontWeight.regular};
  color: #ea792b;
`;
const PhoneWrap = styled.span`
  margin-top: 8px;
  font-family: ${fonts.spoqa};
  font-size: 14px;
  color: #777777;
`;
const AddressWrap = styled.span`
  margin-top: 16px;
  font-size: 14px;
  color: #777777;
  line-height: 23px;
  letter-spacing: -0.28px;
`;

const MemoWrap = styled.span`
  margin-top: 16px;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #4d4d4d;
  line-height: 23px;
  letter-spacing: -0.28px;

  .memo {
    font-weight: ${fontWeight.regular};
    color: #444444;
    line-height: 22px;
  }
`;

const RowSeparator = styled.div`
  position: relative;
  width: 17px;
  &:after {
    content: "";
    position: absolute;
    border-right: 1px solid #999999;
    height: 11px;
    left: 8px;
    bottom: 2px;
  }
`;
const ChangeBtn = styled.button`
  width: 40px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #666666;

  color: #666666;
  font-size: 12px;
  cursor: pointer;
`;

export default DeliveryInfo;
