import React, { useState } from "react";
import DefaultLayout from "components/layouts/DefaultLayout";
import LeaveFirst from "./components/LeaveFirst";
import LeaveSecond from "./components/LeaveSecond";
import LeaveThird from "./components/LeaveThird";
import LeaveReason from "./components/LeaveReason";

const stepList = [
  { step: "WARNINGINFO" },
  { step: "LEAVEREASON" },
  { step: "AUTH" },
  { step: "COMPLETE" },
];
const Leave = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [leaveReason, setLeaveReason] = useState("");
  return (
    <DefaultLayout title="회원탈퇴" navigationVisible={false}>
      {stepList[currentStep].step === "WARNINGINFO" && (
        <LeaveFirst currentStep={currentStep} setCurrentStep={setCurrentStep} />
      )}
      {stepList[currentStep].step === "LEAVEREASON" && (
        <LeaveReason
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          leaveReason={leaveReason}
          setLeaveReason={setLeaveReason}
        />
      )}
      {stepList[currentStep].step === "AUTH" && (
        <LeaveSecond
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          leaveReason={leaveReason}
        />
      )}

      {stepList[currentStep].step === "COMPLETE" && <LeaveThird />}
    </DefaultLayout>
  );
};
export default Leave;
