import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ScrollToTop } from "components";
import InfoTab from "./components/InfoTab";
import ImageSlider from "./components/ImageSlider";
import ProductInfo from "./components/ProductInfo";
import DetailHeader from "./components/DetailHeader";
import PurchaseButton from "./components/PurchaseButton";
import DetailBottomSheet from "./components/DetailBottomSheet";

import colors from "assets/styles/color";
import { getMarketGoods } from "services/apis";
import MetaTag from "utils/MetaTag";
import { NumberFormat } from "utils/utils";

import gobackIcon from "assets/icons/goback-circle.webp";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import ReactGA from "react-ga4";
import { S3_BASE_URL } from "utils/constants";

export const useGetGoodsDetail = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["marketGoods", id],
    () => getMarketGoods(id),
    {
      select: (data) => data.data.data,
      onSuccess: (result) => {
        const totalList = queryClient.getQueryData("goodsAllList");

        let goodsItem =
          totalList &&
          totalList?.goods &&
          totalList?.goods.find((item) => item?.goodsId === result?.goodsId);

        if (
          goodsItem &&
          (goodsItem.standardPrice !== result.standardPrice ||
            goodsItem.sellingPrice !== result.sellingPrice)
        ) {
          queryClient.setQueryData("goodsAllList", (oldData) =>
            oldData
              ? {
                  time: oldData.time,
                  goods: oldData?.goods.map((item) =>
                    item?.goodsId === result?.goodsId
                      ? {
                          ...item,
                          standardPrice: result.standardPrice,
                          sellingPrice: result.sellingPrice,
                        }
                      : item,
                  ),
                }
              : oldData,
          );
        }
      },
      onError: (error) => {
        console.log(error);
      },
    },
  );

  useEffect(() => {
    if (data) {
      setDetail(data);
    }
  }, [data]);

  return { detail, isLoading };
};

export default function Detail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { detail, isLoading } = useGetGoodsDetail();
  const [showBackButton, setShowBackButton] = useState(false);

  const [activeTab, setActiveTab] = useState("INFO");
  // 이벤트 없을 때는 resize +252임. /
  const [resize, setResize] = useState(window.innerWidth + 312);
  const contentRef = useRef(null);

  const onContentClick = () => {
    contentRef.current.scrollIntoView({ behavior: "smooth" });
    setActiveTab("REVIEW");
  };

  const handleTab = (e) => {
    setActiveTab(e);
  };

  const handleResize = () => {
    setResize(window.innerWidth + 312);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY > resize && !showBackButton) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  }, [window.scrollY, showBackButton]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (detail?.goodsName) {
      ReactGA.gtag("event", "view_item", {
        currency: "KRW",
        value: detail?.sellingPrice,
        items: [{ item_id: detail?.goodsId, item_name: detail?.goodsName }],
      });
      ReactGA.gtag("event", "add_to_cart", {
        currency: "KRW",
        value: detail?.sellingPrice,
        items: [{ item_id: detail?.goodsId, item_name: detail?.goodsName }],
      });
    }
    // 상품 상세 페이지 진입 시 스크립트 추가
    if (process.env.REACT_APP_SERVICE_TYPE === "PRODUCTION") {
      const script = document.createElement("script");
      if (detail) {
        script.type = "text/javascript";
        script.innerHTML =
          `window.ENP_VAR = { collect: {} };` +
          `ENP_VAR.collect.productCode = ${detail.goodsId};` +
          `ENP_VAR.collect.productName = "${detail.goodsName}";` +
          `ENP_VAR.collect.price = ${detail.standardPrice};` +
          `ENP_VAR.collect.dcPrice = ${detail.sellingPrice};` +
          `ENP_VAR.collect.soldOut = "${String(detail.isSoldOut)
            .replace("false", "N")
            .replace("true", "Y")}";` +
          `ENP_VAR.collect.imageUrl = "${S3_BASE_URL}/${detail?.goodsImages?.[0]}";` +
          `window.CallMtm = window.CallMtm||function(){(window.CallMtm.q=window.CallMtm.q||[]).push(arguments)};` +
          `CallMtm();`;
        document.head.appendChild(script);
      }
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [detail]);

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoading());
    } else {
      dispatch(hideLoading());
    }
  }, [isLoading]);

  return (
    <>
      {!isLoading && detail?.goodsName && (
        <MetaTag
          title={`${detail?.goodsName} | 금방금방몰`}
          description={`${detail?.goodsName}. ${NumberFormat(
            detail?.sellingPrice,
          )}원 ; 한국금거래소 디지털에셋`}
          keywords="골드바 가격, 골드바, 순금, 24k,돌반지"
          image={detail?.goodsImages && detail?.goodsImages[0]}
          imageWidth={1080}
          imageHeight={1080}
        />
      )}
      {showBackButton && (
        <>
          <ScrollToTop />
          <GoBackButton onClick={handleGoBack}>
            <img src={gobackIcon} alt="뒤로가기" />
          </GoBackButton>
        </>
      )}
      <DetailHeader showBackButton={showBackButton} />
      <ImageSlider data={detail?.goodsImages} isSoldOut={detail?.isSoldOut} />
      <ProductInfo
        onRatingClick={onContentClick}
        goodsId={detail?.goodsId}
        brand={detail?.brand}
        goodsName={detail?.goodsName} // 상품명
        standardPrice={detail?.standardPrice} // 할인 전
        sellingPrice={detail?.sellingPrice} // 할인 후
        isSoldOut={detail?.isSoldOut} // 품절 여부
        eventPoint={detail?.eventPoint} // 이벤트 포인트 안내
      />
      <div className="prdDetail">
        <Bar />
        <div className="vreview-summary-widget"></div>
      </div>
      <Bar />
      <div className="infoTabWrap" ref={contentRef}></div>
      <InfoTab
        activeTab={activeTab}
        handleTab={(e) => {
          setActiveTab(e);
        }}
        goodsId={detail?.goodsId}
        comment={detail?.comment} // 상품 상세 정보 텍스트
        goodsName={detail?.goodsName} // 상품명
        goodsInfos={detail?.goodsInfos} // 기본 정보
        goodsDetailImages={detail?.goodsDetailImages} // 제품 설명 이미지
        goodsDetailInfoImage={detail?.goodsDetailInfoImage} // 상세 정보 이미지
      />
      <PurchaseButton
        isSoldOut={detail?.isSoldOut}
        goodsName={detail?.goodsName} // 상품명
        standardPrice={detail?.standardPrice} // 할인 전
        sellingPrice={detail?.sellingPrice} // 할인 후
        goodsImage={detail?.goodsImages}
      />
      <DetailBottomSheet
        goodsOptionsInfos={detail?.goodsOptionsInfos}
        isSoldOut={detail?.isSoldOut}
      />
    </>
  );
}

const GoBackButton = styled.button`
  position: fixed;
  bottom: 64px;
  left: 0;

  width: 66px;
  height: auto;
  z-index: 200;
  cursor: pointer;

  img {
    width: 100%;
  }

  @media (min-width: 900px) {
    left: calc(50% - 240px);
  }
`;

const Bar = styled.div`
  width: 100%;
  height: 12px;
  background-color: ${colors.Bar};
`;
