import { v4 as uuidv4 } from "uuid";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadTossPayments } from "@tosspayments/payment-sdk";

import { putPreApproval } from "services/apis";
import useUserInfoQuery from "./queries/user";
import { selectBuyInfo } from "features/buy/buySlice";
import { showAlert } from "features/modal/modalSlice";
import useSavePaymentInfo from "./useSavePaymentInfo";

export const useTossPayment = () => {
  const dispatch = useDispatch();
  const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;

  const { data: userInfo } = useUserInfoQuery();
  const { BuyInfo, DeliveryInfo, ShopInfo } = useSelector(selectBuyInfo);
  const [CreatePaymentInfo] = useSavePaymentInfo();
  const paymentInfo = CreatePaymentInfo(
    BuyInfo,
    DeliveryInfo,
    ShopInfo,
    "",
    "",
    "",
    "TOSS_QUICK",
  );

  // 결제 정보 임시 저장
  const PutPreApproval = useMutation(
    () => putPreApproval(paymentInfo),
    ["pre-approval"],
    {},
  );

  /**
   *
   * @param {*} id 상품 goodsId
   * @param {*} txId 서버 임시 저장 정보 ID
   */
  const requestTossPayment = async (txId) => {
    try {
      const paymentInfo = {
        amount: BuyInfo?.depositPrice,
        orderId: uuidv4(),
        orderName: BuyInfo?.orderName,
        customerName: userInfo?.name,
        successUrl: `${window.location.origin}/toss-success/${BuyInfo?.goodsId}/${txId}`,
        failUrl: `${window.location.origin}/toss-fail/${BuyInfo?.goodsId}/${txId}`,
        useEscrow: true, // 230912 에스크로 필수
      };
      await loadTossPayments(clientKey).then((tossPayments) => {
        tossPayments.requestPayment("계좌이체", paymentInfo);
      });
    } catch (err) {
      dispatch(showAlert({ message: err.message }));
    }
  };

  /**
   * @description 임시 저장 후, 토스 퀵계좌결제창 실행
   */
  const TossProcess = async () => {
    try {
      const response = await PutPreApproval.mutateAsync();
      const { success, data } = response.data;
      if (success) {
        // txId 전달
        requestTossPayment(data);
      } else {
        dispatch(showAlert({ message: response.data.message }));
      }
    } catch (error) {
      dispatch(showAlert({ message: error?.response?.data?.message }));
    }
  };

  return {
    requestTossPayment,
    TossProcess,
  };
};
