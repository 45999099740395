import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import Flex from "components/common/flex/Flex";
import DefaultLayout from "components/layouts/DefaultLayout";
import colors from "assets/styles/color";
import fonts from "assets/styles/fonts";
import routes from "routes";
import ic_store from "assets/images/ic_store.svg";
import ic_more_up from "assets/images/ic_more_up.svg";
// import { updateSignUp } from "../../redux/auth/SignUpReducer";
import UsingShopDialog from "./UsingShopDialog";
import { ButtonBase } from "@mui/material";
import { openBottomSheet } from "features/bottomsheet/bottomsheetSlice";

export default function AuthRegisterShop() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locationState = location.state || {};

  const [isVisible, setIsVisible] = useState(false);
  const [recommandShop, setRecommandShop] = useState({
    id: 0,
    name: "",
  });

  const register = () => {
    navigate("/auth/sign-up/register-email", {
      state: {
        ...locationState,
        recommandShopId: recommandShop.id,
      },
    });
  };

  const { id, name } = recommandShop;

  return (
    <DefaultLayout title="가입 경로">
      <UsingShopDialog
        isVisible={isVisible}
        onCloseModal={() => {
          setIsVisible(false);
        }}
        onSelectShop={(shop) => {
          setRecommandShop(shop);
        }}
        purpose={"SIGNUP"}
      />
      <Label>금방금방을 가입 경로를 알려주세요.</Label>
      <Button
        onClick={() => {
          setIsVisible(true);
          dispatch(openBottomSheet());
        }}
        className={`${id && id !== 1 ? "selected" : ""}`}
        // className={`selected`}
        style={{}}
      >
        <ButtonLabel>
          <LabelIcon>
            <img src={ic_store} alt="icon_store" />
          </LabelIcon>
          <div>
            <Title>
              {id && id !== 1 ? name : "금방금방을 추천해 준 대리점이 있어요"}
            </Title>
            <SubTitle>
              대리점 방문, 대리점 지인 추천, 대리점 광고/블로그 등
            </SubTitle>
          </div>
        </ButtonLabel>
      </Button>
      <Button
        onClick={() => {
          setRecommandShop({
            id: 1,
            name: "본사",
          });
        }}
        className={`${id && id == 1 ? "selected" : ""}`}
      >
        <ButtonLabel>
          <LabelIcon>
            <img src={ic_more_up} alt="icon_more_up" />
          </LabelIcon>
          <div>
            <Title>추천 대리점이 없어요.</Title>
            {/* <span className={classes.subTitle}>지인추천, 광고 등</span> */}
          </div>
        </ButtonLabel>
      </Button>
      {/* <Text font={fonts.notoSansKRMedium} className={classes.label1}>
        추천 매장이 없으면 &quot;추천 매장 찾기&quot; 후<br />
        &quot;금방금방 본사&quot;를 선택해주세요
      </Text> */}

      {/* {id && (
        <ShopItemSimple {...recommandShop} shopName={name} className="margin" />
      )} */}
      <Footer>
        {/* <Button
          onClick={() => history.push(routes.authFindShop)}
          label="추천 매장 찾기"
        /> */}
        <NextBtn
          onClick={register}
          className={`${!id ? "disabled" : ""}`}
          // className={`disabled`}
          disabled={!id}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            다음
          </span>
        </NextBtn>
      </Footer>
    </DefaultLayout>
  );
}

const Label = styled.span`
  font-family: "Noto Sans KR";
  padding: 22px 23px 23px 23px;
  color: #333;
  font-size: 18px;
  margin-bottom: 43px;
`;

const Bottom = styled(Flex)`
  position: absolute;
  bottom: 50;
  left: 30;
  width: calc(100% -60px);
`;

const Button = styled(ButtonBase)`
  background-color: #fff;
  border: 1px solid #000 !important;
  margin: 0 20px 18px !important;
  justify-content: flex-start !important;
  border-radius: 8px !important;
  padding: 11px 20px !important;
  height: 68px;
  font-family: "Noto Sans KR";
  &.selected {
    border: 2px solid #fe8b3d !important;
  }
`;

const NextBtn = styled(ButtonBase)`
  background-color: #fe8b3d !important;
  border-radius: 8px !important;
  padding: 23px 0px 22px 0px !important;
  &.disabled {
    background-color: #fff !important;
    border: 1px solid #8d939d !important;
    color: #bbbbbb;
  }
`;

const ButtonLabel = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  font-family: "Noto Sans KR";
`;

const LabelIcon = styled.div`
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  padding-bottom: 2px;
`;

const SubTitle = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
`;

const Footer = styled(Flex)`
  position: absolute;
  bottom: 50px;
  left: 30px;
  width: calc(100% - 60px);
`;
