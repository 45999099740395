import Flex from "components/common/flex/Flex";
import React from "react";
import styled from "styled-components";
import checkActive from "assets/icons/check-active.webp";
import checkInactive from "assets/icons/check-disactive.webp";

const CashReceipt = ({ goodsTrans, receiptRequest, setReceiptRequest }) => {
  const { goodsTransStatus, cashReceipt } = goodsTrans;

  const handleClickReceipt = (value) => {
    setReceiptRequest(value);
  };

  return (
    <Flex row style={{ marginTop: 24, justifyContent: "space-between" }}>
      <Title>현금영수증 발급신청</Title>
      <TabWrap>
        <TabButton
          side="left"
          value="APPLY"
          receiptRequest={receiptRequest}
          goodsTransStatus={goodsTransStatus}
          cashReceipt={cashReceipt}
          onClick={() => {
            if (goodsTransStatus === "RECEIVED") {
              handleClickReceipt("APPLY");
            }
          }}
        >
          <CheckIcon
            src={
              goodsTransStatus === "RECEIVED"
                ? receiptRequest === "APPLY"
                  ? checkActive
                  : checkInactive
                : goodsTransStatus === "CONFIRMED"
                ? cashReceipt === "발급요청"
                  ? checkActive
                  : checkInactive
                : checkInactive
            }
            alt="신청"
            width={16}
          />
          <span>신청</span>
        </TabButton>
        <TabButton
          side="right"
          value="NOT-APPLY"
          receiptRequest={receiptRequest}
          goodsTransStatus={goodsTransStatus}
          cashReceipt={cashReceipt}
          onClick={() => {
            if (goodsTransStatus === "RECEIVED") {
              handleClickReceipt("NOT-APPLY");
            }
          }}
        >
          <CheckIcon
            src={
              goodsTransStatus === "RECEIVED"
                ? receiptRequest === "NOT-APPLY"
                  ? checkActive
                  : checkInactive
                : goodsTransStatus === "CONFIRMED"
                ? cashReceipt !== "발급요청"
                  ? checkActive
                  : checkInactive
                : checkInactive
            }
            alt="미신청"
            width={16}
          />
          <span>미신청</span>
        </TabButton>
      </TabWrap>
    </Flex>
  );
};

const TabWrap = styled(Flex)`
  flex-direction: row;
  flex: 1.2;
`;
//goodsTransStatus가 CONFIRMED(구매확정)일 때, cashReceipt가 발급요청이건 아니건 다 표시를 해줘야 한다.
//goodsTransStatus가 RECEIVED(상품수령)일 때, receiptRequest가 바뀔때마다 표시.
//goodsTransStatus가 그 외일때는 비활성화 표시.
const TabButton = styled(Flex)`
  flex: 1;
  flex-direction: row;
  padding: 8px 16px;
  height: 32px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  // white-space: nowrap;
  border: 1px solid ${({
    receiptRequest,
    value,
    goodsTransStatus,
    cashReceipt,
  }) =>
    goodsTransStatus === "RECEIVED"
      ? receiptRequest === value
        ? "#ffb382"
        : "#dbdbdb"
      : goodsTransStatus === "CONFIRMED"
      ? (cashReceipt === "발급요청" && value === "APPLY") ||
        (!cashReceipt && value === "NOT-APPLY")
        ? "#ffb382"
        : "#dbdbdb"
      : "#dbdbdb"};
  color: ${({ receiptRequest, value, goodsTransStatus, cashReceipt }) =>
    goodsTransStatus === "RECEIVED"
      ? receiptRequest === value
        ? "#444444"
        : "#bbbbbb"
      : goodsTransStatus === "CONFIRMED"
      ? (cashReceipt === "발급요청" && value === "APPLY") ||
        (!cashReceipt && value === "NOT-APPLY")
        ? "#444444"
        : "#bbbbbb"
      : "#bbbbbb"};
  border-top-${({ side }) => side}-radius: 8px;
  border-bottom-${({ side }) => side}-radius: 8px;
`;
const CheckIcon = styled.img`
  width: 8px;
  margin-right: 3px;
`;

const Title = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #333333;
`;

export default CashReceipt;
