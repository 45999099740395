import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { routes } from "routes";
import colors from "assets/styles/color";
import font, { fontWeight } from "assets/styles/fonts";
import { getUserAsset } from "services/apis";
import useUserInfoQuery from "hooks/queries/user";
import { NumberFormat } from "utils/utils";

import assetWallet from "assets/icons/asset-wallet.webp";
import optionOpen from "assets/icons/option-open.webp";
import assetGold from "assets/icons/asset-gold.webp";
import assetSilver from "assets/icons/asset-silver.webp";
import assetPoint from "assets/icons/asset-point-large.webp";
import assetKrw from "assets/icons/asset-krw.webp";

const UserInfo = () => {
  const navigate = useNavigate();
  const { data: userData } = useUserInfoQuery();
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(54);

  const [assets, setAssets] = useState([
    { key: "acceptableKrw", icon: assetKrw, label: "원화", currency: "원" },
    {
      key: "acceptablePoint",
      icon: assetPoint,
      label: "포인트",
      currency: "pt",
    },
    { key: "acceptableGold", icon: assetGold, label: "금", currency: "g" },
    { key: "acceptableSilver", icon: assetSilver, label: "은", currency: "g" },
  ]);

  const { data: userAsset = [] } = useQuery("userAsset", () => {
    return getUserAsset().then(({ data: { success, data } }) => {
      if (success) {
        return data;
      }
    });
  });

  useEffect(() => {
    if (userAsset) {
      setAssets((prevAssets) =>
        prevAssets.map((asset) => ({
          ...asset,
          amount: userAsset[asset.key],
        })),
      );
    }
  }, [userAsset]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
    const resizeObserver = new ResizeObserver(() => {
      setContentHeight(contentRef.current?.offsetHeight);
    });
    resizeObserver.observe(contentRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const handleClickInfo = () => {
    navigate(routes.mypageChangeInfo);
  };

  const handleClickMyAssets = () => {
    setIsOpen(!isOpen);
  };

  const MyAsset = () => {
    return (
      <AssetsList isOpen={isOpen}>
        {assets.map((asset, index) => (
          <AssetItem key={index}>
            <AssetIcon src={asset.icon} alt={`${asset.label} 아이콘`} />
            <AssetLabel>{asset.label}</AssetLabel>
            <AssetAmount>
              <span className="amount">{NumberFormat(asset.amount)}</span>
              <span className="currency">{asset.currency}</span>
            </AssetAmount>
          </AssetItem>
        ))}
      </AssetsList>
    );
  };

  return (
    <Container>
      <UserInfoBox>
        <UserNameWrap>
          <Greet>안녕하세요!</Greet>
          <Name>
            <UserName>{userData?.name}</UserName>
            <SubName>님</SubName>
          </Name>
        </UserNameWrap>
        <RightSide onClick={() => handleClickInfo()}>
          <ButtonTitle>내 정보 변경</ButtonTitle>
        </RightSide>
      </UserInfoBox>
      <AssetsAccordion
        isOpen={isOpen}
        onClick={handleClickMyAssets}
        height={contentHeight}
      >
        <Header>
          <TitleWrap isOpen={isOpen}>
            <img className="wallet" src={assetWallet} alt="자산 이미지" />
            <span>사용가능한 자산</span>
          </TitleWrap>
          <img
            className={`arrow ${isOpen ? "isRotated" : "inverse"}`}
            src={optionOpen}
            alt="자산 화살표"
          />
        </Header>
        <ContentContainer ref={contentRef}>
          {isOpen && <MyAsset />}
        </ContentContainer>
      </AssetsAccordion>
    </Container>
  );
};

export default UserInfo;

const Container = styled.div`
  padding: 24px 16px;
`;

const UserInfoBox = styled.div`
  ${({ theme }) => theme.flexBox("row", "space-between", "flex-end")}
  height: auto;
`;

const RightSide = styled.button`
  cursor: pointer;
  border: 1px solid ${colors.imageBorder};
  border-radius: 16px;
  width: 83px;
  height: 32px;
`;

const ButtonTitle = styled.span`
  color: ${colors.black700};
  font-size: 12px;
  letter-spacing: -0.24px;
`;

const Name = styled.div`
  display: flex;
  ${({ theme }) => theme.flexBox("row", "flex-end", "flex-start")}
  color: ${colors.black300};
`;

const UserName = styled.span`
  font-weight: ${fontWeight.bold};
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.4px;
`;

const Greet = styled.div`
  color: ${colors.black300};
  font-weight: ${fontWeight.regular};
  font-size: 13px;
  letter-spacing: -0.26px;
  line-height: 27px;
`;
const SubName = styled.span`
  font-weight: ${fontWeight.regular};
  font-size: 19px;
  letter-spacing: -0.4px;
  line-height: 27px;
`;

const UserNameWrap = styled.div`
  align-self: center;
`;

// 사용 가능한 자산
const AssetsAccordion = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.white500};
  background-color: ${colors.whiteC};

  display: flex;
  flex-direction: column;

  margin-top: 24px;

  overflow: hidden;
  transition: height 0.3s ease;
  height: ${({ isOpen, height }) => (isOpen ? height + 54 : 54)}px;

  .arrow {
    width: 36px;
    height: 36px;
  }
  .isRotated {
    transform: rotate(180deg);
    transition: 0.3s;
  }

  .inverse {
    transform: rotate(0deg);
    transition: 0.3s;
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 0 4px;
  ${({ theme }) => theme.flexBox("row", "space-between")}
`;

const TitleWrap = styled.div`
  ${({ theme }) => theme.flexCenter}

  .wallet {
    width: 44px;
    height: 44px;
  }

  span {
    font-weight: ${({ isOpen }) => isOpen && fontWeight.medium};
    font-size: 17px;
    line-height: 54px;
    letter-spacing: -0.34px;
    color: ${colors.black300};
  }
`;

const ContentContainer = styled.div`
  height: auto;
`;

const AssetsList = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 8px 16px;
  margin: 0px 16px 16px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${colors.white500};
`;

const AssetItem = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

const AssetIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const AssetLabel = styled.span`
  flex-grow: 1;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: ${colors.black400};
`;

const AssetAmount = styled.span`
  color: ${colors.black300};
  font-size: 15px;
  line-height: 22px;

  .amount {
    font-weight: ${fontWeight.bold};
    font-family: ${font.spoqa};
    letter-spacing: 0.1px;
  }

  .currency {
    font-size: 13px;
    letter-spacing: 0.09px;
    text-align: right;
  }
`;
