import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSigned: false,
  name: "",
  userId: null,
  socialId: null,
  roles: [],
  version: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action) => {
      return { isSigned: true, ...action.payload };
    },
    signOut: () => {
      return { ...initialState };
    },
  },
});

export const { signIn, signOut } = authSlice.actions;

export default authSlice.reducer;
