import styled from "styled-components";
import { useQueryClient, useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "components";
import colors from "assets/styles/color";
import { getMyShop } from "services/apis";
import { setBuyInfo, selectBuyInfo } from "features/buy/buySlice";

const useGetMyShop = () => {
  const { data: MyShopCode, isLoading } = useQuery(
    "my-shop",
    () => {
      return getMyShop().then(({ data }) => {
        if (data.success) {
          return data.data;
        } else {
          // 추천 매장이 없으면
          return { code: "", registerPath: "", shopType: "" };
        }
      });
    },
    // 포커스 및 마운트 때 refetch 되지 않도록 함.
    { refetchOnWindowFocus: false, refetchOnMount: false },
  );
  return { MyShopCode, isLoading };
};

function ShopCode() {
  const dispatch = useDispatch();
  const { MyShopCode, isLoading } = useGetMyShop();
  const { buyShopCode } = useSelector(selectBuyInfo).BuyInfo;
  const [readOnlyStatus, setReadOnlyStatus] = useState(true);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!isLoading) {
      if (buyShopCode !== null) {
        // 전역상태에 값 있으면 그 값으로 바꾸기
        setInputValue(buyShopCode);
      } else {
        dispatch(setBuyInfo({ buyShopCode: MyShopCode?.code }));
        setInputValue(MyShopCode?.code);
      }
      matchShopCode();
    }
  }, [MyShopCode, isLoading]);

  // 변경될 때는 local State를 변경하도록 함.
  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length > 5) {
      e.preventDefault();
      return;
    }
    setInputValue(value);
  };

  // inputValue가 있으면서, 전역상태랑 다르면 dispatch하기
  const handleBlur = async () => {
    dispatch(setBuyInfo({ buyShopCode: inputValue }));
  };

  const matchShopCode = () => {
    const { code, registerPath, shopType } = MyShopCode;
    if (code) {
      if (registerPath === "MALL" && shopType === "HEAD_OFFICE") {
        // 몰가입자이면서, 추천 코드가 본사이면 수정 가능함
        setReadOnlyStatus(false);
      } else {
        // 몰가입자면서, 추천코드가 본사가 아니거나,
        // 앱가입자면서, 추천코드가 있거나
        setReadOnlyStatus(true);
      }
    } else {
      // 추천 코드가 없으면 수정 가능함
      setReadOnlyStatus(false);
    }
  };

  return (
    <Accordion
      marginBottom="16px"
      title="구매한 대리점 코드 입력"
      defaultOpen={!!buyShopCode}
    >
      <PaddingDiv>
        <StyledInput
          type="number"
          value={inputValue}
          placeholder="대리점 코드를 입력하세요."
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={readOnlyStatus}
        />
      </PaddingDiv>
    </Accordion>
  );
}

export default ShopCode;

const PaddingDiv = styled.div`
  padding: 16px;
`;

const StyledInput = styled.input`
  width: 100%;
  border: 1px solid ${colors.inputBorder};
  padding: 8px;

  &::placeholder {
    font-size: 14px;
    letter-spacing: -0.28px;
    color: ${colors.black900};
  }
`;
