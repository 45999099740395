const useScript = () => {
  const naverScript = (transitionType, value) => {
    const checkType = document.getElementById(`type_${transitionType}`);
    const fixScript = document.getElementById("fix-script");

    let script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.src = "//wcs.naver.net/wcslog.js";
    script1.id = `type_${transitionType}`;

    let script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.innerHTML = `
          if (!wcs_add) var wcs_add={};
          wcs_add["wa"] = "s_2cb1acbe1257";
          var _nasa={};
          if (window.wcs ) {
            _nasa["cnv"] = wcs.cnv("${transitionType}","${value}");
            wcs_do(_nasa);
          }
        `;
    script2.id = `type_${transitionType}`;

    const removeAllScriptsById = (id) => {
      const scripts = document.querySelectorAll('script[id="' + id + '"]');
      scripts.forEach(function (script) {
        script.parentNode.removeChild(script);
      });
    };

    if (!value && value !== 0) {
      removeAllScriptsById(`type_${transitionType}`);
    } else {
      if (!checkType) {
        fixScript.parentNode.insertBefore(script1, fixScript);
        fixScript.parentNode.insertBefore(script2, fixScript);
      }
    }
    return { script1, script2 };
  };

  const googleTagScript = (value, transactionId) => {
    let script = document.createElement("script");
    script.innerHTML = `
      gtag('event', 'conversion', {
        'send_to': 'AW-11272049191/RRA6CNOVr-sYEKek9_4p',
        'value': ${value},
        'currency': 'KRW',
        'transaction_id': '${transactionId}'
      });
    `;
    script.id = `ads_purchase`;

    const removeAllScriptsById = (id) => {
      const scripts = document.querySelectorAll('script[id="' + id + '"]');
      scripts.forEach(function (script) {
        script.parentNode.removeChild(script);
      });
    };
    if (!value && value !== 0) {
      removeAllScriptsById(`ads_purchase`);
    } else {
      document.head.appendChild(script);
    }
    return { script };
  };
  return { naverScript, googleTagScript };
};

export default useScript;
