import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DefaultLayout from "components/layouts/DefaultLayout";
import styled from "styled-components";
import cancel from "assets/icons/cancel.webp";
import { validateEmail } from "utils/utils";
import { useNavigate } from "react-router-dom";
import { postEmail } from "services/apis";
import useUserInfoQuery from "hooks/queries/user";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { showAlert } from "features/modal/modalSlice";

const EditEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useUserInfoQuery();
  const checkEmail = data?.email ? true : false;
  const [email, setEmail] = useState(data?.email || "");
  const [editEmail, setEditEmail] = useState("");
  const [validation, setValidation] = useState(false);

  const handleClickSubmit = () => {
    if (validation) {
      dispatch(showLoading());
      postEmail({ email: checkEmail ? editEmail : email })
        .then(({ data: { success, message, data } }) => {
          if (success) {
            if (checkEmail) {
              setEmail(data);
              dispatch(showAlert({ message: "이메일이 수정되었습니다." }));
            } else {
              dispatch(showAlert({ message: "이메일이 등록되었습니다." }));
            }
          } else {
            dispatch(showAlert({ message }));
          }
        })
        .finally(() => {
          dispatch(hideLoading());
          navigate(-1);
        });
    }
  };

  useEffect(() => {
    if (checkEmail) {
      setValidation(validateEmail(editEmail));
    } else {
      setValidation(validateEmail(email));
    }
  }, [email, editEmail]);

  return (
    <DefaultLayout title="이메일" navigationVisible={false}>
      <Container>
        <div>
          <Header>이메일 등록</Header>
          <Guide>이메일을 입력해주세요.</Guide>
          <Bar height={"24px"} />
          <InputBox checkEmail={checkEmail}>
            <div>
              {checkEmail ? (
                <InputHeader>나의 이메일</InputHeader>
              ) : (
                <InputHeader>이메일 주소</InputHeader>
              )}
            </div>
            <Flex>
              <Input
                placeholder="이메일을 입력하세요."
                value={email}
                disabled={checkEmail}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {email !== "" && !checkEmail && (
                <CancelImg
                  src={cancel}
                  alt="cancel"
                  onClick={() => setEmail("")}
                />
              )}
            </Flex>
          </InputBox>
          <Bar height={"18px"} />
          {checkEmail && (
            <InputBox>
              <div>
                <InputHeader>변경할 이메일 주소</InputHeader>
              </div>
              <Flex>
                <Input
                  placeholder="이메일을 입력하세요."
                  value={editEmail}
                  onChange={(e) => {
                    setEditEmail(e.target.value);
                  }}
                />
                {editEmail !== "" && (
                  <CancelImg
                    src={cancel}
                    alt="cancel"
                    onClick={() => setEditEmail("")}
                  />
                )}
              </Flex>
            </InputBox>
          )}
        </div>
        <Button
          validation={validation}
          onClick={() => {
            handleClickSubmit();
          }}
        >
          {checkEmail ? "변경" : "등록"}
        </Button>
      </Container>
    </DefaultLayout>
  );
};

export default EditEmail;

const Container = styled.div`
  padding-bottom: 64px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Header = styled.div`
  margin: 20px 0px 10px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.28px;
  color: #ff6e0e;
`;

const Guide = styled.div`
  padding-left: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 21px;
  color: #222222;
  line-height: 30px;
  letter-spacing: -0.42px;
`;

const Bar = styled.div`
  height: ${(props) => `${props.height}`};
`;
const InputBox = styled.div`
  padding: 16px 0px 0px 15px;
  border: 1px solid ${(props) => `${props.checkEmail ? "#dbdbdb" : "#656667"}`};
  border-radius: 8px;
  margin: 0px 24px;
  background-color: ${(props) => `${props.checkEmail && "#eeeeee"}`};
`;
const InputHeader = styled.span`
  font-weight: normal;
  font-size: 12px;
  color: #666666;
  letter-spacing: -0.24px;
`;
const Input = styled.input`
  height: 47px;
  flex: 1;
  font-size: 16px;
`;
const Button = styled.button`
  height: 48px;
  cursor: ${(props) => (props.validation ? "pointer" : "")};
  background-color: ${(props) => (props.validation ? "#ff6e0e" : "#dddddd")};
  margin: 0px 24px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.32px;
  line-height: 20px;
  color: ${(props) => (props.validation ? "#ffffff" : "#bbbbbb")};
`;

const CancelImg = styled.img`
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
