import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import downArrow from "assets/icons/down-arrow.webp";
import { useLocation, useNavigate } from "react-router-dom";

const Sort = ({ selectSort, setSelectSort }) => {
  return (
    <Container>
      <DropButton selectSort={selectSort} setSelectSort={setSelectSort} />
    </Container>
  );
};
const DropButton = ({ selectSort, setSelectSort }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state || {};
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleClickSelect = (e) => {
    setSelectSort(e.target.value);
    navigate(location.pathname, {
      replace: true,
      state: { ...locationState, status: e.target.value },
    });
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      if (!event.target.closest(".drop-button")) {
        setIsDropdownOpen(false);
      }
    }
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className="drop-button"
      style={{ width: "100%", position: "relative" }}
    >
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        style={{ height: "100%" }}
      >
        <DropSort>
          <SortName>
            {selectSort
              ?.replace("ALL", "전체")
              ?.replace("PAYMENT_COMPLETED", "결제 완료")
              ?.replace("PREPARING", "상품 준비중")
              ?.replace("ARRIVED", "대리점 도착")
              ?.replace("DELIVERY", "배송중")
              ?.replace("RECEIVED", "상품 수령")
              ?.replace("CONFIRMED", "구매 확정")}
          </SortName>
          <DropIcon src={downArrow} alt="아래화살표" />
        </DropSort>
      </button>
      {isDropdownOpen && (
        <DropDownList className="dropdown-content">
          <SortButton
            value="ALL"
            onClick={handleClickSelect}
            select={selectSort === "ALL" ? true : false}
          >
            전체
          </SortButton>
          <SortButton
            value="PAYMENT_COMPLETED"
            onClick={handleClickSelect}
            select={selectSort === "PAYMENT_COMPLETED" ? true : false}
          >
            결제 완료
          </SortButton>
          <SortButton
            value="PREPARING"
            onClick={handleClickSelect}
            select={selectSort === "PREPARING" ? true : false}
          >
            상품 준비중
          </SortButton>
          <SortButton
            value="ARRIVED"
            onClick={handleClickSelect}
            select={selectSort === "ARRIVED" ? true : false}
          >
            대리점 도착
          </SortButton>
          <SortButton
            value="DELIVERY"
            onClick={handleClickSelect}
            select={selectSort === "DELIVERY" ? true : false}
          >
            배송중
          </SortButton>
          <SortButton
            value="RECEIVED"
            onClick={handleClickSelect}
            select={selectSort === "RECEIVED" ? true : false}
          >
            상품 수령
          </SortButton>
          <SortButton
            value="CONFIRMED"
            onClick={handleClickSelect}
            select={selectSort === "CONFIRMED" ? true : false}
            confirm
          >
            구매 확정
          </SortButton>
        </DropDownList>
      )}
    </div>
  );
};
export default Sort;

const Container = styled.div`
  width: 100%;
  height: 56px;
  background-color: ${colors.white};
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  text-align: end;
`;

const DropSort = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`;

const SortName = styled.span`
  font-size: 14px;
  color: #777777;
  font-family: NotoSansKR;
`;

const DropIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const SortButton = styled.button`
  cursor: pointer;
  width: 100%;
  padding: 10px 16px 10px 31px;
  text-align: left;
  height: 40px;
  color: ${(props) =>
    props.select ? "#e76e26" : props.confirm ? "#888888" : "#222222"};
  font-weight: bold;
  font-size: 14px;
  font-family: NotoSansKR;
`;

const DropDownList = styled.div`
  position: absolute;
  width: 128px;
  margin-top: 8px;
  right: 2px;
  z-index: 10;
  background-color: #ffffff;
  padding: 8px 0px;
  border-radius: 10px;
  box-shadow: #cdc9c9 1px 1px 10px 0px;
`;
