import React from "react";
import { ButtonBase } from "@mui/material";
import styled from "styled-components";

import colors from "assets/styles/color";
import fonts from "assets/styles/fonts";
import Location from "assets/icons/location-black.webp";
import Phone from "assets/icons/phone-black.webp";
import Flex from "components/common/flex/Flex";
import Radio from "components/common/radio/Radio";
// import Radio from "../radio/Radio";

export default function ShopItemSelect({
  onClick,
  list,
  shopName,
  address,
  shopId,
  addressDetail,
  shopPhone,
  distance,
  onCheckedChange,
  checked,
  noRadio,
  shopType,
}) {
  const fontWeight = shopType === "HEAD_OFFICE" ? "bold" : "normal";
  return (
    <Root style={{ ...(list && { paddingBottom: "0px" }) }}>
      <CheckButton
        value={checked}
        onChange={(e) => {
          onCheckedChange(e);
        }}
      />

      <CommonButton disabled={!Boolean(onClick)} onClick={onClick}>
        <Flex
          row
          style={{
            alignItems: "center",
          }}
        >
          <Label style={{ fontWeight }}>{shopName}</Label>
          {!!distance && (
            <Flex row style={{ alignItems: "center" }}>
              <Li src={Location} />
              <Distance>{parseFloat(distance)}km</Distance>
            </Flex>
          )}
        </Flex>
        <Address style={{ fontWeight }}>{address}</Address>
      </CommonButton>
      <ButtonBase
        onClick={() => {
          if (shopPhone) {
            window.location.href = "tel:" + shopPhone;
          } else {
            alert("매장 전화번호가 등록되지 않았습니다.");
          }
        }}
      >
        <Ci src={Phone} />
      </ButtonBase>
    </Root>
  );
}

const Root = styled(Flex)`
  flex-direction: row;
  padding: 18px 13px;
  justify-content: flex-start !important;
  /* align-items: center; */
`;

const CheckButton = styled(Radio)`
  padding-right: 10px;
  align-self: stretch;
  align-items: flex-start;
  margin-top: 3px;
`;

const CommonButton = styled(ButtonBase)`
  display: flex !important;
  flex-direction: column !important;
  flex: 1 !important;
  justify-content: flex-end !important;
  /* align-items: stretch; */
  word-break: keep-all;
`;

const Label = styled.span`
  font-family: "Noto Sans KR";
  margin-right: 10px;
  color: #333;
  text-align: left !important;
  font-size: 17px;
`;

const Li = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  object-fit: contain;
`;

const Distance = styled.span`
  color: #333;
  line-height: 1;
  font-size: 12px;
`;

const Address = styled.span`
  font-size: 14px;
  color: #333;
  margin-top: 5px;
  text-align: left;
`;

const Ci = styled.img`
  width: 26px;
  height: 26px;
  object-fit: contain;
`;
