import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import timeIcon from "assets/icons/icon-time.webp";
import downArrow from "assets/icons/down-arrow.webp";
import fonts from "assets/styles/fonts";
import { useLocation, useNavigate } from "react-router-dom";
import Timezone from "components/common/Timezone";

const SortArea = ({ selectSort, time }) => {
  const getFormatTime = (dateData) => {
    const date = new Date(dateData);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return `${year}.${month}.${day}, ${hour}시 ${minutes}분 기준`;
  };

  return (
    <div className="sort-container">
      <DropButton selectSort={selectSort} />
      {time && <Timezone isMinute={true} timeData={time} />}
    </div>
  );
};
const DropButton = ({ selectSort }) => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleClickSelect = (e) => {
    navigate(pathname, {
      replace: true,
      state: { ...state, selectSort: e.target.value },
    });
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      if (!event.target.closest(".drop-button")) {
        setIsDropdownOpen(false);
      }
    }
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <div className="drop-button">
      <button onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <div className="drop-sort">
          <div>
            <span className="sort-name">
              {selectSort
                ?.replace("LATEST", "최신순")
                ?.replace("LOWPRICE", "낮은 가격순")
                ?.replace("HIGHPRICE", "높은 가격순")}
            </span>
          </div>
          <img className="drop-icon" src={downArrow} alt="아래화살표" />
        </div>
      </button>
      {isDropdownOpen && (
        <DropDownList className="dropdown-content">
          <SortButton
            value="LATEST"
            onClick={handleClickSelect}
            select={selectSort === "LATEST" ? true : false}
          >
            최신순
          </SortButton>
          <SortButton
            value="LOWPRICE"
            onClick={handleClickSelect}
            select={selectSort === "LOWPRICE" ? true : false}
          >
            낮은 가격순
          </SortButton>
          <SortButton
            value="HIGHPRICE"
            onClick={handleClickSelect}
            select={selectSort === "HIGHPRICE" ? true : false}
          >
            높은 가격순
          </SortButton>
        </DropDownList>
      )}
    </div>
  );
};

export default SortArea;

const SortContainer = styled.div`
  width: 100%;
  height: 48px;
  background-color: ${colors.white};
  display: flex;
  padding: 18px 16px 16px 16px;
  align-items: center;
  justify-content: space-between;
`;

const DropSort = styled.div`
  display: flex;
  cursor: pointer;
`;

const SortName = styled.span`
  font-size: 14px;
  color: #777777;
  height: 20px;
  font-family: NotoSansKR;
`;

const DropIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-top: 2px;
`;

const TimeSection = styled.div`
  display: flex;
  align-items: center;
`;

const TimeIcon = styled.img`
  width: 11px;
  margin-right: 4px;
`;

const Time = styled.span`
  color: #999999;
  font-size: 13px;
  font-family: ${fonts.spoqa};
  letter-spacing: -0.26px;
`;

const SortButton = styled.button`
  width: 100%;
  padding: 10px 0px;
  text-align: left;
  height: 40px;
  cursor: pointer;
  color: ${(props) => (props.select ? "#e76e26" : "#777777")};
  font-weight: ${(props) => (props.select ? "bold" : "normal")};
  font-size: 14px;
  font-family: NotoSansKR;
  padding: 0px 14px;
`;

const DropDownList = styled.div`
  position: absolute;
  height: 136px;
  width: 96px;
  margin-top: 16px;
  z-index: 10;
  background-color: #ffffff;
  padding: 8px 0px;
  border-radius: 10px;
  box-shadow: #cdc9c9 1px 1px 10px 0px;
`;
