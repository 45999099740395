import React, { useState, useEffect } from "react";
import styled from "styled-components";

import upIcon from "assets/icons/up-circle.webp";

function ScrollToTop() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const ToTop = () => {
    return <img src={upIcon} alt="상단이동" />;
  };

  return (
    <>
      {showButton && (
        <ToTopButton onClick={handleClick}>
          <ToTop />
        </ToTopButton>
      )}
    </>
  );
}

export default ScrollToTop;

const ToTopButton = styled.button`
  position: fixed;
  bottom: 64px;
  right: 0;
  cursor: pointer;
  width: 66px;
  height: auto;

  z-index: 200;
  cursor: pointer;
  img {
    width: 100%;
  }

  @media (min-width: 900px) {
    right: calc(50% - 240px);
  }
`;
