import React from "react";
import styled from "styled-components";
import colors from "assets/styles/color";
import { Accordion } from "components";
import { NumberFormat } from "utils/utils";
import AssetGold from "assets/icons/asset-gold.webp";
import AssetSilver from "assets/icons/asset-silver.webp";
import AssetKRW from "assets/icons/asset-krw.webp";
import AssetPoint from "assets/icons/asset-point.webp";

const MyAsset = ({ userAsset }) => {
  return (
    <Container>
      <Accordion title="나의 보유자산">
        <Wrap>
          <InstantDiscount>
            <FlexCenter>
              <IconImg src={AssetGold} />
              <GrayText>금</GrayText>
            </FlexCenter>
            <FlexCenter>
              <AssetPrice>{NumberFormat(userAsset.gold)}</AssetPrice>
              <Unit>g</Unit>
            </FlexCenter>
          </InstantDiscount>
          <Line />
          <InstantDiscount>
            <FlexCenter>
              <IconImg src={AssetSilver} />
              <GrayText>은</GrayText>
            </FlexCenter>
            <FlexCenter>
              <AssetPrice>{NumberFormat(userAsset.silver)}</AssetPrice>
              <Unit>g</Unit>
            </FlexCenter>
          </InstantDiscount>
          <Line />
          <InstantDiscount>
            <FlexCenter>
              <IconImg src={AssetKRW} />
              <GrayText>원화</GrayText>
            </FlexCenter>
            <FlexCenter>
              <AssetPrice>{NumberFormat(userAsset.krw)}</AssetPrice>
              <Unit>원</Unit>
            </FlexCenter>
          </InstantDiscount>
          <Line />
          <InstantDiscount>
            <FlexCenter>
              <IconImg src={AssetPoint} />
              <GrayText>포인트</GrayText>
            </FlexCenter>
            <FlexCenter>
              <AssetPrice>{NumberFormat(userAsset.point)}</AssetPrice>
              <Unit>pt</Unit>
            </FlexCenter>
          </InstantDiscount>
        </Wrap>
      </Accordion>
    </Container>
  );
};

export default MyAsset;

const Container = styled.div`
  margin: 0px 24px;
`;

const Wrap = styled.div`
  margin: 0px 16px;
`;

const InstantDiscount = styled.div`
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}
  height: 32px;
`;

const GrayText = styled.span`
  font-size: 14px;
  color: ${colors.black400};
`;

const Line = styled.div`
  border: 1px solid #eaeaea;
`;

const IconImg = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 8px;
`;

const AssetPrice = styled.div`
  color: #333333;
  font-weight: bold;
  font-size: 16px;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;
const Unit = styled.div`
  color: #333333;
  font-size: 14px;
  font-weight: normal;
`;
