import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { routes } from "routes";
import { Button } from "components";
import colors from "assets/styles/color";
import { NumberFormat } from "utils/utils";
import Error from "assets/icons/icon-error.webp";

function ChangePriceModal({ prev, next, modalClose }) {
  const id = useParams();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const handleNavigatePurchase = () => {
    auth.roles.includes("ROLE_BANKACCOUNT_VERIFICATION_MALL")
      ? navigate("/settlement", { state: { id: id } })
      : navigate(routes.registerAccount);
  };

  return (
    <FlexColumnCenter>
      <ErrorIcon src={Error} alt="에러 아이콘" />
      <ErrorMsg>시장가 변동으로 상품가 변경</ErrorMsg>
      <Title>변경된 가격으로</Title>
      <Title>결제하시겠습니까?</Title>
      <PriceWrapper>
        <PrevPrice>
          이전 가격
          <div>
            <span className="prev">{NumberFormat(prev)}</span>
            <Won>원</Won>
          </div>
        </PrevPrice>
        <NextPrice>
          변경된 가격
          <div>
            <span className="next">{NumberFormat(next)}</span>
            <Won className="red">원</Won>
          </div>
        </NextPrice>
      </PriceWrapper>
      <ButtonWrapper>
        <Button
          size="sm"
          variant="round"
          disabled="modal"
          text="취소"
          onClickFn={() => modalClose()}
        />

        <PurchaseBtn onClick={handleNavigatePurchase}>
          <Deco>
            <span>변경된 가격</span>으로
          </Deco>
          결제 진행
        </PurchaseBtn>
      </ButtonWrapper>
    </FlexColumnCenter>
  );
}

export default ChangePriceModal;

const FlexColumnCenter = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexCenterColumn}
`;
const ErrorIcon = styled.img`
  width: 48px;
  height: 48px;
`;

const ErrorMsg = styled.div`
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.24px;
  border-bottom: 1px solid #febbbb;
  color: #f93a3c;
  height: 16px;
  margin: 12px 0 16px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.36px;
  color: ${colors.black200};
  margin-bottom: 8px;
`;

const PriceWrapper = styled.div`
  width: 100%;
  margin-top: 28px;
  ${({ theme }) => theme.flexCenterColumn}
`;

const PrevPrice = styled.div`
  width: 90%;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.24px;
  color: ${colors.black900};
  padding: 12px 16px;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")}

  .prev {
    font-family: "SpoqaHanSansNeo";
    font-size: 16px;
  }
`;

const NextPrice = styled.div`
  width: 90%;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.24px;
  color: ${colors.black200};

  padding: 12px 16px;
  background-color: #fff7f1;
  border: 1px solid ${colors.highlightOrange};
  border-radius: 4px;
  ${({ theme }) => theme.flexBox("row", "space-between", "center")};

  .next {
    font-family: "SpoqaHanSansNeo";
    font-size: 18px;
    font-weight: 700;
    color: #f80003;
  }
`;

const Won = styled.span`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.28px;
  color: ${colors.black900};
  margin-left: 2px;

  &.red {
    font-size: 16px;
    letter-spacing: -0.32px;
    color: #f80003;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 48px;
`;

const PurchaseBtn = styled.button`
  width: 200%;
  border-radius: 8px;
  background-color: ${colors.primaryOrange};
  color: white;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.32px;
  margin-left: 8px;

  position: relative;
`;

const Deco = styled.div`
  width: 104px;
  height: 24px;
  color: ${colors.black400};
  background-color: white;
  border: 1px solid #ffb382;
  border-radius: 12px;
  font-size: 12px;
  letter-spacing: -0.12px;

  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.flexCenter}

  span {
    color: ${colors.primaryOrange};
  }
`;
