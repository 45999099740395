import Flex from "components/common/flex/Flex";
import styled from "styled-components";

const ContentBox = ({ title, children, ...rest }) => {
  return (
    <ContentBoxWrap {...rest}>
      <TitleWrap>{title}</TitleWrap>
      <ContentWrap>{children}</ContentWrap>
    </ContentBoxWrap>
  );
};

const ContentBoxWrap = styled(Flex)`
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-top: 16px;
`;

const TitleWrap = styled(Flex)`
  height: 48px;
  margin: 0 16px;
  justify-content: center;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: -0.34px;
  color: #222222;
  border-bottom: 1px solid #eaeaea;
`;

const ContentWrap = styled(Flex)`
  padding: 16px;
`;

export default ContentBox;
