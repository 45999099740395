import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  BuyInfo: {
    orderName: "", // 상품 이름 => 토스
    goodsId: "", // 상품 아이디
    buyShopCode: null, // 대리점 코드 => 입력처리 때문에 null로 해놓기
    shopId: 0, // 대리점 아이디
    buyPoint: 0, // 포인트
    buyKrw: 0, // 현금
    buyPrice: 0,
    useGram: 0, // 사용 중량
    useKrw: 0, // 사용 현금
    usePoint: 0, // 사용 포인트
    isUseKrw: false, // 현금 사용 여부
    visitDate: "",
    visitTime: null,
    openHr: "",
    closeHr: "",
    isDelivery: null,
    sellingPrice: 0, // 상품 판매가
    depositPrice: 0, // 최종 결제 금액
    standardPrice: 0, // 시장가
    // 토스 결제 연동 추가
    depositMethod: "", // 결제 방법: VIRTUAL("가상계좌"), HECTO_SIMPLE("헥토 간편"), TOSS_QUICK("토스 퀵계좌"),
    tossPaymentKey: "", // payment key
    tossOrderId: "", // order id
  },
  ShopInfo: {
    buyShopId: 0,
    buyShopName: "",
    buyShopAddress: "",
    buyShopPhoneNumber: "",
    buyShopDistance: 0,
  },
  DeliveryInfo: {
    postCode: "",
    address: "",
    addressDetail: "",
    addressName: "",
    receiver: "",
    phone: "",
    mobile: "",
    memo: "",
    isDefault: false,
    isEdit: false,
    editId: "",
  },
};

export const buySlice = createSlice({
  name: "buy",
  initialState,
  reducers: {
    setBuyInfo: (state, action) => {
      state.BuyInfo = { ...state.BuyInfo, ...action.payload };
    },
    resetBuyInfo: (state) => {
      state.BuyInfo = initialState.BuyInfo;
    },
    setShopInfo: (state, action) => {
      state.ShopInfo = { ...state.ShopInfo, ...action.payload };
    },
    resetShopInfo: (state) => {
      state.ShopInfo = initialState.ShopInfo;
    },
    setDeliveryInfo: (state, action) => {
      state.DeliveryInfo = { ...state.DeliveryInfo, ...action.payload };
    },
    resetDeliveryInfo: (state) => {
      state.DeliveryInfo = initialState.DeliveryInfo;
    },
  },
});

export const {
  setBuyInfo,
  resetBuyInfo,
  setShopInfo,
  resetShopInfo,
  setDeliveryInfo,
  resetDeliveryInfo,
} = buySlice.actions;
export const selectBuyInfo = (state) => state.buy;

export default buySlice.reducer;
