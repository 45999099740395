import React from "react";
import styled from "styled-components";
import plus from "assets/icons/plus-orange.webp";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import useUserInfoQuery from "hooks/queries/user";

const Content = () => {
  const navigate = useNavigate();
  const { data } = useUserInfoQuery();

  return (
    <Flex>
      {data &&
        (data?.account ? (
          <div style={{ flex: 1 }}>
            <AccountWrap>
              <div>
                <BankName>{data?.account?.bankName}</BankName>
                <AccountNumber>{data?.account?.bankAccount}</AccountNumber>
              </div>
              <DeleteBtn
                className="pointer"
                onClick={() => {
                  navigate(routes.deleteAccount);
                }}
              >
                삭제
              </DeleteBtn>
            </AccountWrap>
            <InfoText>* 변경을 원하시면 삭제 후 재등록 해주세요.</InfoText>
          </div>
        ) : (
          <RegisterBtn
            onClick={() => {
              navigate(routes.registerAccount);
            }}
          >
            <Img src={plus} alt="plus" />
            <span>계좌 등록하기</span>
          </RegisterBtn>
        ))}
    </Flex>
  );
};

export default Content;

const RegisterBtn = styled.button`
  border: 1px solid #ff6e0e;
  background-color: #ffffff;
  color: #ff6e0e;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 48px;
  font-weight: 500;
  flex: 1;
  cursor: pointer;
`;

const Img = styled.img`
  width: 8px;
  height: 20px;
  margin-right: 5px;
`;

const Flex = styled.div`
  display: flex;
`;

const AccountWrap = styled.div`
  padding: 11px 15px 13px 17px;
  display: flex;
  flex: 1;
  height: 68px;
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
`;

const DeleteBtn = styled.button`
  color: #666666;
  font-size: 14px;
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  text-decoration-color: #707070;
  cursor: pointer;
`;

const BankName = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: bold;
`;

const AccountNumber = styled.div`
  color: #666666;
  font-size: 14px;
  margin-top: 3px;
`;

const InfoText = styled.div`
  margin: 4px 0px 0px 13px;
  color: #999999;
  font-size: 14px;
`;
