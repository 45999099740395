import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectBuyInfo } from "features/buy/buySlice";
import PurchaseResult from "./components/PurchaseResult";

function PurchaseFailed() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const { BuyInfo } = useSelector(selectBuyInfo);

  const handleFailed = () => {
    navigate(`/goods-purchase/${BuyInfo?.goodsId}`, {
      replace: true,
      state: {
        FromFailed: true,
      },
    });
  };

  return (
    <PurchaseResult
      icon="Fail"
      title="결제 실패"
      reason={locationState?.errorMessage}
      id={BuyInfo?.goodsId}
      onConfirm={handleFailed} // 확인, X버튼 콜백함수
      isSuccess={false} // 성공 실패 여부
      showCSInfo={true}
    />
  );
}

export default PurchaseFailed;
