import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  state: "",
  data: {},
};

export const bottomsheetSlice = createSlice({
  name: "bottomsheet",
  initialState,
  reducers: {
    // 바텀시트 열기
    openBottomSheet: (state, action) => {
      state.state = "Open";
      state.data = { ...state.data, ...action.payload };
    },
    // 바텀시트 닫기
    closeBottomSheet: (state) => {
      state.state = "Close";
      state.data = initialState.data;
    },
    // 바텀시트 숨기기
    hideBottomSheet: (state) => {
      state.state = "Hidden";
      state.data = initialState.data;
    },
  },
});

export const { openBottomSheet, closeBottomSheet, hideBottomSheet } =
  bottomsheetSlice.actions;
export const selectBottomSheet = (state) => state.bottomsheet;

export default bottomsheetSlice.reducer;
