import React from "react";
import styled from "styled-components";
import ErrorIcon from "assets/icons/icon-error.webp";
import { Button } from "components";
import colors from "assets/styles/color";

const CancelConnectAccount = ({ modalClose, onConfirm }) => {
  return (
    <>
      <MarkIcon src={ErrorIcon} alt="icon" />
      <MainTitle>계좌 연결을 그만하시겠습니까?</MainTitle>
      <SubText>중단하면 연결 전으로 이동됩니다.</SubText>
      <ButtonWrapper>
        <Button
          text="계좌 연결 그만하기"
          size="sm"
          variant="round"
          disabled="modal"
          onClickFn={() => onConfirm()}
        />
        <Button
          text="연결 계속하기"
          size="sm"
          variant="round"
          onClickFn={() => modalClose()}
        />
      </ButtonWrapper>
    </>
  );
};

export default CancelConnectAccount;

const MarkIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-top: 12px;
  margin-bottom: 16px;
`;

const MainTitle = styled.div`
  color: ${colors.black200};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.36px;
  line-height: 28px;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 9px;
`;

const SubText = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${colors.black600};
  line-height: 24.5px;
  letter-spacing: -0.3px;
  text-align: center;
  padding: 0 18px;
  margin-bottom: 22px;
  white-space: pre-line;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexBox("row", "flex-end", "center")};

  /* 첫 버튼 margin 나누기 */
  button:first-child {
    margin-right: 8px;
  }
  /* 동의 버튼 font-weight 굵게 */
  button:last-child {
    font-weight: 700;
  }
`;
