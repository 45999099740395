import Flex from "components/common/flex/Flex";
import DefaultLayout from "components/layouts/DefaultLayout";
import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const HtmlViewer = () => {
  const location = useLocation();
  const locationState = location.state || {};
  const { title = "", content } = locationState;
  return (
    <DefaultLayout title={title}>
      <Container flex={1}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Container>
    </DefaultLayout>
  );
};

export default HtmlViewer;

const Container = styled(Flex)`
  padding: 0 16px;
`;
