import React, { useEffect, useMemo, useState } from "react";
import { ButtonBase, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { escapeRegExp } from "lodash";
import styled from "styled-components";

import { getNearbyShop } from "services/apis";
import { BottomSheet } from "components";
import Search from "assets/icons/search.webp";
import ShopItemSelect from "./ShopItemSelect";
import {
  getCurrentMyPosition,
  objToQueryString,
  getDistanceFromLatLonInKm,
} from "utils/utils";
import Flex from "components/common/flex/Flex";
import { showSnackBar } from "features/snackbar/snackbarSlice";
import { showLoading, hideLoading } from "features/loading/loadingSlice";
import colors from "assets/styles/color";
import {} from "utils/utils";
import useGetUserLocation from "hooks/useUserLocation";
import { closeBottomSheet } from "features/bottomsheet/bottomsheetSlice";
import TextInput from "components/common/Input/TextInput";

function UsingShopDialog({ isVisible, onCloseModal, purpose, onSelectShop }) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [shops, setShops] = useState([]);
  const [shopInfo, setShopInfo] = useState({
    shopId: 0,
    shopName: "",
  });
  const [confirmShop, setConfirmShop] = useState({
    visible: false,
    shopId: 0,
    shopName: "",
  });
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const dispatch = useDispatch();
  const userInfo = useSelector((s) => s.user);

  const { coordinates, loaded } = useGetUserLocation();
  const { latitude: lat = 0, longitude: lng = 0 } = coordinates;

  const handleClickShop = (shop) => {
    if (purpose === "SIGNUP") {
      setSearchKeyword("");
      onCloseModal();
      onSelectShop({
        id: shop?.id,
        name: shop?.name,
      });
    }
  };

  const mapDataToComponent = (shops) => {
    return shops.map((shop, i) => {
      const prevId = shopInfo.shopId;
      const { id, name, address, phoneNumber, distance, shopType } = shop;

      return (
        <ShopItemSelect
          key={i.toString()}
          list
          shopName={name}
          address={address}
          shopPhone={phoneNumber}
          distance={distance}
          onCheckedChange={() => handleClickShop(shop)}
          onClick={() => handleClickShop(shop)}
          checked={prevId === id}
          shopType={shopType}
        />
      );
    });
  };

  function ch2pattern(ch) {
    const offset = 44032; /* '가'의 코드 */
    // 한국어 음절
    if (/[가-힣]/.test(ch)) {
      const chCode = ch.charCodeAt(0) - offset;
      // 종성이 있으면 문자 그대로를 찾는다.
      if (chCode % 28 > 0) {
        return ch;
      }
      const begin = Math.floor(chCode / 28) * 28 + offset;
      const end = begin + 27;
      return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 한글 자음
    if (/[ㄱ-ㅎ]/.test(ch)) {
      const con2syl = {
        ㄱ: "가".charCodeAt(0),
        ㄲ: "까".charCodeAt(0),
        ㄴ: "나".charCodeAt(0),
        ㄷ: "다".charCodeAt(0),
        ㄸ: "따".charCodeAt(0),
        ㄹ: "라".charCodeAt(0),
        ㅁ: "마".charCodeAt(0),
        ㅂ: "바".charCodeAt(0),
        ㅃ: "빠".charCodeAt(0),
        ㅅ: "사".charCodeAt(0),
      };
      const begin =
        con2syl[ch] ||
        (ch.charCodeAt(0) - 12613) /* 'ㅅ'의 코드 */ * 588 + con2syl["ㅅ"];
      const end = begin + 587;
      return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 그 외엔 그대로 내보냄
    // escapeRegExp는 lodash에서 가져옴
    return escapeRegExp(ch);
  }

  function createFuzzyMatcher(input) {
    const pattern = input.split("").map(ch2pattern).join(".*?");
    return new RegExp(pattern);
  }

  // const ShopList = useMemo(() => {
  //   const matchShops = shops.filter(({ name, address }) => {
  //     return createFuzzyMatcher(searchKeyword).test(name);
  //   });
  //   return mapDataToComponent(matchShops);
  // }, [shops, searchKeyword]);

  const init = async () => {
    const shopSearchIf = "RECOMMEND_SHOP";

    getNearbyShop(objToQueryString({ shopSearchIf })).then(
      ({ data: { success, data } }) => {
        if (success) {
          let sortedData = data;
          if (lat && lng) {
            // 위치 허용인 경우
            sortedData = data.map((item) => {
              const { latitude, longitude } = item;
              let distance = 0;
              if (latitude && longitude) {
                distance = getDistanceFromLatLonInKm(
                  lat,
                  lng,
                  latitude,
                  longitude,
                ).toFixed(2);
              }
              return { ...item, distance };
            });
            if (shopSearchIf === "RECOMMEND_SHOP") {
              // visibleOrder와 distance에 따라 정렬
              sortedData = sortedData.sort((a, b) => {
                if (a.visibleOrder !== null && b.visibleOrder !== null) {
                  return a.visibleOrder - b.visibleOrder;
                } else if (a.visibleOrder !== null) {
                  return -1;
                } else if (b.visibleOrder !== null) {
                  return 1;
                } else {
                  return a.distance - b.distance;
                }
              });
            } else {
              // distance에 따라 정렬
              sortedData = sortedData.sort((a, b) => a.distance - b.distance);
            }
          } else {
            sortedData = sortedData.sort((a, b) => {
              let aName = a.name;
              let bName = b.name;
              if (aName < bName) return -1;
              else if (aName === bName) return 0;
              else return 1;
            });
          }
          const matchShops = sortedData.filter(({ name, address }) => {
            return createFuzzyMatcher(searchKeyword).test(name);
          });
          if (matchShops.length === 0) {
            setShops(null);
          } else {
            setShops(mapDataToComponent(matchShops));
          }
        }
      },
    );
  };

  useEffect(() => {
    if (!isVisible) {
      setSearchKeyword("");
      setShops([]);
      onCloseModal();
      dispatch(closeBottomSheet());
    }
  }, [isVisible]);

  return (
    <BottomSheet
      onClose={onCloseModal}
      title={"대리점 찾기"}
      hideGreyBar={true}
    >
      <Flex
        style={{
          flex: 1,
          height: window.innerHeight * 0.8,
          background: "white",
          borderRadius: "18px 18px 0px 0px",
        }}
      >
        {/* <Flex
          style={{
            height: "32px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "64px",
              height: "6px",
              borderRadius: "100px",
              backgroundColor: "#D9D9D9",
            }}
          />
        </Flex> */}
        {/* <SimpleTopBar
          style={{
            padding: "20px 25px",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <span>대리점 찾기</span>
          </div>
          <div className={classes.back} onClick={onCloseModal}>
            <img
              alt="back"
              src={images.close}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </SimpleTopBar> */}
        <div
          style={{
            flex: 1,
            // height: "80%",
            padding: "21px",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              width: "100%",
              justifyContent: "stretch",
              alignItems: "flex-end",
            }}
          >
            <InputText
              placeholder="대리점을 검색하세요. (예:종로, 대전)"
              value={searchKeyword}
              onChange={(value) => {
                setSearchKeyword(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  init();
                }
              }}
            />

            <ButtonBase
              style={{
                width: "45px",
                height: "45px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                init();
              }}
            >
              <img
                src={Search}
                style={{
                  // padding: "10px",
                  width: "100%",
                  height: "100%",
                }}
                alt=""
              />
            </ButtonBase>
          </div>
          <div
            style={{
              padding: "20px 0",
            }}
          >
            {shops === null ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                검색된 결과가 없습니다.
              </div>
            ) : (
              <div
                style={{
                  flex: 1,
                  overflow: "scroll",
                  height: "100%",
                  paddingBottom: "9px",
                }}
              >
                {/* {searchKeyword !== "" && ShopList} */}
                {shops}
              </div>
            )}
          </div>
        </div>
      </Flex>
    </BottomSheet>
  );
}

const InputText = styled(TextInput)`
  width: 100%;
  height: 45px;
  border: 0;
  border-bottom: 1px solid #bbb;
  &.MuiInput-underline:after {
    border-bottom-color: "#fe6e0e";
  }
`;

export default UsingShopDialog;
