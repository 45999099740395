import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "assets/styles/color";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { routes } from "routes";
import fonts, { fontWeight } from "assets/styles/fonts";

const TabArea = ({ goodsGroup, categoryId, setCategoryId }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleChange = (event, newValue) => {
    setCategoryId(newValue);
    navigate(`${routes.goods}/${newValue}`, {
      replace: true,
      state: { ...state, goodsGroupId: newValue },
    });
  };

  return (
    <div className="tab-container">
      <Tabs
        value={categoryId}
        TabIndicatorProps={{ style: { background: "black" } }}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        TabScrollButtonProps={{
          style: {
            width: "40px",
            height: "48px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        {goodsGroup.map((category) => {
          return (
            <Tab
              key={category.goodsGroupId}
              label={category.goodsGroupName}
              value={category.goodsGroupId}
              sx={{
                "&.Mui-selected": {
                  color: "#222222",
                  fontWeight: fontWeight.bold,
                },
              }}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

export default TabArea;

const TabContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 48px;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
`;
