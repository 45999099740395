import React from "react";
import styled from "styled-components";
import colors from "assets/styles/color";

function DetailInfo({ comment }) {
  const Comment = () => {
    return comment?.split("\n").map((comment, k) => {
      return (
        <Text key={k}>
          {comment}
          <br />
        </Text>
      );
    });
  };

  return (
    <Container comment={comment}>
      <TextArea>
        <Comment />
      </TextArea>
    </Container>
  );
}

export default DetailInfo;

const Container = styled.div`
  display: ${({ comment }) => (comment ? "block" : "none")};
  width: 100%;
  ${({ theme }) => theme.flexColumnCenter}
`;

const TextArea = styled.div`
  width: 90%;
  height: auto;
  background-color: ${colors.backgroundYellow};
  padding: 24px;
  margin: 0 auto 40px;

  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  letter-spacing: -0.32px;
  color: ${colors.black400};
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  letter-spacing: -0.32px;
  color: ${colors.black400};
`;
