import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Header from "./Header";
import cancel from "assets/icons/cancel.webp";
import { showAlert } from "features/modal/modalSlice";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import {
  postLeaveVerifyCode,
  patchLeavePreviousVerify,
  patchLeaveUser,
} from "services/apis";
import { useModal } from "hooks/useModal";
import { CustomModal } from "components";
import LeaveConfirm from "./LeaveConfirm";
import useInterval from "hooks/useInterval";
import { useNavigate } from "react-router-dom";

const headerName = "본인인증";

const LeaveSecond = ({ currentStep, setCurrentStep, leaveReason }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verifyCode, setVerifyCode] = useState("");
  const [timeLeft, setTimeLeft] = useState(-1);
  const [stopInterval, setStopInterval] = useState(false);
  const [modalVisible, modalOpen, modalClose] = useModal(false);

  // 핸드폰 인증번호 발송
  const handleClickPostSms = () => {
    if (timeLeft <= 0) {
      dispatch(showLoading());
      resetTimer();
      postLeaveVerifyCode()
        .then(({ data: { success } }) => {
          if (success) {
            dispatch(showAlert({ message: "인증번호가 발송되었습니다." }));
            startTimer();
          }
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  const startTimer = () => {
    setTimeLeft(180);
    setVerifyCode("");
  };

  const resetTimer = () => {
    setTimeLeft(-1);
    setVerifyCode("");
  };

  // 탈퇴 요청 모달 Open
  const handleClickCheck = () => {
    if (verifyCode.length === 6) {
      dispatch(showLoading());
      patchLeavePreviousVerify(verifyCode)
        .then(({ data: { success, message } }) => {
          if (success) {
            setStopInterval(true);
            modalOpen();
          } else {
            dispatch(showAlert({ message }));
          }
        })
        .finally(() => dispatch(hideLoading()));
    } else {
      dispatch(
        showAlert({ message: "인증번호 6자리를 알맞게 입력하여 주세요." }),
      );
    }
  };

  // 회원 탈퇴 요청
  const handleClickLeave = () => {
    dispatch(showLoading());
    patchLeaveUser(leaveReason)
      .then(({ data: { success, message } }) => {
        if (success) {
          setCurrentStep(currentStep + 1);
        } else {
          dispatch(showAlert({ message }));
          modalClose();
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  useInterval(
    () => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    },
    stopInterval ? null : 1000,
  );

  useEffect(() => {
    if (!leaveReason) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      dispatch(
        showAlert({
          message: "인증번호가 만료되었습니다.\n 인증번호를 다시 전송해주세요.",
        }),
      );
      resetTimer();
    }
  }, [timeLeft]);

  return (
    <Container>
      <div>
        <Header header={headerName} />
        <Guide>
          <GuideText>
            회원 탈퇴 진행을 위해
            <br />
            휴대폰 본인인증이 필요합니다.
          </GuideText>
        </Guide>
        <Wrap height={"32px"} />
        <ButtonWrap>
          <Button
            // className={timeLeft > 0 ? "off" : "on"}
            type="SEND-PHONE"
            timeLeft={timeLeft}
            onClick={() => {
              handleClickPostSms();
            }}
          >
            내 휴대폰으로 인증번호 전송
          </Button>
        </ButtonWrap>
        <Wrap height={"12px"} />
        <InputBox>
          <div>
            <InputHeader>휴대폰으로 전송된 인증번호 입력</InputHeader>
          </div>
          <Flex>
            <Input
              placeholder="숫자"
              value={verifyCode}
              onChange={(e) => {
                setVerifyCode(e.target.value);
              }}
            />
            {timeLeft > -1 && (
              <TimeOut>
                {String(parseInt(timeLeft / 60)).padStart(2, "0")} :{" "}
                {String(timeLeft % 60).padStart(2, "0")}
              </TimeOut>
            )}
            {verifyCode !== "" && (
              <CancelImg
                src={cancel}
                alt="cancel"
                onClick={() => setVerifyCode("")}
              />
            )}
          </Flex>
        </InputBox>
      </div>
      <ButtonWrap>
        <Button
          disabled={verifyCode === ""}
          verifyCode={verifyCode}
          type="AUTH-CHECK"
          onClick={() => {
            handleClickCheck();
          }}
        >
          확인
        </Button>
      </ButtonWrap>
      <CustomModal modalVisible={modalVisible} modalClose={modalClose}>
        <LeaveConfirm modalClose={modalClose} onConfirm={handleClickLeave} />
      </CustomModal>
    </Container>
  );
};

export default LeaveSecond;

const Container = styled.div`
  padding: 0px 0px 64px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Guide = styled.div`
  height: 60px;
  padding: 0px 0px 0px 24px;
  margin-top: -6px;
  display: flex;
  align-items: center;
`;
const GuideText = styled.div`
  font-weight: bold;
  font-size: 21px;
  color: #222222;
`;
const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
  margin: ${(props) => `${props.margin}`};
  padding: ${(props) => `${props.padding}`};
`;
const InputBox = styled.div`
  padding: 16px 0px 0px 15px;
  border: 1px solid #656667;
  border-radius: 8px;
  margin: 0px 24px;
`;
const InputHeader = styled.span`
  font-weight: normal;
  font-size: 12px;
  color: #666666;
`;
const Input = styled.input`
  height: 49px;
  flex: 1;
`;
const Button = styled.button`
  flex: 1;
  border: ${(props) =>
    props.type === "SEND-PHONE"
      ? props.timeLeft > 0
        ? "1px solid #707070" //인증번호 전송 후
        : "1px solid #ff6e0e" //인증번호 전송 전
      : ""};
  height: 48px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  cursor: ${(props) =>
    props.type === "SEND-PHONE"
      ? props.timeLeft > 0
        ? "" //인증번호 전송 후
        : "pointer" //인증번호 전송 전
      : props.verifyCode !== ""
      ? "pointer"
      : ""};
  background-color: ${(props) =>
    props.type === "SEND-PHONE"
      ? "#ffffff"
      : props.verifyCode !== ""
      ? "#ff6e0e"
      : "#dddddd"};
  color: ${(props) =>
    props.type === "SEND-PHONE"
      ? props.timeLeft > 0
        ? "#444444"
        : "#ff6e0e"
      : props.verifyCode !== ""
      ? "#ffffff"
      : "#bbbbbb"};
`;
const CancelImg = styled.img`
  width: 48px;
  height: 48px;
  margin-right: -8px;
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TimeOut = styled.span`
  margin: 0px 8px;
  font-size: 14px;
  color: #f80003;
  align-self: center;
`;

const ButtonWrap = styled.div`
  margin: 0px 24px;
  display: flex;
`;
