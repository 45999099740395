import { showAlert } from "features/modal/modalSlice";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { signInUsingCode } from "services/authService";

const Gateway = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search.replace(/&amp;/gi, "&"));
  const dispatch = useDispatch();
  const code = params.get("code");
  const type = params.get("type");
  const purpose = params.get("purpose");
  const name = params.get("name");
  const requestId = params.get("requestId");
  const requireCompleteAction = params.get("requireCompleteAction");
  const error = params.get("error");
  const message = params.get("message");
  const errorType = params.get("errorType");
  const leaveDate = params.get("leaveDate");
  const reSignupDate = params.get("reSignupDate");

  useEffect(() => {
    for (const [key, value] of params) {
      console.log(`${key}: ${value}`);
    }
  }, []);

  useEffect(() => {
    if (error === "true") {
      dispatch(showAlert({ message }));

      navigate && navigate(routes.home, { replace: true });
    }

    // 본인인증 완료
    if (type === "SELF_VERIFICATION_COMPLETED") {
      // 회원가입
      if (purpose === "SIGNUP") {
        if (requireCompleteAction === "true") {
          // 본인인증 후 가입 시작
          navigate(routes.authSignUpAcceptTerms, {
            state: { requestId },
            replace: true,
          });
        } else {
          // 가입 완료
          if (message) {
            dispatch(showAlert({ message }));
          }
          signInUsingCode(code, navigate);
        }
      }
      // 휴대폰번호 변경
      else if (purpose === "UPDATE_PHONENUMBER") {
        signInUsingCode(code, navigate, routes.mypageChangeInfo); // 변경 후 내정보 변경 페이지로 이동
        dispatch(dispatch(showAlert({ message })));
      }
      // 결제비밀번호 변경
      else if (purpose === "UPDATE_SECURE_PASSWORD") {
        if (requireCompleteAction === "true") {
          navigate(routes.authSignUpRegisterPin, {
            replace: true,
            state: { purpose, requestId },
          });
        } else {
          signInUsingCode(code, navigate);
        }
      }
    }
    // Oauth Login
    else if (type === "OAUTH_COMPLETED") {
      if (purpose === "SIGNIN" || purpose === "SIGNUP") {
        signInUsingCode(code, navigate, "", "", true);
      }
    }
  }, []);

  return <div></div>;
};

export default Gateway;
