import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { showAlert } from "features/modal/modalSlice";

import { routes } from "routes";
import Terms from "./components/Terms";
import colors from "assets/styles/color";
import UserInfo from "./components/UserInfo";
import ShopCode from "./components/ShopCode";
import Available from "./components/Available";
import Reservation from "./components/Reservation";
import PickUpMethod from "./components/PickUpMethod";
import OrderProduct from "./components/OrderProduct";
import PurchaseHeader from "./components/PurchaseHeader";
import OverPriceModal from "./components/OverPriceModal";
import EstimatedAmount from "./components/EstimatedAmount";
import ChangePriceModal from "./components/ChangePriceModal";
import ChoosePaymentMethod from "./components/ChoosePaymentMethod";

import { useModal } from "hooks/useModal";
import { validateInfo } from "utils/utils";
import useUserInfoQuery from "hooks/queries/user";
import { Button, CustomModal } from "components";
import { setBuyInfo, selectBuyInfo } from "features/buy/buySlice";
import { showLoading, hideLoading } from "features/loading/loadingSlice";

import { getSessionStorageItem } from "services/storageService";
import { useGetGoodsDetail } from "pages/goods-detail/GoodsDetail";
import { useGetPrePrice } from "pages/goods-purchase/components/EstimatedAmount";
import { useGetPrePriceByZero } from "pages/goods-detail/components/DetailBottomSheet";

import { useTossPayment } from "hooks/useTossPayment"; // 결제 창 로드
import { getMallDepositDir, getEnvConfig } from "services/apis";

function GoodsPurchase() {
  const [bottomSheetState, setBottomSheetState] = useState("Close");

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { PrePriceData } = useGetPrePrice();
  const { refetch } = useGetPrePriceByZero();
  const { data: userInfo } = useUserInfoQuery();
  const { detail, isLoading } = useGetGoodsDetail();
  const { BuyInfo, DeliveryInfo } = useSelector(selectBuyInfo);
  const { isTermsChecked, visitDate } = BuyInfo;

  const [changePriceModalVisible, changePriceModalOpen, changePriceModalClose] =
    useModal(false); // 가격 변동 모달
  const [overPriceModalVisible, overPriceModalOpen, overPriceModalClose] =
    useModal(false); // 가격 초과 모달

  const { TossProcess } = useTossPayment();

  useEffect(() => {
    hideChoosePaymentMethod();
  }, []);

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoading());
    } else {
      dispatch(hideLoading());
    }
  }, [dispatch, detail, isLoading]);

  useEffect(() => {
    !isLoading &&
      dispatch(
        setBuyInfo({
          goodsId: id,
          assetType: detail.assetType,
          standardPrice: detail.standardPrice,
          earliestVisitDate: detail.earliestVisitDate,
        }),
      );
  }, [dispatch, detail, isLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSellingPriceChange = async () => {
    const { sellingPrice } = BuyInfo;
    const newPrePriceByZero = await refetch();
    if (newPrePriceByZero.data.price !== sellingPrice) {
      dispatch(
        setBuyInfo({
          sellingPrice: newPrePriceByZero.data.price,
          prevPrice: sellingPrice,
        }),
      );
    }
    dispatch(setBuyInfo({ buyPrice: newPrePriceByZero.data.buyPrice }));
    return newPrePriceByZero;
  };

  // 매도잔량 부족
  const handleInsufficientStock = (TotalGoodsGram, useGram) => {
    if (PrePriceData?.balanceGrams < TotalGoodsGram - useGram) {
      dispatch(showAlert({ message: PrePriceData?.message + "\n재고 부족" }));
      return true;
    }
    return false;
  };

  const handlePurchaseRequired = () => {
    const { shopId, visitDate, isDelivery } = BuyInfo;
    const { receiver, postCode, address, addressDetail, phone } = DeliveryInfo;

    if (!isTermsChecked) {
      dispatch(
        showAlert({ icon: "Warning", message: "<약관/>을 <선택/>해주세요" }),
      );
    } else {
      const validation = validateInfo(
        isDelivery,
        isDelivery
          ? { receiver, postCode, address, addressDetail, phone }
          : { shopId, visitDate },
      );

      if (!validation.isValid) {
        dispatch(showAlert({ icon: "Warning", message: validation.message }));
      } else {
        return false;
      }
    }
    return true;
  };

  // 결제 방법에 따른 라우팅
  const DepositDirection = (type) => {
    switch (type) {
      // 헥토 간편 결제
      case "HECTO_SIMPLE_PAYMENT":
        return navigate(routes.settlement, {
          state: { id: id, goodsName: `${detail.goodsName}` },
        });
      // 헥토 계좌 등록
      case "HECTO_SIMPLE_REGISTER": {
        return navigate(routes.registerAccount, {
          state: { FromSettlement: true, id: id },
        });
      }
      case "TOSS_QUICK_PAYMENT":
        // 임시 저장 후 토스 결제창 실행
        TossProcess();
        break;
      case "HECTO_SIMPLE_DRIVE":
        // 헥토 결제 유도 바텀시트 열기
        openChoosePaymentMethod();
        break;
      case "CS":
        // 한도 초과 모달 열기
        overPriceModalOpen();
        break;
      default:
        return;
    }
  };

  const onClickPurchase = async () => {
    const qty = getSessionStorageItem("qty");
    const TotalGoodsGram = qty * detail?.goodsGram;
    const { useGram } = BuyInfo;
    // 보관료 체크

    const newPrePriceByZero = await handleSellingPriceChange();
    if (handleInsufficientStock(TotalGoodsGram, useGram)) return;
    if (handlePurchaseRequired()) return;

    const { sellingPrice, depositPrice } = BuyInfo;

    if (newPrePriceByZero.data.price !== sellingPrice) {
      // 가격변동 있으면
      changePriceModalOpen();
    } else {
      // 한도 관련해서 준 것 따라서
      if (depositPrice === 0) {
        navigate(routes.settlement, {
          state: { id: id, goodsName: `${detail.goodsName}` },
        });
      } else {
        getEnvConfig("TOSS_DEPOSIT_ONCE").then((res) => {
          if (Number(res.data.data.value) === 0) {
            navigate(routes.PurchaseUnabled);
          } else {
            getMallDepositDir(depositPrice).then((res) =>
              DepositDirection(res.data.data),
            );
          }
        });
      }
    }
  };

  // 결제수단 선택 바텀시트 관리
  const openChoosePaymentMethod = () => {
    setBottomSheetState("Open");
  };

  const closeChoosePaymentMethod = () => {
    setBottomSheetState("Close");
  };

  const hideChoosePaymentMethod = () => {
    setBottomSheetState("hidden");
  };

  return (
    <>
      <PurchaseHeader title="주문/결제" />
      <CustomModal
        modalVisible={overPriceModalVisible}
        modalClose={overPriceModalClose}
      >
        <OverPriceModal modalClose={overPriceModalClose} />
      </CustomModal>
      <CustomModal
        modalVisible={changePriceModalVisible}
        modalClose={changePriceModalClose}
      >
        <ChangePriceModal
          modalClose={changePriceModalClose}
          prev={BuyInfo?.prevPrice}
          next={BuyInfo?.sellingPrice}
        />
      </CustomModal>
      <ChoosePaymentMethod
        state={bottomSheetState}
        closeChoosePaymentMethod={closeChoosePaymentMethod}
        hideChoosePaymentMethod={hideChoosePaymentMethod}
        distanceFromTop="calc(100vh - 303px)"
      />
      <Container>
        <ShopCode />
        <UserInfo
          name={userInfo?.name}
          phone={userInfo?.phone}
          email={userInfo?.email}
        />
        <PickUpMethod
          earliestVisitDate={detail?.earliestVisitDate}
          isPost={detail?.isPost}
          isShopTaken={detail?.isShopTaken}
        />
        <OrderProduct detail={detail} />
        <Available
          assetType={detail?.assetType}
          goodsGram={detail?.goodsGram}
        />
        {BuyInfo?.shopId !== 0 && <Reservation />}
        <EstimatedAmount />
        <Terms />
        <Button
          text="결제하기"
          size="md"
          variant="round"
          disabled={!isTermsChecked && "login"}
          addStyle={"margin-bottom: 64px"}
          onClickFn={onClickPurchase}
        />
      </Container>
    </>
  );
}

export default GoodsPurchase;

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${colors.purchaseBackground};

  padding: 16px;
`;
