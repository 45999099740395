import { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "components";
import colors from "assets/styles/color";
import { getEnvConfig } from "services/apis";
import Error from "assets/icons/icon-error.webp";

function OverPriceModal({ modalClose }) {
  const handleCall = () => {
    window.location.href = "tel:16708978";
  };

  const [limit, setLimit] = useState(null);
  useEffect(() => {
    getEnvConfig("MALL_DEPOSIT_ONCE").then((res) =>
      setLimit(Math.floor(res?.data?.data?.value / 10000)),
    );
  }, []);

  return (
    <Container>
      <ModalBackdrop onClick={modalClose} />
      <ModalBody>
        <ErrorIcon src={Error} alt="에러 아이콘" />
        <Title>
          결제 금액이 <HighLight>{limit}만원 초과</HighLight>인 경우 <br />
          본사 고객센터로 문의 바랍니다.
        </Title>
        <ButtonWrapper>
          <Button
            size="sm"
            variant="round"
            disabled="modal"
            text="닫기"
            onClickFn={() => modalClose()}
          />
          <PurchaseBtn onClick={handleCall}>전화하기</PurchaseBtn>
        </ButtonWrapper>
        <Desc>
          <div className="cs">고객센터: 1670-8978</div>
          <div className="time">(월~금 / 9am~6pm / 주말•공휴일 휴무)</div>
        </Desc>
      </ModalBody>
    </Container>
  );
}

export default OverPriceModal;

const Container = styled.div`
  width: 100%;
  ${({ theme }) => theme.flexCenterColumn}
`;

const ModalBody = styled.div`
  background-color: white;
  width: 90%;
  height: auto;

  position: fixed;
  top: 47.5%;
  left: 50%;
  z-index: 1020;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  padding: 16px 16px 24px;

  ${({ theme }) => theme.flexBox("column", "flex-start", "center")}

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 432px;
  }
`;

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  cursor: pointer;

  inset: 0;
  background-color: black;
  opacity: 0.54;
  z-index: 1010;
`;

const ErrorIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-top: 16px;
`;

const Title = styled.div`
  height: 54px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.36px;
  line-height: 28px;
  color: ${colors.black200};
  margin-top: 18px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 29px;
`;

const PurchaseBtn = styled.button`
  width: 200%;
  border-radius: 8px;
  background-color: ${colors.primaryOrange};
  color: white;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.32px;
  margin-left: 8px;

  position: relative;
`;

const Message = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.black200};
  letter-spacing: -0.36px;

  &.orange {
    color: ${colors.textOrange};
  }
`;

const HighLight = styled.span`
  position: relative;
  color: ${colors.primaryOrange};

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 4.5px;
    width: 100%;
    height: 9px; // 폰트 절반
    background-color: ${colors.highlightOrange};
    z-index: -1;
  }
`;

const Desc = styled.div`
  font-size: 14px;
  line-height: 24.5px;
  color: ${colors.black900};
  margin-top: 16px;
  ${({ theme }) => theme.flexCenterColumn}

  .cs {
    height: 20px;
  }

  .time {
    font-size: 13px;
    height: 19px;
    margin-top: 2px;
    margin-bottom: 1px;
  }
`;
