import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { selectBuyInfo } from "features/buy/buySlice";
import PurchaseResult from "./components/PurchaseResult";

function PurchaseComplete() {
  const navigate = useNavigate();
  const { BuyInfo } = useSelector(selectBuyInfo);

  const handleSuccess = () => {
    navigate(routes.orderHistory);
  };

  return (
    <PurchaseResult
      icon="Success"
      title="결제 완료"
      amount={BuyInfo?.depositPrice}
      goodsName={BuyInfo?.orderName}
      depositMethod="토스 퀵계좌결제"
      onConfirm={handleSuccess} // 확인, X버튼 콜백함수
      isSuccess={true} // 성공 실패 여부
    />
  );
}

export default PurchaseComplete;
