import { useEffect } from "react";
import styled from "styled-components";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { showAlert } from "features/modal/modalSlice";
import { putMyPageTransDelivery } from "services/apis";
import {
  selectBuyInfo,
  setBuyInfo,
  setDeliveryInfo,
} from "features/buy/buySlice";
import { validateInfo } from "utils/utils";
import { getMyPageTransDetail } from "services/apis";
import DefaultLayout from "components/layouts/DefaultLayout";
import TopLabel from "./components/TopLabel";
import ShopInfo from "./components/ShopInfo";
import ProductInfo from "./components/ProductInfo";
import ButtonToList from "./components/ButtonToList";
import HowReceive from "./components/HowReceive";
import DeliveryInfo from "./components/DeliveryInfo";
import PaymentInfo from "./components/PaymentInfo";

const OrderDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state || {};
  const { orderId = 0 } = locationState;

  const { data: transDetail = {}, refetch } = useQuery(
    ["order", "detail", orderId],
    () =>
      getMyPageTransDetail(orderId).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            return data;
          } else {
            dispatch(
              showAlert({
                message,
                callback: () => {
                  navigate(`/my-page/order-history`, {
                    replace: true,
                  });
                },
              }),
            );
          }
        },
      ),
  );

  const {
    goodsTrans = {},
    shopInfo = {},
    payment = {},
    delivery = {},
  } = transDetail;
  const { canModifyDelivery, isDelivery } = goodsTrans;

  useEffect(() => {
    dispatch(setBuyInfo({ goodsId: goodsTrans?.goodsId }));
  }, [goodsTrans?.goodsId]);

  useEffect(() => {
    dispatch(setBuyInfo({ earliestVisitDate: transDetail?.earliestVisitDate }));
  }, [transDetail?.earliestVisitDate]);

  const { BuyInfo, DeliveryInfo: Delivery } = useSelector(selectBuyInfo);
  const {
    postCode,
    address,
    addressDetail,
    addressName,
    receiver,
    phone,
    mobile,
    memo,
    isDefault,
    isEdit,
  } = Delivery;
  const { shopId, visitDate } = BuyInfo;

  const queryClient = useQueryClient();
  const ChangeDelivery = useMutation(
    ["change-delivery"],
    () =>
      putMyPageTransDelivery(goodsTrans?.goodsTransId, {
        isDelivery,
        delivery: {
          postCode,
          address,
          addressDetail,
          addressName,
          receiver,
          phone,
          mobile,
          memo,
          isDefault,
        },
        visitDate,
        shopId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "order",
          "detail",
          goodsTrans?.goodsTransId,
        ]);
      },
    },
  );

  const handleSave = () => {
    const info = isDelivery
      ? { receiver, phone, postCode, address, addressDetail }
      : { shopId, visitDate };

    const { isValid, message } = validateInfo(isDelivery, info);

    if (!isValid) {
      dispatch(showAlert({ icon: "Warning", message }));
    } else {
      ChangeDelivery.mutate();
      dispatch(setDeliveryInfo({ isEdit: false }));
    }
  };

  return (
    <DefaultLayout title="주문 상세" navigationVisible>
      <Container>
        <TopLabel goodsTrans={goodsTrans} />
        <ProductInfo goodsTrans={goodsTrans} refetch={refetch} />
        <HowReceive {...transDetail} />
        {!isDelivery && (
          <ShopInfo shopInfo={shopInfo} canModifyDelivery={canModifyDelivery} />
        )}
        {/* {!!deliveryTrace?.regiNo && <DeliveryHistory {...deliveryTrace} />} */}
        {!!isDelivery && (
          <DeliveryInfo
            delivery={delivery}
            canModifyDelivery={canModifyDelivery}
          />
        )}
        {isEdit && canModifyDelivery && (
          <SaveBtn onClick={handleSave}>수령방법 변경</SaveBtn>
        )}
        <PaymentInfo payment={payment} />
        <ButtonToList />
      </Container>
    </DefaultLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  background: #fcfcfc;
  padding: 12px 16px 128px 16px;
  flex-direction: column;
`;

const SaveBtn = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: white;
  color: #ff6e0e;
  border: 1px solid #ff6e0e;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
`;
export default OrderDetail;
