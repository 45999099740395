import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { signOut } from "services/authService";

const LeaveThird = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <FlexCenter>
        <Wrap height="50px" />
        <MainText>회원 탈퇴가 완료 되었습니다.</MainText>
        <SubText>그동안 이용해주셔서 감사드립니다.</SubText>
      </FlexCenter>
      <Flex>
        <Button
          onClick={() => {
            signOut();
            navigate("/");
          }}
        >
          확인
        </Button>
      </Flex>
    </Container>
  );
};

export default LeaveThird;
const Container = styled.div`
  flex: 1;
  padding: 0px 24px 64px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrap = styled.div`
  height: ${(props) => `${props.height}`};
  margin: ${(props) => `${props.margin}`};
  padding: ${(props) => `${props.padding}`};
`;

const MainText = styled.div`
  color: #222222;
  font-weight: bold;
  font-size: 21px;
  height: 30px;
`;

const SubText = styled.div`
  font-size: 16px;
  color: #777777;
  margin-top: 6px;
  height: 24px;
`;

const Button = styled.button`
  flex: 1;
  height: 48px;
  background-color: #ff6e0e;
  color: #ffffff;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`;
const Flex = styled.div`
  display: flex;
`;
const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
