import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import loadingReducer from "../features/loading/loadingSlice";
import bottomsheetReducer from "features/bottomsheet/bottomsheetSlice";
import snackbarReducer from "features/snackbar/snackbarSlice";
import modalReducer from "features/modal/modalSlice";
import buyReducer from "features/buy/buySlice";
import authReducer from "features/auth/authSlice";
import expireAlertReducer from "features/expireAlert/expireAlertSlice";
import storageSession from "redux-persist/lib/storage/session";
import { encryptTransform } from "redux-persist-transform-encrypt";

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_PERSIST_SECRET_KEY,
  onError: function (error) {
    console.log(error);
  },
});

const buyPersistConfig = {
  key: "buy",
  storage: storageSession,
  transforms: [encryptor], // 암호화 transform을 적용합니다.
};

const rootReducer = combineReducers({
  loading: loadingReducer,
  bottomsheet: bottomsheetReducer,
  snackbar: snackbarReducer,
  modal: modalReducer,
  buy: persistReducer(buyPersistConfig, buyReducer),
  auth: authReducer,
  expireAlert: expireAlertReducer,
});

export default rootReducer;
