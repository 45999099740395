import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

const MemberWithdrawal = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Title
        className="pointer"
        onClick={() => {
          navigate(routes.leave);
        }}
      >
        회원탈퇴
      </Title>
      <Bar />
    </div>
  );
};

export default MemberWithdrawal;

const Title = styled.div`
  padding: 11px 0px 3px 0px;
  height: 24px;
  font-weight: normal;
  font-size: 13px;
  color: #666666;
`;
const Bar = styled.div`
  height: 40px;
  background-color: #ffffff;
`;
