import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  postSelfVerificationComplete,
  postEventPoint,
  getAuthToken,
} from "services/apis";
import { signInUsingCode } from "services/authService";
import { showAlert } from "features/modal/modalSlice";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import Flex from "components/common/flex/Flex";
import useCryptoKeyboard from "hooks/useCryptoKeyboard";
import DefaultLayout from "components/layouts/DefaultLayout";
import ic_del from "assets/images/ic_del.png";
import { routes } from "routes";
import { getGaClientId } from "utils/utils";
import { openBottomSheet } from "features/bottomsheet/bottomsheetSlice";
import ReactGA from "react-ga4";

const RegisterPin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state || {};
  const [secured, secureActions] = useCryptoKeyboard();
  const [keyArr, setKeyArr] = useState([]);
  const [prevPassword, setPrevPassword] = useState("");
  const { purpose } = locationState;
  const isUpdate = purpose === "UPDATE_SECURE_PASSWORD";

  useLayoutEffect(() => {
    shuffle();
  }, []);

  const shuffle = () => {
    const newArr = Array.from({ length: 10 }, (_, i) => i).sort(
      () => Math.random() - 0.5,
    );

    setKeyArr(Array.from({ length: 4 }).map(() => newArr.splice(0, 3)));
  };

  useEffect(() => {
    if (secured.count === 6) {
      handleClickEnter();
    }
  }, [secured.count]);

  const handleClickEnter = () => {
    if (!prevPassword) {
      setPrevPassword(secured.last);
      secureActions.clearAll();
      shuffle();
    } else {
      if (secured.last === prevPassword) {
        dispatch(showLoading());
        const clientId = getGaClientId();
        postSelfVerificationComplete(
          isUpdate
            ? {
                ...locationState,
                securePassword: secured.result,
              }
            : {
                ...locationState,
                securePassword: secured.result,
                gaInstance: clientId,
              },
        )
          .then(({ data: { success, data: verification, message } }) => {
            if (!success) {
              dispatch(showAlert({ message }));
              setPrevPassword("");
              secureActions.clearAll();
              shuffle();
            } else {
              if (isUpdate) {
                dispatch(
                  showAlert({
                    message: "결제 비밀번호 변경이 완료되었습니다.",
                  }),
                );
                signInUsingCode(
                  verification.code,
                  navigate,
                  routes.mypageChangeInfo,
                ); // 변경 후 내정보 변경 페이지로 이동
              } else {
                getAuthToken(verification.code).then(
                  ({ data: { success, data } }) => {
                    const { authToken } = data;
                    if (success) {
                      const script = document.createElement("script");
                      ReactGA.gtag("event", "sign_up");
                      if (process.env.REACT_APP_SERVICE_TYPE === "PRODUCTION") {
                        script.type = "text/javascript";
                        script.innerHTML =
                          `window.CallMtm = window.CallMtm || function () { (window.CallMtm.q = window.CallMtm.q || []).push( arguments, ); };` +
                          `CallMtm({ convType: 'join' })`;
                        document.head.appendChild(script);
                      }
                      const pointData = {
                        couponStatus: "PAYMENT",
                        eventName:
                          "[금방금방몰-이벤트] 포인트 지급(회원가입시)",
                      };
                      postEventPoint(pointData, authToken).then(
                        ({ data: { data: eventData, success } }) => {
                          dispatch(
                            showAlert({
                              message: "회원가입이 완료되었습니다.",
                              callback: () => {
                                if (
                                  process.env.REACT_APP_SERVICE_TYPE ===
                                  "PRODUCTION"
                                ) {
                                  document.head.removeChild(script);
                                }
                                if (success) {
                                  dispatch(
                                    openBottomSheet({ data: eventData }),
                                  );
                                }
                              },
                            }),
                          );
                        },
                      );
                    }
                  },
                );
                signInUsingCode(verification.code, navigate);
              }
            }
          })
          .finally(() => {
            dispatch(hideLoading());
          });
      } else {
        dispatch(
          showAlert({
            message: "결제 비밀번호가 일치하지 않습니다.\n다시 입력해주세요.",
          }),
        );
        setPrevPassword("");
        secureActions.clearAll();
        shuffle();
      }
    }
  };

  return (
    <DefaultLayout
      title={isUpdate ? "결제 비밀번호 변경" : "결제 비밀번호 등록"}
    >
      <Flex className="keypassword_wrap" flex={1}>
        <Flex className="password_view">
          <h3>
            {!prevPassword
              ? isUpdate
                ? "결제 비밀번호 변경"
                : "결제 비밀번호 등록"
              : "다시 한 번 입력해주세요"}
          </h3>
          <p>결제 시 입력할 비밀번호를 설정해주세요.</p>
          <div className="password_num">
            <ul>
              {Array.from({ length: 6 }).map((_, i) => (
                <li
                  className={secured.count > i ? "on" : ""}
                  key={i.toString()}
                />
              ))}
            </ul>
          </div>
        </Flex>
        <div className="password_key_wrap">
          <div className="password_key">
            {keyArr.map((row, i) => {
              return (
                <ul key={i.toString()}>
                  {i === 3 && (
                    <li
                      className="ft15 on pointer"
                      onClick={() => {
                        secureActions.clearAll();
                      }}
                    >
                      전체 삭제
                    </li>
                  )}

                  {row.map((key, j) => {
                    return (
                      <li
                        className="pointer"
                        key={j.toString()}
                        onClick={() => {
                          secureActions.pressKey(key);
                        }}
                      >
                        {key}
                      </li>
                    );
                  })}

                  {i === 3 && (
                    <li
                      className="pointer"
                      onClick={() => {
                        secureActions.removeOne();
                      }}
                    >
                      <img src={ic_del} alt="삭제" />
                    </li>
                  )}
                </ul>
              );
            })}
          </div>
        </div>
      </Flex>
    </DefaultLayout>
  );
};

export default RegisterPin;
