import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { postAccountCancel } from "services/apis";
import { signInUsingToken } from "services/authService";
import { showAlert } from "features/modal/modalSlice";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import DefaultLayout from "components/layouts/DefaultLayout";
import Flex from "components/common/flex/Flex";
import usePinKeyboard from "hooks/usePinKeyboard";
import ic_del from "assets/images/ic_del.png";

const DeleteAccountPin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [secured, secureActions] = usePinKeyboard();
  const [keyArr, setKeyArr] = useState([]);

  //계좌 삭제 API
  const data = { finPassword: secured.result };
  const Delete = useMutation(() => postAccountCancel(data), ["delete"], {});

  useLayoutEffect(() => {
    shuffle();
  }, []);

  const shuffle = () => {
    const newArr = Array.from({ length: 10 }, (_, i) => i).sort(
      () => Math.random() - 0.5,
    );

    setKeyArr(Array.from({ length: 4 }).map(() => newArr.splice(0, 3)));
  };

  useEffect(() => {
    if (secured.count === 6) {
      handleClickEnter();
    }
  }, [secured.count]);

  const handleClickEnter = async () => {
    dispatch(showLoading());
    try {
      const response = await Delete.mutateAsync();
      if (!response.data.success) {
        dispatch(hideLoading());
        dispatch(showAlert({ message: response.data.message }));
        secureActions.clearAll();
        shuffle();
      } else {
        dispatch(hideLoading());
        signInUsingToken(response.data.data);
        dispatch(
          showAlert({
            message: "계좌가 삭제되었습니다.",
            callback: () => {
              navigate(-1);
            },
          }),
        );
      }
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert({ message: error?.response?.data?.message }));
    }
  };

  return (
    <DefaultLayout title={"입금계좌 삭제"}>
      <Flex className="keypassword_wrap" flex={1}>
        <Flex className="password_view">
          <h3>결제 비밀번호 입력</h3>
          <p>계좌 삭제 시 필요한 결제 비밀번호를 입력해주세요.</p>
          <div className="password_num">
            <ul>
              {Array.from({ length: 6 }).map((_, i) => (
                <li
                  className={secured.count > i ? "on" : ""}
                  key={i.toString()}
                />
              ))}
            </ul>
          </div>
        </Flex>
        <div className="password_key_wrap">
          <div className="password_key">
            {keyArr.map((row, i) => {
              return (
                <ul key={i.toString()}>
                  {i === 3 && (
                    <li
                      className="ft15 on pointer"
                      onClick={() => {
                        secureActions.clearAll();
                      }}
                    >
                      전체 삭제
                    </li>
                  )}

                  {row.map((key, j) => {
                    return (
                      <li
                        className="pointer"
                        key={j.toString()}
                        onClick={() => {
                          secureActions.pressKey(key);
                        }}
                      >
                        {key}
                      </li>
                    );
                  })}

                  {i === 3 && (
                    <li
                      className="pointer"
                      onClick={() => {
                        secureActions.removeOne();
                      }}
                    >
                      <img src={ic_del} alt="삭제" />
                    </li>
                  )}
                </ul>
              );
            })}
          </div>
        </div>
      </Flex>
    </DefaultLayout>
  );
};

export default DeleteAccountPin;
