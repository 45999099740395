import Flex from "components/common/flex/Flex";
import DefaultLayout from "components/layouts/DefaultLayout";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateEmail } from "utils/utils";

const RegisterEmail = () => {
  const location = useLocation();
  const locationState = location.state || {};
  const navigate = useNavigate();
  const { email } = locationState;
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    setValidation(validateEmail(email));
  }, [email]);

  const handleChangeEmail = (email) => {
    setValidation(false);
    navigate(location.pathname, {
      replace: true,
      state: { ...locationState, email: email.split(" ").join("") },
    });
  };

  const handleClickConfirm = () => {
    if (validation) {
      navigate("/auth/sign-up/register-email", {
        replace: true,
        state: { ...locationState, email },
      });
      navigate("/auth/sign-up/register-pin", {
        state: { ...locationState, email },
      });
    }
  };

  return (
    <DefaultLayout title="회원가입">
      <Flex className="join_wrap" flex={1}>
        <div className="join_email">
          <h3>
            <span>이메일</span>을 입력해주세요.
          </h3>
          <div className="email_box">
            <p>이메일</p>
            <input
              type="text"
              placeholder="이메일을 입력하세요."
              value={email}
              onChange={({ target: { value } }) => {
                handleChangeEmail(value);
              }}
            />
          </div>
        </div>
        <button
          className={validation ? "on pointer" : "off"}
          onClick={handleClickConfirm}
        >
          확인
        </button>
      </Flex>
    </DefaultLayout>
  );
};

export default RegisterEmail;
