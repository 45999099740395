import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "features/loading/loadingSlice";
import { getLeaveReason } from "services/apis";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "./Header";
import CheckboxUnClick from "assets/icons/check-grey.webp";
import CheckboxClicked from "assets/icons/check-orange.png";
import font, { fontWeight } from "assets/styles/fonts";
import colors from "assets/styles/color";

const headerName = "탈퇴사유";
const subHeaderName = "무엇이 불편하셨나요?";

const LeaveReason = ({
  currentStep,
  setCurrentStep,
  leaveReason,
  setLeaveReason,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reasonList, setReasonList] = useState([]);

  const handleChangeRadio = (leasonValue) => {
    setLeaveReason(leasonValue);
  };

  const handleClickNext = () => {
    if (leaveReason) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    dispatch(showLoading());
    getLeaveReason()
      .then(({ data: { success, data } }) => {
        if (success) {
          const reasonList = [];
          for (let reason of data) {
            const list = reasonList.find(
              ({ title }) => title === reason.group,
            )?.list;
            if (list) {
              list.push(reason);
            } else {
              reasonList.push({ title: reason.group, list: [reason] });
            }
          }
          setReasonList(reasonList);
        }
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  return (
    <Container>
      <div>
        <Header header={headerName} subHeader={subHeaderName} />
        {reasonList.map(({ title, list }, i) => {
          return (
            <>
              <ul style={{ margin: "30px 24px" }}>
                {list.map(({ description, leaveReason: leasonValue }, j) => {
                  return (
                    <Li key={j.toString()}>
                      <CheckboxIcon
                        src={
                          leaveReason === leasonValue
                            ? CheckboxClicked
                            : CheckboxUnClick
                        }
                        alt="체크박스"
                        onClick={() => {
                          handleChangeRadio(leasonValue);
                        }}
                      />
                      <CheckText>{description}</CheckText>
                    </Li>
                  );
                })}
              </ul>
            </>
          );
        })}
      </div>
      <Flex>
        <Button
          className="pointer"
          type="CANCEL"
          onClick={() => {
            navigate(-1);
          }}
        >
          취소
        </Button>
        <Button
          disabled={!leaveReason}
          type="LEAVE"
          isChecked={leaveReason}
          onClick={() => {
            handleClickNext();
          }}
        >
          다음
        </Button>
      </Flex>
    </Container>
  );
};

export default LeaveReason;

const Container = styled.div`
  padding: 0px 0px 64px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Flex = styled.div`
  display: flex;
  margin: 0px 24px;
  gap: 8px;
`;

const Button = styled.button`
  flex: 1;
  height: 48px;
  cursor: ${(props) =>
    props.type === "LEAVE" ? (props.isChecked ? "pointer" : "") : ""};
  background-color: ${(props) =>
    props.type === "LEAVE"
      ? props.isChecked
        ? "#ff6e0e"
        : "#dddddd"
      : "#e9ecf5"};
  color: ${(props) =>
    props.type === "LEAVE"
      ? props.isChecked
        ? "#ffffff"
        : "#bbbbbb"
      : "#222222"};
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
`;

const Li = styled.li`
  display: flex;
  height: 40px;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding-left: 8px;
  margin-bottom: 4px;
  &:nth-child(2n) {
    background-color: #f3f3f3;
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

const CheckboxIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 11px;
`;

const CheckText = styled.div`
  color: ${colors.black300};
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 22px;
  font-weight: ${fontWeight.regular};
`;
