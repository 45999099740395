import { useState, useEffect } from "react";

const useResponsiveWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calculateWidth = (baseWidth) => {
    if (width <= 900) {
      return (width / 360) * baseWidth;
    }
    return (480 / 360) * baseWidth;
  };

  return { calculateWidth };
};

export default useResponsiveWidth;
