import { routes } from "routes";
import {
  getAuthToken,
  getSelfVerificationRequestIdUsingToken,
  initAuthorization,
  setAuthorization,
} from "./apis";
import { getSessionStorageItem, setSessionStorageItem } from "./storageService";
import { decodeJwt } from "utils/utils";
import { store } from "app/store";
import {
  signIn as signInAction,
  signOut as signOutAction,
} from "features/auth/authSlice";
import { showAlert } from "features/modal/modalSlice";
import { showSnackBar } from "features/snackbar/snackbarSlice";
import { showExpireAlert } from "features/expireAlert/expireAlertSlice";

// 배포 환경에 따른 도메인으로 변경
export const getServiceURL = () => {
  switch (process.env.REACT_APP_SERVICE_TYPE) {
    case "DEVELOPMENT":
      return "https://dev-mall.kumbang.co.kr";
    case "PRODUCTION":
      return "https://kbkb.kr";
    default:
      return "http://localhost:3000";
  }
};

// code 값으로 로그인 (회원가입 시 사용됨)
export const signInUsingCode = async (
  code,
  navigate,
  redirectURI,
  message,
  shouldPostMessage = false,
) => {
  const response = await getAuthToken(code);
  const {
    data: { success, data, message: errorMessage },
  } = response;

  if (success) {
    const { authToken } = data;
    const {
      name,
      roles = "NONE",
      sns,
      sub = "",
      version = "",
    } = decodeJwt(authToken);
    const ids = sub.split(":");

    // 본인인증 완료한 회원
    if (+ids[1]) {
      await signInUsingToken(
        authToken,
        navigate,
        redirectURI,
        message,
        shouldPostMessage,
      );
    } else {
      requestSelfVerification(authToken);
    }
  } else {
    store.dispatch(showAlert({ message: errorMessage }));
    navigate && navigate(routes.home);
  }
};

// token 값으로 로그인
export const signInUsingToken = (
  token,
  navigate,
  redirectURI,
  message,
  shouldPostMessage = false,
) => {
  setSessionStorageItem("accessToken", token);
  setAuthorization(token);

  // 간편 로그인을 위해 외부 페이지로 이동했을 때,
  if (shouldPostMessage) {
    let accessToken = getSessionStorageItem("accessToken");
    window.opener.postMessage(
      {
        type: "simple_login",
        accessToken,
      },
      getServiceURL(),
    );
    window.close();
    return;
  }

  const { name, sub = "", roles = "", version = "" } = decodeJwt(token);
  const ids = sub.split(":");
  const roleList = roles.split(",");
  store.dispatch(
    signInAction({
      name,
      userId: ids[1],
      socialId: ids[0],
      roles: roleList,
      version,
    }),
  );
  message && store.dispatch(showSnackBar({ message }));

  navigate &&
    navigate(
      redirectURI === "null" || redirectURI === undefined
        ? routes.home
        : redirectURI,
      { replace: true },
    );
};

export const signOut = () => {
  setSessionStorageItem("accessToken", "");
  initAuthorization();
  store.dispatch(signOutAction());
};

// 본인인증 요청
export const requestSelfVerification = (token) => {
  getSelfVerificationRequestIdUsingToken({ purpose: "SIGNUP" }, token).then(
    ({ data: { requestId } }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/mall/verification/self/start?requestId=${requestId}`;
    },
  );
};

// 토큰 만료 ( 401 )
export const expireAuth = () => {
  signOut();

  store.dispatch(showExpireAlert());
};
